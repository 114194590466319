import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axiosUtil';
import { loadingActions } from 'slices/loadingSlice';
import {
  CAR_LIST_SEARCH,
  RESERVE_TIME_SEARCH,
  SERVICE_LATITUDE_SEARCH,
  SHOP_STATUS_SEARCH,
  SERVICE_LIST_SEARCH,
  SHOP_SERVICE_SEARCH,
  SHOP_LIST_SEARCH,
  USER_WAIT_TIME,
  USER_TIRE_STORAGE,
} from 'constants/apiURL';
import { getService } from 'pages/reservation/utils/reservationUtil';
import { SHOP_LOAD_COUNT } from 'pages/reservation/shopSelect';
import dayjs from 'dayjs';

// 引数の空き状況リストの重複除去をして日付の昇順でソートする
const formingAvailabilityList = list =>
  list
    .filter((x, i, e) => e.findIndex(y => y.date === x.date) === i)
    .sort((a, b) => Number(a.date) - Number(b.date));

const initialState = {
  mapAreaHeight: 0,
  selectedSrySeq: null,
  mainShopInfo: {
    shopName: '',
    address: '',
    availableTime: null,
    confirmMenuWaitMinutes: null,
  },
  enableResort: true,
  mainShopAvailability: [],
  subShopInfoList: [],
  subShopAvailabilityList: [],
  showRankModal: false,
  showMailAddressSettingModal: false,
  showApproximateTimeUpdateModal: false,
  showVehicleRegisterModal: false,
  showShopChangeModal: false,
  selectService: null,
  carList: [],
  shopList: [],
  shopStatus: null,
  selectShop: null,
  isMainShopLoading: false,
  isSubShopLoading: true,
  subShopLoadingList: [],
  singleSubShopLoadingList: [],
  updateShop: null,
  isTireContract: false,
  subShopQueueList: [],
  subShopQueueLoadList: [],
};

export const fetchShopSelectInit = createAsyncThunk(
  'fetchShopSelectInit',
  async (params, { dispatch }) => {
    dispatch(loadingActions.setPrimaryLoading(true));

    const [shopRes, carRes, shopStatusRes, serviceRes, tireRes] =
      await Promise.all([
        axios.get(SHOP_LIST_SEARCH, { params: { kbn: '5' } }),
        axios.get(CAR_LIST_SEARCH),
        axios.get(SHOP_STATUS_SEARCH.replace('{shopCode}', params.shopCode)),
        axios.get(SERVICE_LIST_SEARCH, { params: { isMain: '0' } }),

        axios.post(USER_TIRE_STORAGE, { userId: params.userId }),
      ]);

    const shopStatus = shopStatusRes?.body?.shopStatus;

    let shopServiceRes;

    if (shopStatus === '0') {
      shopServiceRes = await axios.get(SHOP_SERVICE_SEARCH, {
        params: { shopCode: [params.shopCode] },
      });
    }

    const shopList = shopRes.body.shopList.map(shop => ({
      ...shop,
      value: shop.shopCode,
      label: shop.shopName,
    }));
    const mainShop = shopList.find(s => s.shopCode === params.shopCode);

    const serviceList = serviceRes?.body || [];
    const selectService = getService(
      serviceList,
      params.codeCombinationsList.flat()
    );

    const shopServiceList = shopServiceRes?.body?.[params.shopCode] || [];
    const shopService = getService(
      shopServiceList,
      params.codeCombinationsList.flat()
    );
    const canReserve =
      !!shopService &&
      selectService.aprosSubCategories.length ===
        shopService.aprosSubCategories.length;

    dispatch(loadingActions.setPrimaryLoading(false));

    return {
      shopList,
      carList: carRes.body.carList.map(car => ({
        ...car,
        value: car.sryMkrCd,
        label: car.sryMkrNm1,
      })),
      selectService,
      shopServiceList,
      canReserve,
      shopStatus,
      mainShopInfo: {
        shopCode: mainShop?.shopCode,
        shopName: mainShop?.shopName,
        address: mainShop?.address1 || '' + mainShop?.address2 || '',
      },
      isTireContract: tireRes?.body?.contractStatus === '1',
    };
  }
);

export const fetchMainShopAvailability = createAsyncThunk(
  'shopSelect/fetchMainShopAvailability',
  async (params, { dispatch, getState }) => {
    dispatch(actions.setMainShopLoading(true));

    const reserveTimeRes = await axios.get(RESERVE_TIME_SEARCH, {
      params,
      hiddenLoading: true,
    });

    dispatch(actions.setMainShopLoading(false));

    const {
      shopInfo,
      availableTime,
      confirmMenuWaitMinutes,
      shopName,
      shopAddress,
      tel,
      frontStatus,
    } = reserveTimeRes?.body ?? {};

    return {
      mainShopInfo: {
        shopCode: params.shopCode,
        shopName,
        address: shopAddress,
        availableTime,
        confirmMenuWaitMinutes,
        tel,
        frontStatus,
      },
      // 既存の空き状況リストに新しく取得したリストを結合する
      mainShopAvailability: formingAvailabilityList([
        ...getState().reservationShopSelect.mainShopAvailability,
        ...shopInfo,
      ]),
    };
  }
);

export const fetchSubShop = createAsyncThunk(
  'shopSelect/fetchSubShop',
  async (params, { dispatch, getState }) => {
    const allShopList = getState().reservationShopSelect.shopList;

    dispatch(actions.setSubShopLoading(true));

    const shopListRes = await axios.get(SERVICE_LATITUDE_SEARCH, {
      params: params.searchParams,
      hiddenLoading: true,
    });

    const shopList = shopListRes.body.shopList ?? [];

    let subShopInfoList = shopList
      .map(s => {
        const shop = allShopList.find(e => e.shopCode === s.shopCode);
        return {
          ...s,
          address: `${shop?.address1 || ''}${shop?.address2 || ''}`,
          date: params.date,
        };
      })
      // 店舗コードで重複排除
      .filter((v, i, s) => {
        return s.findIndex(i => i.shopCode === v.shopCode) === i;
      });

    for (const shopCode of subShopInfoList.map(s => s.shopCode)) {
      dispatch(actions.setSingleSubShopLoading({ shopCode, loading: true }));
    }

    dispatch(actions.setSubShopLoading(false));

    return {
      subShopInfoList,
      subShopAvailabilityList: subShopInfoList.map(s => ({
        shopCode: s.shopCode,
      })),
      enableResort: true,
      subShopQueueList: subShopInfoList.map(s => s.shopCode),
      subShopQueueLoadList: subShopInfoList
        .filter((s, i) => i < SHOP_LOAD_COUNT)
        .map(s => s.shopCode),
    };
  }
);

export const fetchSubShopService = createAsyncThunk(
  'shopSelect/fetchSubShopService',
  async (params, { dispatch, getState }) => {
    const selectService = getState().reservationShopSelect.selectService;

    const shopCodeList = params.shopCodeList;

    dispatch(actions.setSubShopLoadingList({ shopCodeList, loading: true }));

    const serviceRes = await axios.get(SHOP_SERVICE_SEARCH, {
      params: { shopCode: shopCodeList },
      hiddenLoading: true,
    });

    const serviceMap = serviceRes?.body || {};

    dispatch(actions.setSubShopLoadingList({ shopCodeList, loading: false }));

    return {
      serviceMap,
      codeCombinationsList: params.codeCombinationsList,
      selectService,
    };
  }
);

export const fetchUserWaitTime = createAsyncThunk(
  'shopSelect/fetchUserWaitTime',
  async params => axios.post(USER_WAIT_TIME, params)
);

export const fetchSubShopAvailability = createAsyncThunk(
  'shopSelect/fetchSubShopAvailability',
  async (params, { dispatch, getState }) => {
    const shopCode = params.shopCode;
    dispatch(actions.setSingleSubShopLoading({ shopCode, loading: true }));

    // サービスが空の場合は検索しない
    const subShopAvailabilityRes =
      params.serviceList.length === 0
        ? {}
        : await axios.get(RESERVE_TIME_SEARCH, {
            params: {
              shopCode: params.shopCode,
              date: params.date,
              chubunCd: params.chubunCd,
              shobunCd: params.shobunCd,
              userRank: params.userRank,
            },
            hiddenLoading: true,
          });

    dispatch(actions.setSingleSubShopLoading({ shopCode, loading: false }));

    // 既存の空き状況リストに新しく取得したリストを結合する
    return {
      shopCode: params.shopCode,
      value:
        params.serviceList.length === 0
          ? { shopCode: params.shopCode, availabilityList: [] }
          : {
              ...(subShopAvailabilityRes.body || {}),
              shopCode: params.shopCode,
              availabilityList: formingAvailabilityList([
                ...(getState().reservationShopSelect.subShopAvailabilityList.find(
                  s => s.shopCode === params.shopCode
                ).availabilityList || []),
                ...(subShopAvailabilityRes.body.shopInfo || []),
              ]),
            },
    };
  }
);

export const updateShopUserWaitTime = createAsyncThunk(
  'shopSelect/updateShopUserWaitTime',
  async params => {
    const userWaitTimeRes = await axios.post(USER_WAIT_TIME, params, {
      ignoreError: true,
    });

    const { getTime, menus, frontStatus } = userWaitTimeRes?.body ?? {};

    const { menuCd, confirmMenuWaitMinutes, shobun = [] } = menus?.[0] ?? {};

    return {
      confirmMenuWaitMinutes,
      getTime,
      menuCd,
      chubunCd: params.chubunCd,
      shobunCd: shobun[0]?.shobunCd,
      sgyTime: shobun[0]?.sgyTime,
      shopCode: params.shopCode,
      frontStatus,
      availableTime: dayjs(
        `${dayjs().format('YYYYMMDD')} ${getTime}`,
        'YYYYMMDD HH:mm'
      )
        .add(confirmMenuWaitMinutes ?? 0, 'minutes')
        .format('MM月DD日 HH:mm'),
    };
  }
);

export const reservationShopSelectSlice = createSlice({
  name: 'reservationShopSelect',
  initialState,
  reducers: {
    setMapAreaHeight: (state, { payload }) => {
      state.mapAreaHeight = payload;
    },
    setSrySeq: (state, { payload }) => {
      state.selectedSrySeq = payload;
    },
    setSubShopQueue: (state, { payload }) => {
      state.subShopQueueList = payload.subShopQueueList;
      state.subShopQueueLoadList = payload.subShopQueueLoadList;
    },
    setMainShopLoading: (state, { payload }) => {
      state.isMainShopLoading = payload;
    },
    setEnableResort: (state, { payload }) => {
      state.enableResort = payload;
    },
    setSubShopLoading: (state, { payload }) => {
      state.isSubShopLoading = payload;
    },
    setSubShopServiceLoading: (state, { payload }) => {
      state.isSubShopServiceLoading = payload;
    },
    setSubShopLoadingList: (state, { payload }) => {
      if (payload.loading) {
        state.subShopLoadingList = [
          ...state.subShopLoadingList,
          ...payload.shopCodeList,
        ];
      } else {
        state.subShopLoadingList = [
          ...state.subShopLoadingList.filter(
            shopCode => !payload.shopCodeList.includes(shopCode)
          ),
        ];
      }
    },
    setSingleSubShopLoading: (state, { payload }) => {
      if (payload.loading) {
        state.singleSubShopLoadingList = [
          ...state.singleSubShopLoadingList,
          payload.shopCode,
        ];
      } else {
        state.singleSubShopLoadingList = [
          ...state.singleSubShopLoadingList.filter(
            shopCode => shopCode !== payload.shopCode
          ),
        ];
      }
    },
    showRankModal: state => {
      state.showRankModal = true;
    },
    setSubShopOneClickDisable: (state, { payload }) => {
      const targetIndex = state.subShopAvailabilityList.findIndex(
        l => l.shopCode === payload.shopCode
      );

      if (targetIndex > -1) {
        state.subShopAvailabilityList[targetIndex].oneClickDisabled =
          payload.oneClickDisabled;
      }
    },
    setMainShopOneClickDisable: (state, { payload }) => {
      state.mainShopOneClickDisabled = payload;
    },
    updateSubShopSearchDate: (state, { payload }) => {
      const updateIndex = state.subShopInfoList.findIndex(
        s => s.shopCode === payload.shopCode
      );
      state.subShopInfoList[updateIndex].date = payload.searchDate;
      state.updateShop = state.subShopInfoList[updateIndex].shopCode;
    },
    clearUpdateShop: state => {
      state.updateShop = null;
    },
    updateSubShopSort: (state, { payload }) => {
      state.subShopInfoList = payload;
      state.enableResort = false;
    },
    showMailAddressSettingModal: (state, { payload }) => {
      state.showMailAddressSettingModal = true;
      state.selectedShopInfo = payload;
    },
    successCloseMailAddressSettingModal: state => {
      state.showMailAddressSettingModal = false;
    },
    showApproximateTimeUpdateModal: state => {
      state.showApproximateTimeUpdateModal = true;
    },
    showVehicleRegisterModal: (state, { payload }) => {
      state.showVehicleRegisterModal = true;
      state.vehicleCloseCallback = payload;
    },
    showShopChangeModal: state => {
      state.showShopChangeModal = true;
    },
    selectShop: (state, { payload }) => {
      state.selectShop = payload.shopInfo;
      state.isMainShop = payload.isMainShop;
    },
    updateWaitTime: (state, { payload }) => {
      state.selectShop = {
        ...state.selectShop,
        ...payload,
      };
      if (payload.isMainShop) {
        state.mainShopInfo = {
          ...state.mainShopInfo,
          ...payload,
        };
      } else {
        const index = state.subShopInfoList.findIndex(
          s => s.shopCode === payload.shopCode
        );
        state.subShopInfoList[index] = {
          ...state.subShopInfoList[index],
          ...payload,
        };
      }
    },
    closeModal: state => {
      state.showRankModal = false;
      state.selectShop = null;
      state.showMailAddressSettingModal = false;
      state.showApproximateTimeUpdateModal = false;
      state.showVehicleRegisterModal = false;
      state.vehicleCloseCallback = null;
      state.showShopChangeModal = false;
      state.selectedShopInfo = null;
    },
    closeVehicleRegisterModal: state => {
      state.showVehicleRegisterModal = false;
      state.vehicleCloseCallback = null;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchShopSelectInit.fulfilled, (state, { payload }) => {
      state.shopList = payload.shopList;
      state.carList = payload.carList;
      state.selectService = payload.selectService;
      state.mainShopInfo = payload.mainShopInfo;
      state.mainShopInfo.canReserve = payload.canReserve;
      state.mainShopInfo.serviceList = payload.shopServiceList;
      state.shopStatus = payload.shopStatus;
      state.isTireContract = payload.isTireContract;
    });
    builder.addCase(
      fetchMainShopAvailability.fulfilled,
      (state, { payload }) => {
        state.mainShopAvailability = payload.mainShopAvailability;
        state.mainShopInfo = {
          ...payload.mainShopInfo,
          canReserve: state.mainShopInfo.canReserve,
          serviceList: state.mainShopInfo.serviceList,
        };
      }
    );
    builder.addCase(fetchSubShopService.fulfilled, (state, { payload }) => {
      const serviceMap = payload.serviceMap;
      for (const key in serviceMap) {
        const index = state.subShopInfoList.findIndex(s => s.shopCode === key);
        if (index > -1) {
          const shopService = getService(
            serviceMap[key],
            payload.codeCombinationsList.flat()
          );
          const canReserve =
            shopService &&
            payload.selectService.aprosSubCategories.length ===
              shopService.aprosSubCategories.length;
          state.subShopInfoList[index].serviceList = canReserve
            ? serviceMap[key]
            : [];
        }
      }
    });

    builder.addCase(
      fetchSubShopAvailability.fulfilled,
      (state, { payload }) => {
        const shopCode = payload.shopCode;
        const index = state.subShopAvailabilityList.findIndex(
          s => s.shopCode === shopCode
        );
        state.subShopAvailabilityList[index] = payload?.value;
        if (payload?.value?.shopAddress) {
          const subShopIndex = state.subShopInfoList.findIndex(
            s => s.shopCode === shopCode
          );
          state.subShopInfoList[subShopIndex].address =
            payload?.value?.shopAddress;
        }
      }
    );

    builder.addCase(fetchSubShop.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(updateShopUserWaitTime.fulfilled, (state, { payload }) => {
      state.selectShop = {
        ...state.selectShop,
        ...payload,
      };
      if (state.mainShopInfo.shopCode === payload.shopCode) {
        state.mainShopInfo = {
          ...state.mainShopInfo,
          ...payload,
        };
      } else {
        const index = state.subShopAvailabilityList.findIndex(
          s => s.shopCode === payload.shopCode
        );
        state.subShopAvailabilityList[index] = {
          ...state.subShopAvailabilityList[index],
          ...payload,
        };
      }
    });
  },
});

export const actions = reservationShopSelectSlice.actions;
export default reservationShopSelectSlice.reducer;
