export const commonPrecautions = (
  <div>
    ＜ご注意＞
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        各種作業における作業工賃につきましては、お申込店舗にご確認ください。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　　</p>
      <p className="whitespace-pre-line">
        ※メンテナンスオプション加入者様は、オイル交換工賃などが無料です。
      </p>
    </div>
    <div>
      　　
      <a
        className="text-link underline after:bottom-0.5 after:w-full after:border-b after:border-solid
    after:border-link hover:text-link-hover after:hover:border-link-hover"
        target="_blank"
        href="https://www.autobacs.com/static_html/info/card/pointup_card.html"
      >
        ※詳細はこちら
      </a>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        オイル、フィルターなどの商品は当日お選びいただきますが、車種によっては在庫を持ち合わせておらず、お取り寄せ対応になる場合がございます。また車種や作業内容によっては、お申込店舗で作業が出来ない場合がございます。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        タイヤ交換をご希望の場合、盗難防止用ロックナットをご利用のお客様は、必ずアダプターをご持参ください。アダプターが無い場合は、作業を行う事が出来ません。
      </p>
    </div>
    ＜お願い＞
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        予約時間の15分前にはご来店いただき、受付していただきます様お願いします。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　　</p>
      <p className="whitespace-pre-line">
        ※開店時刻と同時刻にご予約いただいた場合は、開店時刻に受付をお願いします。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        店舗の状況により、予約時間が多少前後する場合がございます。予めご了承のほどお願いします。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        タイヤ交換作業をご希望の場合、作業当日に、すでにお持ちの「タイヤ」もしくは「タイヤホイールセット」「ナット（純正など）」をお持ちください。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        タイヤ交換の際に、お持ちのタイヤの状態によっては、作業をお断りする場合がございます。
      </p>
    </div>
    ＜キャンセル・変更について＞
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        予約された時間に連絡無くご来店いただけない場合は、キャンセルとさせていただきます。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        WEBからのキャンセルの受付は、作業予約日の前日12:00までとなります。以降は店舗に直接電話連絡をお願いいたします。
      </p>
    </div>
    <div>＜ご予約確定後＞</div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        ご予約の内容について、店舗より電話にて確認をさせていただく場合がございます。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        ご指定のメールアドレスに確認メールを送信いたしますが、念のため表示される予約番号をお控えください。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        携帯メールのドメイン指定受信等（迷惑メール設定）をされている方は、以下のメールアドレスからの受信ができる様に、設定をお願いいたします。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　　</p>
      <p className="whitespace-pre-line">
        送信元アドレス：rsv-info@autobacs.com※設定を行わない場合、予約受付通知・前日のご案内などのメールが届かない場合がございます。
      </p>
    </div>
    ＜不正改造車について＞
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        オートバックスでは不正改造車（ランプの色変更・騒音マフラー・着色フィルム・はみ出しタイヤ・極端のローダウン）に対する交換・取付・その他一般作業をお断りする場合がございます。
        受付時もしくは作業開始時にご確認させていただきますので、予めご了承ください。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　・</p>
      <p className="whitespace-pre-line">
        合法・違法の基準など詳しくはお問合せ下さい。（運転・助手席・フロントガラスのフィルム施工は、
        透過率70％以上をクリアできるUVカットフィルム等以外は貼れません。
      </p>
    </div>
    <div className="flex flex-row">
      <p>　　</p>
      <p className="whitespace-pre-line">
        純正ガラスも着色されている車種もございますのでご注意ください。
      </p>
    </div>
    <div>
      　　
      <a
        className="text-link underline after:bottom-0.5 after:w-full after:border-b after:border-solid
    after:border-link hover:text-link-hover after:hover:border-link-hover"
        target="_blank"
        href="https://yoyaku.autobacs.jp/pit_reserve/fusei.html"
      >
        ※詳細はこちら
      </a>
    </div>
  </div>
);

export const carPrecautions = (
  <p>
    ★ご注意★
    <br />
    エアバッグのリコール未改修の場合、車検が通らなくなります！　※２０１８年５月より
    <br />
    国交省　2017.12.8　「エアバッグのリコール未改修車両を車検で通さない措置を開始します」
    <br />
    ↓国土交通省作成　リーフレット
    <br />
    http://www.mlit.go.jp/jidosha/carinf/rcl/common/data/syaken_teisi_leaflet.pdf
    <br />
    お持ちの車が対象となるかは、以下リンクの検索システムを活用してご確認ください
    <br />
    ↓一般社団法人日本自動車整備振興会連合会ＨＰより
    <br />
    https://www.jaspa.or.jp/portals/recallsearch/index.html
    <br />
    輸入車については以下リンクでご確認ください ↓国土交通省ＨＰより
    <br />
    http://www.mlit.go.jp/jidosha/carinf/rcl/recallinfo_004.html  
    <br />
    <br />
    注意事項
    <br />
    1.改造車について／保安基準・道路運送車両法に適合しない車両については車検の受付をいたしかねます。
    <br />
    <a
      className="text-link underline after:bottom-0.5 after:w-full after:border-b after:border-solid
      after:border-link hover:text-link-hover after:hover:border-link-hover"
      target="_blank"
      href="https://yoyaku.autobacs.jp/pit_reserve/fusei.html"
    >
      ※詳細はコチラ
    </a>
    <br />
    2.入庫車検時に、お車の状態によっては受付日に検査が完了できない場合がございます。
    <br />
    3.時間を要する交換部品が必要となった場合などは、準備が整い次第、改めてご来店をお願いする場合もありますので、あらかじめご了承ください。
    <br />
    4.ご予約時間の１０～１５分前にはご来店いただき、受付をしていただけるようお願いします。
    <br />
    5.開店時刻と同時刻にご予約いただいた場合は、開店時刻にて受付をお願いします。
    <br />
    6.予約された時間に連絡無くご来店いただけない場合はキャンセルとさせていただきます。
    <br />
    7.ご予約のキャンセルは、予約完了時にお送りしているメールに記載のURL、オートバックス公式アプリの予約確認ページ、ご予約いただいた店舗へのお電話にて受け付けております。
    <br />
    8.アプリからの予約キャンセルは、予約前日の12:00までにお願いいたします。
    <br />
    9.当日の作業状況により、作業開始時間が多少前後することがあります。予めご了承ください。
    <br />
    10.特種用途改造車（８ナンバー）、貨物１ナンバー車の受入に関しては、WEB予約は承っておりません。以下、車検コンタクトセンターまでご連絡下さい。
    <br />
    11.同時希望作業やお持込作業などの質問は、ご予約店舗迄直接お問い合わせをお願いいたします。
  </p>
);
