import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { RANK_NAMES, RANK_ICONS } from 'constants/rank';
import Icons from 'images/icons';
import { useDeviceSize } from 'hooks/useDeviceSize';

const UserNameLabel = ({ className }) => {
  const {
    accountInfo: { rankCode, userName },
  } = useSelector(state => state.account);

  const { isSp } = useDeviceSize();

  return (
    <div className={classNames('flex flex-row items-center', className)}>
      <Icons.Image
        src={RANK_ICONS[rankCode]}
        className="h-10 object-contain lg:h-12"
      />
      {isSp ? (
        <div className="ml-2 flex max-w-[300px] flex-col break-words">
          <div className="flex">
            <div className="flex flex-col">
              <label className="lg:text-base text-sm font-bold">
                {`${userName}様は`}
              </label>
              <div className="flex flex-row">
                <label className="lg:text-lg text-base font-bold">
                  {RANK_NAMES[rankCode]}
                </label>
                <label className="lg:text-base text-sm ml-2 self-end font-bold">
                  会員です
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ml-2 flex flex-col whitespace-nowrap sm:flex-row sm:flex-wrap">
          <div className="flex whitespace-nowrap sm:mr-4">
            <div className="flex flex-row">
              <label className="lg:text-base text-sm self-end font-bold">
                {`${userName}様は`}
              </label>
              <label className="lg:text-lg text-base ml-2 self-end font-bold">
                {RANK_NAMES[rankCode]}
              </label>
              <label className="lg:text-base text-sm ml-2 self-end font-bold">
                会員です
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserNameLabel;
