/**
 * 汎用メッセージモーダル用Slice
 */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMessageModalOpen: false,
  message: '',
  btnText: '閉じる',
  onClick: null,
  isToastModalOpen: false,
  toastMessage: '',
  onClose: null,
  isLeaveConfirmModalOpen: false,
  onClickTransition: null,
  onClickCancel: null,
  closeSameWithClick: false,
  showTermsConfirmModal: false,
  isTireStorageModalOpen: false,
  isOpenCalendar: false,
  item: null,
  subItem: null,
  callBack: null,
};

export const messageModalSlice = createSlice({
  name: 'messageModal',
  initialState,
  reducers: {
    showMessageModal: (state, { payload }) => {
      state.isMessageModalOpen = true;
      state.message = payload.message;
      state.btnText = payload.btnText;
      state.onClick = payload.onClick;
      state.closeSameWithClick = payload.closeSameWithClick;
    },
    showToastModal: (state, { payload }) => {
      state.isToastModalOpen = true;
      state.toastMessage = payload.message;
      state.onClose = payload.onClose;
    },
    showLeaveConfirmModalOpen: (state, { payload }) => {
      state.isLeaveConfirmModalOpen = true;
      state.onClickTransition = payload.onClickTransition;
      state.onClickCancel = payload.onClickCancel;
    },
    closeMessageModal: state => {
      state.isMessageModalOpen = false;
      state.message = '';
      state.onClick = null;
      state.closeSameWithClick = false;
    },
    closeToastModal: state => {
      state.isToastModalOpen = false;
      state.toastMessage = '';
      state.onClose = null;
    },
    closeLeaveConfirmModalOpen: state => {
      state.isLeaveConfirmModalOpen = false;
      state.onClick = null;
      state.onClickCancel = null;
    },
    showTermsConfirmModal: state => {
      state.showTermsConfirmModal = true;
    },
    closeTermsConfirmModal: state => {
      state.showTermsConfirmModal = false;
    },
    showTireStorageModal: (state, {payload}) => {
      state.isTireStorageModalOpen = true;
      if(payload){
      state.isOpenCalendar = payload.isOpenCalendar;
      state.item = payload.item;
      state.subItem = payload.subItem;
    }
    },
    closeTireStorageModal: state => {
      state.isTireStorageModalOpen = false;
      state.callBack = null;
    },
    checkTireStorageModal: (state, {payload}) => {
      state.isTireStorageModalOpen = true;
      state.callBack = payload;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {},
});

export const messageModalActions = messageModalSlice.actions;
export default messageModalSlice.reducer;
