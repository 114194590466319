import Icons from 'images/icons';

export const RANK_BRONZE = '01';
export const RANK_SAPPHIRE = '02';
export const RANK_DIAMOND = '03';

export const RANK_NAMES = {
  [RANK_BRONZE]: 'ブロンズ',
  [RANK_SAPPHIRE]: 'サファイア',
  [RANK_DIAMOND]: 'ダイヤモンド',
};

export const RANK_ICONS = {
  [RANK_BRONZE]: Icons.bronze,
  [RANK_SAPPHIRE]: Icons.sapphire,
  [RANK_DIAMOND]: Icons.diamond,
};

/** 当日何時から予約可能か */
export const RANK_TIME = {
  [RANK_BRONZE]: 10,
  [RANK_SAPPHIRE]: 9,
  [RANK_DIAMOND]: 0,
};

export const RANK_ARTICLE = {
  [RANK_BRONZE]:
    'ブロンズ会員は10時から予約受付を開始します。10時以降に画面を更新してください。※ご希望の時間帯が空いていない場合もございます。',
  [RANK_SAPPHIRE]:
    'サファイア会員は9時から予約受付を開始します。9時以降に画面を更新してください。※ご希望の時間帯が空いていない場合もございます。',
  [RANK_DIAMOND]:
    'ダイヤモンド会員はいつでも予約を受け付けています。※ご希望の時間帯が空いていない場合もございます。',
};

export const RankDescription = rank => (
  <>
    <label className="text-sm sm:text-base mt-4 flex flex-col font-bold">
      当日の予約受付について
    </label>
    <div className="text-sm sm:text-base mt-2">{`${RANK_ARTICLE[rank]}`}</div>
  </>
);

export const RANK_BENEFIT = rank =>
  `${RANK_NAMES[rank]}会員特典：当日${RANK_TIME[rank]}時～予約可能`;

/** ランク閾値 */
export const RANK_BORDER = {
  [RANK_BRONZE]: 0,
  [RANK_SAPPHIRE]: 50000,
  [RANK_DIAMOND]: 200000,
};
