// トップ画面: 「今日」押下時のカスタムイベント名
export const TOP_SELECT_SERVICE_TODAY = 'custom.top_select-service_today';
// トップ画面: 「明日以降」押下時のカスタムイベント名
export const TOP_SELECT_SERVICE_AFTER_TOMORROW =
  'custom.top_select-service_after-tomorrow';
// トップ画面: ピットサービスから予約の画面遷移時のカスタムイベント名
export const TOP_PIT_SELECT_SERVICE = 'custom.top_pit-select-service';
// ログイン画面: 「ログイン」押下時のカスタムイベント名
export const LOGIN = 'custom.login';
// 店舗選択画面: 新規車両追加モーダルを閉じる時のカスタムイベント名
export const SHOP_SELECT_ADD_CAR_MODAL_CLOSE =
  'custom.shop-select_add-car-modal-close';
// 店舗選択画面: カレンダー「〇」押下時のカスタムイベント名
export const SHOP_SELECT_CALENDAR_CIRCLE = 'custom.shop-select_calendar-circle';
// 店舗選択画面: 「サービスを選び直す」押下時のカスタムイベント名
export const SHOP_SELECT_CHANGE_SERVICE = 'custom.shop-select_change-service';
// 日時選択画面: 日時変更時のカスタムイベント名
export const DATE_SELECT_CHANGE_DATE = 'custom.date-select_change-date';
// 日時選択画面: カレンダー「○」押下時のカスタムイベント名
export const DATE_SELECT_CALENDAR_CIRCLE = 'custom.date-select_calendar-circle';
// 日時選択画面: カレンダー「□」押下時のカスタムイベント名
export const DATE_SELECT_CALENDAR_SQUARE = 'custom.date-select_calendar-square';
// 日時選択画面: 「□」モーダル内の電話番号押下時のカスタムイベント名(SPのみ)
export const DATE_SELECT_TEL = 'custom.date-select_tel';
// メールアドレス更新: 「予約を確定する」押下時のカスタムイベント名
export const UPDATE_MAIL_ADDRESS_RESERVE = 'custom.update-mail-address_reserve';
// メールアドレス更新: モーダルを閉じる時のカスタムイベント名
export const UPDATE_MAIL_ADDRESS_CLOSE = 'custom.update-mail-address_close';
// ピットサービス予約画面: サービス選択時のカスタムイベント名
export const PIT_SELECT_SERVICE = 'custom.pit_select-service';
// ピットサービス予約画面: モーダルを閉じる時のカスタムイベント名
export const PIT_SELECT_SERVICE_CLOSE = 'custom.pit_select-service_close';
// カルテ画面(ログイン済): 車種変更時のカスタムイベント名
export const KARTE_SELECT_CAR = 'custom.karte_select-car';
// カルテ画面(ログイン済): 「予約へ進む」押下時のカスタムイベント名
export const KARTE_SHOW_DETAIL_SERVICE = 'custom.karte_show-detail-service';
// カルテ画面(ログイン済): 「詳細」押下時のカスタムイベント名
export const KARTE_SELECT_SERVICE = 'custom.karte_select-service';
// 予約完了画面遷移時のカスタムイベント名
export const PITYOYAKU_RESERVE_COMPLETE_CV =
  'custom.pityoyaku_reserve_complete_CV';
