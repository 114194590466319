import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import ModalBase from 'components/modalBase';
import BacsProgress from 'components/bacsProgress';
import Linked from 'components/linked';
import Icons from 'images/icons';
import Path from 'constants/routePath';
import {
  RANK_BRONZE,
  RANK_SAPPHIRE,
  RANK_DIAMOND,
  RANK_NAMES,
  RANK_TIME,
  RANK_BORDER,
} from 'constants/rank';
import AprosButton from 'components/aprosButton/index';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { numberFormat } from 'utils/stringUtil';

const ICON_NAME = {
  [RANK_BRONZE]: Icons.timeBronze,
  [RANK_SAPPHIRE]: Icons.timeSapphire,
  [RANK_DIAMOND]: Icons.timeDiamond,
};

const RankModal = ({ isModalOpen, closeModal, rank, point }) => {
  const [leastRank, setLeastRank] = useState(null);

  useEffect(() => {
    if (rank === RANK_SAPPHIRE) {
      // サファイア会員の場合、ダイヤモンド会員表示
      setLeastRank(RANK_DIAMOND);
    } else if (rank === RANK_BRONZE && Number(dayjs().format('Hmm')) >= 900) {
      // ブロンズ会員で9時以降の場合、サファイア会員表示
      setLeastRank(RANK_SAPPHIRE);
    } else if (rank === RANK_BRONZE) {
      // ブロンズ会員で9時以前の場合、ダイヤモンド会員表示
      setLeastRank(RANK_DIAMOND);
    }
  }, [rank]);

  const { isSp } = useDeviceSize();

  const nextRankBorder = rank === RANK_DIAMOND ? 0 : RANK_BORDER[leastRank];

  const createIcon = displayRank => (
    <div
      className={classNames(
        'relative flex flex-row items-center justify-center gap-x-4 p-2 sm:flex-col sm:pt-6',
        rank === displayRank ? 'mt-5 border border-line sm:mt-0' : ''
      )}
    >
      {rank === displayRank && (
        <label className="text-xs absolute top-0 mt-[-15px] bg-navy px-2 py-1 font-medium text-white">
          現在のランク
        </label>
      )}
      <Icons.Image
        src={ICON_NAME[displayRank]}
        className="h-[120px] w-[120px]"
      />
      <div className="flex flex-col sm:mt-3 sm:text-center">
        <label className="text-sm font-bold">
          {RANK_NAMES[displayRank]}会員
        </label>
        <label className="text-sm">{`当日${RANK_TIME[displayRank]}時〜予約が可能`}</label>
      </div>
    </div>
  );

  const createArrow = () => {
    return (
      <Icons.Image
        src={Icons.pulldown}
        className={isSp ? '' : 'mt-[78px] -rotate-90'}
        style={{
          alignSelf: isSp ? 'center' : 'start',
        }}
      />
    );
  };

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={closeModal}
      className="mx-4 flex w-full flex-col text-navy"
    >
      <div
        id="modal"
        className="scrollBar relative my-2 mr-1 overflow-auto px-4 pb-11 pt-[60px] sm:px-7 sm:pb-11 sm:pt-[66px] lg:mr-2 lg:px-20 lg:pb-[60px] lg:pt-14"
      >
        <div className="absolute right-0 top-0 py-2 pr-2">
          <Icons.Image
            src={Icons.closeModal}
            className="float-right h-8 cursor-pointer lg:h-[50px]"
            onClick={closeModal}
          />
        </div>

        <div className="flex flex-col">
          <label className="text-base text-center font-bold">
            {RANK_NAMES[leastRank]}会員以上で予約可能です
          </label>
          <label className="text-sm text-center font-medium">
            ご利用には会員ランクのアップが必要です
          </label>
        </div>
        <div className="mt-4 flex flex-col items-center justify-between gap-y-2 sm:mt-12 sm:flex-row">
          {createIcon(RANK_BRONZE)}
          {createArrow()}
          {createIcon(RANK_SAPPHIRE)}
          {createArrow()}
          {createIcon(RANK_DIAMOND)}
        </div>
        <div className="mt-6 flex flex-col sm:mt-8">
          <div className="flex flex-row items-end justify-between">
            <label className="text-sm">現在のバックス</label>
            <div className="flex flex-row items-end">
              <label className="text-base mr-2 font-bold sm:leading-7">
                {numberFormat(point)}
              </label>
              <label className="text-sm">バックス</label>
            </div>
          </div>
          <BacsProgress percent={(point / nextRankBorder) * 100} />
          <div className="text-sm flex flex-row items-end justify-between self-center">
            {RANK_NAMES[leastRank]}会員まであと{' '}
            {numberFormat(nextRankBorder < point ? 0 : nextRankBorder - point)}{' '}
            バックス
          </div>
          <div className="text-xs flex flex-row items-end justify-between self-center text-navy-light">
            ※会員ランクは毎月末日時点での保有バックスで決定します
          </div>
          <Linked
            text="ランクアップには"
            href={Path.RANK_UP_INFO}
            isLine={true}
            linkIcon="R"
            className="text-sm mt-2 self-end font-bold sm:mt-4"
          />
          <div className="mt-6 self-center sm:mt-10">
            <AprosButton
              text="閉じる"
              comClassName="sub"
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

export default RankModal;
