import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MAINTENANCE } from 'constants/apiURL';
import axios from 'utils/axiosUtil';

const initialState = {
  maintenanceInfo: null,
};

export const fetchMaintenanceInfo = createAsyncThunk(
  'fetchMaintenanceInfo',
  async () => await axios.get(MAINTENANCE)
);

export const maintenanceSlice = createSlice({
  name: 'maintenanceSlice',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchMaintenanceInfo.fulfilled, (state, { payload }) => {
      state.maintenanceInfo = payload?.body || {};
    });
  },
});

export const maintenanceActions = maintenanceSlice.actions;
export default maintenanceSlice.reducer;
