import React from 'react';
import Icons from 'images/icons';

const Proctitle = ({
  text,
  icon,
  textSize,
  className = {},
  useH1 = false,
  useH2 = false,
}) => {
  let size;
  switch (textSize) {
    case 'l':
      // 小分類モーダル使っている
      size = 'text-[22px] sm:text-[26px] lg:text-[32px]';
      break;
    case 'sl':
      // 小分類モーダルとか使っている
      size = 'text-[20px] sm:text-[30px] lg:text-[32px]';
      break;
    case 'pitSub':
      // ピットサービスから予約画面サブタイトル
      size = 'text-[18px] sm:text-[24px] lg:text-[32px]';
      break;
    default:
      size = 'text-[22px] sm:text-[30px] lg:text-[46px]';
      break;
  }

  const titleContent = (
    <>
      {icon ? (
        <Icons.Image
          src={icon}
          className={`h-[unset] ${className.iconClass}`}
        />
      ) : (useH1 || useH2) ? (
        <div className="h-[unset] w-[6px] bg-red lg:w-[10px]"></div>
      ) : (
        <div></div>
      )}
      <div
        className={`${size} flex items-center font-bold text-navy ${className.textClass}`}
      >
        {text}
      </div>
    </>
  );

  return useH1 ? (
    <h1 className={`flex gap-x-[6px] lg:gap-x-4 ${className.areaClass}`}>
      {titleContent}
    </h1>
  ) : useH2 ? (
    <h2 className={`flex gap-x-[6px] lg:gap-x-4 ${className.areaClass}`}>
      {titleContent}
    </h2>
  ) : (
    <div className={`flex gap-x-[6px] lg:gap-x-4 ${className.areaClass}`}>
      {titleContent}
    </div>
  );
};

export default Proctitle;
