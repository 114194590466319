import { memo, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useDeviceSize } from 'hooks/useDeviceSize';
import Icons from 'images/icons';

const DATE_KBN_DAY_COLOR_CLASS = {
  0: 'bg-background-sub text-navy',
  1: 'bg-calendar-saturday text-link',
  2: 'bg-calendar-sunday text-red',
  3: 'bg-calendar-sunday text-red',
};

const DATE_KBN_WEEK_COLOR_CLASS = {
  0: 'bg-navy/10 text-navy',
  1: 'bg-calendar-saturday text-link',
  2: 'bg-calendar-sunday text-red',
  3: 'bg-calendar-sunday text-red',
};

const DATE_KBN_AVAILABILITY_COLOR_CLASS = {
  0: '',
  1: 'bg-calendar-saturday',
  2: 'bg-calendar-sunday',
  3: 'bg-calendar-sunday',
};

const AVAILABILITY_ICON = {
  '01': Icons.icon_OK_b,
  '02': Icons.icon_OK_s,
  '03': Icons.icon_OK_d,
};

export default memo(
  ({
    dailyInfoList = [],
    rank = null,
    isModifyTodayReservation = false,
    className,
    // 前週リンク押下時
    onClickLeft,
    // 次週リンク押下時
    onClickRight,
    // 〇マーク押下時
    onClickCircle,
    // □マーク押下時
    onClickSquare,
    // 鍵マーク押下時
    onClickLock,
  }) => {
    const firstRef = useRef(null);
    const { isPc, isSp } = useDeviceSize();

    const [_dailyInfoList, setDailyInfoList] = useState([]);
    const [baseDate, setBaseDate] = useState(null);
    const [outIndex, setOutIndex] = useState(-1);
    const [isLeftLinkDisplay, setLeftLinkDisplay] = useState(-1);

    useEffect(() => {
      dailyInfoList.length > 0 &&
        setDailyInfoList(dailyInfoList.filter((_, i) => i < (isPc ? 14 : 7)));
    }, [dailyInfoList, isPc]);

    useEffect(() => {
      setBaseDate(dayjs(_dailyInfoList?.[0]?.date));
      setOutIndex(_dailyInfoList.findIndex(i => i.outOfTermMessage));
      setLeftLinkDisplay(
        dayjs().format('YYYYMMDD') < _dailyInfoList?.[0]?.date
      );
    }, [_dailyInfoList]);

    const createLink = (text, onClick, isDisplay) => (
      <div
        onClick={() => isDisplay && onClick && onClick()}
        className={classNames(
          'text-sm group flex items-center border-b border-white text-white',
          isDisplay
            ? 'cursor-pointer hover:text-white-hover'
            : 'cursor-default opacity-0'
        )}
      >
        {text === '前週' && (
          <Icons.Image src={Icons.linkLW} className="group-hover:opacity-70" />
        )}
        {text}
        {text === '次週' && (
          <Icons.Image src={Icons.linkRW} className="group-hover:opacity-70" />
        )}
      </div>
    );

    const createAvailability = (shopInfo, i) =>
      shopInfo.date + shopInfo.hm < dayjs().format('YYYYMMDDHH:mm') ? (
        // 過去時刻は空欄
        <></>
      ) : shopInfo.availability === '〇' ? (
        // 〇の場合、予約可能
        <div
          onClick={() => onClickCircle && onClickCircle(shopInfo)}
          className="mx-2 my-4 cursor-pointer self-center"
        >
          <Icons.Image
            src={
              shopInfo.date === dayjs().format('YYYYMMDD') &&
              rank &&
              !isModifyTodayReservation
                ? AVAILABILITY_ICON[rank]
                : Icons.icon_OK
            }
            className="h-5 w-5"
          />
        </div>
      ) : shopInfo.availability === '×' ? (
        // ×の場合、■を表示して問い合わせさせる
        <div
          onClick={() => onClickSquare && onClickSquare(i)}
          className="mx-2 my-4 cursor-pointer self-center"
        >
          {shopInfo.closedFlag !== '1' && (
            <Icons.Image src={Icons.icon_NG02} className="h-5 w-5" />
          )}
        </div>
      ) : (
        // 一致しなかったら空欄
        <></>
      );

    // 店休日/鍵マークを生成
    const createDisabledArea = (dailyInfo, i) => {
      const { showKeyFlag, closedFlag } = dailyInfo;
      const isDisplay =
        (outIndex === -1 || i < outIndex) &&
        (showKeyFlag === '1' || closedFlag === '1');
      return (
        isDisplay && (
          <div
            className="absolute h-full w-full overflow-hidden bg-navy/60"
            style={{
              height: 'calc(100% - 1px)',
            }}
          >
            <div
              className="text-lg flex h-full w-full items-center pt-[53px] tracking-widest text-white lg:pt-[51px]"
              style={{ writingMode: 'vertical-lr' }}
            >
              {closedFlag === '1'
                ? '店休日'
                : showKeyFlag === '1' && (
                    <Icons.Image
                      src={Icons.lock}
                      className="h-[32px] w-[28px] cursor-pointer lg:h-[49px] lg:w-[35px]"
                      onClick={() => onClickLock()}
                    />
                  )}
            </div>
          </div>
        )
      );
    };

    // 選択不可領域を生成
    const createMultiDisabledArea = (dailyInfo, i) => {
      const { outOfTermMessage } = dailyInfo;
      const isDisplay = outIndex !== -1 && i >= outIndex;
      return (
        isDisplay && (
          <>
            <div
              className="absolute h-full w-full overflow-hidden bg-navy/60"
              style={{
                height: 'calc(100% - 1px)',
                marginLeft: i === outIndex ? 1 : 0,
              }}
            ></div>
            {outIndex === i && (
              <div
                className="text-lg absolute z-10 mx-auto flex h-full w-full items-center pt-[53px] text-white lg:pt-[51px]"
                style={{
                  writingMode: 'vertical-lr',
                  marginLeft:
                    firstRef?.current?.offsetWidth *
                    ((_dailyInfoList.length - outIndex - 1) / 2),
                }}
              >
                {outOfTermMessage}
              </div>
            )}
          </>
        )
      );
    };

    return (
      <div className={classNames('relative border-line', className)}>
        {/* ヘッダ表示（固定） */}
        <div className="sticky top-0 z-20">
          <div
            className={classNames(
              'flex h-14 w-full flex-row items-center justify-center border-line bg-navy',
              isSp ? 'border-y' : 'border'
            )}
          >
            {createLink(
              '前週',
              () => {
                const newDate = dayjs
                  .max(dayjs(), dayjs(baseDate).add(isPc ? -2 : -1, 'w'))
                  .format('YYYY/M/D');
                onClickLeft(newDate);
              },
              isLeftLinkDisplay
            )}
            <label className="text-base mx-8 text-white">
              {baseDate?.add((isPc ? 7 : 4) - 1, 'd')?.format('YYYY年M月')}
            </label>
            {createLink(
              '次週',
              () => {
                const newDate = dayjs(baseDate)
                  .add(isPc ? 2 : 1, 'w')
                  .format('YYYY/M/D');
                onClickRight(newDate);
              },
              outIndex === -1
            )}
          </div>
          <div className="relative flex flex-row">
            {/* 時間表示 */}
            <div className="flex w-full flex-col">
              <div className="flex h-full w-full flex-col bg-white">
                <div
                  className={classNames(
                    'flex h-14 w-full items-center justify-center border-b border-line text-center',
                    isSp ? '' : 'border-l'
                  )}
                >
                  <label className="text-sm my-4 w-12 text-navy lg:w-40">
                    時間
                  </label>
                </div>
              </div>
            </div>
            {/* 日にち/曜日表示 */}
            {_dailyInfoList.map((info, index) => (
              <div
                key={info.date}
                ref={index === 0 ? firstRef : null}
                className={classNames('relative flex w-full flex-col bg-white')}
              >
                <div
                  className={classNames(
                    'flex h-14 w-full flex-col items-center justify-center border-b border-l border-line text-center',
                    !isSp && index === _dailyInfoList.length - 1
                      ? 'border-r'
                      : ''
                  )}
                >
                  {/* 日にち表示 */}
                  <div
                    className={classNames(
                      'text-sm flex h-full w-full items-center justify-center border-b border-line',
                      DATE_KBN_DAY_COLOR_CLASS[info.dayKbn]
                    )}
                  >
                    {/* 1日はM/D表記にする(SPのレイアウト崩れがあるかもなので、その場合要調整)*/}
                    {dayjs(info.date).format('D') === '1'
                      ? dayjs(info.date).format('M/D')
                      : dayjs(info.date).format('D')}
                  </div>
                  {/* 曜日表示 */}
                  <div
                    className={classNames(
                      'text-sm flex h-full w-full items-center justify-center text-center',
                      DATE_KBN_WEEK_COLOR_CLASS[info.dayKbn]
                    )}
                  >
                    {`${dayjs(info.date).format('ddd')}${
                      info.dayKbn === '3' ? '・祝' : ''
                    }`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="relative flex flex-row">
          {/* 時分表示（縦ごと） */}
          <div className="flex w-full flex-col">
            {_dailyInfoList?.[0]?.hmInfo?.map(info => (
              <div
                key={info.hm}
                className={classNames(
                  'flex h-14 w-full items-center justify-center border-b border-line text-center',
                  isSp ? '' : 'border-l'
                )}
              >
                <label className="text-sm my-4 w-12 text-navy lg:w-40">
                  {info.hm}
                </label>
              </div>
            ))}
          </div>
          {/* 〇/-部分表示（縦ごと） */}
          {_dailyInfoList?.map((info, index) => (
            <div
              key={info.date + '_outer'}
              className={classNames(
                'relative flex w-full flex-col',
                DATE_KBN_AVAILABILITY_COLOR_CLASS[info.dayKbn]
              )}
            >
              {createDisabledArea(info, index)}
              {createMultiDisabledArea(info, index)}

              {/* 〇/-表示 */}
              {info.hmInfo.map(hmInfo => (
                <div
                  key={info.date + hmInfo.hm}
                  className={classNames(
                    'flex h-14 w-full items-center justify-center border-b border-l border-line text-center',
                    !isSp && index === _dailyInfoList.length - 1
                      ? 'border-r'
                      : ''
                  )}
                >
                  {createAvailability(
                    {
                      ...hmInfo,
                      date: info.date,
                      closedFlag: info.closedFlag,
                    },
                    index
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
);
