import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { messageModalActions } from 'slices/messageModalSlice';
import { pageKeyActions } from 'slices/pageKeySlice';

export const useConfirmHistoryBack = ({ isEdited, setIsEdited }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { pageKeyList } = useSelector(state => state.pageKey);

  // ブラウザバック時に呼ばれる
  const handlePopstate = () => {
    dispatch(
      messageModalActions.showLeaveConfirmModalOpen({
        onClickTransition: () => {
          // 前画面に戻る
          window.history.back();
          setIsEdited(false);
        },
        onClickCancel: () =>
          // ダミーのhistory追加
          window.history.pushState(window.history.state, '', null),
      })
    );
  };

  useEffect(() => {
    // 編集時実行
    if (isEdited) {
      // ブラウザバック以外の場合、ダミーのhistory追加
      if (!pageKeyList.includes(location.key) && location.key !== 'default') {
        window.history.pushState(window.history.state, '', null);
        dispatch(pageKeyActions.addPageKey(location.key));
      }
      // event設定
      window.addEventListener('popstate', handlePopstate, false);
    }
    return () => {
      window.removeEventListener('popstate', handlePopstate, false);
    };
  }, [isEdited]);

  useEffect(() => {
    // ブラウザバックの場合、event設定
    if (pageKeyList.includes(location.key) || location.key === 'default') {
      window.addEventListener('popstate', handlePopstate, false);
    }
  }, []);
};
