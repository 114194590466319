import React from 'react';
import { encode } from 'utils/base64Util';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ModalBase from 'components/modalBase/index';
import AprosButton from 'components/aprosButton/index';
import Path from 'constants/routePath';

const TermsConfirmModal = () => {
  const location = useLocation();

  const { queryParams } = location?.state || {};

  const { showTermsConfirmModal } = useSelector(state => state.messageModal);

  const closeModal = () => {
    let params = '';
    //外部遷移用のパラメータがあったら、リダイレクト先のパラメータに設定する
    if (!!queryParams) {
      Object.keys(queryParams).forEach(function (key) {
        if (!queryParams[key]) {
          return;
        }
        const param =
          key === 'p12' ? encode(queryParams[key]) : queryParams[key];
        if (params === '') {
          params = '?' + key + '=' + param;
        } else {
          params += '&' + key + '=' + param;
        }
      });
    } else if (
      location.pathname === Path.RESERVATION_SHOP_SELECT &&
      !!location.state?.yykInfo
    ) {
      //画面表示に必要なパラメータをローカルストレージに保存
      window.localStorage.setItem(
        'yykInfo',
        JSON.stringify(location.state.yykInfo, undefined, 1)
      );
    }

    const redirectURL =
      params === ''
        ? process.env.REACT_APP_BASE_URL + location.pathname + '?terms=terms'
        : process.env.REACT_APP_BASE_URL + Path.TOP + params + '&terms=terms';
    window.abclp.mypage.show({
      redirectURL: redirectURL,
      clpHost: process.env.REACT_APP_CLP_DOMAIN,
      target: 'clp/agreement',
    });
  };

  return (
    <ModalBase
      isModalOpen={showTermsConfirmModal}
      toggleModal={() => {
        //モーダル外押下でモーダル閉じない
      }}
      className={`flex flex-col items-center overflow-auto pb-[60px] pt-14 lg:pb-[104px] lg:pt-24`}
    >
      <label className="text-base mx-4 whitespace-pre-wrap text-center font-bold text-navy sm:mx-8 lg:mx-12">
        各種規約またはプライバシーポリシーが変更されました。
        <br />
        ご確認いただき同意をお願いいたします。
      </label>
      <div className="mt-10 lg:mt-20">
        <AprosButton
          comClassName="sub"
          text="確認する"
          className="w-[260px]"
          onClick={() => closeModal()}
        />
      </div>
    </ModalBase>
  );
};

export default TermsConfirmModal;
