import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  createRef,
  useMemo,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {
  accountActions,
  registerVehicles,
  updateUserMailAddress,
  updateUserShop,
} from 'slices/accountSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import { actions as loginActions } from 'slices/loginSlice';
import { SERVICE_CODE_TIRE } from 'constants/service';
import {
  fetchShopSelectInit,
  fetchSubShop,
  actions,
  fetchMainShopAvailability,
  fetchUserWaitTime,
  updateShopUserWaitTime,
  fetchSubShopAvailability,
  fetchSubShopService,
} from 'slices/reservationShopSelectSlice';
import { waitTimeReserve } from 'slices/reservationDateSelectSlice';
import { displayCautionActions } from 'slices/displayCautionSlice';
import Yup from 'utils/yupUtil';
import Icons from 'images/icons';
import Stepper from 'components/stepper';
import Proctitle from 'components/proctitle';
import AprosButton from 'components/aprosButton';
import Linked from 'components/linked/index';
import AvailabilityShopCalendar from 'components/availabilityShopCalendar';
import Map from 'components/map';
import NotesModal from 'components/messageModals/notesModal';
import TireStorageModal from 'components/messageModals/tireStorageModal';
import ShopSelectBottomItem from './components/shopSelectBottomItem';
import UserNameLabel from './components/userNameLabel';
import WaitTimeContent from './components/waitTimeContent';
import CalendarDescriptionLabel from './components/calendarDescriptionLabel';
import MapLink from './components/mapLink';
import RankModal from './modals/rankModal';
import MailAddressSettingModal from './modals/mailAddressSettingModal';
import ApproximateTimeUpdateModal from './modals/approximateTimeUpdateModal';
import VehicleRegisterModal from './modals/vehicleRegisterModal';
import ShopUpdateModal from './modals/shopUpdateModal';
import { REGISTER_SHOP_STEP } from 'constants/reservation';
import Path, { ERROR_MESSAGE_TRANSITION } from 'constants/routePath';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { getCurrentService } from './utils/reservationUtil';
import { getTermKbn } from 'utils/otherUtil';
import { openTirePage } from 'utils/tireRequestUtil';
import { scrollTo } from 'utils/otherUtil';
import { useConfirmHistoryBack } from 'hooks/useConfirmHistoryBack';
import {
  SHOP_SELECT_ADD_CAR_MODAL_CLOSE,
  SHOP_SELECT_CALENDAR_CIRCLE,
  SHOP_SELECT_CHANGE_SERVICE,
} from '../../constants/gtmCustomEvent';
import { getCustomPropertyForMulti } from '../../utils/gtmUtil';
import { RankDescription } from 'constants/rank';

/** 一回に読み込む店舗数 */
export const SHOP_LOAD_COUNT = 3;

const ShopSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isCurrentPlaceDisabled, setIsCurrentPlaceDisabled] = useState(false);
  const [isAroundPlaceDisabled, setIsAroundPlaceDisabled] = useState(false);

  // 検索で使用する位置情報
  const [currentLocation, setCurrentLocation] = useState(null);
  // マップの中央位置
  const [mapCenter, setMapCenter] = useState(null);
  // 選択中のマーカー（店舗CD）
  const [selectMarker, setSelectMarker] = useState(null);
  // 編集したらブラウザバックで警告を表示する
  const [isEdited, setIsEdited] = useState(false);
  useConfirmHistoryBack({ isEdited, setIsEdited });

  const { isPc, isSp } = useDeviceSize();

  const {
    previousStep,
    serviceSelectScreen,
    baseScreen,
    queryParams,
    ...state
  } = location?.state || {};

  // 外部遷移か
  const isExternalTransition = !!queryParams;

  //セントラルログインページからの遷移か
  const query = new URLSearchParams(useLocation().search);
  const gig_actions = query.get('gig_actions');
  const isLoginRedirect =
    gig_actions !== undefined ? gig_actions === 'sso.login' : false;

  const tmp_codeCombinationsList = isLoginRedirect
    ? JSON.parse(window.localStorage.getItem('codeCombinationsList'))
    : [];
  const tmp_sgyYmd = isLoginRedirect
    ? window.localStorage.getItem('sgyYmd')
    : '';
  const tmp_yykInfo = JSON.parse(window.localStorage.getItem('yykInfo'));

  const yykInfo = isExternalTransition
    ? {
        work: [
          {
            sgyYmd: dayjs().format('YYYYMMDD'),
            sgyStaHm: null,
            codeCombinationsList: [
              [{ chubunCd: queryParams.p10, shobunCd: queryParams.p11 }],
            ],
          },
        ],
      }
    : isLoginRedirect
    ? {
        work: [
          {
            sgyYmd: tmp_sgyYmd,
            sgyStaHm: null,
            codeCombinationsList: tmp_codeCombinationsList,
          },
        ],
      }
    : !state.yykInfo && !!tmp_yykInfo
    ? tmp_yykInfo
    : state.yykInfo;

  const { work = [{}] } = yykInfo || {};

  const [{ sgyYmd, codeCombinationsList }] = work || [];

  const [date, setDate] = useState(dayjs(sgyYmd).format('YYYY/M/D'));

  const ref = useRef();

  const mapRef = useRef();

  const setRef = useCallback(
    node => {
      if (node) {
        dispatch(actions.setMapAreaHeight(node?.offsetHeight ?? 0));
      }
      ref.current = node;
    },
    [dispatch]
  );

  const {
    mapAreaHeight,
    selectedSrySeq,
    mainShopInfo,
    mainShopAvailability,
    subShopInfoList,
    subShopAvailabilityList,
    showRankModal,
    showMailAddressSettingModal,
    showApproximateTimeUpdateModal,
    showVehicleRegisterModal,
    showShopChangeModal,
    carList,
    shopList,
    shopStatus,
    selectService,
    selectShop,
    enableResort,
    isMainShopLoading,
    isSubShopLoading,
    isTireContract,
    subShopLoadingList,
    singleSubShopLoadingList,
    updateShop,
    subShopQueueList,
    subShopQueueLoadList,
    mainShopOneClickDisabled,
    vehicleCloseCallback,
  } = useSelector(state => state.reservationShopSelect);

  useEffect(() => {
    return () => {
      if (isLoginRedirect) {
        //画面遷移時に、ローカルストレージに保存した値を削除
        window.localStorage.removeItem('codeCombinationsList');
        window.localStorage.removeItem('sgyYmd');
      }
      if (tmp_yykInfo) {
        window.localStorage.removeItem('yykInfo');
      }
    };
  }, []);

  const [scrollRef, inView] = useInView();
  useEffect(() => {
    if (subShopQueueLoadList.length === 0)
      dispatch(
        actions.setSubShopQueue({
          subShopQueueList: subShopQueueList,
          subShopQueueLoadList: subShopQueueList.filter(
            (s, i) => i < SHOP_LOAD_COUNT
          ),
        })
      );
  }, [inView]);

  const showSubShopInfoList = useMemo(() => {
    return subShopInfoList?.filter(
      s => s.serviceList && s.serviceList.length > 0
    );
  }, [subShopInfoList, mainShopInfo.shopCode]);

  const showSubShopAvailabilityList = useMemo(
    () =>
      subShopAvailabilityList?.filter(
        s => s.availabilityList && s.availabilityList.length > 0
      ),
    [subShopAvailabilityList]
  );

  const subShopLoad = !!subShopQueueList.filter(
    s => !subShopQueueLoadList.includes(s)
  ).length;

  const {
    vehicles,
    updateSrySeq,
    accountInfo: {
      shopCode,
      rankCode,
      userId,
      cardNumber,
      point,
      mailAddressPc,
      mailAddressMobile,
    },
  } = useSelector(state => state.account);

  const { availableTime, confirmMenuWaitMinutes, frontStatus } = mainShopInfo;

  const showOneClickArea = [0, 2].includes(frontStatus);

  const showTireArea = selectService?.aprosCode === SERVICE_CODE_TIRE;

  const subShopRefs = useRef([]);

  showSubShopInfoList.forEach((_, index) => {
    subShopRefs.current[index] = createRef();
  });

  const mailFormikInitValues = {
    mail: '',
    mailMagazine: '1',
  };
  const mailFormik = useFormik({
    initialValues: mailFormikInitValues,
    validateOnMount: true,
    validationSchema: Yup.object({ mail: Yup.string().required().mail() }),
  });

  useEffect(() => {
    if (!isExternalTransition && !userId) {
      return;
    }
    // 遷移パラメータが不正な場合、エラーを出してTOP画面に遷移
    if (!shopCode || !codeCombinationsList) {
      const message = ERROR_MESSAGE_TRANSITION;
      dispatch(messageModalActions.showMessageModal({ message }));
      dispatch(loginActions.closeAbidDifferenceModal());
      navigate(Path.TOP);
      return;
    }
    dispatch(displayCautionActions.check());

    dispatch(
      fetchShopSelectInit({
        shopCode,
        codeCombinationsList,
        userId,
      })
    );

    // 初期位置設定
    if (yykInfo?.location) {
      // 引数で位置情報が選択されていた場合
      updateLocation(yykInfo?.location);
    }

    return () => {
      dispatch(actions.clear());
    };
  }, [dispatch, shopCode]);

  useEffect(() => {
    // 引数で位置情報が選択されていた場合はアップデートしない
    if (yykInfo?.location) {
      return;
    }

    // 初期位置は本籍店舗
    if (shopList && shopList.length > 0) {
      const targetShop = shopList.find(s => s.shopCode === shopCode);
      if (targetShop?.location) {
        const [lng, lat] = targetShop.location;

        updateLocation({ lat, lng });
      }
    }
  }, [shopList]);

  useEffect(() => {
    // SSOログイン画面柄の遷移、かつ作業内容がタイヤ交換、かつタイヤ保管ユーザーの場合はタイヤ保管モーダルを表示
    if (isLoginRedirect) {
      if (selectService?.aprosCode === SERVICE_CODE_TIRE && isTireContract) {
        dispatch(
          messageModalActions.showTireStorageModal()
        );
      }
    }
    selectService &&
      currentLocation &&
      dispatch(
        fetchSubShop({
          searchParams: {
            longitude: currentLocation.lng,
            currentLongitude: currentLocation.lng,
            latitude: currentLocation.lat,
            currentLatitude: currentLocation.lat,
          },
          sgyYmd,
          rankCode,
          date: sgyYmd ?? dayjs().format('YYYYMMDD'),
          codeCombinationsList,
        })
      );
  }, [currentLocation, selectService]);

  useEffect(() => {
    if (vehicles.length > 0) {
      if (!selectedSrySeq) {
        // 初期車両設定
        dispatch(actions.setSrySeq(yykInfo?.srySeq || vehicles[0].carSeq));
      } else if (updateSrySeq) {
        // 車両を追加した場合、追加車両を選択状態にする
        dispatch(actions.setSrySeq(updateSrySeq));
        dispatch(accountActions.removeUpdateSrySeq());
      }
    }
  }, [dispatch, vehicles]);

  useEffect(() => {
    if (!shopStatus) return;
    if (shopStatus !== '0') {
      dispatch(actions.showShopChangeModal());
    } else if (vehicles.length === 1 && !vehicles[0].modelName) {
      // 未登録車両一つの場合、登録モーダルを表示する
      dispatch(actions.showVehicleRegisterModal(() => navigate(Path.TOP)));
    }
  }, [dispatch, shopStatus]);

  const dateString = dayjs(date).format('YYYYMMDD');

  useEffect(() => {
    if (!mainShopInfo?.serviceList || !selectService) return;
    const result = getCurrentService(mainShopInfo.serviceList, selectService);

    if (!result.chubunCd || result.shobun.length === 0) return;

    const availabilityList = mainShopAvailability
      .filter(availability => availability.date >= dateString)
      .filter(
        (shop, i) =>
          shop.date === dayjs(dateString).add(i, 'd').format('YYYYMMDD')
      )
      .filter((_, i) => (i < isPc ? 14 : 7));
    if (availabilityList.length < (isPc ? 14 : 7)) {
      dispatch(
        fetchMainShopAvailability({
          date: dateString,
          shopCode,
          chubunCd: result.chubunCd,
          shobunCd: result.shobun.map(s => s.shobunCd),
          userRank: rankCode,
        })
      );
    }
  }, [dispatch, mainShopInfo?.canReserve, dateString, selectService]);

  useEffect(() => {
    if (updateShop) {
      // サブ店舗日付のみ更新時
      if (showSubShopInfoList.length === 0 || !selectService) return;

      dispatch(actions.clearUpdateShop());
      const subShop = showSubShopInfoList.find(s => s.shopCode === updateShop);
      if (!subShop) {
        // 更新対象が表示されてない
        return;
      }
      const result = getCurrentService(subShop.serviceList, selectService);

      // 取得済みのデータで表示できる場合、APIは実行しない
      const date = subShop.date;

      const availabilityList = showSubShopAvailabilityList
        .find(s => s.shopCode === updateShop)
        ?.availabilityList.filter(s => s.date >= dayjs(date).format('YYYYMMDD'))
        .filter((s, i) => s.date === dayjs(date).add(i, 'd').format('YYYYMMDD'))
        .filter((_, i) => i < 7);
      if (availabilityList?.length < 7) {
        dispatch(
          fetchSubShopAvailability({
            shopCode: subShop.shopCode,
            date,
            chubunCd: result.chubunCd,
            shobunCd: result.shobun.map(s => s.shobunCd),
            userRank: rankCode,
            serviceList: subShop.serviceList,
          })
        );
      }
      dispatch(actions.clearUpdateShop());
    } else {
      // サブ店舗全体更新時
      if (subShopQueueLoadList.length === 0) return;
      const filter = subShopInfoList.filter(
        s => s.serviceList && subShopQueueLoadList.includes(s.shopCode)
      );
      if (filter.length === subShopQueueLoadList.length) {
        // サービスの読みこみ終わってたら空き状況を検索する
        filter.forEach(s => {
          if (
            subShopAvailabilityList
              .filter(a => a.availabilityList && a.availabilityList.length > 0)
              .map(a => a.shopCode)
              .includes(s.shopCode)
          ) {
            // 空き状況検索が終わっている
            return;
          }

          // 空き状況検索
          const result = getCurrentService(s.serviceList, selectService);
          dispatch(
            fetchSubShopAvailability({
              shopCode: s.shopCode,
              date: s.date ?? sgyYmd ?? dayjs().format('YYYYMMDD'),
              chubunCd: result.chubunCd,
              shobunCd: result.shobun.map(s => s.shobunCd),
              userRank: rankCode,
              serviceList: s.serviceList,
            })
          );
        });
      }
    }
  }, [dispatch, subShopInfoList, selectService]);

  /** キューリストに店舗CDが入っている場合、サービス検索 */
  useEffect(() => {
    if (subShopQueueLoadList.length === 0) return;
    dispatch(
      fetchSubShopService({
        shopCodeList: subShopQueueLoadList,
        codeCombinationsList,
      })
    );
  }, [subShopQueueLoadList]);

  /** 空き状況まで検索が終わった場合 */
  useEffect(() => {
    // 読み込み中のキューがない
    if (subShopQueueLoadList.length === 0) return;

    // 読み込みが終わった空き状況リスト
    const availabilityList = subShopAvailabilityList.filter(
      s => subShopQueueLoadList.includes(s.shopCode) && s.availabilityList
    );

    // 読み込み中の場合
    if (subShopQueueLoadList.length !== availabilityList.length) return;

    // 読み込みが終わった店舗を削除
    const list = subShopQueueList.filter(
      s => !subShopQueueLoadList.includes(s)
    );

    dispatch(
      actions.setSubShopQueue({
        // 残読み込み対象
        subShopQueueList: list,
        // 次の読み込み対象を設定
        subShopQueueLoadList: list.filter((s, i) => i < SHOP_LOAD_COUNT),
      })
    );
  }, [subShopAvailabilityList]);

  /** サービスに一致する店舗が存在しない場合 */
  useEffect(() => {
    if (
      isMainShopLoading ||
      isSubShopLoading ||
      subShopLoadingList.length > 0 ||
      singleSubShopLoadingList.length > 0
    ) {
      return;
    }

    if (
      !mainShopInfo.canReserve &&
      showSubShopInfoList.length === 0 &&
      showSubShopAvailabilityList.length === 0
    ) {
      dispatch(
        messageModalActions.showMessageModal({
          message:
            '該当するメニューのお取り扱いがございません。\n別の店舗で検索してください。',
        })
      );
    }
  }, [
    subShopAvailabilityList,
    isMainShopLoading,
    isSubShopLoading,
    subShopLoadingList,
    singleSubShopLoadingList,
    showSubShopInfoList,
  ]);

  const { makerName, modelName } =
    vehicles.find(v => v.carSeq === selectedSrySeq) ?? {};

  // 位置情報を更新する
  const updateLocation = ({ lat, lng }) => {
    setCurrentLocation({ lat, lng });
    setMapCenter({ lat, lng });
  };

  // 現在位置取得
  const getCurrentPosition = () => {
    // ボタン非活性
    setIsCurrentPlaceDisabled(true);

    // 3秒待ち
    setTimeout(() => {
      setIsCurrentPlaceDisabled(false);
    }, 3000);
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        updateLocation({ lat: latitude, lng: longitude });
      },
      () => {
        dispatch(
          messageModalActions.showMessageModal({
            message:
              '位置情報の取得が許可されていません。\nブラウザの設定を見直してください。',
            closeSameWithClick: true,
            onClick: () => {
              dispatch(actions.setSubShopLoading(false));
              dispatch(messageModalActions.closeMessageModal());
            },
          })
        );
      }
    );
  };

  // 〇押下時
  const onClickCircle = (shopInfo, availity) => {
    // GTMカスタムイベントパラメータ
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push({
      event: SHOP_SELECT_CALENDAR_CIRCLE,
    });

    transitionDateSelect(shopInfo, availity);
  };

  // ■押下時
  const onClickSquare = (shopInfo, availity) => {
    transitionDateSelect(shopInfo, availity);
  };

  const transitionDateSelect = (shopInfo, availity) => {
    if (isSelectVehicleError()) return;
    const { chubunCd, shobun } = getCurrentService(
      shopInfo.serviceList,
      selectService
    );
    navigate(Path.RESERVATION_DATE_SELECT, {
      state: {
        shopInfo,
        yykInfo: {
          ...yykInfo,
          location: currentLocation,
          srySeq: selectedSrySeq,
          sryMkrMei: makerName,
          sshMei: modelName,
          tokCd: shopInfo.shopCode,
          tokNmRyk: shopInfo.shopName,
          work: (yykInfo.work ?? []).map(w => ({
            ...w,
            sgyYmd: availity.date,
            chubunCd,
            shobun,
          })),
        },
        stepLabels: REGISTER_SHOP_STEP,
        baseScreen: Path.RESERVATION_SHOP_SELECT,
        serviceSelectScreen,
      },
    });
  };

  // 鍵マーク押下時
  const onClickLock = () => {
    dispatch(actions.showRankModal());
  };

  // 地図で位置を確認リンク押下時
  const onClickMapLink = shop => {
    isSp && scrollTo(mapRef.current.offsetTop - 10);
    setIsEdited(true);
    // 再検索をしないのでマップの表示位置のみ更新する
    const [lng, lat] = shop.location;
    setMapCenter({ lat, lng });

    setSelectMarker(shop.shopCode);
  };

  // ワンクリック予約押下時
  const onClickOneClick = (shopInfo, isMainShop) => {
    dispatch(
      actions.selectShop({
        shopInfo,
      })
    );
    if (isSelectVehicleError()) return;
    if (!mailAddressPc && !mailAddressMobile) {
      // メールアドレスが登録されていない場合
      dispatch(actions.showMailAddressSettingModal());
    } else {
      // メールアドレスが登録されている場合
      userWaitTimeReserve(shopInfo, isMainShop);
    }
  };

  const userWaitTimeReserve = async (shopInfo, isMainShop) => {
    const { chubunCd, shobun } = getCurrentService(
      shopInfo.serviceList,
      selectService
    );
    const workTimeRes = await dispatch(
      fetchUserWaitTime({
        shopCode: shopInfo.shopCode,
        chubunCd,
        shobunCd: shobun[0]?.shobunCd,
      })
    ).unwrap();
    const { getTime, menus, frontStatus: updateFrontStatus } = workTimeRes.body;
    const {
      menuCd,
      confirmMenuWaitMinutes,
      shobun: menusShobun,
    } = menus?.[0] ?? {};

    const nextAvailableTime = dayjs(getTime, 'HH:mm')
      .add(confirmMenuWaitMinutes, 'minutes')
      .format('MM月DD日 HH:mm');

    if (confirmMenuWaitMinutes !== shopInfo.confirmMenuWaitMinutes) {
      dispatch(
        actions.updateWaitTime({
          confirmMenuWaitMinutes,
          getTime,
          menuCd,
          chubunCd,
          shobunCd: menusShobun[0]?.shobunCd,
          sgyTime: menusShobun[0]?.sgyTime,
          shopCode: shopInfo.shopCode,
          frontStatus: updateFrontStatus,
          isMainShop,
          availableTime: nextAvailableTime,
        })
      );
      dispatch(actions.showApproximateTimeUpdateModal());
    } else {
      const yykRes = await dispatch(
        waitTimeReserve({
          termKbn: getTermKbn(),
          kaiinNo: userId,
          kaiinCardNo: cardNumber,
          srySeq: selectedSrySeq,
          shopCode: shopInfo.shopCode,
          chubunCd,
          shobunCd: shobun[0]?.shobunCd,
          menuCd,
          sgyTime: menusShobun[0]?.sgyTime,
        })
      ).unwrap();

      const { yykNo, sgyStaHm } = yykRes.body;

      navigate(Path.RESERVATION_COMPLETE, {
        state: {
          baseScreen,
          yykInfo: {
            ...yykInfo,
            yykNo,
            tokNmRyk: shopInfo.shopName,
            sryMkrMei: makerName,
            sshMei: modelName,
            srySeq: selectedSrySeq,
            work: yykInfo.work.map(w => ({
              ...w,
              sgyYmd: dayjs(nextAvailableTime, 'MM月DD日 HH:mm').format(
                'YYYYMMDD'
              ),
              sgyStaHm,
            })),
          },
          selectService,
          isWaitingTimeReservation: true,
        },
      });
    }
  };

  const [sortedSubShopInfoList, setSortedSubShopInfoList] = useState([]);

  const _sortedSubShopInfoList = useMemo(() => {
    return (showSubShopInfoList ?? []).map(shop => {
      const { availableCount, shopInfo } =
        showSubShopAvailabilityList?.find(s => s.shopCode === shop.shopCode) ??
        {};
      return {
        ...shop,
        shopInfo,
        availableCount,
      };
    });
  }, [showSubShopInfoList, showSubShopAvailabilityList]);

  useEffect(() => {
    setSortedSubShopInfoList(_sortedSubShopInfoList);
  }, [_sortedSubShopInfoList]);

  useEffect(() => {
    if (
      showSubShopInfoList.length > 0 &&
      showSubShopAvailabilityList.length > 0 &&
      subShopQueueList.length === 0 &&
      enableResort
    ) {
      setSortedSubShopInfoList(pre => {
        const next = [...pre]
          .sort(
            (a, b) =>
              (b.shopInfo ?? []).some(bb => !bb.outOfTermMessage) -
              (a.shopInfo ?? []).some(aa => !aa.outOfTermMessage)
          )
          .sort((a, b) =>
            (b.shopInfo ?? []).some(bb => !bb.outOfTermMessage) >
            (a.shopInfo ?? []).some(aa => !aa.outOfTermMessage)
              ? 1
              : b.availableCount - a.availableCount
          );
        const updateSubShopSort = [];

        next.forEach(n => {
          updateSubShopSort.push(
            subShopInfoList.find(s => n.shopCode === s.shopCode)
          );
        });

        const excluedShopInfo = subShopInfoList.filter(s =>
          updateSubShopSort.every(u => u.shopCode !== s.shopCode)
        );

        dispatch(
          actions.updateSubShopSort([...updateSubShopSort, ...excluedShopInfo])
        );

        return pre;
      });
    }
  }, [
    subShopQueueList,
    showSubShopAvailabilityList,
    showSubShopInfoList,
    singleSubShopLoadingList,
    enableResort,
    subShopInfoList,
  ]);

  const isSelectVehicleError = () => {
    if (!vehicles?.find(v => selectedSrySeq === v?.carSeq)?.modelName) {
      // 未登録車両の場合、登録できない
      dispatch(
        messageModalActions.showMessageModal({
          message:
            '未登録車両での予約はできません。\n新規車両を追加するか、車両を変更してください。',
        })
      );
      return true;
    }
    return false;
  };

  return (
    <>
      <Stepper
        labels={REGISTER_SHOP_STEP}
        previousStep={previousStep || -1}
        activeStep={0}
        fullWidth={false}
        bottomElement={
          <ShopSelectBottomItem
            showSry={true}
            showService={true}
            vehicles={vehicles}
            selectService={selectService}
            selectedSrySeq={selectedSrySeq}
            canServiceSelect={!isExternalTransition}
            onChangeSrySeq={v => {
              setIsEdited(true);
              dispatch(actions.setSrySeq(v));
            }}
            onClickNewSry={() => {
              setIsEdited(true);
              dispatch(actions.showVehicleRegisterModal());
            }}
            onClickSelectService={() => {
              // GTMカスタムイベントパラメータ
              const dataLayer = (window.dataLayer = window.dataLayer || []);
              dataLayer.push({
                event: SHOP_SELECT_CHANGE_SERVICE,
                changeService: [
                  getCustomPropertyForMulti(selectService?.aprosSubCategories),
                ],
              });

              navigate(serviceSelectScreen);
            }}
          />
        }
        className={{
          stepperClass: 'mb-10 max-sm:mx-12 sm:mx-36',
          bottomClass: 'mx-0 h-full text-left lg:w-[800px]',
        }}
      />
      <div className="mt-12 flex flex-col gap-y-4 sm:gap-y-4 lg:flex-row lg:flex-wrap lg:gap-x-16">
        <Proctitle
          useH1={true}
          text="店舗選択"
          className={{ textClass: 'whitespace-nowrap' }}
        />
      </div>
      <UserNameLabel className={'mt-8'} />
      {RankDescription(rankCode)}
      {!isPc && <CalendarDescriptionLabel className="mt-4 sm:mt-7" />}
      <div className="mt-4 sm:mt-5 lg:mt-10">
        <label className="text-base font-bold">よくご利用いただく店舗</label>
        {isPc && <CalendarDescriptionLabel className={'mt-9'} />}
        <div className="mt-2 rounded border border-line bg-white px-0 pt-4 sm:mt-4 sm:px-8 lg:mt-5">
          {isMainShopLoading && !mainShopInfo?.shopName ? (
            <div className="flex h-full items-center justify-center">
              <Icons.Image
                src={Icons.loading}
                className="h-[100px] w-[100px]"
              />
            </div>
          ) : (
            <>
              <div className="flex flex-col max-sm:px-2">
                <label className="text-base font-bold">
                  {mainShopInfo?.shopName}
                </label>
                <label className="text-sm font-medium">
                  {mainShopInfo?.address}
                </label>
                {mainShopInfo.canReserve && showOneClickArea ? (
                  <>
                    <div className="mt-4 flex flex-col flex-wrap whitespace-nowrap sm:flex-row sm:items-center sm:gap-y-4">
                      <div className="flex flex-col sm:mr-8">
                        {showOneClickArea && frontStatus !== 2 ? (
                          <WaitTimeContent
                            availableTime={availableTime}
                            confirmMenuWaitMinutes={confirmMenuWaitMinutes}
                          />
                        ) : (
                          <>
                            {frontStatus === 2 &&
                              '待ち時間予約はただいまご利用いただけません'}
                          </>
                        )}
                      </div>
                      {showOneClickArea && (
                        <div className="mt-2 self-center sm:mt-0">
                          <AprosButton
                            comClassName="reserve"
                            text="ワンクリックで予約する"
                            disabled={
                              mainShopOneClickDisabled || frontStatus === 2
                            }
                            onClick={() => onClickOneClick(mainShopInfo, true)}
                          />
                        </div>
                      )}
                    </div>
                    {showOneClickArea && (
                      <div className="mx-2 mt-6 border-t border-line sm:mx-0 sm:mt-4" />
                    )}
                  </>
                ) : (
                  !mainShopInfo.canReserve && (
                    <div className="text-sm whitespace-pre-wrap pb-8 pt-6 font-bold text-navy-light sm:pt-8 lg:pb-10 lg:pt-10">
                      {`該当するメニューのお取り扱いがございません。${
                        isSp ? '\n' : ''
                      }別の店舗で検索してください。`}
                    </div>
                  )
                )}
              </div>
              {mainShopInfo.canReserve && (
                <>
                  <div className="py-6 sm:py-4">
                    {isMainShopLoading ? (
                      <div className="flex h-full items-center justify-center">
                        <Icons.Image
                          src={Icons.loading}
                          className="h-[100px] w-[100px]"
                        />
                      </div>
                    ) : (
                      <AvailabilityShopCalendar
                        shopInfoList={mainShopAvailability}
                        rank={rankCode}
                        currentDate={date}
                        onClickLeft={date => setDate(date)}
                        onClickRight={date => {
                          dispatch(actions.setEnableResort(false));
                          setDate(date);
                        }}
                        onClickCircle={shopInfo =>
                          onClickCircle(mainShopInfo, shopInfo)
                        }
                        onClickSquare={shopInfo =>
                          onClickSquare(mainShopInfo, shopInfo)
                        }
                        onClickLock={onClickLock}
                        onChangeView={oneClickDisabled => {
                          dispatch(
                            actions.setMainShopOneClickDisable(oneClickDisabled)
                          );
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="mt-10 sm:mt-16 lg:mt-24">
        <label ref={mapRef} className="text-base font-bold">
          店舗を探す
        </label>
        <div className="flex flex-col lg:flex-row lg:gap-x-4">
          <div
            ref={element => setRef(element)}
            className="mt-2 flex h-full w-full flex-col bg-background-sub px-4 py-6 sm:mt-4"
            style={{
              margin: isSp ? '8px calc(50% - 50vw) 0' : '',
              width: isSp ? '100vw' : '',
              flex: 10,
            }}
          >
            <div className="flex flex-row items-center">
              <label className="text-sm mr-2 font-medium sm:mr-4">
                現在地から探す
              </label>
              <AprosButton
                disabled={isCurrentPlaceDisabled}
                comClassName="sub"
                text={
                  <div className="flex flex-row items-center justify-center">
                    <Icons.Image src={Icons.mapNv} />
                    <label>現在地から探す</label>
                  </div>
                }
                onClick={() => {
                  dispatch(actions.setEnableResort(true));
                  dispatch(actions.setSubShopLoading(true));
                  setIsEdited(true);
                  getCurrentPosition();
                  isSp && scrollTo(mapRef.current.offsetTop - 10);
                }}
              />
            </div>
            <Map
              markers={showSubShopInfoList?.map(sub => ({
                lat: sub.location[1],
                lng: sub.location[0],
                shopName: sub.shopName,
                shopCode: sub.shopCode,
              }))}
              center={mapCenter}
              selectMarker={selectMarker}
              disabled={isAroundPlaceDisabled}
              onClickMarker={({ shopCode, lat, lng }) => {
                setIsEdited(true);
                if (selectMarker === shopCode) {
                  setSelectMarker(null);
                } else {
                  setMapCenter({ lat, lng });

                  setSelectMarker(shopCode);
                  // 初回表示時になぜかエラーになるのでsetTimeoutで囲う
                  setTimeout(() => {
                    subShopRefs?.current[
                      showSubShopInfoList
                        .map(s => s.shopCode)
                        .findIndex(s => s === shopCode)
                    ]?.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'nearest',
                    });
                  }, 0);
                }
              }}
              onClickSearch={({ lat, lng }) => {
                // ボタン非活性
                setIsAroundPlaceDisabled(true);

                // 3秒待ち
                setTimeout(() => {
                  setIsAroundPlaceDisabled(false);
                }, 3000);
                dispatch(actions.setEnableResort(true));
                dispatch(actions.setSubShopLoading(true));
                setIsEdited(true);

                updateLocation({ lat, lng });
              }}
            />
          </div>
          <div
            className="scrollBar mt-6 flex w-full flex-col sm:mt-4 lg:overflow-y-scroll lg:pr-2"
            style={{ height: mapAreaHeight, flex: 13 }}
          >
            {isSubShopLoading ||
            (showSubShopInfoList.length === 0 &&
              subShopLoadingList.length > 0) ? (
              <div className="flex h-full items-center justify-center">
                <Icons.Image
                  src={Icons.loading}
                  className="h-[100px] w-[100px]"
                />
              </div>
            ) : showSubShopInfoList.length === 0 ? (
              <>
                <label className="text-sm whitespace-pre-wrap font-bold text-navy-light">
                  {`該当するメニューのお取り扱いがございません。${
                    isPc ? '' : '\n'
                  }別の店舗で検索してください。`}
                </label>
              </>
            ) : (
              <>
                <label className="text-sm w-full font-bold text-navy-light">
                  検索結果：
                  {subShopLoad
                    ? '読み込み中...'
                    : `${showSubShopInfoList.length ?? 0}件`}
                </label>
                {sortedSubShopInfoList.map((shop, i) => {
                  const {
                    confirmMenuWaitMinutes: subConfirmMenuWaitMinutes,
                    availableTime: subAvailableTime,
                    frontStatus: subFrontStatus,
                    availabilityList,
                    oneClickDisabled,
                  } = showSubShopAvailabilityList?.find(
                    s => s.shopCode === shop.shopCode
                  ) ?? {};

                  return (
                    <ul
                      ref={subShopRefs.current[i]}
                      key={shop.shopCode}
                      className={classNames(
                        'mt-2 flex w-full flex-col rounded border border-line pb-4 pt-3 sm:px-7 sm:pb-6 sm:pt-4 lg:px-8',
                        shop.shopCode === selectMarker
                          ? 'bg-background-selectShop'
                          : 'bg-white'
                      )}
                    >
                      <li>
                        <div className="flex flex-row items-center px-2 sm:px-0 lg:justify-between">
                          <label className="text-base font-bold sm:mr-8 lg:mr-0">
                            {shop.shopName}
                          </label>
                          {!isSp && (
                            <MapLink onClick={() => onClickMapLink(shop)} />
                          )}
                        </div>
                        <label className="text-sm px-2 font-medium sm:px-0">
                          {shop.address}
                        </label>
                        {isSp && (
                          <MapLink
                            onClick={() => onClickMapLink(shop)}
                            className="px-2"
                          />
                        )}

                        {singleSubShopLoadingList.indexOf(shop.shopCode) >
                        -1 ? (
                          <div className="mt-4 flex h-[131px] items-center justify-center">
                            <Icons.Image
                              src={Icons.loading}
                              className="h-[100px] w-[100px]"
                            />
                          </div>
                        ) : (
                          <>
                            {[0, 2].includes(subFrontStatus) && (
                              <>
                                <div className="my-0 flex flex-col items-center justify-between sm:mt-4 sm:flex-row">
                                  <label className="text-sm my-4 self-start px-2 font-medium sm:my-0 sm:self-center sm:px-0">
                                    {subFrontStatus !== 2 ? (
                                      <WaitTimeContent
                                        availableTime={subAvailableTime}
                                        confirmMenuWaitMinutes={
                                          subConfirmMenuWaitMinutes
                                        }
                                      />
                                    ) : (
                                      subFrontStatus === 2 &&
                                      '待ち時間予約はただいまご利用いただけません'
                                    )}
                                  </label>
                                  <AprosButton
                                    comClassName="reserve"
                                    text="ワンクリックで予約する"
                                    disabled={
                                      oneClickDisabled || subFrontStatus === 2
                                    }
                                    onClick={() =>
                                      onClickOneClick({
                                        ...shop,
                                        confirmMenuWaitMinutes:
                                          subConfirmMenuWaitMinutes,
                                      })
                                    }
                                  />
                                </div>
                                <div className="mx-2 mt-6 border-t border-line sm:mx-0 sm:mt-4" />
                              </>
                            )}
                            <AvailabilityShopCalendar
                              shopInfoList={availabilityList}
                              isSubShop={true}
                              rank={rankCode}
                              className="mt-4"
                              currentDate={shop.date}
                              onChangeView={oneClickDisabled => {
                                dispatch(
                                  actions.setSubShopOneClickDisable({
                                    shopCode: shop.shopCode,
                                    oneClickDisabled,
                                  })
                                );
                              }}
                              onClickLeft={date => {
                                dispatch(
                                  actions.updateSubShopSearchDate({
                                    shopCode: shop.shopCode,
                                    searchDate: date,
                                  })
                                );
                              }}
                              onClickRight={date => {
                                dispatch(actions.setEnableResort(false));
                                dispatch(
                                  actions.updateSubShopSearchDate({
                                    shopCode: shop.shopCode,
                                    searchDate: date,
                                  })
                                );
                              }}
                              onClickCircle={shopInfo =>
                                onClickCircle(shop, shopInfo)
                              }
                              onClickSquare={shopInfo =>
                                onClickSquare(shop, shopInfo)
                              }
                              onClickLock={onClickLock}
                            />
                          </>
                        )}
                      </li>
                    </ul>
                  );
                })}
                <motion.div className="h-[1px]" ref={scrollRef}>
                  {subShopLoad && (
                    <div className="mt-4 flex h-[131px] items-center justify-center">
                      <Icons.Image
                        src={Icons.loading}
                        className="h-[100px] w-[100px]"
                      />
                    </div>
                  )}
                </motion.div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="mt-16 lg:mt-40"></div>
      <RankModal
        rank={rankCode}
        point={point}
        isModalOpen={showRankModal}
        closeModal={() => dispatch(actions.closeModal())}
      />
      <MailAddressSettingModal
        formik={mailFormik}
        isModalOpen={showMailAddressSettingModal}
        closeModal={() => dispatch(actions.closeModal())}
        onClick={async () => {
          const { mail, mailMagazine } = mailFormik.values;
          await dispatch(
            updateUserMailAddress({
              kbn: '2',
              kaiinNo: userId,
              kaiincrdNo: cardNumber,
              kaiinMalAdrKet: mail,
              kaiinMalSsnKbnKet: mailMagazine,
            })
          ).unwrap();
          dispatch(actions.successCloseMailAddressSettingModal());
          userWaitTimeReserve(selectShop);
        }}
      />

      <ApproximateTimeUpdateModal
        isModalOpen={showApproximateTimeUpdateModal}
        closeModal={() => {
          const result = getCurrentService(
            selectShop.serviceList,
            selectService
          );
          dispatch(actions.closeModal());
          dispatch(
            updateShopUserWaitTime({
              shopCode: selectShop.shopCode,
              chubunCd: result.chubunCd,
              shobunCd: result.shobun.map(s => s.shobunCd)[0],
            })
          );
        }}
        onClick={() => userWaitTimeReserve(selectShop)}
        selectService={selectService}
        minutes={selectShop?.confirmMenuWaitMinutes}
        time={
          selectShop?.availableTime &&
          dayjs(selectShop.availableTime, 'MM月DD日 HH:mm').format('HH:mm')
        }
      />

      <VehicleRegisterModal
        isModalOpen={showVehicleRegisterModal}
        closeModal={() => {
          // GTMカスタムイベントパラメータ
          const dataLayer = (window.dataLayer = window.dataLayer || []);
          dataLayer.push({
            event: SHOP_SELECT_ADD_CAR_MODAL_CLOSE,
          });

          vehicleCloseCallback?.();
          dispatch(actions.closeVehicleRegisterModal());
        }}
        carList={carList}
        onClick={async ({ manufacturer, carModel }) => {
          // 車両追加
          const sryMkrCd = manufacturer;
          const [sshCd, kshCd] = carModel.split('_');

          const maker = carList.find(c => c.sryMkrCd === sryMkrCd);
          const carInfo = maker.modelList.find(
            m => m.sshCd === sshCd && m.kshCd === kshCd
          );
          const params = {
            updateKbn: '1',
            kaiincrdNo: cardNumber,
            sryMkrCd,
            sryMkrMei: maker.sryMkrNm2,
            sshCd,
            sshMei: carInfo.sshNm2,
          };

          await dispatch(registerVehicles(params)).unwrap();

          dispatch(actions.closeVehicleRegisterModal());
        }}
      />

      <ShopUpdateModal
        isModalOpen={showShopChangeModal}
        shopList={shopList}
        onClickTop={() => navigate(Path.TOP)}
        onClickUpdate={async ({ shopCode }) => {
          await dispatch(
            updateUserShop({
              kbn: '2',
              kaiinNo: userId,
              kaiincrdNo: cardNumber,
              hskTenpCd: shopCode,
            })
          ).unwrap();
          dispatch(actions.closeModal());
          if (vehicles.length === 1 && !vehicles[0].modelName) {
            // 未登録車両一つの場合、登録モーダルを表示する
            dispatch(actions.showVehicleRegisterModal());
          }
        }}
      />

      <NotesModal />
      <TireStorageModal
        cardNumber={cardNumber}
        isHiddenClose={isLoginRedirect}
        reserveClick={{}} />
    </>
  );
};

export default ShopSelect;
