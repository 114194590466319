import dayjs from 'dayjs';

const WaitTimeContent = ({ confirmMenuWaitMinutes, availableTime }) => (
  <>
    <div className="flex flex-row items-end">
      {confirmMenuWaitMinutes === 0 ? (
        <label className="text-sm font-medium">今から作業受付が可能です</label>
      ) : (
        <>
          <label className="text-sm font-medium">今なら約</label>
          <label className="text-base font-bold sm:leading-[30px]">
            {confirmMenuWaitMinutes}
          </label>
          <label className="text-sm font-medium">分後のご予約が可能です</label>
        </>
      )}
    </div>
    <div className="flex flex-row items-end whitespace-nowrap">
      <label className="text-xs font-medium">作業開始目安時間</label>
      <label className="text-base ml-2 font-bold text-red sm:leading-[30px]">
        {availableTime &&
          dayjs(availableTime, 'MM月DD日 HH:mm').format('M月D日')}
      </label>
      <label className="text-sm font-bold text-red">
        {availableTime &&
          dayjs(availableTime, 'MM月DD日 HH:mm').format('(ddd)')}
      </label>
      <label className="text-base ml-2 font-bold text-red sm:leading-[30px]">
        {availableTime &&
          dayjs(availableTime, 'MM月DD日 HH:mm').format('HH:mm')}
      </label>
    </div>
  </>
);

export default WaitTimeContent;
