import React from 'react';
import ModalBase from 'components/modalBase';
import AprosButton from 'components/aprosButton';

const ReserveCancelModal = ({
  isModalOpen,
  closeModal,
  data,
  onClickCancel,
}) => {
  const { reserveId, shopName, vehicleName, reserveDate, serviceName } = data || {};

  const createRow = (label, value) => (
    <div className="text-sm flex flex-col gap-x-6 font-medium sm:flex-row">
      <label>{label}</label>
      <label className="whitespace-pre-wrap">{value}</label>
    </div>
  );

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={closeModal}
      className="flex w-full flex-col items-center overflow-auto px-4 pb-11 pt-12 text-navy sm:px-10 sm:pb-[60px] sm:pt-14 lg:max-w-[800px] lg:px-32 lg:pb-[72px] lg:pt-16"
    >
      <label className="text-base font-bold">予約をキャンセルしますか？</label>

      <div
        id="modal"
        className="scrollBar flex w-full flex-col  overflow-y-scroll"
      >
        <div className="mt-8 flex w-full flex-col gap-y-6 sm:gap-y-4 lg:pl-8">
          {createRow('予約日時', reserveDate)}
          {createRow('予約店舗', shopName)}
          {createRow('予約番号', reserveId)}
          {createRow('予約内容', serviceName)}
          {createRow('予約車両', vehicleName)}
        </div>

        <div className="mt-[76px] mb-1 flex w-full flex-row gap-x-[19px] sm:mt-10 sm:gap-x-11 lg:gap-x-[60px]">
          <AprosButton
            text="戻る"
            comClassName="sub"
            className="w-full"
            onClick={closeModal}
          />
          <AprosButton
            text="キャンセルする"
            comClassName="main"
            className="w-full"
            onClick={onClickCancel}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ReserveCancelModal;
