import { memo } from 'react';
import classNames from 'classnames';
import InputTitleLabel from 'components/inputTitleLabel';
import BaseDateInput from './baseDateInput';

export default memo(
  ({
    title = '',
    formik,
    value = '',
    onClick,
    required = false,
    fieldName,
    placeholder = 'YYYY/MM/DD',
    disabled = false,
    className = { boxClass: '', titleClass: '', inputClass: '' },
    ...rest
  }) => {
    return (
      <div className={classNames('w-full p-2', className.boxClass)}>
        <div className="sm:flex sm:flex-row">
          <div className="flex items-center justify-between">
            <InputTitleLabel
              title={title}
              required={required}
              disabled={disabled}
              className={className.titleClass}
            />
          </div>
          <div
            className={classNames(
              'mt-2 flex w-full gap-x-2 sm:mt-0 sm:gap-x-4'
            )}
          >
            <span className="flex w-full">
              <BaseDateInput
                formik={formik}
                value={value}
                fieldName={fieldName}
                placeholder={placeholder}
                disabled={disabled}
                className={classNames(className.inputClass)}
                onClick={onClick}
                {...rest}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
);
