import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Icons from 'images/icons';
import { useDeviceSize } from 'hooks/useDeviceSize';

const Maintenance = () => {
  const { isSp } = useDeviceSize();
  const { maintenanceInfo } = useSelector(state => state.maintenance);
  const { maintenanceStartDate, maintenanceEndDate } = maintenanceInfo || {};

  const convert = d => (d ? dayjs(d).format('YYYY年M月D日 H:mm') : '未定');

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-x-8 gap-y-10 sm:gap-y-14 lg:flex-row">
        <div className="flex flex-col items-center lg:items-start">
          <label className="text-lg font-bold">
            ただいまメンテナンス中です
          </label>
          <div className="text-sm mt-8 flex flex-col flex-wrap text-center font-bold sm:mt-12 sm:flex-row">
            <label>メンテナンス日時{isSp ? '' : '：'}</label>
            <label>
              {convert(maintenanceStartDate)}〜{convert(maintenanceEndDate)}
            </label>
          </div>
          <label className="text-sm mt-3.5 whitespace-pre-wrap text-center font-medium sm:mt-8 lg:text-start">
            ご利用の皆様にはご迷惑をおかけし、{isSp ? '\n' : ''}
            申し訳ございません。{'\n'}
            メンテナンス終了までしばらくお待ちください。
          </label>
        </div>
        <div className="mx-14 sm:mx-28 lg:mx-0">
          <Icons.Image
            src={Icons.maintenance}
            className="max-w-[260px] object-cover sm:h-[300px] sm:max-w-[316px] lg:max-w-[370px]"
          />
        </div>
      </div>
    </>
  );
};

export default Maintenance;
