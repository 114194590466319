import { memo } from 'react';
import classNames from 'classnames';
import Icons from 'images/icons';
import { useHover } from 'react-use';

export default memo(({ shopCode, shopName, lat, lng, selected, onClick }) => {
  const element = hovered => (
    <div className="relative flex items-center justify-center">
      <Icons.Image
        key={shopCode}
        lat={lat}
        lng={lng}
        markerid={shopName}
        onClick={onClick}
        src={selected ? Icons.pinA : Icons.pinD}
        className="cursor-pointer"
      />
      {(selected || hovered) && (
        <div className="absolute bottom-10">
          <label
            className={classNames(
              'balloon',
              'text-sm whitespace-nowrap bg-white text-center font-bold',
              'relative box-border inline-block min-w-[140px] max-w-full rounded border-2 border-line	px-3.5 py-[7px]',
              'before:absolute before:-bottom-[13px] before:left-[calc(50%_+_6px)] before:z-[208] before:-ml-3.5 before:border-8 before:border-t-[6px] before:border-[transparent] before:border-t-white before:content-[""]',
              'after:absolute after:-bottom-[18px] after:left-[calc(50%_+_6px)] after:z-[108] after:-ml-4 after:border-[10px] after:border-t-8 after:border-[transparent] after:border-t-line after:content-[""]'
            )}
          >
            {shopName}
          </label>
        </div>
      )}
    </div>
  );

  const [hoverable, hovered] = useHover(element);

  return hoverable;
});
