import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import store from 'slices/store';
import Loading from 'components/loading';
import MessageModal from 'components/messageModals/messageModal';
import ToastModal from 'components/messageModals/toastModal';
import LeaveConfirmModal from 'components/messageModals/leaveConfirmModal';
import ScrollToTop from 'components/scrollTop';
import TopPage from 'pages/topPage';
import ShopSelect from 'pages/reservation/shopSelect';
import ShopAndServiceSelect from 'pages/reservation/shopAndServiceSelect';
import DateSelect from 'pages/reservation/dateSelect';
import Confirm from 'pages/reservation/confirm';
import Complete from 'pages/reservation/complete';
import ReservationList from 'pages/reservation/list';
import PitService from 'pages/pitService';
import KarteDescription from 'pages/karte/karteDescription';
import Karte from 'pages/karte/karte';
import FAQ from 'pages/faq/faq';
import Maintenance from 'pages/maintenance/maintenance';
import Terms from 'pages/terms/terms';
import Path from 'constants/routePath';
import Lisener from 'components/lisener/index';
import './App.scss';
import RootRoute from './routes/rootRoute';

const App = () => {
  const path = [
    // ログイン状態にかかわらず遷移できる画面
    { path: Path.FAQ, element: <FAQ /> },
    { path: Path.MAINTENANCE, element: <Maintenance /> },
    { path: Path.PIT_SERVICE, element: <PitService /> },
    { path: Path.TOP, element: <TopPage /> },
    { path: Path.TERMS, element: <Terms /> },
    // 未ログイン時に遷移できる画面
    { path: Path.KARTE_DESCRIPTION, element: <KarteDescription /> },
    // ログイン時に遷移できる画面
    { path: Path.KARTE, element: <Karte /> },
    { path: Path.RESERVATION_SHOP_SELECT, element: <ShopSelect /> },
    {
      path: Path.RESERVATION_SERVICE_SELECT,
      element: <ShopAndServiceSelect />,
    },
    { path: Path.RESERVATION_DATE_SELECT, element: <DateSelect /> },
    { path: Path.RESERVATION_CONFIRM, element: <Confirm /> },
    { path: Path.RESERVATION_COMPLETE, element: <Complete /> },
    { path: Path.RESERVATION_LIST, element: <ReservationList /> },
  ];

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Lisener />
        <ScrollToTop />
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        {/* <Suspense fallback={null}> */}

        <Routes>
          <Route exact element={<RootRoute />}>
            {path.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}

            {/* 存在しないパスが指定された */}
            <Route path="*" element={<Navigate to={Path.TOP} replace />} />
          </Route>
        </Routes>
        {/* </Suspense> */}
      </BrowserRouter>
      <Loading />
      <ToastModal />
      <MessageModal />
      <LeaveConfirmModal />
    </Provider>
  );
};

export default App;
