import React from 'react';
import ModalBase from 'components/modalBase';
import AprosButton from 'components/aprosButton';
import Icons from 'images/icons';
import { useDeviceSize } from 'hooks/useDeviceSize';

const LoginGuidanceModal = ({
  isModalOpen,
  closeModal,
  onLoginClick,
  onRegisterClick,
  onCardNumberClick,
}) => {
  const { isTablet, isSp } = useDeviceSize();
  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={closeModal}
      className="flex flex-col overflow-auto px-4 pb-[60px] pt-[60px] text-navy sm:px-5 lg:px-14 "
    >
      <Icons.Image
        src={Icons.closeModal}
        onClick={closeModal}
        className="absolute right-2 top-2 h-8 cursor-pointer lg:h-[50px]"
      />
      <div id="modal" className="scrollBar  overflow-y-scroll">
        <div className="text-sm text-center">
          2024年3月27日より、
          {isSp ? '' : <br />}
          共通オートバックスIDでのログインに変更いたしました。
        </div>
        <div className="text-base mt-3 flex flex-col justify-between border border-solid border-line bg-white px-4 py-[16px] text-center sm:py-[24px] lg:mt-5">
          <span className="text-center font-bold">
            共通オートバックスID
            <span className="text-sm">（メールアドレス）</span>
            {isTablet ? <br /> : ''}
            を登録済みの方
          </span>
          <div className="mt-[16px] sm:mt-[24px]">
            <AprosButton
              text="ログインへ進む"
              comClassName="main"
              className="w-[260px]"
              onClick={onLoginClick}
            />
          </div>
        </div>
        <div className="mt-3 flex flex-col justify-between border border-solid border-line bg-white px-4 py-[16px] text-center sm:py-[24px] lg:mt-5">
          <div className="text-base text-center font-bold">
            共通オートバックスIDが未登録の方
          </div>
          <div className="text-sm mt-[24px] text-center">
            AB ID(カード番号)をお持ちの方は、
            {isSp ? '' : <br />}
            共通オートバックスIDへの移行をお願いいたします。
          </div>

          <div>
            <AprosButton
              text="共通オートバックスID移行へ"
              comClassName="main"
              className="mt-[16px] w-[260px]"
              onClick={onCardNumberClick}
            />
          </div>
          <div className="text-sm mt-[20px] text-center sm:mt-[30px]">
            AB ID(カード番号)をお持ちでない方は、
            {isSp ? '' : <br />}
            新規会員登録をお願いいたします。
          </div>
          <div>
            <AprosButton
              text="新規会員登録へ進む"
              comClassName="main"
              className="mt-[16px] w-[260px]"
              onClick={onRegisterClick}
            />
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

export default LoginGuidanceModal;
