import Icons from 'images/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import Path, { CLOSE_CONFIRM_SCREENS } from 'constants/routePath';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { messageModalActions } from 'slices/messageModalSlice';

const TopIcon = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  return (
    <div
      className={classNames('mr-2 cursor-pointer', className)}
      // 特定の画面では、遷移確認モーダルを表示する
      onClick={() => {
        CLOSE_CONFIRM_SCREENS.includes(pathname)
          ? dispatch(
              messageModalActions.showLeaveConfirmModalOpen({
                onClickTransition: () => navigate(Path.TOP),
              })
            )
          : pathname === Path.TOP
          ? navigate(0)
          : navigate(Path.TOP);
      }}
    >
      <Icons.Image
        src={Icons.logo}
        className="mb-1 h-[15px] w-[115px] lg:h-[25px] lg:w-[192px]"
      />
      <p className="break-keep text-[14px] font-bold lg:text-[16px]">
        ピットサービス予約サイト
      </p>
    </div>
  );
};

export default TopIcon;
