import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * クエリパラメータを取り出すhooks
 */
export const useQueryParams = () => {
  const location = useLocation();
  const [params, setParams] = useState({});

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newParams = {};
    for (const key of Array.from(queryParams.keys())) {
      newParams[key] = queryParams.get(key);
    }
    setParams(newParams);
  }, [location]);

  return params;
};
