import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import minMax from 'dayjs/plugin/minMax';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import './index.css';
dayjs.locale('ja');
dayjs.extend(minMax);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const options = {
  init: {distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}}, // NREUM.init
  info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-5dacd79b312f6413495",applicationID:"1103269032",sa:1}, // NREUM.info
  loader_config: {accountID:"4103616",trustKey:"4103616",agentID:"1103269032",licenseKey:"NRJS-5dacd79b312f6413495",applicationID:"1103269032"} // NREUM.loader_config
};
new BrowserAgent(options);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // useEffect 2回呼ばれているので、コメントアウト
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
