/**
 * 各画面のタイトル定義
 */

// 画面パス一覧
const Title = {
  TOP: 'オイル・タイヤ・車検などのオートバックス公式予約サイト',
  LOGIN: 'ログイン｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
  REGISTER:
    '新規会員登録｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
  LOGIN_REGISTER:
    'ログイン｜新規会員登録｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
  DESCRIPTION:
    'ログイン情報忘れ｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
  // もしかしたらここも追加になるかもしれないので残しておく
  //   RESERVATION_SHOP_SELECT: '/reservation/shop-select',
  RESERVATION_SERVICE_SELECT:
    '店舗から予約｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
  RESERVATION_LIST:
    '予約確認｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
  PIT_SERVICE:
    'ピットサービスから予約｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
  KARTE_DESCRIPTION:
    '車のカルテ｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
  KARTE: '車のカルテ｜オイル・タイヤ・車検などのオートバックス公式予約サイト',
};

export default Title;
