import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import Linked from 'components/linked';
import AprosButton from 'components/aprosButton';
import { openTirePage } from 'utils/tireRequestUtil';
import { SERVICE_CODE_TIRE } from 'constants/service';

const DateSelectOneClickArea = ({
  aprosCode,
  waitTimeInfo,
  cardNumber,
  isTireContract,
  disabled,
  onClick,
  frontStatus,
}) => {
  return (
    <div
      className={classNames(
        'mt-8 flex flex-col border lg:mt-10 lg:max-w-[635px]',
        disabled
          ? 'border-disabled text-disabled'
          : 'border-navy-hover text-navy'
      )}
    >
      {waitTimeInfo?.confirmMenuWaitMinutes !== null && frontStatus === 0 && (
        <div
          className={classNames(
            'flex w-full flex-row items-end justify-center py-1 text-white',
            disabled ? 'bg-disabled' : 'bg-navy-hover'
          )}
        >
          {waitTimeInfo?.confirmMenuWaitMinutes === 0 ? (
            <label className="text-sm font-medium">
              今から作業受付が可能です。
            </label>
          ) : (
            <>
              <label className="text-sm font-medium">今なら約</label>
              <label className="mx-1 text-[22px] font-medium leading-[28px] sm:text-[32px] sm:leading-[35px]">
                {waitTimeInfo?.confirmMenuWaitMinutes}
              </label>
              <label className="text-sm font-medium">
                分後のご予約が可能です
              </label>
            </>
          )}
        </div>
      )}
      <div className="flex w-full flex-col justify-center bg-white px-2 pb-7 pt-2 text-center sm:pb-6 sm:pt-4">
        {waitTimeInfo?.confirmMenuWaitMinutes !== null &&
        waitTimeInfo?.confirmMenuWaitMinutes !== 0 &&
        frontStatus !== 2 ? (
          <>
            <div className="flex flex-row items-end justify-center gap-x-2">
              <label className="text-sm">作業開始目安時間</label>
              <label
                className={classNames(
                  'text-base font-bold',
                  !disabled && 'text-red'
                )}
              >
                {waitTimeInfo &&
                  dayjs(
                    `${dayjs().format('YYYYMMDD')} ${waitTimeInfo.getTime}`,
                    'YYYYMMDD HH:mm'
                  )
                    .add(waitTimeInfo?.confirmMenuWaitMinutes ?? 0, 'minutes')
                    .format('M月D日(ddd) HH:mm')}
              </label>
            </div>
            <div className="text-sm mt-1 sm:mt-2">この時間で予約しますか？</div>
          </>
        ) : (
          <div className="flex flex-row items-end justify-center gap-x-2">
            <label className="text-sm">
              「待ち時間予約」サービスは、現在ご利用できません。
            </label>
          </div>
        )}
        {aprosCode === SERVICE_CODE_TIRE && isTireContract && (
          <div className="mt-3 flex flex-col items-start sm:items-center">
            <label className="text-sm text-left sm:text-center">
              タイヤ保管サービスをご利用の方はここでの予約ができません。
            </label>
            <div className="flex flex-row sm:justify-center">
              <Linked
                text="専用予約ページ"
                isLine={true}
                className={classNames(
                  'text-sm',
                  disabled &&
                    'cursor-default text-disabled after:border-disabled hover:text-disabled hover:after:border-disabled'
                )}
                onClick={() => !disabled && openTirePage(cardNumber)}
              />
              <label className="text-sm">からご予約をお願いします。</label>
            </div>
          </div>
        )}
        <div className="mt-6 sm:mt-5">
          <AprosButton
            text="ワンクリックで予約する"
            disabled={disabled}
            comClassName="reserve"
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

export default DateSelectOneClickArea;
