import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { toFullWidth, zenkakuNum2HankakuNum } from 'utils/stringUtil';
import { useDebounce } from 'react-use';

export default memo(
  ({
    formik = null,
    fieldName = '',
    value = null,
    onChange = null,
    changeAfter = null,
    placeholder = null,
    disabled = false,
    error = false,
    className = '',
    autoFullWidth = false,
    autoHankaku = false,
    numberOnly = false,
    type,
    onBlur,
    ...rest
  }) => {
    const formikValue = formik?.values?.[fieldName];
    useDebounce(
      () => {
        autoFullWidth &&
          formikValue &&
          formik?.setFieldValue(fieldName, toFullWidth(formikValue));
      },
      1000,
      [formikValue, autoFullWidth]
    );

    const [isComposing, setIsComposing] = useState(false);

    useEffect(() => {
      autoHankaku &&
        !isComposing &&
        formik?.setFieldValue(
          fieldName,
          zenkakuNum2HankakuNum(formikValue)
            .replace(/[^0-9]/g, '')
            .slice(0, rest.maxLength)
        );
    }, [formikValue, isComposing]);

    return (
      <div className={classNames('flex w-full flex-col', className)}>
        <textarea
          autoComplete="off"
          placeholder={placeholder}
          disabled={disabled}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
          className={classNames(
            'scrollBar text-sm h-[100px] overflow:hidden bg-white p-2 font-medium sm:h-[127px] sm:pr-3 lg:pr-2 rounded-none border-non bg-white font-medium text-navy outline-none placeholder:text-disabled hover:rounded-none focus:rounded-none disabled:border-disabled disabled:bg-white max-sm:py-1 sm:h-12',
            {
              'border-navy': !formik?.errors[fieldName],
              'border-red': error,
              'border-disabled': disabled,
              'border-1.5': error,
            }
          )}
          value={formik ? formik?.values?.[fieldName] : value}
          onBlur={e => {
            onBlur?.(e);
            formik?.setFieldTouched(fieldName, true);
          }}
          onChange={e => {
            let value = e.target.value;
            if (numberOnly) {
              value = value.replace(/[^0-9０-９]+/, '');
            }
            if (formik) {
              formik?.setFieldValue(fieldName, value);
              if (changeAfter) {
                changeAfter(value);
              }
            } else {
              onChange(value);
            }
          }}
          {...rest}
        />
      </div>
    );
  }
);
