import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RESERVE_REFLECTION } from 'constants/apiURL';
import axios from '../utils/axiosUtil';

const initialState = {
  isError: true,
  isShowTireModal: false,
};

export const reservationReflection = createAsyncThunk(
  'reservationReflection',
  async (params, { rejectWithValue }) => {
    try {
      return await axios.post(RESERVE_REFLECTION, params);
    } catch (error) {
      const errorMessage = error?.response?.data?.displayMessage
        ? error.response.data.displayMessage
        : error?.response?.data?.errorMessage;

      const responseCode = error?.response?.data?.responseCode;

      return rejectWithValue(
        /30100|30400|30500/.test(errorMessage) ? responseCode : errorMessage
      );
    }
  }
);

export const reservationConfirmSlice = createSlice({
  name: 'reservationConfirm',
  initialState,
  reducers: {
    showTireModal: state => {
      state.isShowTireModal = true;
    },
    closeTireModal: state => {
      state.isShowTireModal = false;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {},
});

export const actions = reservationConfirmSlice.actions;
export default reservationConfirmSlice.reducer;
