import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalBase from 'components/modalBase/index';
import AprosButton from 'components/aprosButton/index';
import { actions as loginActions } from 'slices/loginSlice';
import { encode } from 'utils/base64Util';
import Path from 'constants/routePath';
import { sapLogoutAndLogin } from '../../utils/sapSdkUtil';
import { useDeviceSize } from 'hooks/useDeviceSize';

const AbidDifferenceModal = () => {
  const dispatch = useDispatch();
  const { isSp } = useDeviceSize();

  const { accountInfo } = useSelector(state => state.account);

  const {
    showAbidDifferenceModal,
    abidDifferenceMortgage,
    abidDifferenceNextPage,
    queryParams,
  } = useSelector(state => state.login);

  const otherAbId = queryParams?.p12;

  const queryKeys = Object.keys(queryParams ?? {});

  const fromNetShop =
    queryKeys.length === 2 &&
    queryKeys.includes('p10') &&
    queryKeys.includes('p12');

  const closeModal = () => dispatch(loginActions.closeAbidDifferenceModal());

  const handleBeforeUnloadEvent = useCallback(event => {
    event.returnValue = '';
  }, []);

  const transitionToClp = () => {
    //p12をotherAbIdにしてパラメータを作り直す
    let params = '?p12=' + encode(otherAbId);
    Object.keys(queryParams).forEach(function (key) {
      if (key !== 'p12') {
        params += '&' + key + '=' + queryParams[key];
      }
    });

    window.addEventListener('beforeunload', handleBeforeUnloadEvent, true);

    //ログアウトしてから、CLPへ遷移
    sapLogoutAndLogin(
      accountInfo?.cardNumber,
      dispatch,
      process.env.REACT_APP_BASE_URL + Path.TOP + params
    );
  };

  return (
    <ModalBase
      isModalOpen={showAbidDifferenceModal}
      toggleModal={() => {
        !abidDifferenceMortgage && closeModal();
        abidDifferenceMortgage && transitionToClp();
      }}
      className={`flex flex-col items-center overflow-auto pb-9 pt-8 sm:w-[670px] sm:pb-11 sm:pt-10 lg:pb-[60px] lg:pt-14`}
    >
      <label className="text-base whitespace-pre-wrap px-12 text-center font-bold text-navy sm:px-20 lg:px-36">
        {abidDifferenceMortgage && (
          <>
            ピットサービスの予約に進みます。
            <br /> ネットショップのアカウント(AB ID)で
            <br />
            ログインしてください。
          </>
        )}
        {abidDifferenceMortgage || (
          <>
            現在ピットサービス予約サイトにログイン中の
            <br />
            アカウント(AB ID)と異なります。
          </>
        )}
      </label>
      <label className="text-xs mt-4 whitespace-pre-wrap text-center font-medium text-navy-light sm:mt-6 lg:mt-4">
        {abidDifferenceMortgage && (
          <>
            ネットショップ購入品のピット作業は
            <br />
            ご購入時と同じアカウント(AB ID)で{isSp ? '\n' : ''}
            ご予約をお願いいたします。{' '}
          </>
        )}
        {abidDifferenceMortgage || (
          <>※ログアウトし、再度ログインをお願い致します。 </>
        )}
        {abidDifferenceMortgage ? '' : ''}
      </label>
      {abidDifferenceMortgage && (
        <div className="mt-8 lg:mt-12">
          <AprosButton
            comClassName="sub"
            text="閉じる"
            className="w-[260px]"
            onClick={() => {
              transitionToClp();
            }}
          />
        </div>
      )}
      {abidDifferenceMortgage || (
        <div className="mt-8 lg:mt-12">
          <AprosButton
            comClassName="sub"
            text="閉じる"
            className="w-[260px]"
            onClick={() => {
              closeModal();
            }}
          />
        </div>
      )}
    </ModalBase>
  );
};

export default AbidDifferenceModal;
