import './aprosButton.scss';
const AprosButton = ({
  text,
  onClick,
  disabled,
  comStyle = {},
  comClassName,
  className,
  ...restProps
}) => {
  let btnStyle;
  switch (comClassName) {
    case 'main':
      btnStyle =
        'text-[14px] sm:text-[16px] rounded-[22px] h-[40px] w-[180px] bg-navy text-white shadow-main hover:bg-navy-hover active:shadow-none drop-shadow-[2.5px_2.5px_0px_#02060433]';
      break;
    case 'reserve':
      btnStyle =
        'text-[14px] sm:text-[16px] rounded-[22px] h-[40px] w-[260px] bg-red text-white shadow-sub hover:bg-red-hover  active:shadow-none drop-shadow-[2.5px_2.5px_0px_#EAB4AF33]';
      break;
    case 'sub':
      btnStyle = `text-[14px] sm:text-[16px] rounded-[22px] h-[40px] w-[180px] border border-solid border-white-hover bg-white text-navy 
        shadow-main hover:text-white-hover  active:shadow-none drop-shadow-[2.5px_2.5px_0px_#02060433]`;
      break;
    case 'other':
      btnStyle = `text-[14px] sm:text-[16px] h-[60px] w-[165px] rounded-xl bg-white text-navy drop-shadow-[2.5px_2.5px_6px_#00244529] 
      lg:hover:bg-[#EEF0F2] active:drop-shadow-none border-[0.5px] border-[#E5E5E5]`;
      break;
    default:
      break;
  }
  return (
    <button
      onClick={onClick}
      contentEditable={false}
      style={comStyle}
      className={`btn-custom font-bold
      tracking-[.06em] disabled:cursor-not-allowed disabled:bg-disabled
      disabled:text-white disabled:shadow-none disabled:drop-shadow-[5px_5px_0px_#02060433]
      disabled:active:mt-0
      ${btnStyle} ${
        ['main', 'reserve', 'sub'].includes(comClassName) && !disabled
          ? 'active:translate-y-1'
          : ''
      }
      ${className}`}
      disabled={disabled}
      {...restProps}
    >
      {text}
    </button>
  );
};
export default AprosButton;
