import { isDesktop, isIOS, isAndroid } from 'react-device-detect';

export const getTermKbn = () =>
  isDesktop ? '1' : isIOS ? '3' : isAndroid ? '4' : '2';

export const rootUnscroll = () => {
  const body = document.getElementsByTagName('body');
  if (
    /Safari/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent)
  ) {
    body[0].setAttribute('ontouchmove', 'event.preventDefault()');
  } else {
    body[0].style.overflowY = 'hidden';
  }
};
export const rootScroll = () => {
  const body = document.getElementsByTagName('body');

  if (
    /Safari/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent)
  ) {
    body[0].setAttribute('ontouchmove', '');
  } else {
    body[0].style.overflowY = 'scroll';
  }
};

export const preventRootScroll = () => {
  rootUnscroll();
  clearTimeout(window.scrollEndTimer);
  window.scrollEndTimer = setTimeout(rootScroll, 100);
};

export const scrollTo = offset =>
  setTimeout(() => {
    const element = document.getElementById('root');

    element.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  });
