export const REGISTER_SHOP_STEP = ['店舗選択', '日時選択', '予約内容確認'];

export const REGISTER_SERVICE_STEP = [
  '店舗・サービス選択',
  '日時選択',
  '予約内容確認',
];

export const UPDATE_STEP = ['日時変更', '予約内容確認'];

export const CREATE_STEP = ['日時選択', '予約内容確認'];
