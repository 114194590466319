import Linked from 'components/linked';
import ModalBase from 'components/modalBase/index';
import Icons from 'images/icons';
import { useDispatch, useSelector } from 'react-redux';
import { messageModalActions } from 'slices/messageModalSlice';
import { sleep } from 'utils/sleepUtil';
import { openTirePage } from 'utils/tireRequestUtil';



const TireStorageModal = (props) => {
  const dispatch = useDispatch();

  const { isTireStorageModalOpen, isOpenCalendar, callBack } =
    useSelector(state => state.messageModal);

  const { cardNumber, isHiddenClose, reserveClick, openCalendar } = props;

  return (
    <ModalBase
      isModalOpen={isTireStorageModalOpen}
      className="mx-4 flex h-fit w-full max-w-[800px] flex-col pb-8 pt-12 text-navy sm:w-[560px] sm:pb-[60px] sm:pt-[62px] lg:w-full lg:pb-11 lg:pt-12"
    >
      {!isHiddenClose && (
        <div className="absolute right-0 top-0 py-2 pr-2">
          <Icons.Image
            src={Icons.closeModal}
            className="float-right h-8 cursor-pointer lg:h-[50px]"
            onClick={() => dispatch(messageModalActions.closeTireStorageModal())}
          />
        </div>
      )}

      <div
        id="modal"
        className="mr-2 px-4 lg:px-24"
      >
        <div className="flex flex-row items-center justify-center gap-2 px-3 py-2 sm:py-4">
          <Icons.Image src={Icons.alert} />
          <p className="text-sm whitespace-pre-wrap font-bold text-red">
            保管サービスご利用中の<br />
            タイヤ交換ではございませんか?
          </p>
        </div>
        <div className="ml-3 mt-[3px] flex flex-col gap-y-[7px]">
          タイヤ保管サービスご利用中のタイヤは、こちらのフォームではご予約いただけません。専用予約ページからご予約をお願いいたします。
        </div>
        <div className="flex flex-col justify-center items-center mt-6">
          <div className="text-sm whitespace-pre-wrap font-bold text-navy">
            保管中のタイヤの場合
          </div>
          <div>
            <Linked
              text={'保管サービス専用予約ページへ進む'}
              onClick={() => openTirePage(cardNumber, null)}
              isLine
              linkIcon="R"
            />
          </div>
          <div className="text-sm whitespace-pre-wrap font-bold text-navy mt-6">
            保管中のタイヤではない場合
          </div>
          <div>
            <Linked
              text={'予約を続ける'}
              onClick={async () => {
                await dispatch(messageModalActions.closeTireStorageModal())
                // callBackを正常に実行するために500ms待機
                sleep(500)

                //ピット予約ページからの遷移の場合は予約確認画面へ遷移、トップページは予約画面へ遷移もしくはカレンダーモーダルを開く
                if (callBack) {
                  callBack?.()
                } else {
                  //今日を選択した場合は予約確認画面へ遷移、それ以外はカレンダーモーダルを開く
                  isOpenCalendar ? openCalendar(true) : reserveClick()
                }
              }}
              isLine
              linkIcon="R"
            />
          </div>
        </div>
      </div>

      {!isHiddenClose && (
        <div className="mt-8 flex items-center justify-center px-4 lg:px-24">
          <Linked
            text={'閉じる'}
            onClick={() => dispatch(messageModalActions.closeTireStorageModal())}
          />
        </div>
      )}
    </ModalBase>
  );
};
export default TireStorageModal;