import { memo } from 'react';
import classNames from 'classnames';
import InputTitleLabel from 'components/inputTitleLabel';
import BaseTextInput from './baseTextInput';
import { useDeviceSize } from 'hooks/useDeviceSize';

export default memo(
  ({
    title = '',
    formik,
    required = false,
    fieldName,
    value = null,
    placeholder = '入力してください',
    suffix = '',
    fields = null,
    disabled = false,
    numberOnly = false,
    className = { boxClass: '', titleClass: '', inputClass: '' },
    /**
     * タイトルを縦並びにするか
     * タブレットでのみ動作する
     * ※タブレットは横並びと縦並びが混在するため
     * 　PCは横並び固定、SPは縦並び固定
     */
    isTitleVerticalPosition = false,
    isShowErrorArea = true,
    /** エラーメッセージ表示を独自で制御する場合に指定 */
    errorMessage = null,
    // エラーメッセージが長くて表示領域を超える場合、このフラグをtrueにしてメッセージ内に改行コードを入れる
    isLongError = false,
    isErrorAreaAbsolute = false,
    type,
    ...rest
  }) => {
    const { isTablet, isSp } = useDeviceSize();

    const isVertical = isSp || (isTablet && isTitleVerticalPosition);

    const _fields = fields || [
      { fieldName, placeholder, value, suffix, ...rest },
    ];
    const isSuffix = _fields?.[_fields.length - 1]?.suffix;

    const createErrorMessage = field =>
      errorMessage != null
        ? errorMessage
        : (!disabled &&
            formik &&
            formik?.touched[field] &&
            formik?.errors[field]) ||
          '';

    const createErrorMessageSummary = () =>
      _fields?.map(f => createErrorMessage(f.fieldName))?.filter(f => f)?.[0] ??
      '';

    return (
      <div className={classNames('w-full p-2', className.boxClass)}>
        <div
          className={classNames(
            isTitleVerticalPosition
              ? 'flex flex-col gap-y-1 lg:flex-row'
              : 'flex flex-col gap-y-1 sm:flex-row',
            isErrorAreaAbsolute ? 'relative' : ''
          )}
        >
          {!isVertical ? (
            <div className="flex flex-col justify-center">
              {isShowErrorArea && <div className="h-[42px]"></div>}
              <InputTitleLabel
                title={title}
                required={required}
                disabled={disabled}
                className={className.titleClass}
              />
            </div>
          ) : (
            <div
              className={classNames(
                'flex flex-row items-end justify-between',
                isShowErrorArea ? 'h-[42px]' : '',
                isErrorAreaAbsolute && 'absolute bottom-12 right-0'
              )}
            >
              <InputTitleLabel
                title={title}
                required={required}
                disabled={disabled}
                className={classNames(className.titleClass)}
              />
              {isShowErrorArea && (
                <label
                  className={classNames(
                    'text-sm w-auto justify-end text-right text-red',
                    isSuffix ? 'mr-6' : '',
                    isLongError && isSp ? 'whitespace-pre-wrap' : ''
                  )}
                >
                  {createErrorMessageSummary()}
                </label>
              )}
            </div>
          )}
          <div
            className={classNames(
              'flex w-full gap-x-2 sm:gap-x-4',
              isTitleVerticalPosition ? 'lg:mt-0' : 'sm:mt-0'
            )}
          >
            {_fields.map((f, index) => {
              const { fieldName, placeholder, value, suffix, ...frest } = f;
              return (
                <div
                  key={fieldName || index}
                  className="flex w-full flex-col gap-y-1"
                >
                  {isShowErrorArea && !isVertical && (
                    <div
                      className={classNames(
                        'flex flex-row items-end justify-between',
                        !isErrorAreaAbsolute && isShowErrorArea
                          ? 'h-[42px]'
                          : '',
                        isErrorAreaAbsolute ? 'relative' : ''
                      )}
                    >
                      <div></div>
                      <label
                        className={classNames(
                          'text-sm w-auto justify-end text-right text-red',
                          isSuffix ? 'mr-6' : '',
                          isLongError && isSp ? 'whitespace-pre-wrap' : '',
                          isErrorAreaAbsolute && 'absolute right-0'
                        )}
                      >
                        {createErrorMessage(fieldName)}
                      </label>
                    </div>
                  )}
                  <span className="relative flex w-full">
                    <BaseTextInput
                      formik={formik}
                      fieldName={fieldName}
                      placeholder={placeholder}
                      value={value}
                      disabled={disabled}
                      numberOnly={numberOnly}
                      error={!!createErrorMessage(fieldName)}
                      type={type}
                      className={classNames(className.inputClass)}
                      {...frest}
                    />
                    {suffix && (
                      <label
                        className={classNames(
                          'ml-2 self-center',
                          disabled ? 'text-disabled' : 'text-navy'
                        )}
                      >
                        {suffix}
                      </label>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
