import { memo } from 'react';
import classNames from 'classnames';
import Icons from 'images/icons';

export default memo(
  ({
    formik = null,
    value = '',
    fieldName = '',
    placeholder = 'YYYY/MM/DD',
    disabled = false,
    className = '',
    onClick,
    ...rest
  }) => {
    return (
      <div className={classNames('relative flex w-full flex-col', className)}>
        <input
          type="text"
          autoComplete="off"
          placeholder={placeholder}
          disabled={disabled}
          className={classNames(
            'text-sm h-11 w-full cursor-pointer rounded-none border bg-white px-2 font-medium text-navy outline-none placeholder:text-disabled hover:rounded-none focus:rounded-none disabled:border-disabled disabled:bg-white disabled:text-disabled max-sm:py-1 sm:h-12',
            { 'border-disabled': disabled }
          )}
          value={value}
          onClick={onClick}
          readOnly
          {...rest}
        />
        <Icons.Image
          src={Icons.cal}
          className="absolute right-[18px] h-11 w-6 cursor-pointer sm:h-12"
          onClick={onClick}
        />
      </div>
    );
  }
);
