import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showCorporationLoginModal: false,
  closeCorporationLoginModal: false,
  showAbidDifferenceModal: false,
  abidDifferenceMortgage: false,
  abidDifferenceNextPage: null,
  queryParams: null,
  isClosed: false,
};

export const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    showCorporationLoginModal: state => {
      state.showCorporationLoginModal = true;
    },
    closeCorporationLoginModal: state => {
      state.showCorporationLoginModal = false;
    },
    showAbidDifferenceModal: (state, { payload }) => {
      if (!state.isClosed) {
        state.showAbidDifferenceModal = true;
        state.abidDifferenceMortgage = payload.abidDifferenceMortgage;
        state.abidDifferenceNextPage = payload.abidDifferenceNextPage;
        state.queryParams = payload.queryParams;
      }
    },
    closeAbidDifferenceModal: state => {
      state.showAbidDifferenceModal = false;
      state.abidDifferenceMortgage = false;
      state.abidDifferenceNextPage = null;
      state.queryParams = null;
      state.isClosed = true;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {},
});

export const actions = loginSlice.actions;
export default loginSlice.reducer;
