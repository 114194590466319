import React from 'react';
import { Fragment, useState } from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';
import Proctitle from 'components/proctitle';
import Icons from 'images/icons';
import Linked from 'components/linked/index';
import { useNavigate } from 'react-router-dom';
import Path from 'constants/routePath';
import './style.scss';

const FAQ = () => {
  function Icon({ open }) {
    return (
      <svg
        xmlns={Icons.open01}
        className={`${open ? 'rotate-180' : ''} -ml-3 h-5 w-5 lg:-ml-10`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={3}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    );
  }
  const [openAcc1, setOpenAcc1] = useState(false);
  const [openAcc2, setOpenAcc2] = useState(false);
  const [openAcc3, setOpenAcc3] = useState(false);
  const [openAcc4, setOpenAcc4] = useState(false);
  const [openAcc5, setOpenAcc5] = useState(false);
  const [openAcc6, setOpenAcc6] = useState(false);
  const [openAcc7, setOpenAcc7] = useState(false);
  const [openAcc8, setOpenAcc8] = useState(false);
  const [openAcc9, setOpenAcc9] = useState(false);
  const [openAcc10, setOpenAcc10] = useState(false);

  const handleOpenAcc1 = () => setOpenAcc1(cur => !cur);
  const handleOpenAcc2 = () => setOpenAcc2(cur => !cur);
  const handleOpenAcc3 = () => setOpenAcc3(cur => !cur);
  const handleOpenAcc4 = () => setOpenAcc4(cur => !cur);
  const handleOpenAcc5 = () => setOpenAcc5(cur => !cur);
  const handleOpenAcc6 = () => setOpenAcc6(cur => !cur);
  const handleOpenAcc7 = () => setOpenAcc7(cur => !cur);
  const handleOpenAcc8 = () => setOpenAcc8(cur => !cur);
  const handleOpenAcc9 = () => setOpenAcc9(cur => !cur);
  const handleOpenAcc10 = () => setOpenAcc10(cur => !cur);

  const navigate = useNavigate();

  // TODO 文章ここに入れる
  const faqList = [
    {
      question: 'オートバックスの会員制度はどのようなシステムですか',
      answer: (
        <label className="text-[14px] sm:text-[18px]">
          会員制度については
          <Linked
            text="こちら"
            isLine={true}
            href="https://www.autobacs.com/shop/info/questions?subject_id=BA"
            className={'mb-1 inline-flex text-[14px] sm:text-[18px]'}
          />
          をご確認下さい。
        </label>
      ),
    },
    {
      question: 'ログインしたいがカード番号がわかりません',
      answer: (
        <label className="text-[14px] sm:text-[18px]">
          カード番号の確認方法は
          <Linked
            text="こちら"
            isLine={true}
            href="https://yoyaku.autobacs.jp/pit_reserve/cardNo.html"
            className={'mb-1 inline-flex  text-[14px] sm:text-[18px]'}
          />
          をご確認下さい。
        </label>
      ),
    },
    {
      question: 'バックスとはなんですか',
      answer: (
        <label className="text-[14px] sm:text-[18px]">
          バックスについては
          <Linked
            text="こちら"
            isLine={true}
            href={Path.BACS_INFO}
            className={'mb-1 inline-flex text-[14px] sm:text-[18px]'}
          />
          をご確認下さい。
        </label>
      ),
    },
    {
      question: '会員登録・変更方法について',
      answer: (
        <label className="text-[14px] sm:text-[18px]">
          会員登録・変更方法は
          <Linked
            text="こちら"
            isLine={true}
            href="https://www.autobacs.com/shop/info/questions?subject_id=BI"
            className={'mb-1 inline-flex text-[14px] sm:text-[18px]'}
          />
          をご確認下さい。
        </label>
      ),
    },
    {
      question: '予約内容の変更方法について',
      answer: (
        <label className="text-[14px] sm:text-[18px]">
          予約確認画面からご確認いただけます。確認画面は
          <Linked
            text="こちら"
            isLine={true}
            onClick={() => navigate(Path.RESERVATION_LIST)}
            className={'mb-1 inline-flex  text-[14px] sm:text-[18px]'}
          />
        </label>
      ),
    },
    {
      question: '予約のキャンセル方法について',
      answer: (
        <label className="text-[14px] sm:text-[18px]">
          予約確認画面（現在の予約）予約キャンセルからお願いします。確認画面は
          <Linked
            text="こちら"
            isLine={true}
            onClick={() => navigate(Path.RESERVATION_LIST)}
            className={'mb-1 inline-flex text-[14px] sm:text-[18px]'}
          />
        </label>
      ),
    },
    {
      question: '予約番号が分からないのでキャンセル/時間変更ができません',
      answer: (
        <label className="text-[14px] sm:text-[18px]">
          予約番号がなくても予約確認画面（現在の予約）からキャンセルいただけます。確認画面は
          <Linked
            text="こちら"
            isLine={true}
            onClick={() => navigate(Path.RESERVATION_LIST)}
            className={'mb-1 inline-flex text-[14px] sm:text-[18px]'}
          />
        </label>
      ),
    },
    {
      question: '希望のピットサービスがない場合はどうすればいいですか',
      answer: (
        <>
          <div className="flex flex-col text-[14px] sm:text-[18px]">
            <label>
              店舗によってはご希望されているピットサービスの予約ができない場合がございます。
            </label>
            <div className="flex flex-row">
              <label>
                詳細につきましては、ご希望の店舗へ直接お問合せください。店舗検索ページは
                <Linked
                  text="こちら"
                  isLine={true}
                  href="https://www.autobacs.com/shop/search/search?pre_url=/store%3f"
                  className={'mb-1 inline-flex text-[14px] sm:text-[18px]'}
                />
              </label>
            </div>
          </div>
        </>
      ),
    },
    {
      question: '予約日時が選べないのですが',
      answer: (
        <div className="flex flex-col text-[14px] sm:text-[18px]">
          <label>
            作業内容の詳細内容を選択されていない可能性がございます。
            <br></br>
            (オイル交換であれば、フィルターの有無、タイヤ交換であれば作業内容)を選択してください。
            <br></br>
            また、ご希望の作業時間が□の場合は店舗にお問い合わせください。
          </label>
        </div>
      ),
    },
    {
      question:
        '共通オートバックスIDを発行してログインしたら、これまでの予約情報などが見られなくなりました。',
      answer: (
        <div className="flex flex-col text-[14px] sm:text-[18px]">
          <label>
            これまでログインに使用していた会員カード番号で、共通オートバックスIDを発行してください。予約情報などを引き継ぐことができます。
          </label>
        </div>
      ),
    },
  ];

  const getOpenAcc = i => {
    return i === 1
      ? openAcc1
      : i === 2
      ? openAcc2
      : i === 3
      ? openAcc3
      : i === 4
      ? openAcc4
      : i === 5
      ? openAcc5
      : i === 6
      ? openAcc6
      : i === 7
      ? openAcc7
      : i === 8
      ? openAcc8
      : i === 9
      ? openAcc9
      : openAcc10;
  };

  const getHandleOpenAcc = i => {
    return i === 1
      ? handleOpenAcc1
      : i === 2
      ? handleOpenAcc2
      : i === 3
      ? handleOpenAcc3
      : i === 4
      ? handleOpenAcc4
      : i === 5
      ? handleOpenAcc5
      : i === 6
      ? handleOpenAcc6
      : i === 7
      ? handleOpenAcc7
      : i === 8
      ? handleOpenAcc8
      : i === 9
      ? handleOpenAcc9
      : handleOpenAcc10;
  };

  return (
    <>
      <Proctitle
        text="FAQ/よくあるご質問"
        className={{ areaClass: 'pb-10' }}
        useH1={true}
      />
      <Fragment>
        {faqList?.map((faq, i) => (
          <Accordion
            open={getOpenAcc(i + 1)}
            icon={<Icon open={getOpenAcc(i + 1)} />}
          >
            <AccordionHeader
              onClick={getHandleOpenAcc(i + 1)}
              className="mt-[16px] h-[48px] h-auto border-b-0 bg-navy/10 py-0 pl-[13px] lg:pl-[40px]"
            >
              <div className="flex items-center font-bold">
                <div className="mr-[14px] text-[18px] sm:text-[24px]">Q</div>
                <div className="py-3 text-left text-[14px] sm:text-[18px]">
                  {faq.question}
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody className="text-sm pl-[15px] pr-1 lg:px-10 lg:pl-[30px]">
              <div className="flex">
                <div className="mr-[13px] mt-[-6px] pl-[-5px] text-[18px] font-bold sm:text-[24px] lg:mr-[14px] lg:pl-[13px]">
                  A
                </div>
                <div className="flex flex-row text-[14px] sm:text-[18px]">
                  {faq.answer}
                </div>
              </div>
            </AccordionBody>
          </Accordion>
        ))}
      </Fragment>
    </>
  );
};

export default FAQ;
