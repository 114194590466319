import React from 'react';
import ModalBase from 'components/modalBase';
import InputText from 'components/inputText';
import InputTitleLabel from 'components/inputTitleLabel';
import RadioButton from 'components/radioButton';
import AprosButton from 'components/aprosButton';
import Icons from 'images/icons';
import { useDeviceSize } from 'hooks/useDeviceSize';
import {
  UPDATE_MAIL_ADDRESS_CLOSE,
  UPDATE_MAIL_ADDRESS_RESERVE,
} from '../../../constants/gtmCustomEvent';

const MailAddressSettingModal = ({
  formik,
  isModalOpen,
  closeModal,
  onClick,
}) => {
  const { isPc } = useDeviceSize();

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={() => {
        // GTMカスタムイベントパラメータ
        const dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
          event: UPDATE_MAIL_ADDRESS_CLOSE,
        });

        closeModal();
      }}
      className="flex flex-col overflow-auto px-4 pb-10 pt-12 text-navy sm:px-11 sm:pb-10 sm:pt-[66px] lg:px-20 lg:pb-16 lg:pt-16"
    >
      <div className="absolute right-0 top-0 py-2 pr-2">
        <Icons.Image
          src={Icons.closeModal}
          className="float-right h-8 cursor-pointer lg:h-[50px]"
          onClick={() => {
            // GTMカスタムイベントパラメータ
            const dataLayer = (window.dataLayer = window.dataLayer || []);
            dataLayer.push({
              event: UPDATE_MAIL_ADDRESS_CLOSE,
            });

            closeModal();
          }}
        />
      </div>
      <div className="flex flex-col">
        <div className="text-base whitespace-pre-wrap text-center font-bold">
          {isPc
            ? '予約確認メールをお送りします\nメールアドレスの登録が必要です'
            : 'メールアドレスを設定してください'}
        </div>

        <div className="mt-5 sm:mt-3 lg:mt-1">
          <InputText
            formik={formik}
            fieldName="mail"
            title="メールアドレス"
            required={true}
            className={{ boxClass: 'p-0', inputClass: 'lg:ml-6' }}
            isTitleVerticalPosition={true}
          />
        </div>

        <div className="mt-8 flex flex-col lg:mt-10 lg:flex-row">
          <InputTitleLabel
            title="オートバックスからのお得情報"
            required={true}
          />
          <div className="flex flex-row lg:mt-0">
            <RadioButton
              label="受け取る"
              formik={formik}
              fieldName="mailMagazine"
              value="1"
              className={{ labelClass: 'ml-3' }}
            />
            <RadioButton
              label="受け取らない"
              formik={formik}
              fieldName="mailMagazine"
              value="3"
            />
          </div>
        </div>
      </div>

      <div className="mt-10 self-center lg:mt-9">
        <AprosButton
          comClassName="reserve"
          text="予約を確定する"
          disabled={!formik.isValid}
          onClick={() => {
            // GTMカスタムイベントパラメータ
            const dataLayer = (window.dataLayer = window.dataLayer || []);
            dataLayer.push({
              event: UPDATE_MAIL_ADDRESS_RESERVE,
            });

            onClick();
          }}
        />
      </div>
    </ModalBase>
  );
};

export default MailAddressSettingModal;
