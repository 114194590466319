import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messageModalActions } from 'slices/messageModalSlice';
import ModalBase from 'components/modalBase/index';
import AprosButton from 'components/aprosButton/index';

const MessageModal = () => {
  const dispatch = useDispatch();

  const { isMessageModalOpen, closeSameWithClick, btnText, message, onClick } =
    useSelector(state => state.messageModal);

  return (
    <ModalBase
      isModalOpen={isMessageModalOpen}
      toggleModal={() =>
        closeSameWithClick && onClick
          ? onClick()
          : dispatch(messageModalActions.closeMessageModal())
      }
      className={`flex flex-col items-center overflow-auto pb-[60px] pt-14 lg:pb-[104px] lg:pt-24`}
    >
      <label className="text-base mx-4 whitespace-pre-wrap text-center font-bold text-navy sm:mx-8 lg:mx-12">
        {message}
      </label>
      <div className="mt-10 lg:mt-20">
        <AprosButton
          comClassName="sub"
          text={btnText ?? '閉じる'}
          className="w-[260px]"
          onClick={() =>
            onClick
              ? onClick()
              : dispatch(messageModalActions.closeMessageModal())
          }
        />
      </div>
    </ModalBase>
  );
};

export default MessageModal;
