import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Tabs from './components/tabs';
import { useFormik } from 'formik';
import Yup from 'utils/yupUtil';
import { openTirePage } from 'utils/tireRequestUtil';
import { getTermKbn } from 'utils/otherUtil';
import {
  reservationCancel,
  actions,
  fetchReserveList,
  fetchPitServiceList,
} from 'slices/reservationListSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import { UPDATE_STEP } from 'constants/reservation';
import Proctitle from 'components/proctitle';
import Linked from 'components/linked';
import ReserveCard from 'components/reserveCard';
import Path from 'constants/routePath';
import ReserveCancelModal from './modals/reserveCancelModal';
import ServiceLabel from './components/serviceLabel';
import dayjs from 'dayjs';
import Icons from 'images/icons';
import './style.scss';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { Helmet } from 'react-helmet';
import Title from '../../constants/title';

const CURRENT_RESERVE = '1';
const PAST_RESERVE = '2';

const TAB_ITEMS = [
  { key: CURRENT_RESERVE, label: '現在の予約' },
  { key: PAST_RESERVE, label: '過去の予約' },
];

const ReservationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTablet } = useDeviceSize();

  const {
    currentReserveList,
    pitServiceList,
    pastReserveList,
    showCancelModal,
    cancelData,
  } = useSelector(state => state.reservationList);

  const { loading } = useSelector(state => state.loading);

  const {
    accountInfo: { userId, cardNumber },
  } = useSelector(state => state.account);

  const formik = useFormik({
    initialValues: {
      currentTab: CURRENT_RESERVE,
    },
    validateOnMount: true,
    validationSchema: Yup.object({}),
  });

  const { currentTab } = formik.values;

  useEffect(() => {
    return () => {
      dispatch(actions.clear());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchPitServiceList());
  }, []);

  useEffect(() => {
    dispatch(
      fetchReserveList({
        kaiinNo: userId,
        dateKbn: currentTab,
      })
    );
  }, [dispatch, userId, currentTab]);

  const showErrorMessage = isWaitTimeReserve => {
    dispatch(
      messageModalActions.showMessageModal({
        btnText: 'OK',
        message: `予約時間を過ぎているため\n${
          isWaitTimeReserve ? '' : '変更・'
        }キャンセルが受付できません`,
        closeSameWithClick: true,
        onClick: () => {
          dispatch(messageModalActions.closeMessageModal());
          dispatch(
            fetchReserveList({
              kaiinNo: userId,
              dateKbn: '1',
              refresh: true,
            })
          );
        },
      })
    );
  };

  const children = key => {
    const list = CURRENT_RESERVE === key ? currentReserveList : pastReserveList;
    return (
      <div className="mt-4 sm:mt-8 lg:mt-10">
        <Helmet>
          <title>{Title.RESERVATION_LIST}</title>
        </Helmet>
        {!list ? (
          <>
            <div className="flex h-full items-center justify-center">
              <Icons.Image
                src={Icons.loading}
                className="h-[100px] w-[100px]"
              />
            </div>
          </>
        ) : list?.length > 0 ? (
          <>
            {CURRENT_RESERVE === key && (
              <div className="px-4 lg:flex lg:flex-row">
                <label className="text-sm font-medium text-navy">
                  ※予約確認画面より日時変更・予約キャンセルが出来ない場合
                  {isTablet ? <br></br> : ''}
                  直接ご予約の店舗までご連絡ください。
                </label>
                <div className="mt-1 sm:mt-2 lg:ml-4 lg:mt-0">
                  <Linked
                    text={'注意事項はこちら'}
                    href={Path.NOTES}
                    isLine={true}
                    linkIcon="R"
                    className="text-sm whitespace-nowrap font-bold"
                  />
                </div>
              </div>
            )}
            <div className="mt-6">
              {list.map((e, i) => (
                <div
                  key={`${e.yykNo}-${i}`}
                  className="mt-4 flex justify-center sm:mt-8 lg:mt-10 lg:items-center"
                >
                  <ReserveCard
                    reserveData={e}
                    pitServiceList={pitServiceList}
                    linkArea={
                      CURRENT_RESERVE === key ? createLinkArea(e) : null
                    }
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          !loading &&
          ((CURRENT_RESERVE === key && currentReserveList !== null) ||
            (PAST_RESERVE === key && pastReserveList !== null)) && (
            <label className="text-sm font-medium text-navy">
              予約情報はございません。
            </label>
          )
        )}
      </div>
    );
  };

  const createLinkArea = yykInfo => {
    const {
      hasTireStoringLink,
      unchangeable,
      yykCanFlg,
      work,
      yykNo,
      sshMei,
      sryMkrMei,
      tokNmRyk,
      searchMotoKbn,
    } = yykInfo;
    const hasTire = hasTireStoringLink === '1';
    const isTimeAvailable = dayjs().isBefore(
      `${work[0].sgyYmd} ${work[0]?.sgyStaHm}:00`
    );
    const canChange =
      unchangeable === '0' &&
      isTimeAvailable &&
      searchMotoKbn === '2' &&
      !['004', '031'].includes(work[0].chubunCd);

    const canReserve =
      yykCanFlg === '0' &&
      isTimeAvailable &&
      !['004', '031'].includes(work[0].chubunCd) &&
      work.length === 1;

    return hasTire || canChange || canReserve ? (
      <div
        className={classNames(
          'mt-5 flex flex-col border-t border-line pt-5 sm:mt-6 sm:pt-6',
          hasTire ? 'gap-1 sm:gap-2' : 'gap-5'
        )}
      >
        {hasTire && (
          <>
            <label className="whitespace-pre-wrap font-medium">
              タイヤ保管サービスの予約変更・キャンセル
            </label>
            {createLink('専用予約ページ', () =>
              openTirePage(cardNumber, yykNo)
            )}
          </>
        )}
        {canChange &&
          createLink('日時変更', () => {
            dayjs().isBefore(`${work[0].sgyYmd} ${work[0]?.sgyStaHm}:00`)
              ? navigate(Path.RESERVATION_DATE_SELECT, {
                  state: {
                    yykInfo: {
                      ...yykInfo,
                      work: yykInfo.work.map(w => ({
                        ...w,
                        shobun: w.shobun.map(s => ({
                          ...s,
                          chubunCd: w.chubunCd,
                        })),
                      })),
                    },
                    stepLabels: UPDATE_STEP,
                    baseScreen: Path.RESERVATION_LIST,
                  },
                })
              : showErrorMessage();
          })}
        {canReserve &&
          createLink('予約キャンセル', () =>
            dayjs().isBefore(`${work[0].sgyYmd} ${work[0]?.sgyStaHm}:00`)
              ? dispatch(
                  actions.showCancelModal({
                    yykInfo,
                    reserveId: yykNo,
                    reserveDate: (work ?? []).map((item, i, arr) => {
                      const reserveDate =
                        i === 0 || arr[i - 1]?.sgyYmd !== item.sgyYmd;
                      return (
                        <React.Fragment key={i}>
                          {reserveDate && (
                            <p className={i === 0 ? '' : 'mt-3'}>{`${dayjs(
                              item.sgyYmd
                            ).format('YYYY年M月D日(ddd)')} ${dayjs(
                              item.sgyStaHm,
                              'HHmm'
                            ).format('HH:mm')}`}</p>
                          )}
                        </React.Fragment>
                      );
                    }),
                    serviceName: (work ?? []).map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ServiceLabel
                            className={{ labelClass: 'text-sm font-medium' }}
                            serviceInfo={item}
                            pitServiceList={pitServiceList}
                          />
                        </React.Fragment>
                      );
                    }),
                    shopName: tokNmRyk,
                    vehicleName: `${
                      sryMkrMei && sshMei
                        ? `${sryMkrMei} ${sshMei}`
                        : '未登録車両'
                    }`,
                  })
                )
              : showErrorMessage(searchMotoKbn !== '2')
          )}
      </div>
    ) : (
      <></>
    );
  };

  const createLink = (text, onClick) => (
    <Linked
      text={text}
      onClick={onClick}
      isLine={true}
      linkIcon="R"
      className="text-sm whitespace-nowrap font-bold"
    />
  );

  return (
    <>
      <Proctitle text="予約確認" />
      <Tabs
        tabItems={TAB_ITEMS}
        selectTab={currentTab}
        onClick={key => formik.setFieldValue('currentTab', key)}
        className="mt-8 lg:mt-[88px]"
      />
      {children(currentTab)}

      <ReserveCancelModal
        isModalOpen={showCancelModal}
        closeModal={() => dispatch(actions.closeModal())}
        data={cancelData}
        onClickCancel={() => {
          const {
            yykNo,
            yykGetYmd,
            yykGetHms,
            tokCd,
            srySeq,
            searchMotoKbn,
            work: [
              { sgySeq, sgyYmd, sgyStaHm, sgyEndHm, shobun = [], chubunCd },
            ],
            kykBiko,
          } = cancelData.yykInfo;

          if (dayjs().isBefore(`${sgyYmd} ${sgyStaHm}:00`)) {
            // キャンセル処理
            dispatch(
              reservationCancel({
                termKbn: getTermKbn(),
                henkoKbn: '3',
                yykGetYmd,
                yykGetHms,
                kaiinCardNo: cardNumber,
                kaiinNo: userId,
                shopCode: tokCd,
                sgyYmd,
                sgyStaHm,
                sgyEndHm,
                chubunCd,
                shobunCd: shobun.map(sho => sho.shobunCd),
                srySeq,
                sgySeq,
                yykNo,
                kykBiko,
              })
            );
          } else {
            showErrorMessage(searchMotoKbn !== '2');
          }
        }}
      />
    </>
  );
};

export default ReservationList;
