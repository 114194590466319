/** 待ち時間予約検索API */
/** ログアウトAPI */
export const USER_LOGOUT = 'user/logout';
export const USER_WAIT_TIME = 'user/waitTime';
/** 待ち時間予約登録API */
export const USER_WAIT_TIME_RESERVE = 'user/waitTimeReserve';
/** 会員情報登録・更新API */
export const USER_UPDATE = 'user/update';
/** 会員情報取得API */
export const USER_INFO = 'user/info';
/** 会員車両一覧取得API */
export const USER_CAR_LIST = 'user/car/list';
/** タイヤ保管契約状態取得API */
export const USER_TIRE_STORAGE = 'user/tire-storage';
/** 車両情報登録・更新API */
export const USER_CAR_UPDATE = 'user/car/update';
/** 店舗一覧取得API */
export const SHOP_LIST_SEARCH = 'shops/search/shop_list';
/** 店舗状態取得API */
export const SHOP_STATUS_SEARCH = 'shops/{shopCode}/status';
/** 店舗サービス一覧取得API */
export const SHOP_SERVICE_SEARCH = 'shops/service';
/** 店舗サービス一覧取得API（外部遷移用） */
export const SHOP_SERVICE_SEARCH_EXTERNAL = 'shops/service_for_external';
/** 店舗位置情報取得API */
export const SERVICE_LATITUDE_SEARCH = 'shops/latitude';
/** 予約空き状況取得API */
export const RESERVE_TIME_SEARCH = 'shops/reserve_time/search';
/** 空き状況検索API */
export const RESERVE_DETAIL_SEARCH = 'shops/reserve_detail/search';
/** 車両一覧取得API */
export const CAR_LIST_SEARCH = 'cars/list';
/** サービス一覧取得API */
export const SERVICE_LIST_SEARCH = 'pit/service/search';
/** 予約情報取得API */
export const RESERVE_INFO_SEARCH = 'user/reserve/info';
/** 予約情報反映API */
export const RESERVE_REFLECTION = 'user/reserve';
/** カルテ一覧取得API */
export const KARTE_LIST = 'user/car/karte';
/** カルテ詳細取得API */
export const KARTE_INFO = 'user/car/karte/info';
/** メンテナンス情報取得API */
export const MAINTENANCE = 'common/maintenance';
/** お知らせ一覧取得API */
export const INFORMATION = 'common/information';
/** 退会済みチェックAPI */
export const USER_UNSUBSCRIBE_CHECK = 'user/check';
/** SAPエラー検知API */
export const REPORT_SAP_ERROR = 'sap/reportError';
/** タイヤ保管期限取得API */
export const TIRE_STORAGE_LIMIT_SEARCH = 'tireStorage/limit/search';