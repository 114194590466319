import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SERVICE_LIST_SEARCH } from 'constants/apiURL';
import axios from 'utils/axiosUtil';

const initialState = {
  pitServiceData: [],
  showServiceSelectModal: false,
  isShopOpen: false,
  modalContent: {},
};

export const fetchPitServiceList = createAsyncThunk(
  'fetchPitServiceList',
  async () =>
    axios.get(SERVICE_LIST_SEARCH, { params: { isMain: '0', sortNum: 0 } })
);

export const pitServiceSlice = createSlice({
  name: 'pitService',
  initialState,

  reducers: {
    showServiceSelectModal: (state, { payload }) => {
      state.showServiceSelectModal = true;
      state.modalContent = payload;
    },
    closeModal: state => {
      state.showServiceSelectModal = false;
    },
    clear: () => initialState,
  },

  extraReducers: builder => {
    builder.addCase(fetchPitServiceList.fulfilled, (state, { payload }) => {
      state.pitServiceData = payload.body;
    });
  },
});

export const actions = pitServiceSlice.actions;
export default pitServiceSlice.reducer;
