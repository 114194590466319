import axios from 'axios';
import store from 'slices/store';
import { USER_INFO, USER_UNSUBSCRIBE_CHECK } from '../constants/apiURL';
import { logout } from '../slices/accountSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import { sapVerifyLogin } from './sapSdkUtil';
import Path from '../constants/routePath';

export const verifyLogin = async (dispatch, beforeAccountInfo) => {
  const sapResponse = await sapVerifyLogin();

  if (sapResponse?.errorCode === 0) {
    // SAP認証成功

    // 会員情報取得API実行
    const info = await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/' + USER_INFO, {
        cardNumber: sapResponse.data.abCardNumber,
        UID: sapResponse.UID,
        UIDSignature: sapResponse.UIDSignature,
      })
      .catch(function (error) {
        dispatch(
          messageModalActions.showMessageModal({
            message: error.response.data.errorMessage,
          })
        );
      });
    return info?.data ? info.data : null;
  } else if (sapResponse?.errorCode === 206001) {
    //利用規約が更新されていたら、再同意画面へ遷移させる
    dispatch(messageModalActions.showTermsConfirmModal());

    // 会員情報取得API実行
    const info = await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/' + USER_INFO, {
        cardNumber: sapResponse.data.abCardNumber,
        UID: sapResponse.UID,
        UIDSignature: sapResponse.UIDSignature,
      })
      .catch(function (error) {
        dispatch(
          messageModalActions.showMessageModal({
            message: error.response.data.errorMessage,
          })
        );
      });
    return info?.data ? info.data : null;
  } else if (beforeAccountInfo) {
    const { token } = store.getState().account ?? {};
    //tokenが残っていたら、ログアウト済みor退会済みのモーダルを出す。
    let unsubsucribeFlag = false;
    if (token) {
      const res = await axios.get(
        process.env.REACT_APP_API_BASE_URL + '/' + USER_UNSUBSCRIBE_CHECK,
        {
          params: { cardNumber: beforeAccountInfo.cardNumber },
          headers: { token: token },
        }
      );
      unsubsucribeFlag = res.data.body.isUnsubscribe;
      const msg = unsubsucribeFlag
        ? '既に退会済みです'
        : '既にログアウト済みです';
      dispatch(
        messageModalActions.showToastModal({
          message: msg,
        })
      );
    }
    // Redis削除のためログアウトAPIを呼ぶ
    dispatch(
      logout({
        cardNumber: beforeAccountInfo.cardNumber,
      })
    );
    if (unsubsucribeFlag) {
      return 'unsubsucribe';
    }
  } else {
    return null;
  }
};
