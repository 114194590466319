import axios from 'axios';
import qs from 'qs';
import store from 'slices/store';
import { loadingActions } from 'slices/loadingSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import { accountActions } from 'slices/accountSlice';

// TODO: 確定次第変更
// 共通エラーメッセージ
export const COMMON_ERROR_MESSAGE =
  '通信に失敗しました\r\n通信環境の良い場所で再度お試しください';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  },
});

const dispatchErrorMessage = response => {
  store.dispatch(
    messageModalActions.showMessageModal({
      message: response?.data?.displayMessage || COMMON_ERROR_MESSAGE,
    })
  );
};

instance.interceptors.request.use(
  config => {
    const { token } = store.getState().account ?? {};

    !config?.hiddenLoading && store.dispatch(loadingActions.setLoading(true));

    const headers = {
      ...config.headers,
      ...(token && { token }),
    };

    return config.method === 'get'
      ? {
          ...config,
          params: {
            ...config.params,
          },
          headers,
        }
      : {
          ...config,
          data: {
            ...config.data,
          },
          headers,
        };
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => {
    !response.config?.hiddenLoading &&
      store.dispatch(loadingActions.setLoading(false));

    const data = response.data;
    if (
      !response.config?.ignoreError &&
      data.responseCode !== '000' &&
      data.responseCode !== '0'
    ) {
      dispatchErrorMessage(response);
      if (data.responseCode !== '600') {
        return Promise.reject();
      }
    }

    return data;
  },
  async error => {
    const responseCode = error?.response?.data?.responseCode;

    !error?.config?.ignoreError &&
      !/A08(PT|)(301|304|305|316)/.test(responseCode) &&
      !/A1320101/.test(responseCode) &&
      dispatchErrorMessage(error?.response);

    // TODO: ユーザー認証失敗時のエラーコードを定義する
    // セッション切れた場合ログイン情報クリア
    if (/E004/.test(responseCode)) {
      store.dispatch(accountActions.clearAccount());
    }

    if (/A1320101/.test(responseCode)) {
      store.dispatch(
        messageModalActions.showMessageModal({
          message: error?.response?.data?.displayMessage,
          closeSameWithClick: true,
          onClick: () => {
            store.dispatch(accountActions.clearAccount());
            store.dispatch(messageModalActions.closeMessageModal());
            window.location.replace('/');
          },
        })
      );
    }

    !error.config?.hiddenLoading && store.dispatch(loadingActions.loadingOff());

    return Promise.reject(error);
  }
);

export default instance;
