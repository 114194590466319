import { isAndroid, isDesktop, isIOS } from 'react-device-detect';

export const getCustomProperty = codeCombinations => {
  // ユーザプロパティを整形
  let selectService = '';
  codeCombinations.forEach((v, i) => {
    selectService += `{chubunCd: ${v.chubunCd}, shobunCd: ${v.shobunCd}}${
      i === codeCombinations.length - 1 ? '' : ', '
    }`;
  });
  return selectService;
};

export const getCustomPropertyForMulti = subCategories => {
  // ユーザプロパティを整形
  let selectService = '';
  subCategories.forEach((v, i) => {
    v.codeCombinations.forEach((v2, i2) => {
      selectService += `{chubunCd: ${v2.chubunCd}, shobunCd: ${v2.shobunCd}}${
        i2 === v.codeCombinations.length - 1 ? '' : ', '
      }`;
    });
    selectService += `${i === subCategories.length - 1 ? '' : ', '}`;
  });

  return selectService;
};
