import React from 'react';
import classNames from 'classnames';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { sliceByNumberForFill } from 'utils/arrayUtil';
import './../aprosButton/aprosButton.scss';

const CardButton = ({ optVal = [], name, onClick, formik, comStyle }) => {
  const { isSp } = useDeviceSize();

  /*
    optVal:オブジェクト配列で表示する [{label:'',value:''}]
    name:インプットのname
    defVal:デフォルトvalue
    onChange:onChange ()=>{}
    comStyle:スタイル,areaStyは一番外の要素,centerStyはカード要素 {areaSty:{},centerSty:{}}
  */
  return (
    <>
      {sliceByNumberForFill(optVal, isSp ? 2 : 3).map((base, i) => (
        <div
          key={`items_${i}`}
          className="mt-3 flex flex-wrap gap-x-3 sm:mt-2 lg:mt-4 lg:gap-x-6"
          style={{ ...comStyle?.areaSty }}
        >
          {base.map((item, j) =>
            item ? (
              <label key={j} className="flex-1">
                <input
                  type="radio"
                  name={name}
                  value={item.value}
                  checked={item.value === formik.values[name]}
                  onClick={() => onClick(item.value)}
                  onChange={formik.handleChange}
                  className="peer absolute opacity-0"
                />
                <span
                  className={classNames(
                    'btn-custom flex cursor-pointer items-center justify-center rounded-xl font-bold tracking-[.06em]',
                    'bg-white text-navy peer-checked:bg-navy peer-checked:text-white lg:hover:bg-[#EEF0F2]',
                    'border-[0.5px] border-[#E5E5E5]',
                    'drop-shadow-[2px_2px_3px_#00244529] sm:drop-shadow-[3px_3px_6px_#00244529]',
                    'h-[64px] lg:h-[4.125rem]'
                  )}
                  style={comStyle?.centerSty}
                >
                  {item.label}
                </span>
              </label>
            ) : (
              <label key={j} className="flex-1"></label>
            )
          )}
        </div>
      ))}
    </>
  );
};
export default CardButton;
