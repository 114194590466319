import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { KARTE_LIST, RESERVE_INFO_SEARCH, INFORMATION } from 'constants/apiURL';
import axios from '../utils/axiosUtil';
import dayjs from 'dayjs';

const initialState = {
  exchangeDeadlineList: [],
  informationList: [],
  isKarteLoading: false,
};

export const fetchExchangeDeadline = createAsyncThunk(
  'fetchExchangeDeadline',
  async (params, { dispatch }) => {
    dispatch(actions.setKarteLoading(true));

    const [kartRes, reserveRes] = await Promise.all([
      axios.post(KARTE_LIST, {
        kaiincrdNo: params.kaiincrdNo,
        srySeq: params.srySeq,
        shopCode: params.shopCode,
      }),
      axios.post(RESERVE_INFO_SEARCH, {
        kaiinNo: params.userId,
        dateKbn: '1',
      }),
    ]);

    dispatch(actions.setKarteLoading(false));

    const reservedChunbn = (reserveRes.body?.reservationInfo ?? []).flatMap(y =>
      y.work.map(w => w.chubunCd)
    );

    return {
      exchangeDeadlineList: (kartRes.body?.parts ?? []).filter(
        p =>
          !['', '-', null].includes(p.replacementRecommendedMonth) &&
          ![...params.selectChubunCd, ...reservedChunbn].includes(p.chubunCd) &&
          p.reserveDisplay === '1' &&
          dayjs(p.replacementRecommendedMonth, 'YYYY年M月')
            .add(-1, 'months')
            .isSameOrBefore(dayjs(), 'month') &&
          dayjs(p.replacementRecommendedMonth, 'YYYY年M月').isSameOrAfter(
            dayjs(),
            'month'
          )
      ),
    };
  }
);

export const fetchInformation = createAsyncThunk(
  'fetchInformation',
  async params => {
    const informationRes = await axios.get(INFORMATION, {
      params: { isMain: false },
    });

    return {
      informationList: (informationRes.body?.informationList ?? []).filter(i =>
        dayjs().isBetween(i.startDate, i.endDate)
      ),
    };
  }
);

export const reservationCompleteSlice = createSlice({
  name: 'reservationComplete',
  initialState,
  reducers: {
    setKarteLoading: (state, { payload }) => {
      state.isKarteLoading = payload;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchExchangeDeadline.fulfilled, (state, { payload }) => {
      state.exchangeDeadlineList = payload.exchangeDeadlineList;
    });
    builder.addCase(fetchInformation.fulfilled, (state, { payload }) => {
      state.informationList = payload.informationList;
    });
  },
});

export const actions = reservationCompleteSlice.actions;
export default reservationCompleteSlice.reducer;
