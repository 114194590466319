import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPitServiceList, actions } from 'slices/pitServiceSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import Proctitle from 'components/proctitle/index';
import AprosButton from 'components/aprosButton/index';
import TireStorageModal from 'components/messageModals/tireStorageModal';
import Icons from 'images/icons';
import Path from 'constants/routePath';
import {
  MULTI_SELECT_SERVICE,
  TIRE_SWITCH_LABEL,
  SERVICE_CODE_TIRE,
  TIRE_CATEGORY_CODE_WHEEL,
  TIRE_CATEGORY_CODE_WHEEL_LABEL,
  TIRE_CATEGORY_CODE_TIRE,
  TIRE_CATEGORY_CODE_TIRE_LABEL,
} from 'constants/service';
import OtherServiceSelectModal from './modals/otherServiceSelectModal';
import { displayCautionActions } from 'slices/displayCautionSlice';
import NotesModal from 'components/messageModals/notesModal';
import {
  PIT_SELECT_SERVICE,
  PIT_SELECT_SERVICE_CLOSE,
} from '../../constants/gtmCustomEvent';
import {
  getCustomProperty,
  getCustomPropertyForMulti,
} from '../../utils/gtmUtil';
import { Helmet } from 'react-helmet';
import Title from '../../constants/title';
import classNames from '../../../node_modules/classnames/index';
import { useDeviceSize } from 'hooks/useDeviceSize';

const PitService = () => {
  const { accountInfo, isTireContract } = useSelector(state => state.account);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pitServiceData, showServiceSelectModal, isShopOpen, modalContent } =
    useSelector(state => state.pitService);

  const { cautionDisplayDate } = useSelector(state => state.displayCaution);
  const { isSp } = useDeviceSize();

  useEffect(() => {
    dispatch(fetchPitServiceList());
    return () => {
      dispatch(actions.clear());
    };
  }, [dispatch]);

  useEffect(() => {
    isShopOpen &&
      dispatch(
        messageModalActions.showMessageModal({
          message: '現在取り扱っている店舗がありません',
        })
      );
  }, [dispatch, isShopOpen]);

  return (
    <>
      <div className="pb-5 sm:pb-16 lg:pb-20">
        <Helmet>
          <title>{Title.PIT_SERVICE}</title>
        </Helmet>
        <Proctitle text="ピットサービスから予約" useH1={true} />
      </div>
      <div className="flex flex-col gap-y-[70px] lg:flex-row lg:flex-wrap lg:justify-between lg:gap-y-16">
        {pitServiceData.map((item, i) => (
          <div key={i} className="lg:min-h-[380px] lg:w-[47%]">
            <Proctitle
              useH2={true}
              text={<div>{item.aprosCategoryName}</div>}
              icon={
                item.aprosImageUrl.normalIcon.includes('http')
                  ? item.aprosImageUrl.normalIcon
                  : Icons[item.aprosImageUrl.normalIcon]
              }
              textSize="pitSub"
              className={{
                areaClass: 'border-b border-solid border-line pb-1',
                iconClass: 'lg:h-14',
              }}
            />

            <div
              className={`flex flex-wrap gap-x-[3.5%] gap-y-[16px] pt-4 sm:justify-start lg:gap-y-6`}
            >
              {item.aprosSubCategories.map((subItem, idx) => {
                const showIcon =
                  item.aprosCode === SERVICE_CODE_TIRE &&
                  (subItem.aprosSmallCode === TIRE_CATEGORY_CODE_WHEEL ||
                    subItem.aprosSmallCode === TIRE_CATEGORY_CODE_TIRE);
                return (
                  <React.Fragment key={idx}>
                    <AprosButton
                      text={
                        <div
                          className={classNames(
                            'break-keep text-left lg:whitespace-normal',
                            isSp ? 'pl-2 pr-1' : 'pl-3'
                          )}
                          style={{ wordSpacing: '-.3ch' }}
                        >
                          {
                            <p className="break-words">
                              {showIcon
                                ? TIRE_SWITCH_LABEL
                                : subItem.aprosSmallName}
                            </p>
                          }
                          {showIcon && (
                            <p className="text-xs flex items-center font-normal">
                              {subItem.aprosSmallCode ===
                              TIRE_CATEGORY_CODE_WHEEL
                                ? TIRE_CATEGORY_CODE_WHEEL_LABEL
                                : TIRE_CATEGORY_CODE_TIRE_LABEL}
                              <Icons.Image src={subItem.aprosSmallImageUrl} />
                            </p>
                          )}
                        </div>
                      }
                      comClassName="other"
                      className={`text-sm h-[64px] w-[48%] sm:h-[66px] ${
                        showIcon ? 'h-[76px] sm:h-[118px]' : ''
                      }`}
                      onClick={() => {
                        const checkSuccessCallback = () => {
                          if (MULTI_SELECT_SERVICE.includes(item.aprosCode)) {
                            const modalContent = {
                              serviceCode: item.aprosCode,
                              subServiceCode: subItem.aprosSmallCode,
                              subServiceName: subItem.aprosSmallName,
                              options: item.aprosSubCategories.filter(
                                s => s.aprosSmallName !== subItem.aprosSmallName
                              ),
                            };
                            dispatch(
                              actions.showServiceSelectModal(modalContent)
                            );
                          } else {
                            // GTMカスタムイベントパラメータ
                            const dataLayer = (window.dataLayer =
                              window.dataLayer || []);
                            dataLayer.push({
                              event: PIT_SELECT_SERVICE,
                              pitSelectService: getCustomProperty(
                                subItem.codeCombinations
                              ),
                            });

                            navigate(Path.RESERVATION_SHOP_SELECT, {
                              state: {
                                serviceSelectScreen: Path.PIT_SERVICE,
                                yykInfo: {
                                  work: [
                                    {
                                      codeCombinationsList: [
                                        subItem.codeCombinations,
                                      ],
                                    },
                                  ],
                                },
                              },
                            });
                          }
                        };

                        const sendReserve = () => {
                          if (!cautionDisplayDate) {
                            dispatch(
                              displayCautionActions.check(checkSuccessCallback)
                            );
                          } else {
                            checkSuccessCallback();
                          }
                        }
                        
                        // タイヤ交換を選択かつタイヤ保管ユーザーの場合、タイヤ保管モーダルを表示
                        if (item.aprosCode === SERVICE_CODE_TIRE && isTireContract) {
                          dispatch(
                            messageModalActions.checkTireStorageModal(sendReserve)
                          );
                          return;
                        }else{
                          sendReserve();
                        }
                      }}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        ))}
      </div>

      {/* モーダル */}
      <OtherServiceSelectModal
        isModalOpen={showServiceSelectModal}
        serviceName={modalContent?.subServiceName || ''}
        options={modalContent?.options}
        toggleModal={() => {
          // GTMカスタムイベントパラメータ
          const dataLayer = (window.dataLayer = window.dataLayer || []);
          dataLayer.push({
            event: PIT_SELECT_SERVICE_CLOSE,
          });

          dispatch(actions.closeModal());
        }}
        onClickReserve={checkedList => {
          const checkSuccessCallback = () => {
            const data = pitServiceData.find(
              s => s.aprosCode === modalContent.serviceCode
            );

            const { aprosSubCategories = [] } = data;

            const selectedSubCategories = aprosSubCategories.filter(s =>
              [modalContent.subServiceCode, ...checkedList].includes(
                s.aprosSmallCode
              )
            );

            // GTMカスタムイベントパラメータ
            const dataLayer = (window.dataLayer = window.dataLayer || []);
            dataLayer.push({
              event: PIT_SELECT_SERVICE,
              pitSelectService: getCustomPropertyForMulti(
                selectedSubCategories
              ),
            });

            navigate(Path.RESERVATION_SHOP_SELECT, {
              state: {
                serviceSelectScreen: Path.PIT_SERVICE,
                yykInfo: {
                  work: [
                    {
                      codeCombinationsList: selectedSubCategories.map(
                        s => s.codeCombinations
                      ),
                    },
                  ],
                },
              },
            });
          };
          if (!cautionDisplayDate) {
            dispatch(displayCautionActions.check(checkSuccessCallback));
          } else {
            checkSuccessCallback();
          }
        }}
      />
      <NotesModal />
      <TireStorageModal
        cardNumber={accountInfo?.cardNumber}
        isHiddenClose={false} />
    </>
  );
};

export default PitService;
