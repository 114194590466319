import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions, fetchTopInit } from 'slices/topSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { useWindowSize } from 'hooks/useWindowSize';
import CardButton from 'components/cardButton';
import RadioButton from 'components/radioButton/index';
import AprosButton from 'components/aprosButton/index';
import AprosCarousel from 'components/aprosCarousel/index';
import Proctitle from 'components/proctitle/index';
import Linked from 'components/linked/index';
import Icons from 'images/icons';
import Path from 'constants/routePath';
import {
  MULTI_SELECT_SERVICE,
  SERVICE_CODE_TIRE,
  TIRE_CATEGORY_CODE_TIRE,
  TIRE_CATEGORY_CODE_TIRE_LABEL,
  TIRE_CATEGORY_CODE_WHEEL,
  TIRE_CATEGORY_CODE_WHEEL_LABEL,
  TIRE_SWITCH_LABEL,
} from 'constants/service';
import CalendarModal from './modals/calendarModal';
import NotesModal from 'components/messageModals/notesModal';
import TireStorageModal from 'components/messageModals/tireStorageModal';
import SingleServiceSelectModal from './modals/singleServiceSelectModal';
import MultiServiceSelectModal from './modals/multiServiceSelectModal';
import { displayCautionActions } from 'slices/displayCautionSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import {
  TOP_PIT_SELECT_SERVICE,
  TOP_SELECT_SERVICE_AFTER_TOMORROW,
  TOP_SELECT_SERVICE_TODAY,
} from '../../constants/gtmCustomEvent';
import {
  getCustomProperty,
  getCustomPropertyForMulti,
} from '../../utils/gtmUtil';
import { Helmet } from 'react-helmet';
import Title from '../../constants/title';

// メリット
const merit = [
  {
    img: Icons.karte,
    text: `「車のカルテ」で\n愛車を管理`,
    link: '詳しくはこちら',
  },
  {
    img: Icons.machijikan,
    text: `事前予約で\n待ち時間を短縮`,
  },
  {
    img: Icons.kantan,
    text: `カンタン操作で\nすぐに予約できる`,
  },
];

const TopPage = () => {
  const { isPc, isTablet, isSp } = useDeviceSize();
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountInfo, isTireContract } = useSelector(state => state.account);

  const [subChanged, setSubChanged] = useState(false);

  const topRef = useRef(null);

  const subRef = useRef(null);

  const datePickRef = useRef(null);

  // バナー
  const bannerData = isSp
    ? [
        {
          icon: Icons.bnr_center,
          url: 'https://www.autobacs.com/static_html/srv/syaken/top.html',
          alt: '車検見積',
        },
        {
          icon: Icons.bnr_right,
          url: 'https://www.autobacs.com/static_html/cars/kaitori/top.html',
          alt: '愛車買取',
        },
        {
          icon: Icons.bnr_left,
          url: 'https://www.autobacs.com/static_html/srv/syaken/article/3022/',
          alt: '12ヶ月点検',
        },
      ]
    : [
        {
          icon: Icons.bnr_left,
          url: 'https://www.autobacs.com/static_html/srv/syaken/article/3022/',
          alt: '12ヶ月点検',
        },
        {
          icon: Icons.bnr_center,
          url: 'https://www.autobacs.com/static_html/srv/syaken/top.html',
          alt: '車検見積',
        },
        {
          icon: Icons.bnr_right,
          url: 'https://www.autobacs.com/static_html/cars/kaitori/top.html',
          alt: '愛車買取',
        },
      ];

  // ログインしているか
  const isLogIn = !!accountInfo?.cardNumber;
  const {
    serviceList,
    pitServiceData,
    showSingleServiceSelectModal,
    showMultiServiceSelectModal,
    modalContent,
  } = useSelector(state => state.top);

  const calcWidth =
    (width < 1360 ? width : 1360) / (isPc ? 215 : isTablet ? 230 : 130);

  const [unselectedErrorMain, setUnselectedErrorMain] = useState('');
  const [unselectedErrorSub, setUnselectedErrorSub] = useState('');

  // メンテナンス情報
  const { maintenanceInfo } = useSelector(state => state.maintenance);

  const { cautionDisplayDate } = useSelector(state => state.displayCaution);

  const formik = useFormik({
    initialValues: { serviceMain: null, serviceSub: null },
    validateOnMount: true,
    validationSchema: Yup.object({}),

    onSubmit: values => {},
  });
  const { serviceMain, serviceSub } = formik.values;

  const [arrowTopIcon, setArrowTopIcon] = useState(Icons.topArrow);
  const [arrowCenterIcon, setArrowCenterIcon] = useState(Icons.topArrow);
  const [arrowBottomIcon, setArrowBottomIcon] = useState(Icons.topArrow);

  const serviceSubList =
    serviceList?.find(s => s.aprosCode === serviceMain)?.aprosSubCategories ||
    [];

  useEffect(() => {
    // アイコンgif再生
    setArrowTopIcon(Icons.topArrowGif);
    if (window.localStorage.getItem('redux_localstorage_simple_account')) {
      window.localStorage.removeItem('redux_localstorage_simple_account');
    }
  }, []);

  useEffect(() => {
    dispatch(fetchTopInit());
    return () => {
      dispatch(actions.clear());
    };
  }, [dispatch]);

  // ピットサービスから予約データ処理
  const carouselContent = pitServiceData.map((item, i) => (
    <div
      key={i}
      id={item.aprosCode}
      className="relative flex h-[120px] w-[120px] cursor-pointer flex-col items-center justify-center gap-[3px] rounded-xl border-[0.5px]
            border-solid border-[#E5E5E5] bg-white shadow-btn-3xl peer-checked:bg-navy peer-checked:text-white sm:h-48 sm:w-48
            lg:h-[200px] lg:w-[200px] lg:gap-[15px] lg:hover:bg-[#EEF0F2]"
    >
      <Icons.Image
        src={
          item.aprosImageUrl.normalIcon.includes('http')
            ? item.aprosImageUrl.normalIcon
            : Icons[item.aprosImageUrl.normalIcon]
        }
        className="pointer-events-none h-12 w-12 lg:h-20 lg:w-20"
        alt={item.aprosCategoryName}
      />
      <div
        className={`${
          isTablet ? 'text-[16px]' : 'text-base'
        } whitespace-pre-wrap text-center font-bold text-navy`}
      >
        {item.aprosCategoryName}
      </div>
    </div>
  ));

  // かんたん操作カードボタン処理
  const treatedServiceContent = serviceList.map(item => ({
    value: item.aprosCode,
    label: (
      <div
        className={classNames(
          'flex w-full items-center justify-start gap-1 whitespace-pre-wrap text-[14px] lg:whitespace-normal lg:text-[16px]',
          isSp ? 'pl-2' : 'pl-3'
        )}
        style={{ wordSpacing: '-.3ch' }}
      >
        <Icons.Image
          src={
            // アイコン差し替え処理
            serviceMain === item.aprosCode
              ? item.aprosImageUrl.onClickIcon.includes('http')
                ? item.aprosImageUrl.onClickIcon
                : Icons[item.aprosImageUrl.onClickIcon]
              : item.aprosImageUrl.normalIcon.includes('http')
              ? item.aprosImageUrl.normalIcon
              : Icons[item.aprosImageUrl.normalIcon]
          }
          className="w-10"
        />
        {item.aprosCategoryName}
      </div>
    ),
  }));

  // バナーデータ処理
  const bannerContent = bannerData.map((item, i) => (
    <div className="text-sm relative flex h-full w-full items-center justify-center text-disabled">
      <Icons.Image
        src={item.icon}
        alt={item.alt}
        onClick={() => window.open(item.url)}
      />
    </div>
  ));

  const onSelectDate = calOpen => {
    if (serviceMain) {
      if (serviceSub) {
        // タイヤ保管ユーザーかつタイヤ保管サービスを選択している場合はタイヤ保管モーダルを表示
        if (serviceMain === SERVICE_CODE_TIRE && isTireContract) {
          dispatch(messageModalActions.showTireStorageModal({ isOpenCalendar: calOpen }));
        } else {
          calOpen ? setIsCalOpen(!isCalOpen) : transitionShopSelect();
        }
      } else {
        setUnselectedErrorSub('選択してください');
        setArrowCenterIcon(Icons.topArrow);
        setTimeout(() => setArrowCenterIcon(Icons.topArrowGifBotom));
      }
    } else {
      setUnselectedErrorMain('選択してください');

      setArrowTopIcon(Icons.topArrow);
      setTimeout(() => setArrowTopIcon(Icons.topArrowGif));
    }
  };

  // 日にちを選ぶ
  const dateData = [
    {
      label: (
        <div className="w-full gap-4 pl-4 text-left sm:flex">
          今日
          <p className="text-xs font-medium text-navy-light">
            {dayjs().format('M月D日(ddd)')}
          </p>
        </div>
      ),
      value: 'today',
      onClick: () => onSelectDate(false),
    },
    {
      label: (
        <div className="w-full gap-x-4 pl-4 text-left sm:flex sm:flex-wrap">
          明日以降
          <div className="text-xs flex items-center gap-x-[10px] font-medium">
            <p className="text-navy-light">日付を指定</p>
            <Icons.Image src={Icons.cal} />
          </div>
        </div>
      ),
      value: 'dateDesignation',
      onClick: () => onSelectDate(true),
    },
  ];

  // 予約画面に遷移
  const transitionShopSelect = day => {
    const naviToShopSelect = () => {
      const data = pitServiceData.find(s => s.aprosCode === serviceMain);
      const { codeCombinations } = data?.aprosSubCategories?.find(
        s => s.aprosSmallCode === serviceSub
      );

      // GTMカスタムイベントパラメータ
      const dataLayer = (window.dataLayer = window.dataLayer || []);
      if (!day) {
        // 今日
        dataLayer.push({
          event: TOP_SELECT_SERVICE_TODAY,
          topSelectService: [getCustomProperty(codeCombinations)],
        });
      } else {
        // 明日以降
        dataLayer.push({
          event: TOP_SELECT_SERVICE_AFTER_TOMORROW,
          topSelectService: [getCustomProperty(codeCombinations)],
        });
      }

      navigate(Path.RESERVATION_SHOP_SELECT, {
        state: {
          serviceSelectScreen: Path.TOP,
          yykInfo: {
            work: [
              {
                sgyYmd: (day ?? dayjs()).format('YYYYMMDD'),
                codeCombinationsList: [codeCombinations],
              },
            ],
          },
        },
      });
    };

    if (!cautionDisplayDate) {
      dispatch(displayCautionActions.check(naviToShopSelect));
    } else {
      naviToShopSelect();
    }
  };

  // カレンダーモーダル開閉
  const [isCalOpen, setIsCalOpen] = useState(false);

  useEffect(() => {
    if (isSp && serviceMain && !serviceSub) {
      var element = document.getElementById('root');

      element.scrollTo({
        top: subRef.current?.offsetTop - 20,
        behavior: 'smooth',
      });
    }
  }, [isSp, subRef.current, serviceMain, serviceSub]);

  useEffect(() => {
    if (isSp && serviceSub) {
      var element = document.getElementById('root');

      element.scrollTo({
        top: datePickRef.current?.offsetTop - topRef.current?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [isSp, datePickRef.current, serviceSub]);

  return (
    <>
      {/* ログイン変更案内エリア */}
      {isLogIn || (
        <div className="text-sm flex flex-col justify-between gap-y-1.5 border border-solid border-line bg-white px-2 py-3 sm:gap-y-4 sm:px-6 sm:py-4 lg:mt-3 lg:gap-y-6">
          <label className="text-sm">
            3月27日から、共通オートバックスIDによるログインに変更いたしました。
            <Linked
              text="共通オートバックスIDについてはこちら"
              isLine={true}
              href="https://www.autobacs.com/sso/new/top.html"
              className={'text-sm mb-1 inline-flex cursor-pointer'}
            />
            をご確認ください。
          </label>
        </div>
      )}

      {/* メンテナンスエリア */}
      {maintenanceInfo?.display && (
        <div className="flex flex-col justify-between gap-y-1.5 border border-solid border-line bg-white px-2 py-3 sm:gap-y-4 sm:px-6 sm:py-4 lg:mt-3 lg:gap-y-6">
          <p className="text-sm font-medium text-navy">
            平素はオートバックスの各種サービスをご愛顧頂き、誠にありがとうございます。
            <br />
            下記日程にてサイトのメンテナンスを行います。
            <br />
            皆様には、ご不便をお掛けいたしますが、時間を改めて、アクセス頂きます様、宜しくお願い申し上げます。
          </p>
          <p className="whitespace-pre-wrap text-[14px] font-bold text-navy sm:text-[18px] lg:text-[20px]">
            メンテナンス予定日時{isPc ? '：' : '\n'}
            {dayjs(maintenanceInfo?.maintenanceStartDate).format('M月D日 H:mm')}
            ～{dayjs(maintenanceInfo?.maintenanceEndDate).format('M月D日 H:mm')}{' '}
            予定
          </p>
        </div>
      )}

      {/* かんたん操作エリア */}
      <div
        className={classNames(
          'mb-2 flex items-center justify-center bg-contain lg:bg-top-page',
          maintenanceInfo?.display ? 'lg:mt-[72px]' : 'lg:mt-16'
        )}
        style={!isPc ? { margin: '0 calc(50% - 50vw) 0', width: '100vw' } : {}}
      >
        <div
          className="lg:px-auto mt-2 flex h-full w-full items-center justify-center sm:mt-4 lg:mt-0 lg:px-10 lg:py-10"
          style={{ background: 'rgba(255,255,255,0.9)' }}
        >
          <div className="w-full lg:max-w-[950px]">
            <Helmet>
              <title>{Title.TOP}</title>
            </Helmet>
            <h1>
              <Icons.Image
                src={isSp ? Icons.bnrTopSp : Icons.bnrTopPc}
                className="h-auto w-full object-cover"
                alt="カンタン操作・お店で待たない来店予約"
              />
            </h1>
            <div className="bg-background-sub px-4 pb-6 sm:px-10 sm:pb-3 lg:bg-white/95 lg:pb-11">
              <div ref={topRef}>
                <Icons.Image
                  src={arrowTopIcon}
                  className="ml-[7px] h-10 py-1"
                />
                <div className="mb-2 flex items-center gap-3 text-[22px] font-bold text-navy lg:gap-6">
                  サービスを選ぶ
                  {unselectedErrorMain && (
                    <div className="text-[13px] font-medium text-red lg:text-[16px]">
                      {unselectedErrorMain}
                    </div>
                  )}
                </div>
                <div ref={subRef} />
                <CardButton
                  formik={formik}
                  optVal={treatedServiceContent}
                  name="serviceMain"
                  onClick={v => {
                    setUnselectedErrorMain('');
                    setSubChanged(false);
                    const data = serviceList.find(s => s.aprosCode === v);
                    if (data?.aprosSubCategories.length > 1) {
                      formik.setFieldValue('serviceSub', null);
                      setArrowCenterIcon(Icons.topArrow);
                      setTimeout(() => {
                        setArrowCenterIcon(Icons.topArrowGifCenter);
                      }, 0);
                    } else {
                      formik.setFieldValue(
                        'serviceSub',
                        data?.aprosSubCategories?.[0]?.aprosSmallCode
                      );
                      setArrowBottomIcon(Icons.topArrow);
                      setTimeout(() => {
                        setArrowBottomIcon(Icons.topArrowGifBotom);
                      }, 0);
                    }
                  }}
                />
              </div>
              {serviceMain &&
                serviceList?.find(s => s.aprosCode === serviceMain)
                  ?.aprosSubCategories?.length > 1 && (
                  <>
                    <div className="flex flex-row items-center gap-x-4">
                      <Icons.Image
                        src={arrowCenterIcon}
                        className="mb-3 ml-[7px] mt-4 h-10 py-1 lg:mb-3 lg:mt-5"
                      />
                      {unselectedErrorSub && (
                        <div className="flex min-h-[12px] flex-col justify-center lg:h-9">
                          <div className="text-[13px] font-medium text-red lg:text-[16px]">
                            {unselectedErrorSub}
                          </div>
                        </div>
                      )}
                    </div>
                    <div />
                    <div
                      className="flex flex-wrap gap-x-10 font-medium sm:gap-x-12 lg:gap-x-14"
                      onChange={() => {
                        setUnselectedErrorSub('');
                        setArrowCenterIcon(Icons.topArrow);
                        if (!subChanged) {
                          setArrowBottomIcon(Icons.topArrow);
                          setTimeout(() => {
                            setArrowBottomIcon(Icons.topArrowGifBotom);
                          }, 0);
                          setSubChanged(true);
                        }
                      }}
                    >
                      {serviceList
                        .find(s => s.aprosCode === serviceMain)
                        .aprosSubCategories?.map((item, i) => (
                          <RadioButton
                            key={i}
                            formik={formik}
                            fieldName="serviceSub"
                            value={item.aprosSmallCode}
                            basisFull={
                              serviceMain === SERVICE_CODE_TIRE &&
                              [
                                TIRE_CATEGORY_CODE_WHEEL,
                                TIRE_CATEGORY_CODE_TIRE,
                              ].includes(item.aprosSmallCode) &&
                              isTablet
                            }
                            label={
                              serviceMain === SERVICE_CODE_TIRE &&
                              item.aprosSmallCode ===
                                TIRE_CATEGORY_CODE_WHEEL ? (
                                <div className="flex flex-row items-center">
                                  <p className="text-sm pr-[155px] sm:pr-[186px]">
                                    {TIRE_SWITCH_LABEL}
                                  </p>
                                  <p className="text-xs absolute ml-[80px] flex items-center bg-white px-2 font-normal sm:ml-[100px] lg:bg-[#E5E5E5]">
                                    {TIRE_CATEGORY_CODE_WHEEL_LABEL}
                                    <Icons.Image
                                      src={item.aprosSmallImageUrl}
                                      className="ml-2 h-7 w-7"
                                    />
                                  </p>
                                </div>
                              ) : serviceMain === SERVICE_CODE_TIRE &&
                                item.aprosSmallCode ===
                                  TIRE_CATEGORY_CODE_TIRE ? (
                                <div className="flex flex-row items-center">
                                  <p className="text-sm pr-[143px] sm:pr-[170px]">
                                    {TIRE_SWITCH_LABEL}
                                  </p>
                                  <p className="text-xs absolute ml-[80px] flex items-center bg-white px-2 font-normal sm:ml-[100px] lg:bg-[#E5E5E5]">
                                    {TIRE_CATEGORY_CODE_TIRE_LABEL}
                                    <Icons.Image
                                      src={item.aprosSmallImageUrl}
                                      className="ml-2 h-7 w-7"
                                    />
                                  </p>
                                </div>
                              ) : (
                                item.aprosSmallName
                              )
                            }
                            className={{ boxClass: 'my-0 mb-4' }}
                          />
                        ))}
                    </div>
                  </>
                )}

              <div>
                {serviceMain && serviceSub && (
                  <div ref={datePickRef}>
                    <Icons.Image
                      src={arrowBottomIcon}
                      className={classNames(
                        'ml-[7px] h-10 py-1',
                        serviceSubList.length > 1
                          ? ''
                          : 'mb-3 mt-4 lg:mb-3 lg:mt-5'
                      )}
                    />
                  </div>
                )}
                <div
                  className={classNames(
                    'mb-2 text-[22px] font-bold text-navy',
                    serviceMain && serviceSub ? '' : 'mt-8'
                  )}
                >
                  日にちを選ぶ
                </div>
                <div className="flex gap-x-3 lg:gap-x-6">
                  {dateData.map((item, i) => (
                    <AprosButton
                      comClassName="other"
                      text={item.label}
                      key={i}
                      className="h-[60px] flex-1 sm:h-[66px]"
                      onClick={item.onClick}
                    />
                  ))}
                  {/* レイアウト調整用に空要素を置く */}
                  {!isSp && (
                    <AprosButton
                      comClassName="other"
                      disabled={true}
                      className="flex-1 opacity-0 disabled:cursor-default"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* バナーエリア */}
      <div
        className="bar-area flex w-full justify-center sm:mt-4 lg:mt-[72px]"
        style={isSp ? { margin: '8px calc(50% - 50vw) 0', width: '100vw' } : {}}
      >
        <AprosCarousel
          carouselContent={bannerContent}
          spaceBetween={isPc ? 24 : isTablet ? 12 : 8}
          slidesPerView={1.1}
          loopedSlides={bannerContent.length}
          breakpoints={{
            375: { slidesPerView: 1.1 },
            640: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          loop={true}
          centeredSlides={isSp}
        />
      </div>
      {/* ピットサービスから予約エリア */}
      <div className="mb-12 mt-14 flex flex-col sm:mb-20 sm:mt-20 sm:gap-6 lg:mt-24">
        <Proctitle text="ピットサービスから予約" useH2={true} />
        <div
          className="flex h-[136px] w-full justify-center sm:mt-4 sm:h-52 lg:mt-6"
          style={
            isSp
              ? {
                  margin: '16px calc(50% - 50vw) 0',
                  width: '100vw',
                }
              : {}
          }
        >
          <AprosCarousel
            spaceBetween={0}
            slidesPerView={calcWidth}
            carouselContent={carouselContent}
            loop={true}
            loopedSlides={carouselContent.length}
            onClick={(_, e) => {
              const naviToShopSelect = () => {
                const aprosCode = e.target.id;
                const data = pitServiceData.find(
                  s => s.aprosCode === aprosCode
                );
                const modalContent = {
                  serviceCode: data?.aprosCode,
                  serviceName: data?.aprosCategoryName,
                  options: data?.aprosSubCategories,
                };

                if (MULTI_SELECT_SERVICE.includes(aprosCode)) {
                  // 小カテゴリが複数あり、複数選択できる場合
                  dispatch(actions.showMultiServiceSelectModal(modalContent));
                } else if (data?.aprosSubCategories?.length > 1) {
                  // 小カテゴリが複数あり、単一選択できる場合
                  dispatch(actions.showSingleServiceSelectModal(modalContent));
                } else {
                  // 小カテゴリなし
                  const [{ codeCombinations }] = data.aprosSubCategories;
                  // GTMカスタムイベントパラメータ
                  const dataLayer = (window.dataLayer = window.dataLayer || []);
                  dataLayer.push({
                    event: TOP_PIT_SELECT_SERVICE,
                    topSelectService: [getCustomProperty(codeCombinations)],
                  });

                  navigate(Path.RESERVATION_SHOP_SELECT, {
                    state: {
                      serviceSelectScreen: Path.TOP,
                      yykInfo: {
                        work: [{ codeCombinationsList: [codeCombinations] }],
                      },
                    },
                  });
                }
              };
              if (!cautionDisplayDate) {
                dispatch(displayCautionActions.check(naviToShopSelect));
              } else {
                naviToShopSelect();
              }
            }}
          />
        </div>
        <div>
          <Linked
            text="もっとみる"
            onClick={() => navigate(Path.PIT_SERVICE)}
            isLine
            linkIcon="R"
            className="text-base float-right font-bold"
          />
        </div>
      </div>
      {/* メリットエリア */}
      <div className="flex flex-col gap-2 lg:gap-4">
        <div className="flex items-end justify-center text-[16px] font-bold leading-3 text-navy sm:text-[24px] lg:text-[30px]">
          ピットサービス予約の
          <p className="text-[24px] leading-4 text-red sm:text-[46px] sm:leading-8 lg:leading-6">
            &nbsp;3&nbsp;
          </p>
          つのメリット
        </div>

        <div
          className={`mt-4 flex  ${
            isSp ? 'flex-col' : ''
          } items-center justify-center gap-6 sm:gap-4 lg:gap-[62px]`}
        >
          {merit.map((item, i) => (
            <div
              className="flex h-[230px] w-[230px] flex-col items-center justify-center whitespace-pre-wrap bg-white
                text-center text-[18px] font-bold text-navy sm:h-[236px] sm:w-[236px]
                sm:text-[20px] lg:h-[448px] lg:w-[448px] lg:text-[40px]"
              key={i}
            >
              {item.text}
              <div>
                <Icons.Image
                  src={item.img}
                  alt={item.text}
                  className={classNames(
                    'object-cover',
                    i === 0
                      ? 'h-[120px] w-[180px] sm:h-[107px] sm:w-[160px] lg:h-[220px] lg:w-[330px]'
                      : 'h-[130px] w-[200px] sm:h-[120px] sm:w-[180px] lg:h-[240px] lg:w-[360px]'
                  )}
                />
              </div>
              {/* ログイン時：カルテページ、未ログイン：カルテ説明ページ */}
              {item.link && (
                <Linked
                  text={item.link}
                  onClick={() =>
                    navigate(isLogIn ? Path.KARTE : Path.KARTE_DESCRIPTION)
                  }
                  isLine
                  linkIcon="R"
                  className="pt-2 text-[16px] sm:text-[18px] lg:mt-[18px]"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* モーダル */}
      <CalendarModal
        isModalOpen={isCalOpen}
        toggleModal={() => setIsCalOpen(!isCalOpen)}
        onSelect={transitionShopSelect}
      />

      <SingleServiceSelectModal
        isModalOpen={showSingleServiceSelectModal}
        toggleModal={() => dispatch(actions.closeModal())}
        serviceName={modalContent?.serviceName}
        options={modalContent?.options}
        onClickReserve={checked => {
          const naviToShopSelect = () => {
            const serviceCode = modalContent.serviceCode;
            const data = pitServiceData.find(s => s.aprosCode === serviceCode);
            const { codeCombinations } = data?.aprosSubCategories?.find(
              s => s.aprosSmallCode === checked
            );

            // GTMカスタムイベントパラメータ
            const dataLayer = (window.dataLayer = window.dataLayer || []);
            dataLayer.push({
              event: TOP_PIT_SELECT_SERVICE,
              topSelectService: [getCustomProperty(codeCombinations)],
            });

            navigate(Path.RESERVATION_SHOP_SELECT, {
              state: {
                serviceSelectScreen: Path.TOP,
                yykInfo: {
                  work: [{ codeCombinationsList: [codeCombinations] }],
                },
              },
            });
          };
          if (!cautionDisplayDate) {
            dispatch(displayCautionActions.check(naviToShopSelect));
          } else {
            naviToShopSelect();
          }
        }}
      />
      <MultiServiceSelectModal
        isModalOpen={showMultiServiceSelectModal}
        toggleModal={() => dispatch(actions.closeModal())}
        serviceName={modalContent?.serviceName}
        options={modalContent?.options}
        onClickReserve={checkedList => {
          const naviToShopSelect = () => {
            const data = pitServiceData.find(
              s => s.aprosCode === modalContent.serviceCode
            );

            const { aprosSubCategories = [] } = data;

            const selectedSubCategories = aprosSubCategories.filter(s =>
              [modalContent.subServiceCode, ...checkedList].includes(
                s.aprosSmallCode
              )
            );

            // GTMカスタムイベントパラメータ
            const dataLayer = (window.dataLayer = window.dataLayer || []);
            dataLayer.push({
              event: TOP_PIT_SELECT_SERVICE,
              topSelectService: getCustomPropertyForMulti(
                selectedSubCategories
              ),
            });

            navigate(Path.RESERVATION_SHOP_SELECT, {
              state: {
                serviceSelectScreen: Path.TOP,
                yykInfo: {
                  work: [
                    {
                      codeCombinationsList: selectedSubCategories.map(
                        s => s.codeCombinations
                      ),
                    },
                  ],
                },
              },
            });
          };

          if (!cautionDisplayDate) {
            dispatch(displayCautionActions.check(naviToShopSelect));
          } else {
            naviToShopSelect();
          }
        }}
      />
      <NotesModal />
      <TireStorageModal
        cardNumber={accountInfo?.cardNumber}
        isHiddenClose={false}
        reserveClick={transitionShopSelect}
        openCalendar={setIsCalOpen} />
    </>
  );
};

export default TopPage;
