import React, { useState, useEffect } from 'react';
import ModalBase from 'components/modalBase/index';
import AprosButton from 'components/aprosButton/index';
import Proctitle from 'components/proctitle/index';
import Checkbox from 'components/checkbox/index';
import Icons from 'images/icons';

const MultiServiceSelectModal = ({
  isModalOpen,
  toggleModal,
  serviceName,
  options,
  onClickReserve,
}) => {
  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    setCheckedList([]);
  }, [isModalOpen]);

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      className="flex flex-col overflow-auto px-4 pb-11 pt-12 sm:px-8 sm:pb-[60px] sm:pt-[66px] lg:px-14 lg:pb-[60px] lg:pt-14"
    >
      <div className="absolute right-0 top-0 py-2 pr-2">
        <Icons.Image
          src={Icons.closeModal}
          className="float-right h-8 cursor-pointer lg:h-[50px]"
          onClick={toggleModal}
        />
      </div>
      <div id="modal" className="scrollBar  overflow-y-scroll">
        <Proctitle text={serviceName} textSize="l" />
        <div className="mt-4 flex flex-wrap gap-x-8 gap-y-6 sm:mt-8 lg:mt-6 lg:gap-x-14 lg:gap-y-8">
          {options?.map((item, i) => (
            <Checkbox
              key={i}
              fieldName={item.aprosSmallCode}
              value={checkedList.includes(item.aprosSmallCode)}
              text={item.aprosSmallName}
              className={{
                boxClass: 'my-0',
                labelClass: 'mx-0 ml-2 font-medium',
              }}
              onChange={() => {
                if (!checkedList.includes(item.aprosSmallCode)) {
                  const newList = [...checkedList];
                  newList.push(item.aprosSmallCode);
                  setCheckedList(newList);
                } else {
                  setCheckedList(
                    checkedList.filter(l => l !== item.aprosSmallCode)
                  );
                }
              }}
            />
          ))}
        </div>
        <div className="mt-8 mb-3 flex items-center justify-center sm:mt-16 lg:mt-20">
          <AprosButton
            disabled={checkedList.length === 0}
            comClassName="reserve"
            text="予約へ進む"
            className="lg:w-80"
            onClick={() => onClickReserve(checkedList)}
          />
        </div>
      </div>
    </ModalBase>
  );
};
export default MultiServiceSelectModal;
