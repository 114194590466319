import React, { useState, useEffect } from 'react';
import ModalBase from 'components/modalBase';
import InputSelect from 'components/inputSelect';
import AprosButton from 'components/aprosButton';
import prefectures from 'constants/prefectures';

const ShopUpdateModal = ({
  isModalOpen,
  shopList,
  onClickTop,
  onClickUpdate,
}) => {
  const [prefecture, setPrefecture] = useState(null);
  const [shopCode, setShopCode] = useState(null);

  useEffect(() => {
    setPrefecture(null);
    setShopCode(null);
  }, [isModalOpen]);

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      className="flex flex-col overflow-auto px-4 pb-11 pt-12 text-navy sm:px-10 sm:pt-[66px] sm:pb-[60px] lg:px-20 lg:pb-[60px] lg:pt-14"
    >
      <div className="flex flex-col sm:items-center">
        <div className="text-base whitespace-pre-wrap text-center font-bold">
          ご利用店舗変更
        </div>

        <div className="text-sm mt-4 whitespace-pre-wrap text-center font-medium sm:mt-6">
          ご登録いただいているご利用店舗は予約のお取り扱いがございません{'\n'}
          別の店舗をご登録ください
        </div>

        <div className="mt-8">
          <InputSelect
            fieldName="prefecture"
            title="都道府県"
            className={{
              boxClass: 'w-full p-0 sm:px-4',
              titleClass: 'mr-0 sm:w-[72px]',
              inputClass: 'w-full sm:ml-8 sm:w-80',
            }}
            options={prefectures}
            value={prefecture}
            onChange={v => {
              setPrefecture(v);
              setShopCode(null);
            }}
            isShowErrorArea={false}
          />
        </div>

        <div className="mt-6 sm:mt-8">
          <InputSelect
            fieldName="shopCode"
            title="店舗名"
            className={{
              boxClass: 'w-full p-0 sm:px-4',
              titleClass: 'mr-0 sm:w-[72px]',
              inputClass: 'w-full sm:ml-8 sm:w-80',
            }}
            options={shopList.filter(s => s.prefecturesCode === prefecture)}
            value={shopCode}
            onChange={setShopCode}
            isShowErrorArea={false}
          />
        </div>
      </div>

      <div className="mt-10 flex w-full flex-row gap-x-[19px] sm:gap-x-11 lg:gap-x-[52px]">
        <AprosButton
          text="トップへ戻る"
          comClassName="sub"
          className="w-full"
          onClick={() => onClickTop()}
        />
        <AprosButton
          text="更新する"
          comClassName="main"
          className="w-full"
          disabled={!prefecture || !shopCode}
          onClick={() => onClickUpdate({ prefecture, shopCode })}
        />
      </div>
    </ModalBase>
  );
};

export default ShopUpdateModal;
