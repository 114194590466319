import { memo, useState, useLayoutEffect } from 'react';
import { Progress } from 'antd';
import classNames from 'classnames';
import { sleep } from 'utils/sleepUtil';
import './style.scss';

/**
 * 予約画面上部のステップ表示部品
 *
 * NOTE: ひとまず3ステップまでしか考慮できていないので、それ以上のステップを追加する場合は要修正
 */
export default memo(
  ({
    labels = [],
    previousStep = -1,
    activeStep = 0,
    bottomElement = null,
    onClick = null,
    fullWidth,
    className = { stepperClass: '', bottomClass: '' },
  }) => {
    const [_activeStep, setActiveStep] = useState(previousStep);
    const [_percent, setPercent] = useState(null);
    const [_init, setInit] = useState(true);
    const [isBottomDisplay, setBottomDisplay] = useState(false);

    useLayoutEffect(() => {
      (async () => {
        setBottomDisplay(false);
        if (_init) {
          // 初回表示時
          await sleep(0.5);
          if (previousStep > activeStep) {
            await backStep(activeStep);
            await displayBottomElement();
          } else {
            await nextStep(activeStep);
          }
          setInit(false);
        } else if (previousStep === _activeStep) {
          // 一つ進む
          await nextStep(activeStep);
        } else {
          // 戻る（1 or 2）
          await backStep(activeStep + 1);
          await sleep(0.2);
          await backStep(activeStep);
          setActiveStep(activeStep);
          await displayBottomElement();
        }
      })();
    }, [activeStep]);

    // ステップを一つ進める
    const nextStep = async step => {
      setPercent(calcPercent(step));
      await sleep(0.5);
      setActiveStep(step);
      await displayBottomElement();
    };

    // ステップを一つ戻す
    const backStep = async step => {
      setActiveStep(step);
      await sleep(0.1);
      setPercent(calcPercent(step));
    };

    // 下部ビューを表示する
    const displayBottomElement = async () => {
      await sleep(0.2);
      setBottomDisplay(true);
    };

    // プログレスバーの値を計算
    const calcPercent = step =>
      step === -1 || step === 0 ? 0 : step === labels?.length - 1 ? 100 : 50;

    return (
      <>
        <div style={{ textAlign: '-webkit-center', margin: '0 auto' }}>
          <div className="w-full">
            <div
              className={classNames(
                'stepper relative h-auto w-auto text-left',
                className.stepperClass
              )}
            >
              <Progress
                className="accent-red"
                percent={
                  _percent !== null ? _percent : calcPercent(previousStep)
                }
                showInfo={false}
                strokeColor={'#CA4539'}
                trailColor={'#D0D0D9'}
              />
              {labels.map((label, index) => {
                return (
                  <span
                    key={label}
                    className={classNames(
                      'absolute top-0 flex w-[30px] flex-col bg-background-main',
                      index === 0
                        ? '-left-4'
                        : index === labels.length - 1
                        ? '-right-4'
                        : 'left-[calc(50%_-_16px)]'
                    )}
                  >
                    <span
                      className={classNames(
                        'relative h-[30px] w-[30px] items-center rounded-full transition-all',
                        index <= _activeStep ? 'bg-red/40' : 'bg-line/40'
                      )}
                    >
                      <span
                        className={classNames(
                          'absolute left-[5px] top-[5px] h-5 w-5 rounded-full transition-all',
                          index <= _activeStep ? 'bg-red' : 'bg-line'
                        )}
                      />
                    </span>
                    <label
                      onClick={() => index < _activeStep && onClick?.(index)}
                      className={classNames(
                        'self-center whitespace-nowrap text-[12px] transition-all sm:text-[18px]',
                        index <= _activeStep ? 'text-red' : 'text-line',
                        index < _activeStep ? 'cursor-pointer underline' : ''
                      )}
                    >
                      {label}
                    </label>
                  </span>
                );
              })}
            </div>
          </div>
          <div
            className={classNames('mt-14 h-auto', className.bottomClass)}
            style={{
              margin: '0 auto',
            }}
          >
            {bottomElement ? (
              <div
                className={classNames(
                  'transition-all duration-300',
                  isBottomDisplay ? 'opacity-100' : 'opacity-0'
                )}
                style={{
                  textAlign: '-webkit-center',
                }}
              >
                {bottomElement}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
);
