import React, { useState, useEffect } from 'react';
import ModalBase from 'components/modalBase';
import InputSelect from 'components/inputSelect';
import AprosButton from 'components/aprosButton';
import Icons from 'images/icons';

const VehicleRegisterModal = ({
  isModalOpen,
  closeModal,
  carList,
  onClick,
}) => {
  const [manufacturer, setManufacturer] = useState(null);
  const [carModel, setCarModel] = useState(null);

  useEffect(() => {
    setManufacturer(null);
    setCarModel(null);
  }, [isModalOpen]);

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={closeModal}
      className="flex flex-col overflow-auto px-4 pb-11 pt-12 text-navy sm:px-[33px] sm:pb-11 sm:pt-[66px] lg:px-20 lg:pb-16 lg:pt-14"
    >
      <div className="absolute right-0 top-0 py-2 pr-2">
        <Icons.Image
          src={Icons.closeModal}
          className="float-right h-8 cursor-pointer lg:h-[50px]"
          onClick={closeModal}
        />
      </div>
      <div className="flex flex-col lg:items-center">
        <div className="text-base whitespace-pre-wrap text-center font-bold">
          新規車両追加
        </div>

        <div className="mt-12 sm:mt-8">
          <InputSelect
            fieldName="manufacturer"
            title="メーカー"
            required={true}
            isShowErrorArea={false}
            isErrorAreaAbsolute={true}
            className={{
              boxClass: 'p-0',
              titleClass: 'w-28',
              inputClass: 'sm:ml-6 lg:ml-8 lg:w-80',
            }}
            minLabelWidth={130}
            options={carList}
            value={manufacturer}
            onChange={v => {
              setManufacturer(v);
              setCarModel(null);
            }}
          />
        </div>

        <div className="mt-12 sm:mt-8">
          <InputSelect
            fieldName="carModel"
            title="車種"
            required={true}
            minLabelWidth={130}
            isShowErrorArea={false}
            isErrorAreaAbsolute={true}
            className={{
              boxClass: 'p-0',
              titleClass: 'w-28',
              inputClass: 'sm:ml-6 lg:ml-8 lg:w-80',
            }}
            options={carList
              .find(c => c.sryMkrCd === manufacturer)
              ?.modelList?.map(model => ({
                ...model,
                value: `${model.sshCd}_${model.kshCd}`,
                label: model.sshNm1,
              }))}
            value={carModel}
            onChange={setCarModel}
          />
        </div>
      </div>

      <div className="mt-10 self-center lg:mt-14">
        <AprosButton
          comClassName="reserve"
          text="登録する"
          disabled={!manufacturer || !carModel}
          onClick={() => onClick({ manufacturer, carModel })}
        />
      </div>
    </ModalBase>
  );
};

export default VehicleRegisterModal;
