import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobila: false,
};

export const mobilaSlice = createSlice({
  name: 'mobilaSlice',
  initialState,
  reducers: {
    setMobila: (state, { payload }) => {
      state.isMobila = payload;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {},
});

export const mobilaActions = mobilaSlice.actions;
export default mobilaSlice.reducer;
