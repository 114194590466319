/**
 * 各画面パスや画面ごとの違いなどの定義
 */

// 画面パス一覧
const Path = {
  TOP: '/',
  DESCRIPTION: '/description',
  RESERVATION_SHOP_SELECT: '/reservation/shop-select',
  RESERVATION_SERVICE_SELECT: '/reservation/shop-and-service-select',
  RESERVATION_DATE_SELECT: '/reservation/date-select',
  RESERVATION_CONFIRM: '/reservation/confirm',
  RESERVATION_COMPLETE: '/reservation/complete',
  RESERVATION_LIST: '/reservation/list',
  PIT_SERVICE: '/pit-service',
  KARTE_DESCRIPTION: '/karte/description',
  KARTE: '/karte',
  FAQ: '/faq',
  MAINTENANCE: '/maintenance',
  TERMS: process.env.REACT_APP_TERMS,
  PRIVACY_POLICY: process.env.REACT_APP_PRIVACY_POLICY,
  CORPORATION_INFO: 'https://www.autobacs.co.jp/ja/index.html',
  USER_CAR_INFO: process.env.REACT_APP_CAR_INFO,
  BRAND_SITE_LP: 'https://www.autobacs.com/membership/top.html',
  BACS_INFO: process.env.REACT_APP_BACS_INFO,
  RANK_UP_INFO: process.env.REACT_APP_RANK_UP_INFO,
  MAINTENANCE_OPTION_INFO: process.env.REACT_APP_MAINTENANCE_OPTION_INFO,
  DOWNLOAD_APP: 'https://www.autobacs.com/static_html/shp/app/index.html',
  DOWNLOAD_APP_IOS:
    'https://apps.apple.com/app/apple-store/id564530827?ct=toplp',
  DOWNLOAD_APP_ANDROID:
    'https://play.google.com/store/apps/details?id=jp.com.android.autobacs&referrer=utm_source=autobacs&utm_medium=cpc&anid=admob&utm_campaign=toplp',
  TIRE: process.env.REACT_APP_TIRE_URL,
  NOTES: 'https://www.autobacs.com/shop/info/yoyakunotes/',
};

export default Path;

// 各画面ごとの親ビューに設定するクラス
export const COMPONENT_PARENT_CLASS = {
  [Path.TOP]: 'mt-5 mb-24 sm:mt-4 sm:mb-32 lg:mt-0 lg:mb-40',
  [Path.DESCRIPTION]: 'mt-7 sm:mt-6 lg:mt-10',
  [Path.RESERVATION_SHOP_SELECT]: 'mt-5 mb-6 sm:mt-[43px] lg:mt-[25px]',
  [Path.RESERVATION_SERVICE_SELECT]:
    'mt-5 mb-24 lg:mb-32 sm:mt-[43px] lg:mt-[25px]',
  [Path.RESERVATION_DATE_SELECT]:
    'mt-5 mb-24 sm:mb-32 sm:mt-[43px] lg:mb-40 lg:mt-[25px]',
  [Path.RESERVATION_CONFIRM]:
    'mt-5 mb-24 sm:mb-32 sm:mt-[43px] lg:mb-40 lg:mt-[25px]',
  [Path.RESERVATION_COMPLETE]: 'mt-9 mb-24 sm:mt-[62px] sm:mb-32 lg:mt-[53px]',
  [Path.RESERVATION_LIST]: 'mb-24 mt-[52px] sm:mb-32 sm:mt-6 lg:mt-[88px]',
  [Path.PIT_SERVICE]: 'mt-[52px] mb-[100px] sm:mb-32 sm:mt-10 lg:mt-[88px]',
  [Path.KARTE_DESCRIPTION]: 'mt-[46px] sm:mt-6 lg:mt-32',
  [Path.KARTE]: 'mt-[52px] mb-24 sm:mt-6 sm:mb-32 lg:mt-[88px] lg:mb-[86px]',
  [Path.FAQ]: 'mt-[52px] mb-24 sm:mt-6 sm:mb-32 lg:mt-[88px] lg:mb-40',
  [Path.TERMS]: 'mt-[52px] mb-9 sm:mt-6 sm:mb-32 lg:mt-[88px] lg:mb-[86px]',
  // コンテンツの大きさを設定する（画面サイズ-ヘッダ-フッタ）
  [Path.MAINTENANCE]:
    'lg:min-h-[calc(100vh_-_96px_-_107px)] sm:min-h-[calc(100vh_-_60px_-_111px)] min-h-[calc(100vh_-_60px_-_122px)]',
};

// ヘッダのリンクを表示しない画面一覧
export const HEADER_LINK_HIDDEN_SCREENS = [
  Path.RESERVATION_SHOP_SELECT,
  Path.RESERVATION_SERVICE_SELECT,
  Path.RESERVATION_DATE_SELECT,
  Path.RESERVATION_CONFIRM,
  Path.RESERVATION_COMPLETE,
  Path.MAINTENANCE,
];

// 親ビューをposition: relative;にする画面一覧
export const RELATIVE_SCREENS = [Path.KARTE_DESCRIPTION];

// 画面を閉じる際に確認ダイアログを表示する画面一覧
export const CLOSE_CONFIRM_SCREENS = [
  Path.RESERVATION_SHOP_SELECT,
  Path.RESERVATION_SERVICE_SELECT,
  Path.RESERVATION_DATE_SELECT,
  Path.RESERVATION_CONFIRM,
];

/** 遷移エラーメッセージ */
export const ERROR_MESSAGE_TRANSITION =
  'パラメータが不十分のため、処理に失敗しました。';
