import { memo } from 'react';
import classNames from 'classnames';
import './style.scss';

export default memo(
  ({
    formik,
    fieldName,
    value,
    text,
    disabled = false,
    className = { boxClass: '', labelClass: '' },
    onChange = null,
  }) => {
    return (
      <div
        className={classNames(
          'my-3 flex flex-row items-center',
          className.boxClass
        )}
      >
        {/* チェック要素（画面には表示されない） */}
        <input
          id={fieldName}
          type="checkbox"
          onChange={e => {
            if (onChange) {
              onChange(e);
            } else {
              formik.setFieldValue(fieldName, e.target.checked);
              setTimeout(() => formik.setFieldTouched(fieldName, true));
            }
          }}
          name={fieldName}
          checked={(formik ? formik?.values?.[fieldName] : value) ?? false}
          className="baseCheckbox-input"
          disabled={disabled}
        />
        {/* 疑似要素のチェック（実際に表示されるチェック） */}
        <label
          className="baseCheckbox-dummy cursor-pointer"
          htmlFor={fieldName}
        />
        {/* ラベル */}
        <label
          className={classNames(
            'check-box text-sm whitespace-nowrap px-3 font-medium',
            disabled
              ? 'cursor-default text-disabled'
              : 'cursor-pointer text-navy',
            className.labelClass
          )}
          htmlFor={fieldName}
        >
          {text}
        </label>
      </div>
    );
  }
);
