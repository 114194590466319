import React from 'react';
import classNames from 'classnames';
import Icons from 'images/icons';

const CalendarDescriptionLabel = ({ className }) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      <div className="flex flex-row items-center">
        <Icons.Image src={Icons.icon_OK} />
        <label className="text-sm">：空きあり</label>
      </div>
      <div className="mt-2 flex flex-row items-center">
        <Icons.Image src={Icons.icon_NG02} />
        <label className="text-sm">：空きなし（お問い合わせください）</label>
      </div>
    </div>
  );
};

export default CalendarDescriptionLabel;
