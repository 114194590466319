import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDisableHistoryBack } from 'hooks/useDisableHistoryBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchExchangeDeadline,
  fetchInformation,
} from 'slices/reservationCompleteSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import { actions as loginActions } from 'slices/loginSlice';
import { mobilaActions } from 'slices/mobilaSlice';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { KARTE_ICONS } from 'constants/karte';
import classNames from 'classnames';
import AprosButton from 'components/aprosButton';
import ReserveCard from 'components/reserveCard';
import Linked from 'components/linked';
import Icons from 'images/icons';
import Path, { ERROR_MESSAGE_TRANSITION } from 'constants/routePath';
import { isIOS, isAndroid } from 'react-device-detect';
import { PITYOYAKU_RESERVE_COMPLETE_CV } from '../../constants/gtmCustomEvent';

const Complete = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isSp } = useDeviceSize();
  const {
    baseScreen,
    yykInfo,
    selectService,
    externalSelectSerivce,
    isWaitingTimeReservation,
  } = location.state || {};

  const { tokCd, sryMkrMei, sshMei, srySeq, work } = yykInfo || {};

  const isModify = baseScreen === Path.RESERVATION_LIST;

  const { exchangeDeadlineList, informationList, isKarteLoading } = useSelector(
    state => state.reservationComplete
  );

  const selectChubunCd = selectService
    ? selectService.aprosSubCategories[0]?.codeCombinations.map(c => c.chubunCd)
    : externalSelectSerivce
    ? externalSelectSerivce.map(e => ({
        chubunCd: e.chubunCd,
      }))
    : (work ?? []).map(w => ({
        chubunCd: w.chubunCd,
      }));

  const {
    accountInfo: { cardNumber, userId },
  } = useSelector(state => state.account);

  useEffect(() => {
    // 遷移パラメータが不正な場合、エラーを出してTOP画面に遷移
    if (!yykInfo) {
      const message = ERROR_MESSAGE_TRANSITION;
      dispatch(messageModalActions.showMessageModal({ message }));
      dispatch(loginActions.closeAbidDifferenceModal());
      navigate(Path.TOP);
      return;
    }

    if (!isModify) {
      dispatch(
        fetchExchangeDeadline({
          kaiincrdNo: cardNumber,
          srySeq,
          userId,
          selectChubunCd,
          shopCode: tokCd,
        })
      );

      dispatch(fetchInformation());
    }

    // MOBILA遷移の情報を削除する
    dispatch(mobilaActions.setMobila(false));

    let str = '';
    if (selectService) {
      for (let i = 0; i < selectService.aprosSubCategories.length; i++) {
        str +=
          selectService.aprosCategoryName ===
          selectService.aprosSubCategories[i].aprosSmallName
            ? selectService.aprosCategoryName
            : selectService.aprosCategoryName +
              '(' +
              selectService.aprosSubCategories[i].aprosSmallName +
              ')';
        if (i < selectService.aprosSubCategories.length - 1) {
          str += ',';
        }
      }
    } else if (externalSelectSerivce) {
      (externalSelectSerivce ?? []).map(
        ({ chubunCd, chubunNm, shobunCd, shobunNm }, i) => {
          str += chubunNm;
          if (shobunNm) {
            str += '(' + shobunNm + ')';
          }
          if (i < externalSelectSerivce.length - 1) {
            str += ',';
          }
        }
      );
    }
    // GTMカスタムイベントパラメータ
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push({
      event: PITYOYAKU_RESERVE_COMPLETE_CV,
      pityoyaku_reserve_complete_CV: str,
    });
  }, [dispatch, isModify, srySeq, userId, tokCd]);

  // useDisableHistoryBack();

  // 表示開始日時でソート(降順) ※informationListは表示期間内のものしか返却されない
  const sortedInformationList = informationList.slice().sort(function (a, b) {
    return a.startDate > b.startDate ? -1 : 1;
  });

  // お知らせ項目は運用上2つだけしか登録されない
  // 表示は一つだけなので表示開始が古い方を削除(一つだけなら消さない)
  if (sortedInformationList.length > 1) sortedInformationList.pop();

  return (
    <>
      <div className="flex w-full flex-col lg:mx-auto lg:w-[960px]">
        <div className="flex flex-col text-center">
          <label className="text-lg font-bold text-navy">
            {isModify ? '予約日時を変更しました' : '予約が完了しました'}
          </label>
        </div>
        <div className="mt-10 w-full self-center sm:mt-8">
          <ReserveCard
            reserveData={yykInfo}
            selectService={selectService}
            externalSelectSerivce={externalSelectSerivce}
          />
        </div>
        <div className="text-xs mt-4 whitespace-pre-wrap font-medium sm:mt-6">
          <label>
            時間には余裕を持ってご来店ください。{'\n'}
            予約時間に遅れますとお待ちいただく場合がございます。
          </label>
          {!isWaitingTimeReservation && (
            <label>
              {'\n'}
              予約の確認・変更・キャンセルは、ご登録いただいているメールアドレスにお送りするメールのURLからも行えます。
              {'\n'}
              ご不明点ございましたら、ご予約の店舗へお問い合わせ下さい。
            </label>
          )}
        </div>
        <div className="mt-6 flex flex-col items-center gap-y-6 text-center sm:mt-8 sm:gap-y-4 lg:mt-10">
          {isWaitingTimeReservation && (
            <Linked
              text="注意事項を必ずご確認ください"
              isLine={true}
              linkIcon="R"
              className="text-sm font-bold"
              href={Path.NOTES}
            />
          )}
          <AprosButton
            text={isModify ? '予約確認へ' : 'トップページへ'}
            comClassName="sub"
            onClick={() => {
              if (isModify) {
                navigate(Path.RESERVATION_LIST);
                navigate(0);
              } else {
                navigate(Path.TOP);
              }
            }}
            className="w-72"
          />
        </div>
        {isKarteLoading ? (
          <div className="mt-10 flex h-full items-center justify-center">
            <Icons.Image src={Icons.loading} className="h-[100px] w-[100px]" />
          </div>
        ) : (
          exchangeDeadlineList.length > 0 && (
            <div className="mt-10 flex flex-col border border-line bg-background-sub px-4 pb-6 pt-3 sm:px-14 sm:pb-8 sm:pt-8 lg:mt-16">
              <div className="flex flex-row items-center justify-center">
                <Icons.Image src={Icons.alert} className="h-[35px] w-[35px]" />
                <label
                  className={classNames(
                    'ml-2 font-bold text-navy',
                    isSp ? 'text-[16px]' : 'text-[22px]'
                  )}
                >
                  交換時期が迫っています
                </label>
              </div>
              <div className="text-sm mb-3 mt-4 flex flex-row items-center font-medium text-navy">
                <label>対象車両</label>
                <label className="ml-2 sm:ml-3">{`${
                  sryMkrMei && sshMei ? `${sryMkrMei} ${sshMei}` : '未登録車両'
                }`}</label>
              </div>
              {exchangeDeadlineList.map((e, i) => (
                <div
                  key={e.partName}
                  className={classNames(
                    'flex flex-col border-t border-line pt-4 lg:flex-row lg:items-center lg:pt-6',
                    i === exchangeDeadlineList.length - 1 ? '' : 'mb-6'
                  )}
                >
                  <div className="flex flex-row items-center lg:flex-1">
                    <Icons.Image
                      src={KARTE_ICONS[e.partName]}
                      className="h-8 w-8"
                    />
                    <label className="text-sm ml-2 font-bold text-navy">
                      {e.partName}
                    </label>
                  </div>
                  <div
                    className="mt-2 flex flex-col gap-y-4 sm:mt-6 sm:flex-row sm:flex-wrap lg:mt-0"
                    style={{ flex: 2 }}
                  >
                    <div className="text-sm flex flex-col gap-2 font-medium text-navy lg:flex-1">
                      <div className="flex flex-row">
                        <label>次回交換推奨月</label>
                        <label className="ml-2">
                          {e.replacementRecommendedMonth}
                        </label>
                      </div>
                      <div className="flex flex-row">
                        <label>前回交換日</label>
                        <label className="ml-2 w-36">{e.replacementDate}</label>
                      </div>
                    </div>
                    <div className="flex justify-center self-center sm:mx-auto lg:flex-1">
                      {e.reserveDisplay === '1' && (
                        <AprosButton
                          comClassName="reserve"
                          text="予約へ進む"
                          className="mx-0 sm:mx-4 lg:w-full"
                          onClick={() => {
                            navigate(Path.RESERVATION_SERVICE_SELECT, {
                              state: {
                                serviceSelectScreen: Path.RESERVATION_COMPLETE,
                                selectedSrySeq: srySeq,
                                shopCode: tokCd,
                                chubunCd: e.chubunCd,
                                shobunCd: e.shobunCd,
                              },
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
      {!isModify &&
        sortedInformationList.map(i => (
          <div
            key={i.title}
            className="sm:x-6 mt-10 flex flex-col items-center border border-line bg-white px-4 py-6 sm:items-start lg:mt-28 lg:px-40 lg:py-8"
          >
            <label className="text-base font-bold text-red">{i.title}</label>
            <div className="mt-4 flex flex-col items-center gap-4 gap-x-10 sm:mt-2 sm:flex-row lg:mt-4 lg:gap-x-14">
              <label className="text-sm font-medium text-navy">
                {i.information}
              </label>
              {/* URL存在チェック */}
              {(isIOS && i.iosUrl !== null && i.iosUrl.includes('https://') && (
                <div>
                  <AprosButton
                    comClassName="reserve"
                    text="ダウンロードはこちら"
                    onClick={() => {
                      i.iosUrl && window.open(i.iosUrl);
                    }}
                  />
                </div>
              )) ||
                (isAndroid &&
                  i.androidUrl !== null &&
                  i.androidUrl.includes('https://') && (
                    <div>
                      <AprosButton
                        comClassName="reserve"
                        text="ダウンロードはこちら"
                        onClick={() => {
                          i.androidUrl && window.open(i.androidUrl);
                        }}
                      />
                    </div>
                  )) ||
                (!isIOS &&
                  !isAndroid &&
                  i.webUrl !== null &&
                  i.webUrl.includes('https://') && (
                    <div>
                      <AprosButton
                        comClassName="reserve"
                        text="ダウンロードはこちら"
                        onClick={() => {
                          i.webUrl && window.open(i.webUrl);
                        }}
                      />
                    </div>
                  ))}
            </div>
          </div>
        ))}
    </>
  );
};

export default Complete;
