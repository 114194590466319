import React from 'react';
import Icons from 'images/icons';
import classNames from '../../../node_modules/classnames/index';
import { useDeviceSize } from 'hooks/useDeviceSize';

const Linked = ({
  text,
  href,
  target,
  isLine,
  linkIcon,
  className,
  comStyle,
  onClick,
  disabled = false,
  nextline = [],
}) => {
  const { isSp } = useDeviceSize();
  return (
    <a
      href={href}
      target={
        href !== undefined && href.indexOf('https') !== -1 ? '_blank' : target
      }
      className={`group ${
        disabled ? 'pointer-events-none' : ''
      } relative flex w-fit cursor-pointer items-center text-[14px] sm:text-[18px] ${
        isLine
          ? `text-link after:absolute after:bottom-0.5 after:w-full after:border-b after:border-solid
          after:border-[#23629B]
          after:border-link hover:text-link-hover after:hover:border-link-hover`
          : 'text-navy hover:text-[#647A8D]'
      } ${className}`}
      style={comStyle}
      onClick={onClick}
    >
      {linkIcon ? (
        linkIcon === 'L' ? (
          <>
            <Icons.Image
              src={isLine ? Icons.linkL : Icons.linkLNv}
              className={classNames('group-hover:opacity-70', {
                'w-[14px]': isSp,
              })}
            />
            {text}
          </>
        ) : (
          <>
            {text}
            <Icons.Image
              src={isLine ? Icons.linkR : Icons.linkRNv}
              className={classNames('group-hover:opacity-70', {
                'w-[14px]': isSp,
              })}
            />
          </>
        )
      ) : (
        <> {text}</>
      )}
      {nextline.length === 0
        ? null
        : nextline.map((item, i) => (
            <>
              <br />
              {item}
            </>
          ))}
    </a>
  );
};
export default Linked;
