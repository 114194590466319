import { useLocation } from 'react-router-dom';
import { SERVICE_SELECT_TRANSITION_TYPE } from 'constants/service';
import Path from 'constants/routePath';

/**
 * 店舗・サービス選択画面のパラメータを取り出すhooks
 */
export const useShopAndServiceSelectParams = () => {
  const location = useLocation();

  const {
    previousStep,
    serviceSelectScreen,
    queryParams,
    yykInfo,
    baseChubunCd,
    ...state
  } = location?.state || {};

  // 遷移先画面から上部リンクで戻った場合、yykInfo内から遷移前の情報を取り出す
  if (yykInfo) {
    // 遷移パターン判断
    const transitionPattern = yykInfo.transitionPattern;
    const paramShopCode = yykInfo.paramShopCode;
    const tokCd = yykInfo.tokCd;
    const chubunCd = yykInfo.work[0].chubunCd;
    const shobunCd = yykInfo.work[0].shobun.flatMap(c => c.shobunCd);

    return {
      ...state,
      previousStep,
      serviceSelectScreen,
      paramShopCode,
      tokCd,
      chubunCd,
      shobunCd,
      transitionPattern,
      yykInfo,
      baseChubunCd,
    };
  } else {
    // 遷移パターン判断
    const transitionPattern = (() => {
      // クエリパラメータがある場合、外部遷移か判定する
      if (queryParams && Object.keys(queryParams).length > 0) {
        const { p2, p10, st, bcd } = queryParams;
        // 本籍店舗コード判定
        if (!p2 && !st) {
          // 本籍店舗の指定がない場合
          return SERVICE_SELECT_TRANSITION_TYPE.BRAND_WORK;
        } else {
          if (p10 || bcd) {
            // 中分類コードが指定されている場合
            return SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO_WORK;
          } else {
            // 中分類コードが指定されていない場合
            return SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO;
          }
        }
      }

      // 外部遷移ではなかった場合、内部遷移
      if (Path.RESERVATION_COMPLETE === serviceSelectScreen) {
        return SERVICE_SELECT_TRANSITION_TYPE.COMPLETE;
      } else if (Path.KARTE === serviceSelectScreen) {
        return SERVICE_SELECT_TRANSITION_TYPE.KARTE;
      }
      return SERVICE_SELECT_TRANSITION_TYPE.HEADER;
    })();

    const shopCode = {
      [SERVICE_SELECT_TRANSITION_TYPE.HEADER]: null,
      [SERVICE_SELECT_TRANSITION_TYPE.BRAND_WORK]: null,
      [SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO]:
        queryParams?.p2 || queryParams?.st,
      [SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO_WORK]:
        queryParams?.p2 || queryParams?.st,
      [SERVICE_SELECT_TRANSITION_TYPE.COMPLETE]: state.shopCode,
      [SERVICE_SELECT_TRANSITION_TYPE.KARTE]: state.shopCode,
    }[transitionPattern];

    const chubunCd = {
      [SERVICE_SELECT_TRANSITION_TYPE.HEADER]: null,
      [SERVICE_SELECT_TRANSITION_TYPE.BRAND_WORK]:
        queryParams?.p10 || queryParams?.bcd,
      [SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO]: null,
      [SERVICE_SELECT_TRANSITION_TYPE.SHOP_INFO_WORK]:
        queryParams?.p10 || queryParams?.bcd,
      [SERVICE_SELECT_TRANSITION_TYPE.COMPLETE]: state.chubunCd,
      [SERVICE_SELECT_TRANSITION_TYPE.KARTE]: state.chubunCd,
    }[transitionPattern];

    const shobunCd = state.shobunCd;

    return {
      ...state,
      previousStep,
      serviceSelectScreen,
      paramShopCode: shopCode,
      tokCd: null,
      baseChubunCd: chubunCd,
      chubunCd,
      shobunCd,
      transitionPattern,
      yykInfo,
      queryParams,
    };
  }
};
