import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messageModalActions } from 'slices/messageModalSlice';
import ModalBase from 'components/modalBase/index';
import AprosButton from 'components/aprosButton/index';

const LeaveConfirmModal = () => {
  const dispatch = useDispatch();

  const { isLeaveConfirmModalOpen, onClickTransition, onClickCancel } =
    useSelector(state => state.messageModal);

  return (
    <ModalBase
      toggleModal={() =>
        dispatch(messageModalActions.closeLeaveConfirmModalOpen())
      }
      isModalOpen={isLeaveConfirmModalOpen}
      className="flex flex-col items-center overflow-auto px-4 pb-[60px] pt-14 sm:px-10 lg:px-20 lg:pb-[104px] lg:pt-24"
    >
      <label className="text-base whitespace-pre-wrap text-center font-bold text-navy lg:mx-24">
        このページを離れますか？{'\n'}入力された内容は全て失われます。
      </label>

      <div className="mt-10 flex w-full flex-row gap-x-[19px] sm:gap-x-11 lg:mt-20 lg:gap-x-[52px]">
        <AprosButton
          text="ページを離れる"
          comClassName="sub"
          className="w-full"
          onClick={() => {
            onClickTransition();
            dispatch(messageModalActions.closeLeaveConfirmModalOpen());
          }}
        />
        <AprosButton
          text="キャンセル"
          comClassName="main"
          className="w-full"
          onClick={() => {
            onClickCancel && onClickCancel();
            dispatch(messageModalActions.closeLeaveConfirmModalOpen());
          }}
        />
      </div>
    </ModalBase>
  );
};

export default LeaveConfirmModal;
