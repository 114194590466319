import { useLayoutEffect, useState } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';

export const DEVICE_TYPE = {
  PC: 'pc',
  TABLET: 'tablet',
  SP: 'sp',
};

export const useDeviceSize = () => {
  const [width] = useWindowSize();
  const [isPc, setPc] = useState(false);
  const [isTablet, setTablet] = useState(false);
  const [isSp, setSp] = useState(false);
  const [deviceType, setDeviceType] = useState(false);
  useLayoutEffect(() => {
    const _deviceType = (() => {
      if (width >= 1024) {
        return DEVICE_TYPE.PC;
      } else if (width >= 640) {
        return DEVICE_TYPE.TABLET;
      } else {
        return DEVICE_TYPE.SP;
      }
    })();
    setDeviceType(_deviceType);
    setPc(_deviceType === DEVICE_TYPE.PC);
    setTablet(_deviceType === DEVICE_TYPE.TABLET);
    setSp(_deviceType === DEVICE_TYPE.SP);
  }, [width]);
  return { deviceType, isPc, isTablet, isSp };
};
