// 配列を指定の数値ごとの配列に分割する
export const sliceByNumber = (array, number) => {
  if (!number) return [];
  const length = Math.ceil(array.length / number);
  return new Array(length)
    .fill()
    .map((_, i) => array.slice(i * number, (i + 1) * number));
};

// 配列を指定の数値ごとの配列に分割し、指定の数値に満たない場合、nullで埋める
export const sliceByNumberForFill = (array, number) => {
  if (!number) return [];
  const slices = sliceByNumber(array, number);
  const result = [];
  for (const slice of slices) {
    while (slice.length < number) {
      slice.push(null);
    }
    result.push(slice);
  }
  return result;
};
