import React from 'react';
import classNames from 'classnames';
import Linked from 'components/linked';
import InputSelect from 'components/inputSelect';
import ServiceNameLabel from './serviceNameLabel';
import { useDeviceSize } from 'hooks/useDeviceSize';

const ShopSelectBottomItem = ({
  showSry = true,
  showService = true,
  vehicles,
  selectService,
  selectedSrySeq,
  canServiceSelect = true,
  requiredTime = null,
  onChangeSrySeq,
  onClickNewSry,
  onClickSelectService,
}) => {
  const { isSp } = useDeviceSize();

  const selectedVehicle = vehicles.find(
    v => `${v.carSeq}` === `${selectedSrySeq}`
  );

  return (
    <div
      className={classNames(
        'border border-line bg-background-sub px-4 py-4 text-left sm:px-6 lg:px-10',
        showSry && showService ? '' : 'sm:w-1/2'
      )}
      style={{
        alignSelf: 'center',
        margin: '0 auto',
      }}
    >
      <div className="text-sm sm:flex sm:flex-row">
        {showSry && (
          <div
            className={classNames(
              'flex flex-col',
              showService ? 'sm:mr-10' : 'w-full'
            )}
          >
            <label className="font-medium">この車両で予約する</label>
            <div>
              {vehicles.length === 1 ? (
                <div className="flex w-[256px] items-center sm:my-2 sm:min-h-[48px]">
                  {vehicles[0].label}
                </div>
              ) : (
                <InputSelect
                  fieldName={'selectedSrySeq'}
                  disabled={false}
                  className={{
                    boxClass: showService
                      ? 'w-full p-0 sm:my-2 sm:w-64'
                      : 'w-full p-0 sm:my-2',
                  }}
                  options={vehicles}
                  isShowErrorArea={false}
                  value={selectedSrySeq}
                  onChange={onChangeSrySeq}
                />
              )}
            </div>
            {selectedVehicle?.label === '未登録' && (
              <div
                className={`text-red ${
                  showService
                    ? 'w-full p-0 sm:my-2 sm:w-64'
                    : 'w-full p-0 sm:my-2'
                }`}
              >
                未登録車両での予約はできません。新規車両を追加するか、車両を変更してください。
              </div>
            )}
            <Linked
              text="新規車両追加"
              linkIcon="R"
              isLine={true}
              className={'text-sm font-bold'}
              onClick={onClickNewSry}
            />
          </div>
        )}
        {showService && (
          <div className="flex flex-col max-sm:mt-6">
            <label className="font-medium">選択中のサービス</label>
            {selectService && (
              <ServiceNameLabel
                selectService={selectService}
                className={{
                  boxClass: 'sm:my-2 sm:min-h-[48px]',
                  labelClass: 'font-bold',
                }}
              />
            )}
            {canServiceSelect && (
              <Linked
                text="サービスを選び直す"
                linkIcon="R"
                isLine={true}
                className="text-sm font-bold"
                onClick={onClickSelectService}
              />
            )}
            {!!requiredTime && (
              <label className="font-medium">
                作業目安時間：{requiredTime}分～
              </label>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopSelectBottomItem;
