import { useRef } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

const EllipsisText = ({ text }) => {
  const ref = useRef();

  return (
    <LinesEllipsis
      text={text}
      maxLine="3"
      ref={ref}
      ellipsis="..."
      basedOn="letters"
      onReflow={state => {
        if (state.clamped) {
          ref.current.setState({
            clamped: false,
            text: '',
          });
        }
      }}
    />
  );
};
export default EllipsisText;
