import Icons from 'images/icons';

export const KARTE_ICONS = {
  エンジンオイル: Icons.oil,
  オイルフィルター: Icons.filterOil,
  夏タイヤ: Icons.tireAll,
  スタッドレスタイヤ: Icons.winter,
  エアコンフィルター: Icons.filterAircon,
  ワイパー: Icons.wiper,
  バッテリー: Icons.battery,
  エアフィルター: Icons.filterAir,
  ATF: Icons.atf,
};

/**
 * 車画像名の末尾
 */
export const CAR_IMAGE_SUFFIX = {
  エンジンオイル: 'e',
  オイルフィルター: 'e',
  バッテリー: 'b',
  ATF: 'b',
  エアコンフィルター: 'a',
  エアフィルター: 'a',
  ワイパー: 'w',
  夏タイヤ: 't',
  スタッドレスタイヤ: 't',
};

/**
 * 車画像のマッピング
 */
export const CAR_IMAGE = {
  car_a_t: Icons.car_a_t,
  car_a_w_t: Icons.car_a_w_t,
  car_a_w: Icons.car_a_w,
  car_a: Icons.car_a,
  car_b_a_t: Icons.car_b_a_t,
  car_b_a_w_t: Icons.car_b_a_w_t,
  car_b_a_w: Icons.car_b_a_w,
  car_b_a: Icons.car_b_a,
  car_b_t: Icons.car_b_t,
  car_b_w_t: Icons.car_b_w_t,
  car_b_w: Icons.car_b_w,
  car_b: Icons.car_b,
  car_e_a_t: Icons.car_e_a_t,
  car_e_a_w_t: Icons.car_e_a_w_t,
  car_e_a_w: Icons.car_e_a_w,
  car_e_a: Icons.car_e_a,
  car_e_b_a_t: Icons.car_e_b_a_t,
  car_e_b_a_w: Icons.car_e_b_a_w,
  car_e_b_a: Icons.car_e_b_a,
  car_e_b_t: Icons.car_e_b_t,
  car_e_b_w_t: Icons.car_e_b_w_t,
  car_e_b_w: Icons.car_e_b_w,
  car_e_b: Icons.car_e_b,
  car_e_t: Icons.car_e_t,
  car_e_w_t: Icons.car_e_w_t,
  car_e_w: Icons.car_e_w,
  car_e: Icons.car_e,
  car_t: Icons.car_t,
  car_w_t: Icons.car_w_t,
  car_w: Icons.car_w,
  car_all: Icons.car_all,
  car: Icons.car,
  car_a_t_img: Icons.car_a_t_img,
  car_a_w_t_img: Icons.car_a_w_t_img,
  car_a_w_img: Icons.car_a_w_img,
  car_a_img: Icons.car_a_img,
  car_b_a_t_img: Icons.car_b_a_t_img,
  car_b_a_w_t_img: Icons.car_b_a_w_t_img,
  car_b_a_w_img: Icons.car_b_a_w_img,
  car_b_a_img: Icons.car_b_a_img,
  car_b_t_img: Icons.car_b_t_img,
  car_b_w_t_img: Icons.car_b_w_t_img,
  car_b_w_img: Icons.car_b_w_img,
  car_b_img: Icons.car_b_img,
  car_e_a_t_img: Icons.car_e_a_t_img,
  car_e_a_w_t_img: Icons.car_e_a_w_t_img,
  car_e_a_w_img: Icons.car_e_a_w_img,
  car_e_a_img: Icons.car_e_a_img,
  car_e_b_a_t_img: Icons.car_e_b_a_t_img,
  car_e_b_a_w_img: Icons.car_e_b_a_w_img,
  car_e_b_a_img: Icons.car_e_b_a_img,
  car_e_b_t_img: Icons.car_e_b_t_img,
  car_e_b_w_t_img: Icons.car_e_b_w_t_img,
  car_e_b_w_img: Icons.car_e_b_w_img,
  car_e_b_img: Icons.car_e_b_img,
  car_e_t_img: Icons.car_e_t_img,
  car_e_w_t_img: Icons.car_e_w_t_img,
  car_e_w_img: Icons.car_e_w_img,
  car_e_img: Icons.car_e_img,
  car_t_img: Icons.car_t_img,
  car_w_t_img: Icons.car_w_t_img,
  car_w_img: Icons.car_w_img,
  car_all_img: Icons.car_all_img,
  car_img: Icons.car,
};
