import React from 'react';
import ModalBase from 'components/modalBase/index';
import { KARTE_ICONS } from 'constants/karte';
import Icons from 'images/icons';

const KarteDetailModal = ({ isModalOpen, toggleModal, item }) => {
  const createDetail = (label, value, detail) => (
    <div className="text-sm  flex flex-row font-medium sm:gap-x-16 lg:gap-x-24">
      <label className="min-w-[130px] whitespace-nowrap sm:min-w-[144px]">
        {label}
      </label>
      {detail ? (
        <div className="flex w-full flex-col">
          <label>{item.itemName}</label>
          <label className="text-xs font-medium text-navy-light">
            複数の購入商品がある場合も1件のみ表示となります。
          </label>
        </div>
      ) : (
        <label className="w-full">{value}</label>
      )}
    </div>
  );

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      className="mx-4 flex h-fit w-full flex-col text-navy sm:w-full sm:max-w-[590px] lg:w-full lg:max-w-[1105px]"
    >
      <div
        id="modal"
        className="scrollBar my-2 mr-1 overflow-y-scroll px-4 py-10 sm:px-6 sm:pb-10 sm:pt-[66px] lg:mr-2 lg:px-14 lg:pb-12 lg:pt-10"
      >
        <div className="absolute right-0 top-0 py-2 pr-2">
          <Icons.Image
            src={Icons.closeModal}
            className="float-right h-8 cursor-pointer lg:h-[50px]"
            onClick={toggleModal}
          />
        </div>

        <div className="flex flex-row items-center">
          <Icons.Image src={KARTE_ICONS[item.partName]} className="text-base" />
          <label className="text-base ml-1 font-bold sm:ml-2">
            {item.partName}
          </label>
        </div>

        <label className="text-sm mt-2 whitespace-pre-wrap font-medium lg:mt-4">
          {item.description}
        </label>

        <div className="my-2 h-[1px] bg-line sm:my-6 sm:h-[1px]" />

        <div className="flex flex-col gap-y-4 sm:gap-y-6 lg:gap-y-8">
          {createDetail('交換目安', item.replacementGuide)}
          {createDetail('点検車両名', item.vehicleName)}
          {createDetail('次回交換推奨月', item.replacementRecommendedMonth)}
          {createDetail('前回交換日', item.replacementDate)}
          {createDetail('前回交換走行距離', item.mileage)}
          {createDetail('前回作業店舗', item.shopName)}
          {createDetail(
            '前回購入商品',
            item.itemName,
            '複数の購入商品がある場合も1件のみ表示となります。'
          )}
        </div>
      </div>
    </ModalBase>
  );
};

export default KarteDetailModal;
