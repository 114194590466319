/**
 * サービスリストからcodeCombinationに一致するサービスを取り出す
 * 小カテゴリが一致しないselectAprosSubCategoriesは削る
 *
 * @param serviceList 検索対象のサービスリスト
 * @param codeCombinations 選択サービスのcodeCombination
 * @returns 一致するサービス情報 一致しない場合、null
 */
export const getService = (serviceList = [], codeCombinations = []) => {
  let selectService = null;
  const selectAprosSubCategories = [];
  for (const { chubunCd, shobunCd } of codeCombinations) {
    for (const service of serviceList) {
      for (const aprosSubCategory of service.aprosSubCategories) {
        for (const codeCombination of aprosSubCategory.codeCombinations) {
          if (
            chubunCd === codeCombination.chubunCd &&
            shobunCd === codeCombination.shobunCd
          ) {
            if (
              selectAprosSubCategories.filter(
                s => aprosSubCategory.aprosSmallCode === s.aprosSmallCode
              ).length === 0
            ) {
              selectAprosSubCategories.push(aprosSubCategory);
            }
            if (!selectService) {
              selectService = service;
            }
          }
        }
      }
    }
  }

  if (selectAprosSubCategories.length > 0) {
    selectService = {
      ...selectService,
      aprosSubCategories: selectAprosSubCategories,
    };
  }

  return selectService;
};

/**
 * 選択サービスに一致する中分類コード、小分類コードを取得する
 * ※該当する中分類コードが2つ以上ある場合、大きい数値の方を取り出す
 *
 * @param serviceList 店舗に紐づくサービスリスト
 * @param selectService 選択サービス
 * @returns 中分類コード（1つ）と小分類コード（複数）のObject
 */
export const getCurrentService = (serviceList = [], selectService) => {
  const result = {};
  const shobun = [];
  for (const subCategory of selectService?.aprosSubCategories || []) {
    const shopService = getService(serviceList, subCategory.codeCombinations);
    if (!shopService) continue;
    const { chubunCd, shobunCd } = [
      ...shopService.aprosSubCategories[0].codeCombinations,
    ].sort((a, b) => Number(b.chubunCd) - Number(a.chubunCd))[0];

    result.chubunCd = chubunCd;
    shobun.push({ chubunCd: chubunCd, shobunCd: shobunCd });
  }
  return { ...result, shobun };
};

export const chubunCdToAprosCode = (serviceList = [], chubunCd) => {
  let aprosCode = null;
  for (const service of serviceList) {
    for (const aprosSubCategory of service.aprosSubCategories) {
      for (const codeCombination of aprosSubCategory.codeCombinations) {
        if (
          '4' !== codeCombination.convertPattern &&
          chubunCd === codeCombination.chubunCd
        ) {
          aprosCode = service.aprosCode;
          break;
        }
      }
    }
  }
  return aprosCode;
};

export const getAllChunbunCd = serviceList =>
  (serviceList ?? []).flatMap(a =>
    (a.aprosSubCategories ?? []).flatMap(sub =>
      (sub.codeCombinations ?? []).flatMap(c => c.chubunCd)
    )
  );

export const getAllServiceCodeList = serviceList =>
  (serviceList ?? []).flatMap(a =>
    (a.aprosSubCategories ?? []).flatMap(sub => sub.codeCombinations ?? [])
  );

export const isServiceSupport = (shopServiceList = [], shobun = []) => {
  const allServiceCode = getAllServiceCodeList(shopServiceList);
  return (
    shobun.length >= 1 &&
    shobun.every(sho =>
      allServiceCode.some(
        s =>
          sho.chubunCd === s.chubunCd &&
          (!s.shobunCd || sho.shobunCd === s.shobunCd)
      )
    )
  );
};
