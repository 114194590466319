import { Navigate, useLocation } from 'react-router-dom';
import Path from 'constants/routePath';
import DefaultRoute from './defaultRoute';

const BeforeLoginRoute = ({ isLogin }) => {
  const location = useLocation();

  // ログイン時に遷移する画面
  const afterLogin = {
    [Path.DESCRIPTION]: Path.TOP,
    [Path.KARTE_DESCRIPTION]: Path.KARTE,
  };

  const to = afterLogin[location.pathname];

  return !isLogin ? (
    <DefaultRoute />
  ) : (
    <Navigate replace to={to} state={location.state} />
  );
};

export default BeforeLoginRoute;
