import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showDetailModal: false,
  detailModalName: '',
  detailModalIcons: {},
};

export const descriptionSlice = createSlice({
  name: 'descriptionSlice',
  initialState,
  reducers: {
    showDetailModal: (state, { payload }) => {
      state.showDetailModal = true;
      state.detailModalName = payload.name;
      state.detailModalIcons = payload.icons;
    },
    closeDetailModal: state => {
      state.showDetailModal = false;
      state.detailModalName = '';
      state.detailModalIcons = {};
    },
    clear: () => initialState,
  },
  extraReducers: builder => {},
});

export const actions = descriptionSlice.actions;
export default descriptionSlice.reducer;
