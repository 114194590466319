import React from 'react';
import ModalBase from 'components/modalBase';
import Linked from 'components/linked';
import AprosButton from 'components/aprosButton';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { DATE_SELECT_TEL } from '../../../constants/gtmCustomEvent';

const WorkDescriptionModal = ({
  isModalOpen,
  toggleModal,
  shopName,
  shopTel,
  supportChargeFlag,
  isOilWork = false,
}) => {
  const { isSp } = useDeviceSize();

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      className="flex flex-col items-center overflow-auto px-2 py-14 text-navy"
    >
      <div className="text-base whitespace-pre-wrap text-center">
        ご来店順にて作業受付ができる場合がございます{'\n'}
        {isOilWork && supportChargeFlag !== '1'
          ? '詳しくは店舗もしくはサポートセンターへお問い合わせください'
          : '詳しくは店舗へお問い合わせください'}
      </div>
      <div className="text-base mt-9 text-center font-bold">{shopName}</div>
      <Linked
        text={shopTel}
        href={`tel:${shopTel}`}
        isLine={true}
        className={`text-base mt-2 font-bold ${
          isSp ? '' : 'pointer-events-none'
        }`}
        onClick={() => {
          // GTMカスタムイベントパラメータ
          const dataLayer = (window.dataLayer = window.dataLayer || []);
          dataLayer.push({
            event: DATE_SELECT_TEL,
          });
        }}
      />
      {isOilWork && supportChargeFlag !== '1' && (
        <>
          <div className="text-base mt-7 text-center font-bold">
            オイル交換予約サポートセンター
          </div>
          <div className="mt-2">受付時間 10:00～20:00</div>
          <Linked
            href={`tel:${'0120-974-643'}`}
            className={!isSp && 'pointer-events-none'}
            text={
              <div className="mt-2">
                <label className="text-base font-bold">0120-974-643</label>
                <label className="text-sm ml-1 font-medium">
                  {`(フリーコール)`}
                </label>
              </div>
            }
            isLine={true}
          />
        </>
      )}
      <AprosButton
        text="閉じる"
        comClassName="sub"
        className="mt-8 w-64 sm:mt-12"
        onClick={toggleModal}
      />
    </ModalBase>
  );
};

export default WorkDescriptionModal;
