import React from 'react';
import { Progress } from 'antd';

const BacsProgress = ({ percent }) => (
  <Progress
    percent={percent}
    showInfo={false}
    strokeColor={{
      from: '#0087D1',
      to: '#74B8E3',
    }}
    trailColor="#DFDFE6"
    strokeWidth={12}
  />
);
export default BacsProgress;
