/**
 * 予約時注意事項モーダルの表示を管理するSlice
 * ※localStorageに保存するため他のSliceとは切り離す
 */

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  cautionDisplayDate: null,
  isNotesModalOpen: false,
  closeCallback: null,
};

export const displayCautionSlice = createSlice({
  name: 'displayCaution',
  initialState,
  reducers: {
    check: (state, { payload }) => {
      if (!state.cautionDisplayDate) {
        // 初回は必ず表示する
        state.isNotesModalOpen = true;
        state.closeCallback = payload;
      } else {
        // 2回目以降は最終表示から7日経過していたら表示する
        const toDate = dayjs(state.cautionDisplayDate).add(7, 'days');
        if (dayjs().isAfter(toDate)) {
          state.isNotesModalOpen = true;
        }
      }
    },
    closeModal: state => {
      state.isNotesModalOpen = false;
      state.closeCallback = null;
      state.cautionDisplayDate = null;
    },
    saveDisplayDate: state => {
      state.isNotesModalOpen = false;
      // 最後に表示した時間を保存
      state.cautionDisplayDate = dayjs().format('YYYYMMDDHHmmss');
      state.closeCallback = null;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {},
});

export const displayCautionActions = displayCautionSlice.actions;
export default displayCautionSlice.reducer;
