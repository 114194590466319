import { memo } from 'react';
import classNames from 'classnames';

export default memo(
  ({
    title = '',
    minLabelWidth,
    required = false,
    disabled = false,
    className = '',
  }) => {
    return (
      <>
        {title ? (
          <div
            className={classNames(
              'mr-2 flex w-auto flex-row items-center whitespace-nowrap',
              className,
              minLabelWidth ? `min-w-[${minLabelWidth}px]` : ''
            )}
          >
            <label
              className={classNames(
                'text-sm font-medium',
                disabled ? 'text-disabled' : 'text-navy'
              )}
            >
              {title}
            </label>
            {required && !disabled && (
              <div className="mx-2 flex items-center justify-center bg-red px-1">
                <label className="text-xs mb-[1px] font-medium text-white">
                  必須
                </label>
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  }
);
