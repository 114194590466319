import React, { useRef, useState, memo, useCallback, useEffect } from 'react';
import GoogleMapReact from 'google-maps-react-markers';
import classNames from 'classnames';
import AprosButton from 'components/aprosButton';
import Marker from './marker';

const GOOGLE_MAP_KEY = 'AIzaSyBEzDDx_Nvr-RAPYczF0AJjUve2cFg1tCU';

export default memo(
  ({
    markers = [],
    center,
    selectMarker,
    onClickMarker,
    onClickSearch,
    className,
    disabled,
  }) => {
    const containerRef = useRef();
    const mapRef = useRef();

    const [mapHeight, setMapHeight] = useState(0);

    const setRef = useCallback(node => {
      if (node) {
        setMapHeight(node?.offsetWidth ?? 0);
      }
      containerRef.current = node;
    }, []);

    useEffect(() => {
      mapRef.current && center && mapRef.current.setCenter(center);
    }, [center]);

    return (
      <div
        ref={element => setRef(element)}
        className={classNames(
          'map relative mt-6 flex w-full border-2 border-line',
          className
        )}
        style={{ height: mapHeight }}
      >
        <GoogleMapReact
          apiKey={GOOGLE_MAP_KEY}
          defaultCenter={center ? center : { lat: 35.650606, lng: 139.799115 }}
          defaultZoom={12}
          onGoogleApiLoaded={({ map }) => (mapRef.current = map)}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            styles: mapStyle,
          }}
          // 警告の対策
          libraries={['&callback=Function.prototype']}
        >
          {markers
            .filter(marker => marker.lat && marker.lng)
            .map((marker, i) => (
              <Marker
                key={i}
                lat={marker.lat}
                lng={marker.lng}
                shopCode={marker.shopCode}
                shopName={marker.shopName}
                selected={selectMarker === marker.shopCode}
                onClick={() => {
                  onClickMarker(marker);
                }}
              />
            ))}
        </GoogleMapReact>
        <AprosButton
          text="この周辺で探す"
          comClassName="main"
          className="absolute top-[4px] left-1/2 w-[200px] shadow-none sm:top-2 sm:w-60"
          style={{ transform: 'translate(-50%, 0)' }}
          disabled={disabled}
          onClick={() => {
            onClickSearch({
              lat: mapRef.current.center.lat(),
              lng: mapRef.current.center.lng(),
            });
          }}
        />
      </div>
    );
  }
);

const mapStyle = [
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [{ color: '#f3f3f1' }],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#d0cece' }],
  },
  { featureType: 'landscape.natural', stylers: [{ color: '#c3e3ce' }] },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ color: '#9f9f9f' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'labels.icon',
    stylers: [{ color: '#6c6c6c' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#b1b1b1' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#c8ccd7' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#c8ccd7' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'simplified' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#b1b1b1' }],
  },
  { featureType: 'road.local', stylers: [{ visibility: 'on' }] },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d6d1d1' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [{ color: '#8ab4cd' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#a0a0a0' }],
  },
  { featureType: 'transit.station', stylers: [{ visibility: 'on' }] },
  { featureType: 'water', stylers: [{ color: '#cce9ed' }] },
];
