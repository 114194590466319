import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  RESERVE_INFO_SEARCH,
  RESERVE_REFLECTION,
  SERVICE_LIST_SEARCH,
} from 'constants/apiURL';
import { messageModalActions } from 'slices/messageModalSlice';
import axios from '../utils/axiosUtil';

const initialState = {
  currentReserveList: null,
  pastReserveList: null,
  showCancelModal: false,
  cancelData: null,
  refresh: false,
  pitServiceList: [],
};

export const fetchPitServiceList = createAsyncThunk(
  'confirm/fetchPitServiceList',
  async () =>
    axios.get(SERVICE_LIST_SEARCH, { params: { isMain: '0', sortNum: 0 } })
);

export const fetchReserveList = createAsyncThunk(
  'fetchReserveList',
  async (params, { getState }) => {
    if (
      (!params.refresh &&
        getState().reservationList.currentReserveList !== null &&
        params.dateKbn === '1') ||
      (getState().reservationList.pastReserveList !== null &&
        params.dateKbn === '2')
    ) {
      return;
    }

    const [reserveRes] = await Promise.all([
      axios.post(RESERVE_INFO_SEARCH, params, { hiddenLoading: true }),
    ]);

    return {
      [params.dateKbn === '1' ? 'currentReserveList' : 'pastReserveList']:
        convertReserveInfo(reserveRes),
    };
  }
);

const convertReserveInfo = res => {
  const { yykGetYmd, yykGetHms, reservationInfo = [] } = res?.body ?? {};

  return reservationInfo.map(i => ({
    ...i,
    yykGetYmd,
    yykGetHms,
  }));
};

export const reservationCancel = createAsyncThunk(
  'reservationCancel',
  async (params, { dispatch }) => {
    dispatch(actions.closeModal());

    await axios.post(RESERVE_REFLECTION, params);

    dispatch(
      messageModalActions.showToastModal({
        message: '予約をキャンセルしました。',
      })
    );
    dispatch(
      fetchReserveList({
        kaiinNo: params.kaiinNo,
        dateKbn: '1',
        refresh: true,
      })
    );
  }
);

export const reservationListSlice = createSlice({
  name: 'reservationList',
  initialState,
  reducers: {
    showCancelModal: (state, { payload }) => {
      state.showCancelModal = true;
      state.cancelData = payload;
    },
    closeModal: state => {
      state.showCancelModal = false;
      state.cancelData = null;
    },
    refresh: state => {
      state.refresh = !state.refresh;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchReserveList.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    });
    builder.addCase(fetchPitServiceList.fulfilled, (state, { payload }) => {
      state.pitServiceList = payload.body;
    });
  },
});

export const actions = reservationListSlice.actions;
export default reservationListSlice.reducer;
