import axios from 'axios';
import { REPORT_SAP_ERROR } from '../constants/apiURL';
import Path from '../constants/routePath';
import { logout, logoutWithMessage } from '../slices/accountSlice';

export const sapLogin = (redirectURL, isMobila = false) => {
  if (isMobila) {
    window.localStorage.setItem('isMobila', isMobila);
  }
  window.gigya.sso.login({
    authFlow: 'redirect',
    redirectURL: redirectURL,
    context: {
      screenID: 'gigya-login-screen',
      lang: 'ja',
      serviceName: 'apros_pit',
      serviceUrl: 'https://pityoyaku.autobacs.com/',
    },
    callback: function (response) {
      reportError(response, null);
    },
  });
};

const sapSsoLogin = (redirectURL, screenID, isMobila = false) => {
  if (isMobila) {
    window.localStorage.setItem('isMobila', isMobila);
  }

  window.gigya.sso.login({
    authFlow: 'redirect',
    redirectURL: redirectURL,
    context: {
      screenID: screenID,
      lang: 'ja',
      serviceName: 'apros_pit',
      serviceUrl: 'https://pityoyaku.autobacs.com/',
    },
    callback: function (response) {
      reportError(response, null);
    },
  });
};

export const sapRegister = (redirectURL, isMobila = false) => {
  sapSsoLogin(redirectURL, 'gigya-register-screen', isMobila);
};

export const sapCardNumber = (redirectURL, isMobila = false) => {
  sapSsoLogin(redirectURL, 'gigya-card-number-authentication-screen', isMobila);
};

export const sapLogoutWithMessage = (cardNumber, navigate, dispatch) => {
  window.gigya.accounts.logout({
    callback: function (response) {
      reportError(response, cardNumber);
    },
  });
  window.gigya.accounts.addEventHandlers({
    onLogout: function (event) {
      dispatch(
        logoutWithMessage({
          cardNumber: cardNumber,
          onClose: () => {
            navigate(Path.TOP);
            window.location.reload();
          },
        })
      );
    },
  });
};

export const sapLogout = (cardNumber, dispatch) => {
  window.gigya.accounts.logout({
    callback: function (response) {
      reportError(response, cardNumber);
    },
  });
  window.gigya.accounts.addEventHandlers({
    onLogout: function (event) {
      dispatch(
        logout({
          cardNumber: cardNumber,
        })
      );
    },
  });
};

export const sapLogoutAndLogin = (cardNumber, dispatch, redirectURL) => {
  window.gigya.accounts.logout({
    callback: function (response) {
      reportError(response, cardNumber);
    },
  });
  window.gigya.accounts.addEventHandlers({
    onLogout: function (event) {
      dispatch(
        logout({
          cardNumber: cardNumber,
        })
      );
      sapLogin(redirectURL, false);
    },
  });
};

export const sapVerifyLogin = async () => {
  const verifyLogin = async () => {
    return new Promise((resolve, reject) => {
      window.gigya.accounts.verifyLogin({
        callback: function (response) {
          resolve(response);
        },
        onError: function (error) {
          reject(error);
        },
      });
    });
  };

  const sapResponse = await verifyLogin();

  await reportError(sapResponse, null);

  return sapResponse;
};

const reportError = async (response, cardNumber) => {
  if (response?.errorCode !== 0) {
    // 会員情報取得API実行
    const apiResponse = await axios.post(
      process.env.REACT_APP_API_BASE_URL + '/' + REPORT_SAP_ERROR,
      {
        sapMethod: response.operation,
        errorCode: response.errorCode,
        errorMessage: response.errorMessage,
        displayPath: window.location.pathname,
        cardNumber: cardNumber,
      }
    );
  }
};
