import React from 'react';
import ModalBase from 'components/modalBase';
import { useDeviceSize } from 'hooks/useDeviceSize';
import Linked from 'components/linked/index';
import { useDispatch, useSelector } from 'react-redux';
import { sapLogoutAndLogin } from 'utils/sapSdkUtil';
import Path from 'constants/routePath';

const CorporationLoginModal = ({ isModalOpen, closeModal }) => {
  const { isPc, isTablet } = useDeviceSize();
  const dispatch = useDispatch();
  const { accountInfo } = useSelector(state => state.account);

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={() => {
        //モーダル外押下で何もしない
      }}
      className="px-13 flex h-[300px] flex-col items-center overflow-auto pb-5 pt-10 text-navy sm:h-[441px] sm:pb-11 sm:pt-[65px] lg:h-[401px] lg:pb-[60px] lg:pt-[70px]"
    >
      <label className="text-base whitespace-pre-wrap text-center font-bold">
        法人カードをご利用のお客様は{'\n'}予約サイトをご利用いただけません。
        {'\n'}
        ご予約はお電話にて承っております。
      </label>

      <div className="mb-1 mt-2 items-center text-center">
        <Linked
          text={`0120-982-391 (フリーコール)`}
          href={`tel:0120-982-391`}
          isLine={true}
          disabled={isTablet}
          className="text-base whitespace-pre-wrap font-bold sm:text-[22px] "
        />
      </div>
      {isPc ? '' : '\n'}
      <label className="text-sm whitespace-pre-wrap text-center sm:text-[18px]">
        12:00～18:00 (土・日・祝祭日を除く)
      </label>
      <div className="text-sm mt-7 whitespace-pre-wrap text-center sm:mt-5 sm:text-[18px]">
        別のアカウントを利用する場合は{isPc ? '' : '\n'}
        こちらから
        <a
          className="group relative w-fit cursor-pointer items-center border-solid text-link after:absolute after:bottom-0.5 after:left-0 after:w-full
          after:border-b after:border-solid after:border-[#23629B]
          after:border-link hover:text-link-hover after:hover:border-link-hover"
          onClick={() => {
            //ログアウトしてから、CLPへ遷移
            sapLogoutAndLogin(
              accountInfo?.cardNumber,
              dispatch,
              process.env.REACT_APP_BASE_URL + Path.TOP
            );
          }}
        >
          ログイン
        </a>
        してください。
        <br />
        (法人カード以外のAB IDをご利用ください)
      </div>
    </ModalBase>
  );
};

export default CorporationLoginModal;
