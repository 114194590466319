import React from 'react';
import ModalBase from 'components/modalBase';
import AprosButton from 'components/aprosButton';
import Icons from 'images/icons';
import ServiceNameLabel from '../components/serviceNameLabel';

const ApproximateTimeUpdateModal = ({
  isModalOpen,
  closeModal,
  onClick,
  selectService,
  minutes,
  time,
}) => {
  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={closeModal}
      className="flex flex-col overflow-auto px-4 pb-10 pt-12 text-navy sm:px-11 sm:pb-10 sm:pt-[66px] lg:px-20 lg:pb-[92px] lg:pt-[78px]"
    >
      <div className="absolute right-0 top-0 py-2 pr-2">
        <Icons.Image
          src={Icons.closeModal}
          className="float-right h-8 cursor-pointer lg:h-[50px]"
          onClick={closeModal}
        />
      </div>

      <div className="flex flex-col">
        <div className="flex flex-row flex-wrap items-center justify-center">
          <label className="text-base font-bold">作業開始目安時間が約</label>
          <label className="mx-1 mb-2 text-[22px] font-bold text-red sm:text-[32px]">
            {minutes}
          </label>
          <label className="text-base font-bold">分後</label>
          <label className="text-base font-bold">(</label>
          <label className="text-sm mt-1 font-medium">作業開始</label>
          <label className="text-sm mt-1 font-medium">目安時間</label>
          <label className="mx-1 mb-2 text-[22px] font-bold text-red sm:text-[32px]">
            {time}
          </label>
          <label className="text-base font-bold">) </label>
          <label className="text-base font-bold">に更新されました</label>
        </div>

        <div className="mt-8 flex flex-col items-center gap-y-2 sm:mt-[22px] lg:mt-[39px] lg:gap-y-4">
          {selectService && (
            <ServiceNameLabel
              className={{ labelClass: 'text-sm' }}
              selectService={selectService}
            />
          )}
          <label className="text-sm">この内容でよろしいですか？</label>
        </div>

        <div className="mt-10 self-center lg:mt-[60px]">
          <AprosButton
            comClassName="reserve"
            text="予約を確定する"
            onClick={onClick}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ApproximateTimeUpdateModal;
