import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CAR_LIST_SEARCH,
  RESERVE_DETAIL_SEARCH,
  SERVICE_LIST_SEARCH,
  SHOP_LIST_SEARCH,
  SHOP_STATUS_SEARCH,
  USER_TIRE_STORAGE,
  USER_WAIT_TIME,
  USER_WAIT_TIME_RESERVE,
  SHOP_SERVICE_SEARCH_EXTERNAL,
} from 'constants/apiURL';
import { getService } from 'pages/reservation/utils/reservationUtil';
import axios from '../utils/axiosUtil';
import { loadingActions } from './loadingSlice';

const initialState = {
  selectedSrySeq: null,
  carList: [],
  shopInfo: {},
  shopList: [],
  dailyInfo: [],
  menuComment: '',
  requiredTime: '',
  nextWeekFlag: '0',
  targetIndex: -1,
  outOfTermIndex: -1,
  outOfTermMessage: '',
  waitTimeInfo: null,
  selectService: null,
  externalSelectSerivce: null,
  shopService: null,
  shopStatus: null,
  showDescriptionModal: false,
  showCalendarModal: false,
  showVehicleRegisterModal: false,
  showRankModal: false,
  showMailAddressSettingModal: false,
  showApproximateTimeUpdateModal: false,
  shopAvailabilityLoading: false,
  isTireContract: false,
};

export const fetchShopServiceExternal = createAsyncThunk(
  'fetchShopServiceExternal',
  async params => {
    const serviceRes = await axios.get(SHOP_SERVICE_SEARCH_EXTERNAL, {
      params: { shopCode: params },
    });

    return serviceRes.body;
  }
);

export const fetchDateSelectInit = createAsyncThunk(
  'fetchDateSelectInit',
  async (params, { dispatch }) => {
    dispatch(loadingActions.setPrimaryLoading(true));

    const [carRes, shopRes, statusRes, shopServiceRes, tireRes] =
      await Promise.all([
        axios.get(CAR_LIST_SEARCH),
        axios.get(SHOP_LIST_SEARCH, { params: { kbn: '5' } }),
        axios.get(SHOP_STATUS_SEARCH.replace('{shopCode}', params.shopCode)),
        axios.get(SERVICE_LIST_SEARCH, {
          params: { tokCd: params.shopCode, isMain: '0', sortNum: 0 },
        }),
        axios.post(USER_TIRE_STORAGE, { userId: params.userId }),
      ]);

    let userWaitTimeRes;

    try {
      userWaitTimeRes = await axios.post(
        USER_WAIT_TIME,
        {
          shopCode: params.shopCode,
          chubunCd: params.chubunCd,
          shobunCd: params.shobunCd[0]?.shobunCd,
        },
        { ignoreError: true }
      );
    } catch (error) {}

    const shopList = shopRes.body.shopList.map(shop => ({
      ...shop,
      value: shop.shopCode,
      label: shop.shopName,
    }));

    const selectService = getService(
      shopServiceRes?.body || [],
      params.shobunCd
    );

    dispatch(loadingActions.setPrimaryLoading(false));

    const { getTime, menus, frontStatus } = userWaitTimeRes?.body ?? {};

    const { menuCd, confirmMenuWaitMinutes, shobun = [] } = menus?.[0] ?? {};

    return {
      carList: carRes.body.carList.map(car => ({
        ...car,
        value: car.sryMkrCd,
        label: car.sryMkrNm1,
      })),
      shopList,
      shopInfo: shopList.find(s => s.shopCode === params.shopCode),
      waitTimeInfo: {
        confirmMenuWaitMinutes,
        getTime,
        menuCd,
        chubunCd: params.chubunCd,
        shobunCd: shobun[0]?.shobunCd,
        sgyTime: shobun[0]?.sgyTime,
        frontStatus,
      },
      shopStatus: statusRes.body.shopStatus,
      selectService,
      shopService: shopServiceRes?.body || [],
      isTireContract: tireRes?.body?.contractStatus === '1',
    };
  }
);

export const fetchShopAvailability = createAsyncThunk(
  'fetchShopAvailability',
  async (params, { dispatch }) => {
    dispatch(actions.setShopAvailabilityLoading(true));
    const [reserveDetailRes] = await Promise.all([
      axios.get(RESERVE_DETAIL_SEARCH, {
        params,
        hiddenLoading: true,
      }),
    ]);
    dispatch(actions.setShopAvailabilityLoading(false));
    return reserveDetailRes?.body;
  }
);

export const fetchUserWaitTime = createAsyncThunk(
  'dateSelect/fetchUserWaitTime',
  async params => axios.post(USER_WAIT_TIME, params)
);

export const updateUserWaitTime = createAsyncThunk(
  'dateSelect/updateUserWaitTime',
  async params => {
    const userWaitTimeRes = await axios.post(USER_WAIT_TIME, params, {
      ignoreError: true,
    });

    const { getTime, menus, frontStatus } = userWaitTimeRes?.body ?? {};

    const { menuCd, confirmMenuWaitMinutes, shobun = [] } = menus?.[0] ?? {};

    return {
      waitTimeInfo: {
        confirmMenuWaitMinutes,
        getTime,
        menuCd,
        chubunCd: params.chubunCd,
        shobunCd: shobun[0]?.shobunCd,
        sgyTime: shobun[0]?.sgyTime,
        frontStatus,
      },
    };
  }
);

export const waitTimeReserve = createAsyncThunk(
  'dateSelect/waitTimeReserve',
  async params => axios.post(USER_WAIT_TIME_RESERVE, params)
);

export const reservationDateSelectSlice = createSlice({
  name: 'reservationDateSelect',
  initialState,
  reducers: {
    setSrySeq: (state, { payload }) => {
      state.selectedSrySeq = payload;
    },
    showDescriptionModal: state => {
      state.showDescriptionModal = true;
    },
    closeDescriptionModal: state => {
      state.showDescriptionModal = false;
    },
    toggleDescriptionModal: state => {
      state.showDescriptionModal = !state.showDescriptionModal;
    },
    showCalendarModal: state => {
      state.showCalendarModal = true;
    },
    closeCalendarModal: state => {
      state.showCalendarModal = false;
    },
    showVehicleRegisterModal: state => {
      state.showVehicleRegisterModal = true;
    },
    closeVehicleRegisterModal: state => {
      state.showVehicleRegisterModal = false;
    },
    updateWaitTime: (state, { payload }) => {
      state.waitTimeInfo = payload;
    },
    updateExternalSelectService: (state, { payload }) => {
      state.externalSelectSerivce = payload;
    },
    showRankModal: state => {
      state.showRankModal = true;
    },
    closeRankModal: state => {
      state.showRankModal = false;
    },
    showMailAddressSettingModal: state => {
      state.showMailAddressSettingModal = true;
    },
    showApproximateTimeUpdateModal: state => {
      state.showApproximateTimeUpdateModal = true;
    },
    closeModal: state => {
      state.showMailAddressSettingModal = false;
      state.showApproximateTimeUpdateModal = false;
    },
    setShopAvailabilityLoading: (state, { payload }) => {
      state.shopAvailabilityLoading = payload;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchDateSelectInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchShopAvailability.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(updateUserWaitTime.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
  },
});

export const actions = reservationDateSelectSlice.actions;
export default reservationDateSelectSlice.reducer;
