import { memo } from 'react';
import classNames from 'classnames';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { preventRootScroll } from 'utils/otherUtil';
import { isSafari } from 'react-device-detect';
import './style.scss';

export default memo(
  ({
    formik = null,
    fieldName = '',
    value = null,
    onChange = null,
    changeAfter = null,
    placeholder = '選択してください',
    options = [],
    disabled = false,
    error = false,
    className = '',
    virtual = true,
  }) => {
    return (
      <div
        className={classNames(
          'base-input-select',
          'flex w-full flex-col',
          className
        )}
      >
        <Select
          name={fieldName}
          placeholder={placeholder}
          className={classNames(
            'text-sm h-11 w-full rounded-none border bg-white text-navy outline-none hover:rounded-none focus:rounded-none disabled:border-disabled disabled:bg-white disabled:text-disabled sm:h-12',
            {
              'border-navy': !formik?.errors[fieldName],
              'border-red': error,
              'border-disabled': disabled,
              'border-1.5': error,
            }
          )}
          onPopupScroll={isSafari ? preventRootScroll : null}
          suffixIcon={<CaretDownOutlined className="text-navy" />}
          value={formik ? formik?.values?.[fieldName] : value}
          popupClassName={'custom-dropdown'}
          onBlur={_ => formik?.setFieldTouched(fieldName, true)}
          virtual={virtual}
          onChange={v => {
            if (formik) {
              formik?.setFieldValue(fieldName, v);
              setTimeout(() => formik?.setFieldTouched(fieldName, true));
              if (changeAfter) {
                changeAfter(v);
              }
            } else {
              onChange(v);
            }
          }}
        >
          {options?.map(o => (
            <Select.Option key={o.value} value={o.value}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
);
