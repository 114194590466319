import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Path, { ERROR_MESSAGE_TRANSITION } from 'constants/routePath';
import { messageModalActions } from 'slices/messageModalSlice';
import { actions as loginActions } from 'slices/loginSlice';
import { useQueryParams } from './useQueryParams';
import { decode } from 'utils/base64Util';
import { mobilaActions } from 'slices/mobilaSlice';

/**
 * 外部遷移の引数から遷移先情報を取り出すhooks
 */
export const useExternalTransition = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { accountInfo } = useSelector(state => state.account);
  const queryParams = useQueryParams();
  // restには、'p3','p4','p5','p13'+期待しないパラメータが含まれる
  const { p1, p2, p6, p7, p8, p9, p10, p11, p12, st, bcd, sid, v1, ...rest } =
    queryParams;
  const prefix = ['p3', 'p4', 'p5', 'p13'];

  // パラメータに引数のkey以外が含まれているか判定する
  const hasUnmatchKey = (params, keys) => {
    const allKeys = Object.keys(params);
    for (const key of allKeys) {
      if (prefix.filter(p => key.startsWith(p)).length > 0) {
        // 在庫引当用のパラメータ確認処理
        for (const p of prefix) {
          if (key.startsWith(p) && !keys.includes(p)) {
            return true;
          }
        }
      } else if (!keys.includes(key)) {
        return true;
      }
    }
    return false;
  };

  // パラメータ不正エラーを表示する
  const showError = () => {
    const message = ERROR_MESSAGE_TRANSITION;
    dispatch(messageModalActions.showMessageModal({ message }));
    dispatch(loginActions.closeAbidDifferenceModal());
  };

  // エラーチェックを行い、遷移パラメータを作成する
  const createLink = (to, useParams) => {
    if (hasUnmatchKey(queryParams, useParams)) {
      showError();
      return { to: Path.TOP };
    }
    return {
      to,
      state: {
        ...location.state,
        queryParams: { ...queryParams, p12: decode(p12) },
      },
    };
  };

  // 外部遷移チェック
  const createRedirect = () => {
    // queryParamsから必要なパラメータ以外を排除
    for (const param in rest) {
      if (prefix.filter(p => param.startsWith(p)).length === 0) {
        delete queryParams[param];
      }
    }

    // CLPからの遷移の場合、外部遷移パラメータがないこともあるので、外部戦かどうか判断する前に、MOBILAフラグを確認する
    if (
      window.localStorage.getItem('isMobila') === true ||
      window.localStorage.getItem('isMobila') === 'true'
    ) {
      dispatch(mobilaActions.setMobila(true));
      window.localStorage.removeItem('isMobila');
    }

    // クエリパラメータがない場合、外部遷移ではない
    if (Object.keys(queryParams).length === 0) return null;

    // MOBILA経由か
    if (p1) {
      dispatch(mobilaActions.setMobila(p1 === 'MOBILA'));
    }

    if (v1) {
      // オイル交換・タイヤ交換のLPページ
      return createLink(Path.RESERVATION_SERVICE_SELECT, ['v1', 'p10']);
    } else if (p1 && p1 === 'MOBILA') {
      // MOBILA経由遷移
      return createLink(accountInfo ? Path.TOP : 'TODO', ['p1', 'p12']);
    } else if (p1 && p1 !== 'MOBILA') {
      // 店舗在庫引当経由遷移
      return createLink(Path.RESERVATION_DATE_SELECT, [
        'p1',
        'p2',
        'p3',
        'p4',
        'p5',
        'p6',
        'p7',
        'p8',
        'p9',
        'p10',
        'p11',
        'p12',
        'p13',
      ]);
    } else if (st || bcd) {
      // 店舗・法人・MK戦略（店舗/中分類指定）
      return createLink(Path.RESERVATION_SERVICE_SELECT, ['st', 'bcd']);
    } else if (sid) {
      // 店舗・法人・MK戦略（店舗/中分類/小分類指定）
      return createLink(Path.RESERVATION_DATE_SELECT, ['sid']);
    } else if (p2 && p10 && p11) {
      // 店舗/中分類/小分類指定
      return createLink(Path.RESERVATION_DATE_SELECT, [
        'p2',
        'p10',
        'p11',
        'p12',
      ]);
    } else if (p10 && p11) {
      // 中分類/小分類指定
      return createLink(Path.RESERVATION_SHOP_SELECT, ['p10', 'p11', 'p12']);
    } else {
      // 上記パターン以外 ※パラメータ不正もここに来る可能性あり
      return createLink(Path.RESERVATION_SERVICE_SELECT, [
        'p2',
        'p10',
        'p11',
        'p12',
      ]);
    }
  };

  return { redirect: createRedirect() };
};
