import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: '必須項目です',
  },
  string: {
    min: ({ min }) => `${min}文字以上入力してください`,
    max: ({ max }) => `${max}文字以内で入力して下さい`,
  },
  array: {
    min: ({ min }) => `${min}個以上登録必須です`,
    max: ({ max }) => `最大${max}個までです`,
  },
});

Yup.addMethod(Yup.string, 'selected', function (msg) {
  return this.test('selected', '必須項目です', value => !!value);
});

Yup.addMethod(Yup.boolean, 'checked', function (msg) {
  return this.oneOf([true], '必須項目です');
});

Yup.addMethod(Yup.string, 'tel', function () {
  return this.test(
    'tel',
    '形式が異なります',
    value => !value || /^([0-9]{10}|[0-9]{11})$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'telKeitai', function () {
  return this.test(
    'telKeitai',
    '形式が異なります',
    value => !value || /^([0-9]{11})$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'zipCode', function () {
  return this.test(
    'zipCode',
    '半角数字7桁で入力してください',
    value => !value || /(^$)|^[0-9]{7,7}$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'selected', function () {
  return this.test('selected', '必須項目です', value => !!value);
});

Yup.addMethod(Yup.string, 'mail', function () {
  return this.test(
    'mail',
    '形式が異なります',
    value =>
      !value ||
      (!(value.length > 50) &&
        /^[a-zA-Z.0-9-._/#?!%&~+]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          value
        ))
  );
});

Yup.addMethod(Yup.string, 'mailConfirm', function () {
  return this.test(
    'mailConfirm',
    'メールアドレスが\n一致しません',
    (value, context) => value === context.parent.mail
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_19', function () {
  return this.test(
    'FULL_WIDTH_19',
    '全角19文字以内で入力してください',
    (value, context) => {
      const fullName =
        (context.parent.lastName || '') + (context.parent.firstName || '');
      return (
        !fullName ||
        /^[^0-9０-９ 　!-/:-@[-`{-~¥“”‘’€£•`]{1,19}$/.test(fullName)
      );
    }
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_19_SURROGATE_PAIR', function () {
  return this.test(
    'FULL_WIDTH_19_SURROGATE_PAIR',
    '全角19文字以内で入力してください',
    value => !value || /^((?![\uD800-\uDBFF][\uDC00-\uDFFF]).)*$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_19_EMOJI', function () {
  return this.test(
    'FULL_WIDTH_19_EMOJI',
    '全角19文字以内で入力してください',
    value =>
      !value ||
      /^((?!(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]).)*$/.test(
        value
      )
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_KANA_11', function () {
  return this.test(
    'FULL_WIDTH_KANA_11',
    '全角カナ11文字以内で入力して下さい',
    (value, context) => {
      const fullNameKana =
        (context.parent.lastNameKana || '') +
        (context.parent.firstNameKana || '');
      return !fullNameKana || /^[ァ-ンｧ-ﾝﾞﾟ\-ー]{1,11}$/.test(fullNameKana);
    }
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_KANA_11_SURROGATE_PAIR', function () {
  return this.test(
    'FULL_WIDTH_KANA_11_SURROGATE_PAIR',
    '全角カナ11文字以内で入力して下さい',
    value => !value || /^((?![\uD800-\uDBFF][\uDC00-\uDFFF]).)*$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_KANA_11_EMOJI', function () {
  return this.test(
    'FULL_WIDTH_KANA_11_EMOJI',
    '全角カナ11文字以内で入力して下さい',
    value =>
      !value ||
      /^((?!(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]).)*$/.test(
        value
      )
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_20', function () {
  return this.test(
    'FULL_WIDTH_20',
    '全角20文字以内で入力してください',
    value => !value || /^.{0,20}$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_20_SURROGATE_PAIR', function () {
  return this.test(
    'FULL_WIDTH_20_SURROGATE_PAIR',
    '全角20文字以内で入力してください',
    value => !value || /^((?![\uD800-\uDBFF][\uDC00-\uDFFF]).)*$/.test(value)
  );
});

Yup.addMethod(Yup.string, 'FULL_WIDTH_20_EMOJI', function () {
  return this.test(
    'FULL_WIDTH_20_EMOJI',
    '全角20文字以内で入力してください',
    value =>
      !value ||
      /^((?!(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]).)*$/.test(
        value
      )
  );
});

export default Yup;
