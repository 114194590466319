import React from 'react';
import Proctitle from 'components/proctitle';

const Terms = () => {
  return (
    <>
      <Proctitle text="サイト利用規約" className={{ areaClass: 'pb-10' }} />
      <div className="font-sans text-[14px] text-navy sm:text-[18px]">
        <div>第1章 総 則</div>
        <div>第1条(URL)</div>
        <div>
          本規約は、当社が運営するウェブサイトであるオートバックス・ドットコムautobacs.com(https://www.autobacs.com/
          及びhttps://www.autobacs.co.jp)において適用されるものとします。
        </div>
        <div>第2条(定義)</div>
        <div>
          本規約においては、次の各号記載の用語はそれぞれ次のとおり定義します。
        </div>
        <div>
          (1)「本ウェブサイト」とは、第1条に規定するＵＲＬのもとに当社が運営するウェブサイトをいいます。
        </div>
        <div>
          (2)「利用者」とは、本ウェブサイトにアクセスし、本ウェブサイトを利用する者の総称をいうものとし、(3)の「利用登録完了者」もこれに含まれます。
        </div>
        <div>
          (3)「利用登録完了者」とは、利用者のうち、所定の手続(以下、ウェブ利用登録という)をもって登録を行い、当社が承諾した者をいいます。
        </div>
        <div>
          (4)「ウェブサイト提供」とは、当社が本ウェブサイトをインターネット上で提供することをいいます。
        </div>
        <div>第3条(規約の遵守)</div>
        <div>
          (1)利用者は、本ウェブサイトを利用するにあたり、本規約を遵守するものとします。
        </div>
        <div>
          (2)当社は、別途、本ウェブサイトに掲示する等により個別規定を定め、また、随時利用者に対して通知して追加規定を定めることがあります。これらは本規約と一体をなし、かつ本規定に優先するものとして、利用者は遵守しなければならないものとします。
        </div>
        <div>
          (3)当社は、本規約を変更する必要が生じた場合は、相当の予告期間をおいて本ウェブサイト(https://www.autobacs.com/
          及びhttps://www.autobacs.co.jp)において変更後の内容を利用者に周知することにより、変更することができるものとします。予告期間経過後は、変更後の内容が適用されるものとします。
        </div>
        <div>第4条(設備等の設置及び維持)</div>
        <div>
          本ウェブサイトへアクセスするために必要な設備一切は、利用者が自らの費用と責任で準備し、正常に稼働するように維持するものとします。
        </div>
        <div>第5条(禁止事項)</div>
        <div>
          (1)当社を始め、他の利用者や第三者の財産(著作権その他の知的財産権を含みます)、信用、プライバシーまたはその他の権利を侵害する行為、あるいはそのおそれのある行為
        </div>
        <div>
          (2)当社を始め、他の利用者や第三者に不利益または損害を与える行為、あるいはそのおそれのある行為
        </div>
        <div>
          (3)他の利用者や第三者の個人情報を収集もしくは蓄積する行為、あるいはそのおそれのある行為
        </div>
        <div>(4)当社を始め、他の利用者や第三者を誹謗中傷する行為</div>
        <div>
          (5)犯罪行為もしくは犯罪に結びつく行為、あるいはそのおそれのある行為
        </div>
        <div>(6)公序良俗に反する行為、あるいはそのおそれのある行為</div>
        <div>
          (7)公序良俗に反する情報を他の利用者または第三者に開示、提供する行為
        </div>
        <div>(8)選挙運動、またはこれに類する行為</div>
        <div>
          (9)性風俗、宗教もしくは政治に関する活動、またはこれに類する行為
        </div>
        <div>
          (10)本ウェブサイト上で、あるいはウェブサイト提供に関連して、営利を目的とした行為、またはその準備を目的とした行為
        </div>
        <div>
          (11)チェーンレター、スパムメール、ジャンクメール、無限連鎖講、マルチあるいはマルチまがい、その他勧誘等を目的とする情報を開示またはメールなどの方法で送信する行為
        </div>
        <div>
          (12)コンピュータウイルス等の有害なプログラムや情報を開示またはメールなどの方法で送信する行為
        </div>
        <div>(13)ログインＩＤまたはパスワードを不正に使用する行為</div>
        <div>(14)本ウェブサイトに不正にアクセスする行為</div>
        <div>(15)本ウェブサイトに不正の目的でアクセスする行為</div>
        <div>(16)ウェブサイト提供に妨害となる行為</div>
        <div>(17)法令に違反する行為、あるいはそのおそれのある行為</div>
        <div>
          (18)上記各号の行為を第三者にさせ、あるいは加担する行為、あるいはそのおそれのある行為
        </div>
        <div>(19)その他、当社が不適切と判断する行為</div>
        <br></br>
        <div>第2章 ウェブサイト提供</div>
        <div>第6条(本ウェブサイトにより提供するサービス)</div>
        <div>
          (1)当社が本ウェブサイトにより提供し、あるいは提供する予定のサービスは次のとおりです。
        </div>
        <div>1 本ウェブサイトによる商品またはサービスの提供</div>
        <div>
          2
          提供する商品またはサービスについてのオンライン電子カタログ等による情報の提供
        </div>
        <div>3 車両情報を基にした適合・マッチングシステムによる商品提案</div>
        <div>
          4
          提供する商品またはサービスの受け取り、取り付けについてのオンライン予約
        </div>
        <div>5 その他、当社が定めるサービス</div>
        <div>
          (2)提供するサービスの一部は、「ウェブ利用登録完了者」のみが利用できるものとします。
        </div>
        <div>
          (3)利用者は、サービスを私的にのみ利用するものとし、これに関連して営業を行わないものとします。
        </div>
        <div>
          (4)当社は、利用者に事前に通知することなく、サービスの提供を中止し、あるいはその内容を変更することがあります。これにより、利用者に不利益や損害が発生した場合であっても、当社はその責任を負わないものとします。
        </div>
        <div>第7条(本ウェブサイトの変更、中断、停止)</div>
        <div>
          (1)本ウェブサイトは、当社の判断により任意に変更、中断、停止できるものとします。
        </div>
        <div>
          (2)ウェブサイト提供が変更、中断、停止された等により、利用者または第三者が被ったいかなる不利益、損害についても、当社は理由の如何を問わず一切の責任を負わないものとします。
        </div>
        <div>第8条(権利の帰属等)</div>
        <div>
          (1)本ウェブサイトを構成するすべてのプログラム、ソフトウェア、著作権、ビジネスモデル、手続き、商標、商号、意匠、サービスマーク、データベースおよびそれらに付随するすべての技術は当社の承諾なく、利用し、あるいは第三者に利用させることはできません。
        </div>
        <div>
          (2)利用者が本ウェブサイトにアップロードした情報またはファイルについては、当社及び当社が許諾した者に対して、所有権や知的財産権等の権利行使はできず、何らの請求権も有しないものとします。
        </div>
        <div>
          (3)利用者が本ウェブサイトにアップロードした情報またはファイルについては、当社及び当社が許諾した者に対して、それらを蓄積、複製、頒布及び利用する権利を与えたものとします。
        </div>
        <div>
          (4)利用者は、本ウェブサイトにアップロードした情報またはファイルについては著作者人格権に基づく請求権を当社に対し事前に放棄したものとし、当社は任意にこれを変更、要約、削除することができるものとします。
        </div>
        <div>
          (5)利用者は、本ウェブサイトにアップロードした情報またはファイルについて第三者や当社に損害を与えた場合には、その責任を負うものとします。
        </div>
        <div>
          (6)利用者は、本ウェブサイトを通じて収集した情報またはファイルについて、当該権利者の許諾を得ないで、利用し、あるいは第三者に利用させることはできません。
        </div>
        <div>(7)本ウェブサイトをリンクするには、当社の承諾を要します。</div>
        <div>
          (8)本条の規定に違反して紛争が生じた場合、利用者は、自己の費用と責任において、当該紛争を解決するとともに、いかなる場合も当社を免責し、当社に損害を与えないものとします。
        </div>
        <br></br>
        <div>第3章 利用登録</div>
        <div>第9条(ウェブ利用登録)</div>
        <div>
          (1)ウェブ利用登録を希望する者(以下「登録希望者」といいます)は、当社が定める手続に従って、入会の申込みをし、当社が承諾した場合、本ウェブサイトの会員機能の利用が可能となります。
        </div>
        <div>
          なお、「オートバックス
          会員カード」または、「当グループ提携カード」への入会が必須となります。
        </div>
        <div>
          (2)入会申込みにあたっては、必ず次のことを実行しなければならないものとします。
        </div>
        <div>
          1
          本規約を熟読し、その内容を理解し、その内容に拘束されることを承諾したうえで、所定のウェブ利用登録手続を行うこと。
        </div>
        <div>
          2
          氏名(本名に限ります)、生年月日、住所、電話番号、電子メールアドレス等、ウェブ利用登録のために必要なものとして、当社が入会登録画面で設定する事項すべてを当社に届け出ること。
        </div>
        <div>
          (3)入会希望者が18歳未満の場合、親権者または後見人による承諾を得たうえで、所定の会員登録手続を行うこと。
        </div>
        <div>
          (4)次に定める事由のいずれかに該当する場合、入会申込みはできないものとし、当社は入会を承諾しない場合があります。なお不承諾については、後に拒絶の通知を送りあるいは掲示する場合があります。
        </div>
        <div>1 登録希望者の実在が確認できない場合</div>
        <div>2 登録希望者が日本国外に居住する場合</div>
        <div>3 登録希望者がすでに「利用登録完了者」となっている場合</div>
        <div>
          4
          登録希望者が本規約に違反したことがあったり、登録資格の停止、抹消事由に該当したことがある場合
        </div>
        <div>5 登録事項に誤りがあるため、その他の理由で連絡が取れない場合</div>
        <div>
          6 その他、「利用登録完了者」とすることを不適切と当社が判断した場合
        </div>
        <div>
          (5)当社がウェブ利用登録手続きの承諾をした場合であっても、前項のいずれかの事由に該当することが判明した場合、当社は当該承諾を取消すことがあります。
        </div>
        <div>
          (6)ウェブ利用資格は、他に譲渡したり、他の者に利用させたりすることはできません。
        </div>
        <div>第10条(届出事項の変更)</div>
        <div>
          「利用登録完了者」は、ウェブ利用登録手続きの際に当社に届け出た事項に変更のあった場合は、遅滞なく、当社あてに所定の方法で届け出るものとします。なお、「利用登録完了者」は名義の変更をすることはできません。
        </div>
        <div>第11条(情報の取扱い)</div>
        <div>
          ウェブ利用登録手続きの際に送付された情報及び「利用登録完了者」が届け出た変更事項の情報は当社のデータベースに登録され、当該登録情報は当社の所有とし、当社及び当社グループにおいて利用することが許諾されたものとします。なお、当社は、会員の同意がある場合や監督官庁からの要請、法令による開示が求められた場合等正当な理由がある場合の他は、個人識別が可能な状態で当社グループ外の第三者に情報を提供しないものとします。
        </div>
        <div>第12条(当社から「利用登録完了者」への通知方法)</div>
        <div>
          (1)当社から個別の「利用登録完了者」に対する通知は、「利用登録完了者」が登録事項として届け出ているアドレス宛の電子メールアドレスによることを原則とします。但し、やむを得ない場合、本ウェブサイト上に掲示すること、その他当社が適当と認めるその他の方法による場合があるものとします。
        </div>
        <div>
          (2)当社から「利用登録完了者」一般に対する通知は、会員が登録事項として届け出ているアドレス宛の電子メール、本ウェブサイト上の掲示またはその他当社が適当と認めるその他の方法により送付されるものとします。
        </div>
        <div>
          (3)当社からの通知が電子メールで行われる場合、「利用登録完了者」が登録事項として届け出ているアドレスの所在するサーバー宛に電子メールを発信し、当該サーバーに到着したときをもって会員への通知が完了したものとみなします。
        </div>
        <div>
          (4)当社からの通知が本ウェブサイト上の掲示により行われる場合、当該通知が本ウェブサイト上に掲示され、当該ウェブサイトにアクセスすれば当該通知を閲覧することが可能となったときをもって「利用登録完了者」への通知が完了したものとみなします。
        </div>
        <div>
          (5)「利用登録完了者」が登録事項を誤り、あるいは登録事項の変更を怠った場合は、当社からの通知が不到達となっても、通常、到達すべき時に到達したとみなされることを予め異議なく承諾するものとします。
        </div>
        <div>第13条(ログインＩＤ名及びパスワードの管理)</div>
        <div>
          (1)「利用登録完了者」は、本ウェブサイト上の「利用登録完了者」としてのサービスの提供を受ける場合には、当社が使用を認めたログインＩＤとパスワード、若しくは「オートバックス
          会員カード」または、「当グループ提携カード」に記載または登録された特定の情報(以下、カード情報という)を使用するものとします。
        </div>
        <div>
          (2)「利用登録完了者」は、ログインＩＤ及びパスワード、またはカード情報の管理責任を負うものとします。
        </div>
        <div>
          (3)「利用登録完了者」は、ログインＩＤ及びパスワード、またはカード情報を自己の正当な利用以外、第三者に利用させたり、貸与、名義貸し、譲渡、担保提供等いかなる処分もしてはならないものとします。
        </div>
        <div>
          (4)ログインＩＤ及びパスワード、またはカード情報の管理不十分、使用上の過誤、第三者の使用等による損害発生の責任は「利用登録完了者」が負うものとし、当社は一切責任を負わないものとします。
        </div>
        <div>
          (5)「利用登録完了者」は、ログインＩＤ及びパスワード、またはカード情報が盗まれたり、第三者によって使用されていることを知った場合、直ちに当社に連絡するとともに、当社からの指示がある場合にはこれに従うものとします。
        </div>
        <div>第14条(退会)</div>
        <div>
          (1)「利用登録完了者」が退会を希望する場合には、当社所定の書式・方法にて当社に届け出るものとします。なお、「オートバックス
          会員カード」または、「当グループ提携カード」からの退会手続きがあわせて必要です。
        </div>
        <div>
          (2)「利用登録完了者」が退会する場合、当該「利用登録完了者」は本ウェブサイトの利用に関する一切の権利、特典を失うものとし、退会に伴って何らの請求権も取得するものではありません。
        </div>
        <div>
          (3)「利用登録完了者」が死亡した場合には、その時点で退会したものとみなしますが、退会手続は当社が知った後に行われることになります。
        </div>
        <div>
          (4)会員が暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等社会運動標ぼうゴロ、または、特殊知能暴力集団等の反社会勢力であることが判明した場合は、不正行為や迷惑行為等の有無にかかわらず、当グループは当該会員を退会させることができるものとします。
        </div>
        <div>第15条(会員資格の停止・抹消)</div>
        <div>
          (1)「利用登録完了者」が以下の事由のいずれかに該当する場合、当社は、「利用登録完了者」に何ら事前の通知または催告をすることなく、利用資格を一次停止し、または抹消することができるものとします。
        </div>
        <div>
          1 第9条第3項に定める事由のいずれかに該当することが判明した場合
        </div>
        <div>
          2
          ログインＩＤ、パスワード、カード情報を不正に使用しまたは使用させた場合
        </div>
        <div>
          3
          当社が本ウェブサイト上で提供する情報を当社の承諾を得ることなく改変した場合
        </div>
        <div>
          4 不正の目的をもって本ウェブサイトにより提供するサービスを利用した場合
        </div>
        <div>5 手段を問わず、サービスの運営を妨害した場合</div>
        <div>6 第5条に定める禁止行為を行った場合</div>
        <div>
          7
          「利用登録完了者」について、差押、仮差押、仮処分、強制執行、破産等の申立がなされた場合
        </div>
        <div>8 長期の利用がない場合</div>
        <div>9 本規約のいずれかの条項に違反した場合</div>
        <div>10 その他、「利用登録完了者」として不適格と当社が判断した場合</div>
        <div>
          (2)会員資格が抹消された場合、当該会員は、当社に対する債務があるときにはその全額を直ちに支払わなければならないものとします。
        </div>
        <br></br>
        <div>第4章 その他</div>
        <div>第16条(責任)</div>
        <div>
          (1)ウェブサイト提供において、遅滞、変更、中断、中止、停止もしくは廃止、情報の流出、消失もしくは変質、バグ等、その他ウェブサイト提供に関連して発生した利用者または第三者の損害について、当社は一切の責任を負わず、免責されるものとします。
        </div>
        <div>
          (2)利用者が本ウェブサイトの利用によって第三者に対して損害を与えた場合、利用者は自己の責任と費用をもって解決し、当社に損害を与えないものとします。また、利用者が本規約に反した行為または不正もしくは違法な行為によって当社に損害を与えた場合、当社は当該利用者に対して損害賠償の請求ができるものとします。
        </div>
        <div>第17条(準拠法)</div>
        <div>
          本規約の効力、履行及び解釈に関しては日本国法が適用されるものとします。
        </div>
        <div>第18条(合意管轄)</div>
        <div>
          本規約に関して紛争が生じた場合、東京地方裁判所を第一審の合意管轄裁判所とします。
        </div>
        <div>以上</div>
      </div>
    </>
  );
};

export default Terms;
