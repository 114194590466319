import { useSelector } from 'react-redux';
import Icons from 'images/icons';

/* ローディング部品 */

const Loading = () => {
  const { loading, primaryLoading, authLoading } = useSelector(state => state.loading);
  return (
    <>
      {(loading || primaryLoading || authLoading) && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-[20000] flex h-full w-screen overflow-y-scroll overscroll-y-contain">
          <div className="flex h-screen w-full items-center justify-center backdrop-blur-[3px]">
            <Icons.Image
              src={Icons.loading}
              className="h-[60px] w-[60px] sm:h-[80px] sm:w-[80px] lg:h-[100px] lg:w-[100px]"
            />
          </div>
          {/** スクロールを禁止するためのつっかえ棒 */}
          <div className="bg-transparent h-[calc(100vh_+_1px)] w-[1px]"></div>
        </div>
      )}
    </>
  );
};

export default Loading;
