import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import AprosButton from 'components/aprosButton';
import Linked from 'components/linked';
import { KARTE_ICONS } from 'constants/karte';
import Path from 'constants/routePath';
import { useDeviceSize } from 'hooks/useDeviceSize';
import Icons from 'images/icons';
import { isIOS, isAndroid } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import Title from '../../constants/title';
import LoginGuidanceModal from '../../components/headerRow/modal/loginGuidanceModal';
import { sapLogin, sapRegister, sapCardNumber } from '../../utils/sapSdkUtil';
import { useSelector } from 'react-redux';

const KarteDescription = () => {
  const navigate = useNavigate();

  const { isPc, isTablet, isSp } = useDeviceSize();

  const [previousViewRef, previousInView] = useInView();

  const [ref, inView] = useInView();

  const [gifImage, setGifImage] = useState();

  const [showLoginGuidance, setLoginGuidance] = useState(false);

  const { isMobila } = useSelector(state => state.mobila);

  useEffect(() => {
    setGifImage(isSp ? Icons.halfSp : Icons.fullSp);

    return () => {
      setGifImage();
    };
  }, [isSp]);

  useEffect(() => {
    !previousInView &&
      inView &&
      setGifImage(pre => {
        const updateImage = isSp ? Icons.halfSpGif : Icons.fullSpGif;

        return pre === updateImage ? pre : updateImage;
      });
  }, [previousInView, inView]);

  const karteItems = [
    '夏タイヤ',
    'スタッドレスタイヤ',
    'エンジンオイル',
    'オイルフィルター',
    'エアフィルター',
    'ワイパー',
    'バッテリー',
    'ATF',
    'エアコンフィルター',
  ];

  return (
    <>
      <Helmet>
        <title>{Title.KARTE_DESCRIPTION}</title>
      </Helmet>
      {/* 概要 */}
      {!isPc && (
        <h1 className="flex flex-col font-bold">
          <label className="text-xl">メンテナンス時期を見逃さない</label>
          <label
            className={classNames(
              isTablet ? 'text-lg mb-11 mt-2' : 'text-base mt-4'
            )}
          >
            車のカルテでメンテナンス情報がわかります。
          </label>
        </h1>
      )}
      <div className="flex flex-col sm:flex-row sm:gap-x-4 lg:gap-x-12">
        {!isSp && (
          <div className="flex-1">
            <Icons.Image
              src={Icons.aboutKarte1}
              alt="車のカルテでメンテナンス情報がわかります"
            />
          </div>
        )}
        <div className="flex flex-col" style={{ flex: isPc ? 2 : 1 }}>
          {isPc && (
            <h1 className="flex flex-col">
              <label className="text-xl font-bold">車のカルテで</label>
              <label className="text-xl mt-[-16px] font-bold">
                メンテナンス時期を見逃さない
              </label>
              <label className="text-lg mt-2 font-medium">
                車のカルテでメンテナンス情報がわかります。
              </label>
            </h1>
          )}
          <div className="mt-8 flex flex-col border border-line bg-white pb-7 pt-4 sm:mt-4 lg:mt-16 lg:w-[800px] lg:pb-7 lg:pt-6">
            <label
              className={classNames(
                'mb-6 text-center font-bold',
                isTablet ? 'text-xs' : 'text-base'
              )}
            >
              ▼ ご利用にはログインが必要です ▼
            </label>
            <div className="flex flex-col gap-x-16 gap-y-6 px-10 sm:px-14 lg:flex-row lg:px-16">
              <AprosButton
                text="ログイン/新規会員登録"
                comClassName="main"
                className="w-full lg:ml-[180px] lg:w-[310px]"
                onClick={() => {
                  setLoginGuidance(true);
                }}
              />
            </div>
          </div>
          {isSp && (
            <div className="flex flex-1 justify-center">
              <Icons.Image
                src={Icons.aboutKarte1}
                className="mt-6 w-[80%]"
                alt="車のカルテでメンテナンス情報がわかります"
              />
            </div>
          )}
          {isPc ? (
            <div className="mt-20 flex flex-row items-center">
              <Icons.Image
                src={Icons.appIcon}
                className="h-20 w-20"
                alt="オートバックスアプリからも確認できます。"
              />
              <div className="ml-4 flex flex-col">
                <label className="text-base font-bold">
                  オートバックスアプリからも確認できます。
                </label>
                <Linked
                  text="ダウンロードはこちら"
                  href={Path.DOWNLOAD_APP}
                  linkIcon="R"
                  className="text-base whitespace-nowrap font-bold"
                  isLine={true}
                />
              </div>
              <Icons.Image
                src={Icons.imageQR}
                alt="オートバックスアプリダウンロード"
                className="ml-8 h-[200px] w-[200px]"
              />
            </div>
          ) : (
            <div className="mt-7 flex flex-col items-center sm:items-start">
              <label className="text-sm font-medium">
                メンテナンス情報はアプリからも確認できます。
              </label>
              <div className="mt-4 flex flex-row items-center gap-x-8">
                {isTablet && (
                  <Icons.Image
                    src={Icons.imageQR}
                    alt="オートバックスアプリダウンロード"
                    className="h-[127px] w-[127px]"
                  />
                )}
                <Linked
                  text="ダウンロードはこちら"
                  href={
                    isIOS
                      ? Path.DOWNLOAD_APP_IOS
                      : isAndroid
                      ? Path.DOWNLOAD_APP_ANDROID
                      : Path.DOWNLOAD_APP
                  }
                  linkIcon="R"
                  className="text-sm h-fit whitespace-nowrap font-bold"
                  isLine={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* メンテナンスが必要な理由 */}
      <div
        className="flex justify-center bg-background-sub"
        style={{
          margin: isPc
            ? '192px calc(50% - 50vw) 0'
            : isTablet
            ? '146px calc(50% - 50vw) 0'
            : '80px calc(50% - 50vw) 0',
          width: '100vw',
        }}
      >
        <div className="relative mx-4 flex flex-col items-center self-center sm:mx-10 lg:mx-10 lg:max-w-[1472px]">
          <label className="text-xl mt-3 border-b-8 border-red font-bold sm:mt-4 lg:mt-6">
            メンテナンスが必要な理由
          </label>
          <Icons.Image
            src={
              isPc
                ? Icons.aboutKarte2
                : isTablet
                ? Icons.aboutKarte2Tb
                : Icons.aboutKarte2SP
            }
            alt="メンテナンスが必要な理由"
            className={isSp ? 'mt-6' : 'mt-20'}
          />
          <div className="mb-10 mt-10 border border-line bg-white px-4 py-4 sm:mb-12 sm:px-6 sm:py-8 lg:mb-16 lg:px-20 lg:py-10">
            <div className="flex flex-row">
              <Icons.Image
                src={Icons.check}
                className="text-base -mt-0.5 h-8 sm:mt-1"
              />
              <label className="text-base ml-2 font-bold sm:font-medium">
                メンテナンスを怠ると、車に関して危険なことが起こる可能性があります。
              </label>
            </div>
            <div className="mt-4 flex flex-row">
              <Icons.Image
                src={Icons.check}
                className="text-base -mt-0.5 h-8 sm:mt-1"
              />
              <label className="text-base ml-2 font-bold sm:font-medium">
                車を定期的にメンテナンスすることは、車を長く安心して乗ることにつながります。
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* 車のカルテのポイント */}
      <div className="flex flex-col">
        <motion.div
          className="mt-16  sm:mt-20 lg:mt-24"
          ref={previousViewRef}
        />
        <div className="flex flex-col items-center justify-center font-bold lg:flex-row lg:flex-wrap lg:items-end">
          <motion.label
            ref={ref}
            className={classNames(
              'whitespace-pre-wrap text-center sm:text-start',
              isSp ? 'text-xl' : 'text-lg'
            )}
          >
            メンテナンス情報が一目でわかる{isSp ? '\n' : ''}「車のカルテ」
          </motion.label>
          <div className="mt-2 flex flex-row items-end sm:mt-0 lg:ml-5">
            <label
              className={classNames(
                'text-red',
                isSp ? 'text-[36px] leading-tight' : 'text-[66px] leading-none'
              )}
            >
              2
            </label>
            <label className={classNames(isSp ? 'text-xl' : 'text-lg')}>
              つのポイント
            </label>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="mt-14" style={{ flex: 2 }}>
              <Icons.Image
                src={gifImage}
                alt="車のカルテ"
                className={classNames('mx-auto w-4/5 lg:max-h-192', {
                  'my-[-24px]': isTablet,
                })}
              />
            </div>
            <div
              className="mt-8 flex flex-col lg:mt-20"
              style={{ flex: isTablet ? 3 : 5 }}
            >
              <div className="flex flex-col gap-y-2 sm:gap-y-4">
                <div className="text-lg h-fit w-fit bg-navy px-3 py-2 font-bold text-white sm:py-1 lg:py-0">
                  POINT1
                </div>
                <label className="text-base text-red">
                  愛車のメンテナンスを手間なく管理
                </label>
                <label className="text-sm font-medium text-navy">
                  オートバックスでのメンテナンス履歴や点検結果を自動で反映
                  。手で入力する手間はありません。
                </label>
                <label className="text-sm font-medium text-navy">
                  次回の点検・交換時期もすぐにわかります。
                </label>
              </div>
              <div className="mt-10 flex flex-col gap-y-2 sm:mt-14 sm:gap-y-4 lg:mt-28">
                <div className="text-lg h-fit w-fit bg-navy px-3 py-2 font-bold text-white sm:py-1 lg:py-0">
                  POINT2
                </div>
                <label className="text-base text-red">お買い物にも便利</label>
                <label className="text-sm font-medium text-navy">
                  オートバックスでの購入履歴も自動で反映。
                </label>
                <label className="text-sm font-medium text-navy">
                  さらに車検証を登録すれば、適合商品がすぐにわかりお買い物も楽に。お店で迷うことはありません。
                </label>
              </div>
            </div>
          </div>
          <div className="mt-8 flex w-full flex-col items-center border border-line bg-white px-2 py-5 sm:mt-8 sm:px-10 sm:py-8 lg:mt-14 lg:py-10">
            <label className="text-lg mb-6 font-bold sm:mb-14">
              車のカルテで確認できる主要9項目
            </label>
            <div className="flex flex-wrap gap-x-1.5 gap-y-4 sm:justify-center sm:gap-x-8 sm:gap-y-8 lg:gap-x-16">
              {karteItems.map((item, i) => (
                <ul
                  key={item}
                  className={classNames(
                    'flex flex-row items-center',
                    isSp
                      ? i === item.length - 1
                        ? 'w-[200px]'
                        : `${i % 2 === 0 ? 'w-[150px]' : ''}`
                      : ''
                  )}
                >
                  <li className="flex flex-row items-center">
                    <Icons.Image
                      src={KARTE_ICONS[item]}
                      className="h-[35px] w-[35px]"
                      alt={item}
                    />
                    <label
                      className={classNames(
                        'ml-1 whitespace-nowrap font-bold sm:ml-4',
                        isSp ? 'text-sm' : 'text-base'
                      )}
                    >
                      {item}
                    </label>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* 車のカルテで最適なメンテナンス */}
      <div
        className="flex justify-center bg-background-sub"
        style={{
          margin: isPc ? '196px calc(50% - 50vw) 0' : '56px calc(50% - 50vw) 0',
          width: '100vw',
        }}
      >
        <div className="relative mx-4 flex flex-col items-center self-center sm:mx-10 lg:mx-auto lg:px-10">
          <label className="text-lg mt-3 whitespace-nowrap border-b-8 border-red font-bold sm:top-[-40px] sm:mt-4 lg:mt-6">
            車のカルテで最適なメンテナンス
          </label>
          <div className="mb-4 mt-[70px] flex w-full flex-col gap-y-16 sm:mb-12 sm:mt-[70px] sm:gap-x-10 sm:gap-y-14 lg:mx-10 lg:mb-0 lg:mt-[132px] lg:flex-row lg:justify-between">
            <div className="flex flex-1 flex-row-reverse sm:items-center lg:max-w-[375px] lg:flex-col lg:items-start">
              <div className="flex flex-col" style={{ flex: isTablet ? 3 : 1 }}>
                <label
                  className={classNames(
                    'font-bold',
                    isTablet ? 'text-[20px]' : 'text-lg'
                  )}
                >
                  車のカルテ一覧
                </label>
                <label
                  className={classNames(
                    'text-sm mt-2 whitespace-pre-wrap font-medium lg:mt-4'
                  )}
                >
                  主要7カテゴリに関して前回交換日、
                  次回交換推奨月が一覧で確認できます。
                </label>
              </div>
              <div style={{ flex: 1 }} className="mr-4 sm:mx-8 lg:mx-0 lg:mt-6">
                <Icons.Image src={Icons.sp1} alt="車のカルテ一覧" />
              </div>
            </div>
            <div className="flex flex-1 flex-row-reverse sm:mt-0 sm:flex-row sm:items-center lg:max-w-[375px] lg:flex-col lg:items-start">
              {isTablet && <div style={{ flex: 1 }}></div>}
              <div className="flex flex-col" style={{ flex: isTablet ? 2 : 1 }}>
                <label
                  className={classNames(
                    'font-bold',
                    isTablet ? 'text-[20px]' : 'text-lg'
                  )}
                >
                  車のカルテ詳細
                </label>
                <label
                  className={classNames(
                    'text-sm mt-2 whitespace-pre-wrap font-medium lg:mt-4'
                  )}
                >
                  各カテゴリ毎に前回交換時の走行距離、 購入商品が確認できます。
                </label>
              </div>
              <div style={{ flex: 1 }} className="mr-4 sm:mx-8 lg:mx-0 lg:mt-6">
                <Icons.Image src={Icons.sp2} alt="車のカルテ詳細" />
              </div>
            </div>
            <div className="flex flex-1 flex-row-reverse sm:mt-0 sm:items-center lg:max-w-[375px] lg:flex-col lg:items-start">
              <div className="flex flex-col" style={{ flex: isTablet ? 3 : 1 }}>
                <label
                  className={classNames(
                    'font-bold',
                    isTablet ? 'text-[20px]' : 'text-lg'
                  )}
                >
                  点検結果
                </label>
                <label
                  className={classNames(
                    'text-sm mt-2 whitespace-pre-wrap font-medium lg:mt-4'
                  )}
                >
                  ピット入庫・点検を受けられたユーザーは当日に点検結果を確認できます。
                </label>
              </div>
              <div style={{ flex: 1 }} className="mr-4 sm:mx-8 lg:mx-0 lg:mt-6">
                <Icons.Image src={Icons.sp3} alt="点検結果" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* かかりつけ整備士を持とう。 */}
      <div className="mt-16 lg:mt-32">
        <div className="flex flex-row items-center justify-between">
          <label className="text-xl font-bold">
            かかりつけ整備士を持とう。
          </label>
          {isTablet && (
            <div className="mx-6 flex flex-row">
              <Icons.Image
                src={Icons.charactorMan}
                alt="かかりつけ整備士を持とう。"
                className="aspect-auto h-48"
              />
              <Icons.Image
                src={Icons.charactorWoman}
                alt="かかりつけ整備士を持とう。"
                className="aspect-auto h-48"
              />
            </div>
          )}
        </div>
        <div className="mt-8 flex w-full flex-row items-end gap-x-6 lg:mt-16">
          {isPc && (
            <Icons.Image
              src={Icons.charactorMan}
              className="aspect-auto h-96"
              alt="かかりつけ整備士を持とう。"
            />
          )}
          <div className="aspect-video h-full w-full">
            <iframe
              style={{ width: '100%', height: '100%' }}
              src="https://www.youtube.com/embed/_vGGHR46BUY"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
          {isPc && (
            <Icons.Image
              src={Icons.charactorWoman}
              alt="かかりつけ整備士を持とう。"
              className="aspect-auto h-96"
            />
          )}
        </div>
      </div>

      {/* ピットサービス予約のメリット */}
      <div
        className="flex justify-center bg-background-sub"
        style={{
          margin: isPc
            ? '185px calc(50% - 50vw) 0'
            : '106px calc(50% - 50vw) 0',
          width: '100vw',
        }}
      >
        <div className="relative mx-4 flex w-full flex-col items-center self-center sm:mx-10 lg:mx-10 lg:max-w-[1472px]">
          <label className="text-lg mt-3 whitespace-nowrap border-b-8 border-red font-bold sm:mt-4 lg:mt-6">
            ピットサービス予約のメリット
          </label>
          <div className="mt-16 flex w-full flex-col sm:mt-32 lg:flex-row lg:justify-evenly">
            <div className="flex flex-col items-center">
              <Icons.Image
                src={Icons.machijikan}
                alt="事前予約で待ち時間を短縮"
                className={'max-w-[323px] object-cover sm:max-w-[460px]'}
              />
              <label className="text-lg mt-2 whitespace-nowrap font-bold sm:mt-4 lg:mt-10">
                事前予約で待ち時間を短縮
              </label>
              <div className="flex flex-col">
                <div className="mt-4 flex flex-row sm:mt-6 lg:mt-[30px]">
                  <label className="text-sm text-red">●</label>
                  <label className="text-sm ml-2 font-medium text-navy">
                    お店で長時間お待たせしません。
                  </label>
                </div>
                <div className="mt-4 flex flex-row">
                  <label className="text-sm text-red">●</label>
                  <label className="text-sm ml-2 font-medium text-navy">
                    空いた時間を効率的に使えます。
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-10 flex flex-col items-center lg:mt-0">
              <Icons.Image
                src={Icons.kantan}
                alt="カンタン操作ですぐに予約できる"
                className={'max-w-[323px] object-cover sm:max-w-[460px]'}
              />
              <label className="text-lg mt-2 whitespace-nowrap font-bold sm:mt-4 lg:mt-10">
                カンタン操作ですぐに予約できる
              </label>
              <div className="flex flex-col">
                <div className="mt-4 flex flex-row sm:mt-6 lg:mt-[30px]">
                  <label className="text-sm text-red">●</label>
                  <label className="text-sm ml-2 font-medium text-navy">
                    パソコンやスマホから、いつでも
                    {isSp || isTablet ? <br /> : ''}
                    どこでも予約可能。
                  </label>
                </div>
                <div
                  className={classNames('mt-4 flex flex-row', {
                    'justify-center': isPc,
                  })}
                >
                  <label className="text-sm text-red">●</label>
                  <label className="text-sm ml-2 font-medium text-navy">
                    予約の変更・キャンセルにも対応。
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[40px] sm:mt-[50px] lg:mt-[80px]">
            <label className="text-sm ml-2 font-medium text-navy">
              ログインまたは新規登録後にご予約いただけます。
            </label>
          </div>
          <AprosButton
            text="WEBで予約する"
            comClassName="reserve"
            className="mb-24 mt-5 sm:mb-32 sm:mt-[30px] lg:mb-40 lg:mt-[40px]"
            onClick={() => navigate(Path.RESERVATION_SERVICE_SELECT)}
          />
          <LoginGuidanceModal
            isModalOpen={showLoginGuidance}
            closeModal={() => setLoginGuidance(false)}
            onLoginClick={() =>
              sapLogin(process.env.REACT_APP_BASE_URL + Path.KARTE, isMobila)
            }
            onCardNumberClick={() =>
              sapCardNumber(
                process.env.REACT_APP_BASE_URL + Path.KARTE,
                isMobila
              )
            }
            onRegisterClick={() =>
              sapRegister(process.env.REACT_APP_BASE_URL + Path.KARTE, isMobila)
            }
          />
        </div>
      </div>
    </>
  );
};

export default KarteDescription;
