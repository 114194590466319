import Linked from 'components/linked/index';
import Path from 'constants/routePath';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { useNavigate } from 'react-router-dom';

const Copyright = () => {
  const navigate = useNavigate();

  const { isSp } = useDeviceSize();

  const footerLink = [
    { nm: 'プライバシーポリシー', link: Path.PRIVACY_POLICY },
    { nm: 'サイト利用規約', link: Path.TERMS },
    { nm: '企業情報', link: Path.CORPORATION_INFO },
    { nm: 'FAQ/よくあるご質問', onClick: () => navigate(Path.FAQ) },
  ];
  return (
    <footer className="w-full">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-6 bg-background-sub p-4 sm:justify-center lg:gap-x-8 lg:p-0 lg:py-6">
        {isSp ? (
          <>
            <div className="flex items-center justify-center gap-x-4 sm:gap-x-6 lg:gap-x-8">
              <Linked
                text={'プライバシーポリシー'}
                href={Path.PRIVACY_POLICY}
                className="text-[14px] font-medium sm:text-[16px] lg:text-[18px]"
              />
              <div className="h-[20px] w-[1px] bg-navy"></div>
              <Linked
                text={'サイト利用規約'}
                href={Path.TERMS}
                className="text-[14px] font-medium sm:text-[16px] lg:text-[18px]"
              />
            </div>

            <div className="flex items-center justify-center gap-x-4 sm:gap-x-6 lg:gap-x-8">
              <Linked
                text={'企業情報'}
                href={Path.CORPORATION_INFO}
                className="text-[14px] font-medium sm:text-[16px] lg:text-[18px]"
              />
              <div className="h-[20px] w-[1px] bg-navy"></div>
              <Linked
                text={'FAQ/よくあるご質問'}
                onClick={() => navigate(Path.FAQ)}
                className="text-[14px] font-medium sm:text-[16px] lg:text-[18px]"
              />
            </div>
          </>
        ) : (
          <ul className="flex items-center justify-center gap-x-4 sm:gap-x-6 lg:gap-x-8">
            {footerLink.map((item, i) => (
              <li
                key={i}
                className="flex items-center justify-center gap-x-4 sm:gap-x-6 lg:gap-x-8"
              >
                <Linked
                  text={item.nm}
                  href={item.link}
                  onClick={item.onClick}
                  className="text-[14px] font-medium sm:text-[16px] lg:text-[18px]"
                />
                {i !== footerLink.length - 1 && (
                  <div className="h-[20px] w-[1px] bg-navy"></div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      <p className="flex h-6 items-center justify-center bg-navy text-[10px] text-line sm:h-[55px] sm:text-[14px] lg:h-8 lg:text-[16px]">
        {'Copyright (C) AUTOBACS SEVEN CO.,LTD. All Rights Reserved.'}
      </p>
    </footer>
  );
};

export default Copyright;
