import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserMailAddress } from 'slices/accountSlice';
import Yup from 'utils/yupUtil';
import Linked from 'components/linked/index';
import { openTirePage } from 'utils/tireRequestUtil';
import dayjs from 'dayjs';
import Stepper from 'components/stepper';
import Proctitle from 'components/proctitle';
import AvailabilityCalendar from 'components/availabilityCalendar';
import DateInput from 'components/inputDate';
import NotesModal from 'components/messageModals/notesModal';
import ModalCalendar from 'pages/topPage/modals/calendarModal';
import UserNameLabel from './components/userNameLabel';
import CalendarDescriptionLabel from './components/calendarDescriptionLabel';
import ShopSelectBottomItem from './components/shopSelectBottomItem';
import WorkDescriptionModal from './modals/workDescriptionModal';
import Icons from 'images/icons';
import Path, { ERROR_MESSAGE_TRANSITION } from 'constants/routePath';
import {
  DATE_SELECT_TRANSITION_TYPE,
  SERVICE_CODE_OIL,
} from 'constants/service';
import { registerVehicles } from 'slices/accountSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import { actions as loginActions } from 'slices/loginSlice';
import {
  fetchDateSelectInit,
  fetchShopAvailability,
  actions,
  waitTimeReserve,
  fetchUserWaitTime,
  updateUserWaitTime,
  fetchShopServiceExternal,
} from 'slices/reservationDateSelectSlice';
import { displayCautionActions } from 'slices/displayCautionSlice';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { useConfirmHistoryBack } from 'hooks/useConfirmHistoryBack';
import { getTermKbn } from 'utils/otherUtil';
import { isEmpty } from 'utils/stringUtil';
import { UPDATE_STEP, CREATE_STEP } from 'constants/reservation';
import VehicleRegisterModal from './modals/vehicleRegisterModal';
import DateSelectOneClickArea from './components/dateSelectOneClickArea';
import RankModal from './modals/rankModal';
import ServiceNameLabel from './components/serviceNameLabel';
import MailAddressSettingModal from './modals/mailAddressSettingModal';
import ApproximateTimeUpdateModal from './modals/approximateTimeUpdateModal';
import { SERVICE_CODE_TIRE } from 'constants/service';
import {
  DATE_SELECT_CALENDAR_CIRCLE,
  DATE_SELECT_CALENDAR_SQUARE,
  DATE_SELECT_CHANGE_DATE,
} from '../../constants/gtmCustomEvent';
import { RankDescription } from 'constants/rank';

const DateSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isSp } = useDeviceSize();

  // 編集したらブラウザバックで警告を表示する
  const [isEdited, setIsEdited] = useState(false);
  useConfirmHistoryBack({ isEdited, setIsEdited });

  const mailFormik = useFormik({
    initialValues: {
      mail: '',
      mailMagazine: '1',
    },
    validateOnMount: true,
    validationSchema: Yup.object({ mail: Yup.string().required().mail() }),
  });

  const {
    vehicles,
    accountInfo: {
      cardNumber,
      point,
      rankCode,
      userId,
      mailAddressMobile,
      mailAddressPc,
    },
  } = useSelector(state => state.account);

  const {
    selectedSrySeq,
    carList,
    shopInfo,
    menuComment,
    requiredTime,
    dailyInfo,
    waitTimeInfo,
    supportChargeFlag,
    selectService,
    shopStatus,
    showDescriptionModal,
    showCalendarModal,
    showVehicleRegisterModal,
    showRankModal,
    showMailAddressSettingModal,
    showApproximateTimeUpdateModal,
    shopAvailabilityLoading,
    isTireContract,
    externalSelectSerivce,
    ...rest
  } = useSelector(state => state.reservationDateSelect);

  const {
    stepLabels,
    baseScreen,
    previousStep,
    serviceSelectScreen,
    queryParams,
    baseChubunCd,
  } = location.state || {};

  const showTireArea =
    selectService?.aprosCode === SERVICE_CODE_TIRE &&
    ((selectService?.aprosSubCategories[0]?.codeCombinations[0]?.chubunCd ===
      '009' &&
      selectService?.aprosSubCategories[0]?.codeCombinations[0]?.shobunCd ===
        '101') ||
      (selectService?.aprosSubCategories[0]?.codeCombinations[0]?.chubunCd ===
        '009' &&
        selectService?.aprosSubCategories[0]?.codeCombinations[0]?.shobunCd ===
          '102') ||
      (selectService?.aprosSubCategories[0]?.codeCombinations[0]?.chubunCd ===
        '009' &&
        selectService?.aprosSubCategories[0]?.codeCombinations[0]?.shobunCd ===
          '104') ||
      (selectService?.aprosSubCategories[0]?.codeCombinations[0]?.chubunCd ===
        '024' &&
        selectService?.aprosSubCategories[0]?.codeCombinations[0]?.shobunCd ===
          '101'));

  // 遷移パターン判断
  const transitionPattern = (() => {
    // クエリパラメータがある場合、外部遷移か判定する
    if (queryParams && Object.keys(queryParams).length > 0) {
      const { p1, p2, p10, p11, sid } = queryParams;
      if (p1 && p1 !== 'MOBILA') {
        // 店舗在庫引当
        const p3Keys = Object.keys(queryParams).filter(k => k.startsWith('p3'));
        if (p3Keys.length === 0) {
          // パラメータ不正
          return null;
        }
        for (const p3Key of p3Keys) {
          const [, key] = p3Key.split('-');
          const p3 = queryParams[p3Key];
          const p4 = queryParams[`p4-${key}`];
          const p5 = queryParams[`p5-${key}`];
          if (!p3 || !p4 || !p5) {
            // パラメータ不正
            return null;
          }
        }
        // 店舗在庫引当の場合
        return DATE_SELECT_TRANSITION_TYPE.SHOP_STOCK_MORTGAGE;
      } else if (p2 && p10 && p11) {
        // 外部遷移
        return DATE_SELECT_TRANSITION_TYPE.SHOP_INFO_WORK;
      } else if (sid) {
        // 店舗・法人・MK戦略
        return DATE_SELECT_TRANSITION_TYPE.SHOP_INFO_SID;
      } else {
        // パラメータ不正
        return null;
      }
    }

    // 外部遷移ではなかった場合、内部遷移
    if (Path.RESERVATION_SHOP_SELECT === baseScreen) {
      return DATE_SELECT_TRANSITION_TYPE.SHOP_SELECT;
    } else if (Path.RESERVATION_SERVICE_SELECT === baseScreen) {
      return DATE_SELECT_TRANSITION_TYPE.SERVICE_SELECT;
    } else if (Path.RESERVATION_LIST === baseScreen) {
      return DATE_SELECT_TRANSITION_TYPE.MODIFY;
    }

    // パラメータ不正
    return null;
  })();

  // 外部遷移か
  const isExternal = [
    DATE_SELECT_TRANSITION_TYPE.SHOP_STOCK_MORTGAGE,
    DATE_SELECT_TRANSITION_TYPE.SHOP_INFO_WORK,
    DATE_SELECT_TRANSITION_TYPE.SHOP_INFO_SID,
  ].includes(transitionPattern);

  // 店舗在庫引当か
  const isStock =
    transitionPattern === DATE_SELECT_TRANSITION_TYPE.SHOP_STOCK_MORTGAGE;

  const createTenpBiko = () => {
    if (!isStock) {
      return;
    }

    const stockList = [];
    const p3Keys = Object.keys(queryParams).filter(k => k.startsWith('p3'));
    for (const p3Key of p3Keys) {
      const [, key] = p3Key.split('-');
      const p3 = queryParams[p3Key];
      const p4 = queryParams[`p4-${key}`];
      const p5 = queryParams[`p5-${key}`];
      const p13 = queryParams[`p13-${key}`];
      stockList.push({ p3, p4, p5, p13 });
    }

    if (stockList.length === 0) return null;
    const createText = (text, prefix) => `${prefix}：${text || ''}`;
    return [
      createText(queryParams.p1, 'ネット通販注文番号'),
      ...stockList.flatMap(s => [
        createText(s.p3, '商品CD'),
        createText(s.p4, '商品名'),
        createText(s.p5, '数量'),
        createText(s.p13, '在庫取置担当者'),
      ]),
    ].join('\n');
  };

  const yykInfo = (() => {
    if (!transitionPattern) {
      // パラメータ不正
      return null;
    } else if (isExternal) {
      // 外部遷移の場合はqueryパラメータから生成
      const hasSID =
        transitionPattern === DATE_SELECT_TRANSITION_TYPE.SHOP_INFO_SID;

      const sidParams = (() => {
        if (!hasSID) return null;
        const sid = queryParams.sid;
        const [p2, other] = sid?.split('-');
        if (other?.length !== 8) return null;
        const p10 = other?.substr(2, 3);
        const p11 = other?.substr(5, 3);
        return (p2 && p10 && p11 && { p2, p10, p11 }) || null;
      })();
      if (hasSID && !sidParams) {
        return null;
      }

      const { p1, p2, p8, p10, p11 } = hasSID ? sidParams : queryParams;

      return {
        tokCd: p2,
        work: [
          {
            sgyYmd: dayjs().format('YYYYMMDD'),
            sgyStaHm: null,
            chubunCd: p10,
            shobun: [{ chubunCd: p10, shobunCd: p11 }],
          },
        ],
        yykKbn: isStock ? 'N' : null,
        ecOrderNo: isStock ? `C4_${p1}` : null,
        tenpBiko: isStock ? createTenpBiko() : null,
        srySeq: isStock && !isEmpty(p8) ? Number(p8) : null,
      };
    } else {
      // 内部遷移の場合はstateから取り出す
      return location.state.yykInfo;
    }
  })();

  const { work = [{}], tokCd, srySeq } = yykInfo || {};

  const [{ sgyYmd, sgyStaHm, shobun = [], chubunCd }] = work || {};

  // 登録or更新
  const isModify = transitionPattern === DATE_SELECT_TRANSITION_TYPE.MODIFY;

  // オイル交換の場合
  const isOilWork = SERVICE_CODE_OIL === selectService?.aprosCode;

  const [date, setDate] = useState(dayjs(sgyYmd).format('YYYY/M/D'));

  // 予約済み作業の日付が当日かどうか
  const todayReservation = dayjs(sgyYmd).isSame(dayjs(), 'day');

  const dateString = dayjs(date).format('YYYYMMDD');

  // 当日がカレンダーに含まれるか
  const isToday =
    dailyInfo && dailyInfo.find(d => d.date === dayjs().format('YYYYMMDD'));

  // 車両が未登録か確認し未登録の場合、登録モーダルを表示する
  const checkVehiclesRegister = () => {
    if (vehicles.filter(v => v.modelName).length === 0) {
      // 未登録車両しかない
      dispatch(actions.showVehicleRegisterModal());
    }
  };

  useEffect(() => {
    if (isStock) {
      const queryParamsKeys = Object.keys(queryParams);

      !queryParamsKeys.includes('p8') &&
        !queryParamsKeys.includes('p9') &&
        !queryParamsKeys.includes('13') &&
        navToTop();
    }
  }, [isStock, queryParams]);

  const navToTop = () => {
    const message = ERROR_MESSAGE_TRANSITION;
    dispatch(messageModalActions.showMessageModal({ message }));
    dispatch(loginActions.closeAbidDifferenceModal());
    navigate(Path.TOP);
  };

  useEffect(() => {
    const checkServiceSurpport = async () => {
      const externalServiceList = await dispatch(
        fetchShopServiceExternal(tokCd)
      ).unwrap();

      if (
        !shobun.every(sho =>
          externalServiceList.some(
            s =>
              sho.chubunCd === s.chubunCd &&
              (!s.shobunCd || sho.shobunCd === s.shobunCd)
          )
        )
      ) {
        navToTop();
      } else {
        dispatch(
          actions.updateExternalSelectService(
            shobun.map(sho =>
              externalServiceList.find(
                s =>
                  sho.chubunCd === s.chubunCd &&
                  (!s.shobunCd || sho.shobunCd === s.shobunCd)
              )
            )
          )
        );
      }
    };

    isExternal && checkServiceSurpport();
  }, [isExternal, tokCd]);

  useEffect(() => {
    // 遷移パラメータが不正な場合、エラーを出してTOP画面に遷移
    if (!tokCd || !chubunCd || shobun.length === 0) {
      navToTop();
      return;
    }

    // 外部遷移の場合、注意事項モーダル表示
    if (isExternal) {
      dispatch(displayCautionActions.check());
    }
    dispatch(
      fetchDateSelectInit({
        userId: userId,
        shopCode: tokCd,
        chubunCd: chubunCd,
        shobunCd: shobun,
      })
    );
    return () => {
      dispatch(actions.clear());
    };
  }, [dispatch]);

  useEffect(() => {
    // 遷移パラメータが不正な場合、API呼ばない
    if (!tokCd || !chubunCd || shobun.length === 0) {
      return;
    }

    dispatch(
      fetchShopAvailability({
        date: dateString,
        shopCode: tokCd,
        chubunCd,
        shobunCd: shobun.map(sho => sho.shobunCd),
        userRank: rankCode,
        enableRank: isModify && todayReservation ? '0' : '1',
      })
    );
  }, [dispatch, dateString]);

  useEffect(() => {
    if (vehicles.length > 0) {
      if (!selectedSrySeq) {
        // 初期車両設定
        dispatch(actions.setSrySeq(srySeq || vehicles[0].carSeq));
      }
    }
  }, [dispatch, vehicles]);

  useEffect(() => {
    // 店舗ステータス取得前
    if (shopStatus === null) return;
    // 車両取得前
    if (vehicles.length === 0) return;

    if (isExternal) {
      if (shopStatus === '0') {
        // 店舗予約可能な場合、車両登録有無を確認
        checkVehiclesRegister();
      } else {
        // 店舗が予約不可能な場合、パラメータエラー
        const message = '予約不可能な店舗です。';
        dispatch(messageModalActions.showMessageModal({ message }));
        navigate(Path.TOP);
      }
    }
  }, [shopStatus, vehicles]);

  const waitTimeSgyStaHm =
    waitTimeInfo &&
    dayjs(waitTimeInfo.getTime, 'HH:mm')
      .add(waitTimeInfo.confirmMenuWaitMinutes, 'minutes')
      .format('HH:mm');

  // 通常遷移の場合のステップバー下部表示
  const bottomElement = () => (
    <div className="text-sm border border-line bg-background-sub px-2 py-3 text-left sm:px-10">
      <div className="flex flex-col gap-y-2">
        {isModify && (
          <label className="font-medium">{`${dayjs(sgyYmd).format(
            'YYYY年M月D日(ddd)'
          )} ${dayjs(sgyStaHm, 'HHmm').format('HH:mm')}`}</label>
        )}
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:items-center">
          {!isModify && (
            <label className="font-medium">選択中のサービス：</label>
          )}
          <ServiceNameLabel
            selectService={selectService}
            externalSelectSerivce={externalSelectSerivce}
            className={{ labelClass: 'text-sm font-bold' }}
          />
        </div>
        {!isModify && !isStock && (
          <label className="font-medium">
            作業時間は目安のため、店舗・車種により異なります。詳しくは店舗にご確認ください。
          </label>
        )}
        <label className="font-medium">作業目安時間：{requiredTime}分～</label>
      </div>
    </div>
  );

  // 外部遷移の場合のステップバー下部表示
  const externalTransitionBottomElement = () => (
    <ShopSelectBottomItem
      showSry={true}
      showService={true}
      vehicles={vehicles}
      selectService={selectService}
      selectedSrySeq={selectedSrySeq}
      canServiceSelect={false}
      requiredTime={requiredTime}
      onChangeSrySeq={v => {
        setIsEdited(true);
        dispatch(actions.setSrySeq(v));
      }}
      onClickNewSry={() => {
        setIsEdited(true);
        dispatch(actions.showVehicleRegisterModal());
      }}
    />
  );

  const userWaitTimeReserve = async () => {
    const workTimeRes = await dispatch(
      fetchUserWaitTime({
        shopCode: tokCd,
        chubunCd,
        shobunCd: shobun[0]?.shobunCd,
      })
    ).unwrap();

    const { getTime, menus, frontStatus } = workTimeRes.body;

    const {
      menuCd,
      confirmMenuWaitMinutes,
      shobun: menusShobun,
    } = menus?.[0] ?? {};

    if (confirmMenuWaitMinutes !== waitTimeInfo.confirmMenuWaitMinutes) {
      dispatch(
        actions.updateWaitTime({
          confirmMenuWaitMinutes,
          getTime,
          menuCd,
          chubunCd,
          shobunCd: menusShobun[0]?.shobunCd,
          sgyTime: menusShobun[0]?.sgyTime,
          frontStatus,
        })
      );

      dispatch(actions.showApproximateTimeUpdateModal());
    } else {
      const yykRes = await dispatch(
        waitTimeReserve({
          termKbn: getTermKbn(),
          kaiinNo: userId,
          kaiinCardNo: cardNumber,
          srySeq: selectedSrySeq,
          shopCode: tokCd,
          chubunCd: waitTimeInfo.chubunCd,
          shobunCd: waitTimeInfo.shobunCd,
          menuCd: waitTimeInfo.menuCd,
          sgyTime: waitTimeInfo.sgyTime,
        })
      ).unwrap();

      const { yykNo, sgyStaHm } = yykRes.body;

      navigate(Path.RESERVATION_COMPLETE, {
        state: {
          baseScreen: baseScreen || Path.RESERVATION_DATE_SELECT,
          yykInfo: {
            ...yykInfo,
            yykNo,
            work: yykInfo.work.map(w => ({
              ...w,
              sgyYmd: dayjs().format('YYYYMMDD'),
              sgyStaHm,
            })),
          },
          selectService,
          externalSelectSerivce,
          isWaitingTimeReservation: true,
        },
      });
    }
  };

  return (
    <>
      <Stepper
        labels={stepLabels || (isModify ? UPDATE_STEP : CREATE_STEP)}
        previousStep={
          previousStep ? previousStep : isModify || isExternal ? -1 : 0
        }
        activeStep={isModify || isExternal ? 0 : 1}
        bottomElement={
          isExternal && (!isStock || isEmpty(queryParams.p8))
            ? externalTransitionBottomElement()
            : bottomElement()
        }
        className={{
          stepperClass: 'mb-10 max-sm:mx-12 sm:mx-36',
          bottomClass: 'mx-0 h-full text-left lg:w-[800px]',
        }}
        onClick={index => {
          if (index === 0) {
            if (baseScreen === Path.RESERVATION_SHOP_SELECT) {
              navigate(Path.RESERVATION_SHOP_SELECT, {
                state: {
                  yykInfo,
                  requiredTime,
                  previousStep: 1,
                  serviceSelectScreen,
                },
              });
            } else if (baseScreen === Path.RESERVATION_SERVICE_SELECT) {
              navigate(Path.RESERVATION_SERVICE_SELECT, {
                state: {
                  yykInfo,
                  requiredTime,
                  previousStep: 1,
                  serviceSelectScreen,
                  baseChubunCd,
                },
              });
            }
          }
        }}
      />

      {!menuComment && shopAvailabilityLoading ? (
        <div className="mt-10 flex h-full items-center justify-center">
          <Icons.Image src={Icons.loading} className="h-[100px] w-[100px]" />
        </div>
      ) : (
        menuComment && (
          <>
            <div className="mt-10 flex h-12 flex-row items-center bg-navy/10 sm:mt-12">
              <Icons.Image
                src={Icons.alert}
                className="ml-4 h-7 w-7 sm:ml-6 lg:ml-8"
              />
              <label className="text-sm ml-2 font-bold text-navy">
                {shopInfo?.shopName}からの注意事項
              </label>
            </div>
            <div
              className="text-sm mx-0 mt-4 whitespace-pre-wrap font-medium sm:mx-6 sm:mt-6 lg:mx-8"
              dangerouslySetInnerHTML={{
                __html: menuComment.replaceAll('\\r\\n', '<br/>'),
              }}
            />
          </>
        )
      )}
      <div className="mt-14 flex flex-col sm:mt-16 lg:mt-20">
        <Proctitle
          useH1={true}
          text={isModify ? '日時変更' : '日時選択'}
          className={{ textClass: 'whitespace-nowrap' }}
        />
        {(transitionPattern === DATE_SELECT_TRANSITION_TYPE.SERVICE_SELECT ||
          (isExternal && !isStock)) &&
          [0, 2].includes(waitTimeInfo?.frontStatus) && (
            <DateSelectOneClickArea
              aprosCode={selectService?.aprosCode}
              waitTimeInfo={waitTimeInfo}
              cardNumber={cardNumber}
              isTireContract={isTireContract}
              disabled={!isToday || 2 === waitTimeInfo?.frontStatus}
              frontStatus={waitTimeInfo?.frontStatus}
              onClick={async () => {
                if (!mailAddressPc && !mailAddressMobile) {
                  // メールアドレスが登録されていない場合
                  dispatch(actions.showMailAddressSettingModal());
                  return;
                }
                userWaitTimeReserve();
              }}
            />
          )}
        <DateInput
          title="日付を選択してください"
          className={{
            boxClass: 'mt-8 p-0 lg:mt-10',
            inputClass: 'ml-0 w-48 sm:ml-6',
          }}
          value={date}
          onClick={() => dispatch(actions.showCalendarModal())}
        />
        <UserNameLabel className="mt-8 lg:mt-10" />
        {RankDescription(rankCode)}
        <CalendarDescriptionLabel className="mt-5 sm:mt-7 lg:mt-9" />
      </div>
      <div
        style={{
          margin: isSp ? '0 calc(50% - 50vw) 0' : '',
          width: isSp ? '100vw' : '',
        }}
      >
        {shopAvailabilityLoading ? (
          <div className="mt-10 flex h-full items-center justify-center">
            <Icons.Image src={Icons.loading} className="h-[100px] w-[100px]" />
          </div>
        ) : (
          <AvailabilityCalendar
            dailyInfoList={dailyInfo}
            rank={rankCode}
            isModifyTodayReservation={isModify && todayReservation}
            className="mt-4"
            onClickLeft={newDate => {
              setIsEdited(true);
              setDate(newDate);
            }}
            onClickRight={newDate => {
              setIsEdited(true);
              setDate(newDate);
            }}
            onClickCircle={hmInfo => {
              const sry = vehicles?.find(
                v => `${selectedSrySeq}` === `${v?.carSeq}`
              );
              if (!sry?.modelName && !isModify && !isExternal) {
                // 未登録車両かつ予約新規登録の場合、登録できない（予約変更では未登録車両でも許可）
                dispatch(
                  messageModalActions.showMessageModal({
                    message:
                      '未登録車両での予約はできません。\n新規車両を追加するか、車両を変更してください。',
                  })
                );
                return;
              }

              // GTMカスタムイベントパラメータ
              const dataLayer = (window.dataLayer = window.dataLayer || []);
              dataLayer.push({
                event: DATE_SELECT_CALENDAR_CIRCLE,
              });

              // 編集を反映した後遷移する
              setIsEdited(true);
              setTimeout(() => {
                navigate(Path.RESERVATION_CONFIRM, {
                  state: {
                    ...rest,
                    yykInfo: {
                      ...yykInfo,
                      tokNmRyk: shopInfo?.shopName,
                      sgyYmd: hmInfo.date,
                      sgyStaHm: dayjs(hmInfo.hm, 'HH:mm').format('HHmm'),
                      srySeq: selectedSrySeq,
                      sryMkrMei: sry?.makerName,
                      sshMei: sry?.modelName,
                    },
                    requiredTime,
                    availity: hmInfo,
                    stepLabels:
                      stepLabels || (isModify ? UPDATE_STEP : CREATE_STEP),
                    baseScreen: baseScreen || Path.RESERVATION_DATE_SELECT,
                    previousStep: isModify || isExternal ? 0 : 1,
                    serviceSelectScreen,
                    externalSelectSerivce,
                    selectService,
                    queryParams,
                    isTireContract,
                  },
                });
              }, 0);
            }}
            onClickSquare={() => {
              // GTMカスタムイベントパラメータ
              const dataLayer = (window.dataLayer = window.dataLayer || []);
              dataLayer.push({
                event: DATE_SELECT_CALENDAR_SQUARE,
              });

              dispatch(actions.showDescriptionModal());
            }}
            onClickLock={() => dispatch(actions.showRankModal())}
          />
        )}
      </div>
      <RankModal
        rank={rankCode}
        point={point}
        isModalOpen={showRankModal}
        closeModal={() => dispatch(actions.closeRankModal())}
      />

      <WorkDescriptionModal
        isModalOpen={showDescriptionModal}
        toggleModal={() => dispatch(actions.toggleDescriptionModal())}
        shopName={shopInfo?.shopName}
        shopTel={shopInfo?.tel}
        isOilWork={isOilWork}
        supportChargeFlag={supportChargeFlag}
      />

      <ModalCalendar
        isModalOpen={showCalendarModal}
        toggleModal={() => dispatch(actions.closeCalendarModal())}
        onSelect={v => {
          setIsEdited(true);
          setDate(v.format('YYYY/M/D'));

          // GTMカスタムイベントパラメータ
          const dataLayer = (window.dataLayer = window.dataLayer || []);
          dataLayer.push({
            event: DATE_SELECT_CHANGE_DATE,
          });

          dispatch(actions.closeCalendarModal());
        }}
        paramDate={dayjs(date)}
        disabledDate={d =>
          dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(d, 'day'))
        }
      />

      <VehicleRegisterModal
        isModalOpen={showVehicleRegisterModal}
        closeModal={() => dispatch(actions.closeVehicleRegisterModal())}
        carList={carList}
        onClick={async ({ manufacturer, carModel }) => {
          // 車両追加
          const sryMkrCd = manufacturer;
          const [sshCd, kshCd] = carModel.split('_');

          const maker = carList.find(c => c.sryMkrCd === sryMkrCd);
          const carInfo = maker.modelList.find(
            m => m.sshCd === sshCd && m.kshCd === kshCd
          );
          const params = {
            updateKbn: '1',
            kaiincrdNo: cardNumber,
            sryMkrCd,
            sryMkrMei: maker.sryMkrNm2,
            sshCd,
            sshMei: carInfo.sshNm2,
          };

          await dispatch(registerVehicles(params)).unwrap();

          dispatch(actions.closeVehicleRegisterModal());
        }}
      />

      <MailAddressSettingModal
        formik={mailFormik}
        isModalOpen={showMailAddressSettingModal}
        closeModal={() => dispatch(actions.closeModal())}
        onClick={async () => {
          const { mail, mailMagazine } = mailFormik.values;
          await dispatch(
            updateUserMailAddress({
              kbn: '2',
              kaiinNo: userId,
              kaiincrdNo: cardNumber,
              kaiinMalAdrKet: mail,
              kaiinMalSsnKbnKet: mailMagazine,
            })
          ).unwrap();
          dispatch(actions.closeModal());
          userWaitTimeReserve();
        }}
      />
      <ApproximateTimeUpdateModal
        isModalOpen={showApproximateTimeUpdateModal}
        closeModal={() => {
          dispatch(actions.closeModal());
          dispatch(
            updateUserWaitTime({
              shopCode: tokCd,
              chubunCd,
              shobunCd: shobun[0]?.shobunCd,
            })
          );
        }}
        onClick={userWaitTimeReserve}
        selectService={selectService}
        minutes={waitTimeInfo?.confirmMenuWaitMinutes}
        time={waitTimeSgyStaHm}
      />

      <NotesModal />
    </>
  );
};

export default DateSelect;
