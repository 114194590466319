import { memo } from 'react';
import classNames from 'classnames';

export default memo(({ tabItems, selectTab, onClick, className }) => {
  return (
    <div className={classNames('flex flex-row', className)}>
      {tabItems.map(tab => {
        const selected = selectTab === tab.key;
        return (
          <div
            key={tab.key}
            className={classNames(
              'text-lg flex-1 cursor-pointer pb-2 text-center font-bold sm:pb-4 lg:pb-6',
              selected ? 'border-red' : 'border-line'
            )}
            style={{ borderBottomWidth: selected ? 4 : 2 }}
            onClick={() => onClick(tab.key)}
          >
            {tab.label}
          </div>
        );
      })}
    </div>
  );
});
