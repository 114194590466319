import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messageModalActions } from 'slices/messageModalSlice';
import ModalBase from 'components/modalBase/index';

const ToastModal = () => {
  const dispatch = useDispatch();

  const { isToastModalOpen, toastMessage, onClose } = useSelector(
    state => state.messageModal
  );

  useEffect(() => {
    isToastModalOpen &&
      setTimeout(() => {
        onClose && onClose();
        dispatch(messageModalActions.closeToastModal());
      }, 1000);
  }, [dispatch, isToastModalOpen]);

  return (
    <ModalBase
      isModalOpen={isToastModalOpen}
      blur={false}
      className="flex items-center justify-center py-16 sm:py-[104px] lg:py-[146px]"
    >
      <label className="text-base font-bold text-navy">{toastMessage}</label>
    </ModalBase>
  );
};

export default ToastModal;
