import React from 'react';
import classNames from 'classnames';
import Linked from 'components/linked';
import Icons from 'images/icons';

const MapLink = ({ onClick, className }) => {
  return (
    <div
      className={classNames(
        'flex flex-row items-center whitespace-nowrap',
        className
      )}
    >
      <Icons.Image src={Icons.mapBu} className="text-sm" />
      <Linked
        text="地図で位置を確認"
        isLine={true}
        linkIcon="R"
        className="text-sm mb-1 font-bold"
        onClick={() => onClick()}
      />
    </div>
  );
};

export default MapLink;
