import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SERVICE_LIST_SEARCH } from 'constants/apiURL';
import { loadingActions } from 'slices/loadingSlice';
import axios from 'utils/axiosUtil';

const initialState = {
  serviceList: [],
  pitServiceData: [],
  showSingleServiceSelectModal: false,
  showMultiServiceSelectModal: false,
  modalContent: null,
};

export const fetchTopInit = createAsyncThunk(
  'fetchTopInit',
  async (_, { dispatch }) => {
    dispatch(loadingActions.setPrimaryLoading(true));

    const [mainRes, pitRes] = await Promise.all([
      axios.get(SERVICE_LIST_SEARCH, { params: { isMain: '1', sortNum: 0 } }),
      axios.get(SERVICE_LIST_SEARCH, { params: { isMain: '0', sortNum: 1 } }),
    ]);

    dispatch(loadingActions.setPrimaryLoading(false));

    return { serviceList: mainRes.body, pitServiceData: pitRes.body };
  }
);

export const topSlice = createSlice({
  name: 'top',
  initialState,
  reducers: {
    showSingleServiceSelectModal: (state, { payload }) => {
      state.showSingleServiceSelectModal = true;
      state.modalContent = payload;
    },
    showMultiServiceSelectModal: (state, { payload }) => {
      state.showMultiServiceSelectModal = true;
      state.modalContent = payload;
    },
    closeModal: state => {
      state.showSingleServiceSelectModal = false;
      state.showMultiServiceSelectModal = false;
      state.modalContent = null;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchTopInit.fulfilled, (state, { payload }) => {
      state.serviceList = payload.serviceList;
      state.pitServiceData = payload.pitServiceData;
    });
  },
});

export const actions = topSlice.actions;
export default topSlice.reducer;
