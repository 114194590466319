import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/scss';
import './style.scss';
import classNames from '../../../node_modules/classnames/index';

const AprosCarousel = ({
  slidesPerView,
  spaceBetween = 0,
  slidesPerGroup = 1,
  breakpoints,
  loop,
  centeredSlides,
  onClick,
  carouselContent,
  height,
  loopedSlides,
}) => {
  return (
    <Swiper
      // 表示されるスライドの枚数
      slidesPerView={slidesPerView}
      // スライドの間隔ー単位はpx
      spaceBetween={spaceBetween}
      slidesPerGroup={slidesPerGroup}
      breakpoints={breakpoints}
      loop={loop}
      centeredSlides={centeredSlides}
      loopedSlides={loopedSlides}
      pagination={false}
      navigation={true}
      modules={[Navigation]}
      className={classNames(
        'apros_carousel--swiper w-full',
        height ? height : 'h-full'
      )}
      onClick={onClick}
    >
      {carouselContent.map((item, i) => (
        <SwiperSlide key={i}>{item}</SwiperSlide>
      ))}
    </Swiper>
  );
};
export default AprosCarousel;
