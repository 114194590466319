import React, { useState, useEffect } from 'react';
import { Calendar } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ja_JP';
import Icons from 'images/icons';
import './calendarStyle.scss';
import { useDeviceSize } from 'hooks/useDeviceSize';

const ModalCalendar = ({
  isModalOpen,
  toggleModal,
  onSelect,
  paramDate,
  disabledDate,
}) => {
  const [_date, setDate] = useState(paramDate);

  useEffect(() => {
    setDate(paramDate);
    return () => {
      setDate(null);
    };
  }, [paramDate]);

  const { isPc } = useDeviceSize();

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  const renderDateCell = date => {
    const day = date.get('D');
    const month = date.get('M');
    const thisMonth = dayjs().month();
    return (
      <div
        className={`text-sm flex justify-center font-sans font-medium ${
          month === thisMonth ? 'text-navy' : 'text-[#4C657C]'
        }`}
      >
        {/* ホバー効果 */}
        <div className="flex h-6 w-6 items-center justify-center rounded-full hover:bg-navy-shadow hover:text-[#647A8D]">
          {day}
        </div>
      </div>
    );
  };

  const closeModal = () => {
    toggleModal?.();
    setTimeout(() => setDate(paramDate ?? dayjs()), 500);
  };

  const headerRender = ({ value, onChange, onTypeChange }) => {
    let current = value?.clone();
    const thisDate = value.format('YYYY年MM月');
    const lastMonth = current.subtract(1, 'months');
    const nextMonth = current.subtract(-1, 'months');

    return (
      <div
        className="relative flex items-center justify-center gap-8 py-5"
        onClick={e => e.stopPropagation()}
      >
        <Icons.Image
          src={Icons.linkLNv}
          className="cursor-pointer"
          onClick={() => setDate(lastMonth)}
        />
        <div className="text-sm font-sans font-bold text-navy">{thisDate}</div>
        <Icons.Image
          src={Icons.linkRNv}
          className="cursor-pointer"
          onClick={() => setDate(nextMonth)}
        />
        <Icons.Image
          src={Icons.closeModal}
          className="absolute right-0 h-8 cursor-pointer lg:h-[50px]"
          onClick={closeModal}
        />
      </div>
    );
  };

  return (
    <div
      className={`fixed left-0 top-0 z-50 h-full w-full transition-all duration-200 ease-linear ${
        isModalOpen ? 'opacity-100' : 'invisible opacity-20'
      }`}
      onClick={closeModal}
    >
      <div className="absolute left-0 top-0 h-full w-full bg-[rgb(2,6,4,0.15)] backdrop-blur-[15px] backdrop-brightness-90">
        <div className="flex h-full w-full items-center justify-center">
          <div className="apros--modal_calendar mx-4 w-[25rem]">
            <Calendar
              fullscreen={false}
              locale={locale}
              dateFullCellRender={renderDateCell}
              headerRender={headerRender}
              onSelect={day => onSelect(day)}
              value={_date}
              disabledDate={date =>
                disabledDate
                  ? disabledDate(date)
                  : dayjs().isAfter(dayjs(date, 'day'))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalCalendar;
