import Icons from 'images/icons';
import classNames from 'classnames';
import {
  SERVICE_CODE_TIRE,
  TIRE_SWITCH_LABEL,
  TIRE_SWITCH_CHUBUN_CD,
  TIRE_CATEGORY_CODE_WHEEL,
  TIRE_CATEGORY_CODE_WHEEL_LABEL,
  TIRE_CATEGORY_CODE_WHEEL_ICON,
  TIRE_CATEGORY_CODE_TIRE,
  TIRE_CATEGORY_CODE_TIRE_LABEL,
  TIRE_CATEGORY_CODE_TIRE_ICON,
  STATUTORY_INSPECTION_CODE,
  VEHICLE_INSPECTION_CODE,
} from 'constants/service';

// 他の画面影響しないように、一旦chubunNmLabelを触らずこれを使います。
const ServiceLabel = ({
  serviceInfo,
  className = { boxClass: '', labelClass: '' },
  pitServiceList,
}) => {
  const { chubunCd, chubunNm, shobun = [] } = serviceInfo;

  const selectPitService = pitServiceList.find(p =>
    p.aprosSubCategories.some(sub =>
      sub.codeCombinations.some(s => s.chubunCd === chubunCd)
    )
  );

  return (
    <div
      className={classNames('flex flex-col justify-center', className.boxClass)}
    >
      {(shobun ?? []).map(({ shobunCd, shobunNm }, i) => {
        const { aprosSmallCode } =
          selectPitService?.aprosSubCategories.find(sub =>
            sub.codeCombinations.some(s => s.shobunCd === shobunCd)
          ) ?? {};

        return (
          <div key={i} className="flex flex-row flex-wrap items-center">
            {selectPitService?.aprosCode === SERVICE_CODE_TIRE &&
            (aprosSmallCode === TIRE_CATEGORY_CODE_WHEEL ||
              aprosSmallCode === TIRE_CATEGORY_CODE_TIRE) &&
            chubunCd === TIRE_SWITCH_CHUBUN_CD ? (
              <>
                <label className={className.labelClass}>
                  {`${chubunNm}(${TIRE_SWITCH_LABEL}　`}
                </label>
                <label className={className.labelClass}>
                  {`${
                    aprosSmallCode === TIRE_CATEGORY_CODE_WHEEL
                      ? TIRE_CATEGORY_CODE_WHEEL_LABEL
                      : TIRE_CATEGORY_CODE_TIRE_LABEL
                  }`}
                  &nbsp;
                </label>
                <Icons.Image
                  src={
                    aprosSmallCode === TIRE_CATEGORY_CODE_WHEEL
                      ? TIRE_CATEGORY_CODE_WHEEL_ICON
                      : TIRE_CATEGORY_CODE_TIRE_ICON
                  }
                  className="h-6 w-6"
                />
                <label className={className.labelClass}>)</label>
              </>
            ) : chubunCd === VEHICLE_INSPECTION_CODE &&
              shobunCd === STATUTORY_INSPECTION_CODE ? (
              shobunNm
            ) : (
              <label className={className.labelClass}>
                {`${chubunNm}${
                  chubunNm !== shobunNm ? '(' + shobunNm + ')' : ''
                }`}
              </label>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ServiceLabel;
