import { useEffect } from 'react';

export const CloseConfirm = () => {
  const handleBeforeUnloadEvent = e => {
    e = e || window.event;
    if (e) {
      e.returnValue = 'Sure?';
    }
    return 'Sure?';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
  }, []);

  return <></>;
};

export default CloseConfirm;
