import Icons from 'images/icons';
import classNames from 'classnames';
import {
  SERVICE_CODE_TIRE,
  TIRE_SWITCH_LABEL,
  TIRE_SWITCH_CHUBUN_CD,
  TIRE_CATEGORY_CODE_WHEEL,
  TIRE_CATEGORY_CODE_WHEEL_LABEL,
  TIRE_CATEGORY_CODE_WHEEL_ICON,
  TIRE_CATEGORY_CODE_TIRE,
  TIRE_CATEGORY_CODE_TIRE_LABEL,
  TIRE_CATEGORY_CODE_TIRE_ICON,
  VEHICLE_INSPECTION_CODE,
  STATUTORY_INSPECTION_CODE,
} from 'constants/service';

const ServiceNameLabel = ({
  selectService = {
    aprosCode: '',
    aprosCategoryName: '',
    aprosSubCategories: [{ aprosSmallCode: '', aprosSmallName: '' }],
  },
  externalSelectSerivce,
  className = { boxClass: '', labelClass: '' },
}) => {
  const { aprosCode, aprosCategoryName, aprosSubCategories } =
    selectService || {};
  return (
    <div
      className={classNames('flex flex-col justify-center', className.boxClass)}
    >
      {externalSelectSerivce
        ? (externalSelectSerivce ?? []).map(
            ({ chubunCd, chubunNm, shobunCd, shobunNm }, i) => (
              <div key={i} className="flex flex-row flex-wrap items-center">
                {chubunCd === TIRE_SWITCH_CHUBUN_CD &&
                (shobunCd === TIRE_CATEGORY_CODE_WHEEL ||
                  shobunCd === TIRE_CATEGORY_CODE_TIRE) ? (
                  <>
                    <label className={className.labelClass}>
                      {`${chubunNm}(${TIRE_SWITCH_LABEL}　`}
                    </label>
                    <label className={className.labelClass}>
                      {`${
                        shobunCd === TIRE_CATEGORY_CODE_WHEEL
                          ? TIRE_CATEGORY_CODE_WHEEL_LABEL
                          : TIRE_CATEGORY_CODE_TIRE_LABEL
                      }`}
                      &nbsp;
                    </label>
                    <Icons.Image
                      src={
                        shobunCd === TIRE_CATEGORY_CODE_WHEEL
                          ? TIRE_CATEGORY_CODE_WHEEL_ICON
                          : TIRE_CATEGORY_CODE_TIRE_ICON
                      }
                      className="h-6 w-6"
                    />
                    <label className={className.labelClass}>)</label>
                  </>
                ) : chubunCd === VEHICLE_INSPECTION_CODE &&
                  shobunCd === STATUTORY_INSPECTION_CODE ? (
                  shobunNm
                ) : (
                  <label className={className.labelClass}>
                    {`${chubunNm}${
                      shobunNm !== chubunNm ? '(' + shobunNm + ')' : ''
                    }`}
                  </label>
                )}
              </div>
            )
          )
        : (aprosSubCategories ?? []).map(
            ({ aprosSmallCode, aprosSmallName }, i) => (
              <div key={i} className="flex flex-row flex-wrap items-center">
                {aprosCode === SERVICE_CODE_TIRE &&
                (aprosSmallCode === TIRE_CATEGORY_CODE_WHEEL ||
                  aprosSmallCode === TIRE_CATEGORY_CODE_TIRE) ? (
                  <>
                    <label className={className.labelClass}>
                      {`${aprosCategoryName}(${TIRE_SWITCH_LABEL}　`}
                    </label>
                    <label className={className.labelClass}>
                      {`${
                        aprosSmallCode === TIRE_CATEGORY_CODE_WHEEL
                          ? TIRE_CATEGORY_CODE_WHEEL_LABEL
                          : TIRE_CATEGORY_CODE_TIRE_LABEL
                      }`}
                      &nbsp;
                    </label>
                    <Icons.Image
                      src={
                        aprosSmallCode === TIRE_CATEGORY_CODE_WHEEL
                          ? TIRE_CATEGORY_CODE_WHEEL_ICON
                          : TIRE_CATEGORY_CODE_TIRE_ICON
                      }
                      className="h-6 w-6"
                    />
                    <label className={className.labelClass}>)</label>
                  </>
                ) : (
                  <label className={className.labelClass}>
                    {`${aprosCategoryName}${
                      aprosCategoryName !== aprosSmallName
                        ? '(' + aprosSmallName + ')'
                        : ''
                    }`}
                  </label>
                )}
              </div>
            )
          )}
    </div>
  );
};

export default ServiceNameLabel;
