import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageKeyList: [],
};

export const pageKeySlice = createSlice({
  name: 'pageKey',
  initialState,
  reducers: {
    addPageKey: (state, { payload }) => {
      if (!state.pageKeyList.includes(payload)) {
        state.pageKeyList.push(payload);
      }
    },
    clear: () => initialState,
  },
  extraReducers: builder => {},
});

export const pageKeyActions = pageKeySlice.actions;
export default pageKeySlice.reducer;
