export const getPrefectures = value => prefectures.find(g => g.value === value);

const prefectures = [
  { label: '北海道', value: '01', lat: 43.064301, lng: 141.346874 },
  { label: '青森県', value: '02', lat: 40.824622, lng: 140.740598 },
  { label: '岩手県', value: '03', lat: 39.7036, lng: 141.152709 },
  { label: '宮城県', value: '04', lat: 38.268812, lng: 140.872082 },
  { label: '秋田県', value: '05', lat: 39.718611, lng: 140.102401 },
  { label: '山形県', value: '06', lat: 38.240422, lng: 140.363592 },
  { label: '福島県', value: '07', lat: 37.750301, lng: 140.467522 },
  { label: '茨城県', value: '08', lat: 36.341793, lng: 140.446802 },
  { label: '栃木県', value: '09', lat: 36.566672, lng: 139.883093 },
  { label: '群馬県', value: '10', lat: 36.390698, lng: 139.060451 },
  { label: '埼玉県', value: '11', lat: 35.857431, lng: 139.648901 },
  { label: '千葉県', value: '12', lat: 35.605045, lng: 140.123325 },
  { label: '東京都', value: '13', lat: 35.689753, lng: 139.691731 },
  { label: '神奈川県', value: '14', lat: 35.447495, lng: 139.6424 },
  { label: '新潟県', value: '15', lat: 37.902419, lng: 139.023225 },
  { label: '富山県', value: '16', lat: 36.695275, lng: 137.211342 },
  { label: '石川県', value: '17', lat: 36.59473, lng: 136.625582 },
  { label: '福井県', value: '18', lat: 36.065219, lng: 136.221682 },
  { label: '山梨県', value: '19', lat: 35.664161, lng: 138.568459 },
  { label: '長野県', value: '20', lat: 36.651296, lng: 138.181239 },
  { label: '岐阜県', value: '21', lat: 35.391228, lng: 136.722311 },
  { label: '静岡県', value: '22', lat: 34.976944, lng: 138.383009 },
  { label: '愛知県', value: '23', lat: 35.180344, lng: 136.906632 },
  { label: '三重県', value: '24', lat: 34.730272, lng: 136.508598 },
  { label: '滋賀県', value: '25', lat: 35.004528, lng: 135.868607 },
  { label: '京都府', value: '26', lat: 35.021393, lng: 135.755439 },
  { label: '大阪府', value: '27', lat: 34.686555, lng: 135.519474 },
  { label: '兵庫県', value: '28', lat: 34.691287, lng: 135.183061 },
  { label: '奈良県', value: '29', lat: 34.685326, lng: 135.832751 },
  { label: '和歌山県', value: '30', lat: 34.226041, lng: 135.167504 },
  { label: '鳥取県', value: '31', lat: 35.503867, lng: 134.237716 },
  { label: '島根県', value: '32', lat: 35.472324, lng: 133.05052 },
  { label: '岡山県', value: '33', lat: 34.661759, lng: 133.934399 },
  { label: '広島県', value: '34', lat: 34.396603, lng: 132.459621 },
  { label: '山口県', value: '35', lat: 34.18613, lng: 131.470497 },
  { label: '徳島県', value: '36', lat: 34.065756, lng: 134.559297 },
  { label: '香川県', value: '37', lat: 34.340045, lng: 134.043369 },
  { label: '愛媛県', value: '38', lat: 33.841669, lng: 132.765371 },
  { label: '高知県', value: '39', lat: 33.5597, lng: 133.531096 },
  { label: '福岡県', value: '40', lat: 33.606781, lng: 130.418307 },
  { label: '佐賀県', value: '41', lat: 33.24957, lng: 130.299804 },
  { label: '長崎県', value: '42', lat: 32.744814, lng: 129.8737 },
  { label: '熊本県', value: '43', lat: 32.789816, lng: 130.74169 },
  { label: '大分県', value: '44', lat: 33.238205, lng: 131.612625 },
  { label: '宮崎県', value: '45', lat: 31.911058, lng: 131.423883 },
  { label: '鹿児島県', value: '46', lat: 31.560166, lng: 130.557994 },
  { label: '沖縄県', value: '47', lat: 26.212418, lng: 127.680895 },
];

export default prefectures;
