import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadingActions } from 'slices/loadingSlice';
import axios from 'utils/axiosUtil';
import {
  USER_CAR_LIST,
  USER_INFO,
  USER_CAR_UPDATE,
  USER_TIRE_STORAGE,
  TIRE_STORAGE_LIMIT_SEARCH,
  USER_UPDATE,
  USER_LOGOUT,
} from 'constants/apiURL';

const initialState = {
  token: null,
  accountInfo: {
    shopCode: '',
    rankCode: '',
    userId: '',
    cardNumber: '',
    point: '',
    mailAddressPc: '',
    mailAddressMobile: '',
  },
  vehicles: [],
  isTireContract: false,
  tireContractDate: null,
  transitionAfterMessage: null,
  onTransitionAfterClose: null,
  updateSrySeq: null,
};

export const fetchAccountRelatedInfo = createAsyncThunk(
  'fetchAccountRelatedInfo',
  async ({ cardNumber }) => {
    const vehiclesRes = await axios.post(USER_CAR_LIST, { cardNumber });
    return {
      vehicles: vehiclesRes.body.car.map(c => ({
        label: c.modelName ? `${c.makerName} ${c.modelName}` : '未登録',
        value: c.carSeq,
        ...c,
      })),
    };
  }
);

export const registerVehicles = createAsyncThunk(
  'registerVehicles',
  async (params, { dispatch }) => {
    dispatch(loadingActions.setPrimaryLoading(true));
    const updateRes = await axios.post(USER_CAR_UPDATE, params);
    const listRes = await axios.post(USER_CAR_LIST, {
      cardNumber: params.kaiincrdNo,
    });
    dispatch(loadingActions.setPrimaryLoading(false));
    return { updateRes, listRes };
  }
);

export const updateUserShop = createAsyncThunk(
  'updateUserShop',
  async (params, { dispatch }) => {
    dispatch(loadingActions.setPrimaryLoading(true));
    const updateRes = await axios.post(USER_UPDATE, params);
    const userRes = await axios.post(USER_INFO, {
      cardNumber: updateRes.body.cardNumber,
    });
    dispatch(loadingActions.setPrimaryLoading(false));
    return { accountInfo: userRes.body };
  }
);

export const updateUserMailAddress = createAsyncThunk(
  'updateUserMailAddress',
  async (params, { dispatch }) => {
    dispatch(loadingActions.setPrimaryLoading(true));
    const updateRes = await axios.post(USER_UPDATE, params);
    const userRes = await axios.post(USER_INFO, {
      cardNumber: updateRes.body.cardNumber,
    });
    dispatch(loadingActions.setPrimaryLoading(false));
    return { accountInfo: userRes.body };
  }
);

export const fetchTireContractInfo = createAsyncThunk(
  'fetchTireContractInfo',
  async ({ userId }) => {
    const tireRes = await axios.post(USER_TIRE_STORAGE, { userId });
    if (tireRes?.body?.contractStatus === '1') {
      const tireInfoRes = await axios.post(TIRE_STORAGE_LIMIT_SEARCH, { userId });
      return { tireRes, tireInfoRes };
    }
    return { tireRes };
  }
);

export const logoutWithMessage = createAsyncThunk(
  'logoutWithMessage',
  async (params, { dispatch }) => {
    if (params.cardNumber) {
      await axios.post(
        USER_LOGOUT,
        { cardNumber: params.cardNumber },
        { ignoreError: true }
      );
    }
    return { onClose: params.onClose };
  }
);

export const logout = createAsyncThunk(
  'logout',
  async (params, { dispatch }) => {
    if (params.cardNumber) {
      await axios.post(
        USER_LOGOUT,
        { cardNumber: params.cardNumber },
        { ignoreError: true }
      );
    }
  }
);

export const accountSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    updateAccountInfo: (state, { payload }) => {
      state.accountInfo = payload.info.body;
    },
    updateToken: (state, { payload }) => {
      state.token = payload.token;
    },
    clearTransitionAfterMessage: state => {
      state.transitionAfterMessage = null;
      state.onTransitionAfterClose = null;
    },
    removeUpdateSrySeq: state => {
      state.updateSrySeq = null;
    },
    clearAccount: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchAccountRelatedInfo.fulfilled, (state, { payload }) => {
      state.vehicles = payload.vehicles;
    });
    builder.addCase(registerVehicles.fulfilled, (state, { payload }) => {
      state.vehicles = payload.listRes.body.car.map(c => ({
        label: c.modelName ? `${c.makerName} ${c.modelName}` : '未登録',
        value: c.carSeq,
        ...c,
      }));
      state.updateSrySeq = Number(payload.updateRes.body.srySeq);
    });
    builder.addCase(updateUserShop.fulfilled, (state, { payload }) => {
      state.transitionAfterMessage = 'ご利用店舗を変更しました。';
      state.accountInfo = payload.accountInfo;
    });
    builder.addCase(updateUserMailAddress.fulfilled, (state, { payload }) => {
      state.accountInfo = payload.accountInfo;
    });
    builder.addCase(fetchTireContractInfo.fulfilled, (state, { payload }) => {
      state.isTireContract = payload.tireRes?.body?.contractStatus === '1';
      state.tireContractDate = payload.tireInfoRes?.body?.tireStorageLimitDate;
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => ({
      ...initialState,
    }));
    builder.addCase(logout.rejected, (state, { payload }) => ({
      ...initialState,
    }));
    builder.addCase(logoutWithMessage.fulfilled, (state, { payload }) => ({
      ...initialState,
      transitionAfterMessage: 'ログアウトしました。',
      onTransitionAfterClose: payload?.onClose,
    }));
    builder.addCase(logoutWithMessage.rejected, (state, { payload }) => ({
      ...initialState,
      transitionAfterMessage: 'ログアウトしました。',
    }));
  },
});

export const accountActions = accountSlice.actions;
export default accountSlice.reducer;
