import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  fetchKarteInfo,
  fetchKarteInfoDetail,
  actions,
} from 'slices/karteSlice';
import AprosButton from 'components/aprosButton';
import Linked from 'components/linked';
import Proctitle from 'components/proctitle';
import InputSelect from 'components/inputSelect';
import { KARTE_ICONS, CAR_IMAGE, CAR_IMAGE_SUFFIX } from 'constants/karte';
import Path from 'constants/routePath';
import dayjs from 'dayjs';
import Icons from 'images/icons';
import { displayCautionActions } from 'slices/displayCautionSlice';
import { openTirePage } from 'utils/tireRequestUtil';
import KarteDetailModal from './modals/karteDetailModal';
import NotesModal from 'components/messageModals/notesModal';
import {
  KARTE_SELECT_CAR,
  KARTE_SELECT_SERVICE,
  KARTE_SHOW_DETAIL_SERVICE,
} from '../../constants/gtmCustomEvent';
import { Helmet } from 'react-helmet';
import Title from '../../constants/title';

const KarteDescription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { cautionDisplayDate } = useSelector(state => state.displayCaution);

  const {
    karteInfo,
    selectedSrySeq,
    showDetailModal,
    selectedKarteInfo,
    karteInfoDetail,
    tireContractDate,
    tireContractAlert,
  } = useSelector(state => state.karte);

  const { accountInfo, vehicles, isTireContract } = useSelector(state => state.account);
  const { cardNumber, userId } = accountInfo || {};

  const vehicleName = vehicles.find(v => v.carSeq === selectedSrySeq)?.label;

  const [imageGif, setImageGif] = useState(CAR_IMAGE['car']);

  const [ref, inView] = useInView();

  const [once, setOnce] = useState(false);

  useEffect(() => {
    inView && setOnce(false);
  }, [inView]);

  useEffect(() => {
    const carImage = decisionCarImage(karteInfo?.parts, true);

    setOnce(pre => {
      setImageGif(preGif => {
        return pre &&
          Object.keys(CAR_IMAGE)
            .filter(key => !/img/.test(key))
            .map(key => CAR_IMAGE[key])
            .some(gif => gif === preGif)
          ? preGif
          : carImage;
      });
      return pre;
    });

    return () => {
      setImageGif();
    };
  }, [karteInfo?.parts, inView]);

  useEffect(() => {
    return () => {
      dispatch(actions.clear());
    };
  }, [dispatch]);

  useEffect(() => {
    // 車両取得時
    vehicles.length > 0 &&
      dispatch(actions.setSelectedSrySeq(vehicles[0].carSeq));
  }, [dispatch, vehicles]);

  useEffect(() => {
    // 車両変更時
    if (selectedSrySeq) {
      setImageGif(CAR_IMAGE.car_img);
      dispatch(
        fetchKarteInfo({
          karteParams: {
            kaiincrdNo: cardNumber,
            srySeq: selectedSrySeq
          },
          tireStorageParams: {
            userId: userId,
            srySeq: selectedSrySeq
          },
          isTireContract: isTireContract
        })
      );
    }
  }, [dispatch, selectedSrySeq]);

  const decisionCarImage = (parts, displayGif) => {
    // アラート箇所に一致する画像を判定する
    const decision = n =>
      parts?.find(e => e.partName === n)?.alert === '1' ?? false;
    const suffixs = [
      ...new Set(
        Object.entries(CAR_IMAGE_SUFFIX)
          .filter(([k]) => decision(k))
          .map(([, v]) => v)
      ),
    ];

    // CAR_IMAGE_SUFFIXの個数
    const count = Object.values(CAR_IMAGE_SUFFIX).filter(
      (e, i, s) => s.indexOf(e) === i
    ).length;
    const imageName = `car${(s =>
      s.length === 0 ? '' : s.length === count ? '_all' : `_${s.join('_')}`)(
      suffixs
    )}`;
    // const imageName = 'car_all';

    return CAR_IMAGE[displayGif ? imageName : `${imageName}_img`];
  };

  return (
    <>
      <Helmet>
        <title>{Title.KARTE}</title>
      </Helmet>
      <Proctitle text="車のカルテ" useH1={true} />
      <InputSelect
        title="車種"
        options={vehicles}
        value={selectedSrySeq}
        onChange={v => {
          // GTMカスタムイベントパラメータ
          const dataLayer = (window.dataLayer = window.dataLayer || []);
          dataLayer.push({
            event: KARTE_SELECT_CAR,
          });

          dispatch(actions.setSelectedSrySeq(v));
        }}
        className={{
          boxClass: 'mt-6 p-0 sm:mt-14',
          titleClass: '',
          inputClass: 'sm:ml-8 sm:w-96',
        }}
        isShowErrorArea={false}
      />
      <div className="mt-4 flex flex-col gap-x-8 lg:mt-6 lg:flex-row">
        <div className="flex h-fit flex-1 flex-col border border-line bg-white px-4 py-8 sm:px-8 lg:py-10">
          <div className="sm:px-20 sm:py-10 lg:px-20 lg:py-10">
            <Icons.Image src={imageGif} />
          </div>
          <motion.div className="mt-8 sm:mt-0" ref={ref} />
          <label className="text-sm font-medium">
            走行距離入力日　{karteInfo.lastCheckDate}
          </label>
          <label className="text-sm font-medium">
            走行距離　{karteInfo.mileage}
          </label>
          {(isTireContract && tireContractDate) && (
            <div className="w-full">
              {!tireContractAlert ? (
                <div className="flex justify-between text-sm mt-4 border-b border-line py-2 mb-1">
                  <label className="text-sm font-bold">
                    タイヤ保管サービス
                  </label>
                  <div>
                    <Linked
                      text={'予約'}
                      onClick={() => openTirePage(cardNumber, null)}
                      isLine
                      linkIcon="R"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-between text-sm mt-4 border-b border-line py-2 mb-1 break-keep">
                  <div className="flex">
                    <Icons.Image
                      src={Icons.alert}
                      className="sm:h-6 sm:w-6 h-5 w-5"
                    />
                    <label className="text-sm font-bold ml-1">
                      タイヤ保管期限が近づいています
                    </label>
                  </div>
                  <div>
                    <Linked
                      text={'予約'}
                      onClick={() => openTirePage(cardNumber, null)}
                      isLine
                      linkIcon="R"
                    />
                  </div>
                </div>
              )}
              <label className="text-sm font-medium break-keep">
                タイヤ保管サービス保管期限　{dayjs(tireContractDate).format('YYYY年M月D日')}
              </label>
            </div>
          )}
        </div>
        <div className="mt-[51px] flex flex-1 flex-col gap-y-11 sm:mt-14 sm:gap-y-12 lg:mt-0 lg:border lg:border-line lg:bg-white lg:px-10 lg:py-8">
          {karteInfo?.parts?.map(item => {
            const {
              partId,
              partName,
              replacementRecommendedMonth,
              replacementDate,
              reserveDisplay,
              alert,
              chubunCd,
              shobunCd,
            } = item;
            return (
              <ul key={partId} className="flex flex-col">
                <li className="flex flex-row items-end justify-between border-b border-line">
                  <div className="flex flex-row items-center">
                    <Icons.Image
                      src={alert === '1' ? Icons.alert : KARTE_ICONS[partName]}
                      className="text-base"
                      alt={partName}
                    />
                    <label className="text-base ml-1 font-bold sm:ml-2">
                      {partName}
                    </label>
                  </div>
                  <Linked
                    text="詳細"
                    isLine={true}
                    linkIcon="R"
                    className="text-sm mb-1 h-fit font-bold"
                    onClick={() => {
                      // GTMカスタムイベントパラメータ
                      const dataLayer = (window.dataLayer =
                        window.dataLayer || []);
                      dataLayer.push({
                        event: KARTE_SHOW_DETAIL_SERVICE,
                        karteShowDetailService: `{partId: ${partId}, partName: ${partName}`,
                      });

                      dispatch(
                        fetchKarteInfoDetail({
                          ...item,
                          cardNumber,
                          selectedSrySeq,
                        })
                      );
                    }}
                  />
                </li>
                <div className="mt-4 flex flex-col flex-wrap items-center justify-between gap-y-4 sm:mt-2 sm:flex-row">
                  <div className="text-sm flex flex-1 flex-col gap-y-2 font-medium">
                    <div className="flex flex-row">
                      <label className="w-32">次回交換推奨月</label>
                      <label
                        className={`ml-10 w-36 ${
                          alert === '1' ? 'text-red' : ''
                        }`}
                      >
                        {replacementRecommendedMonth}
                      </label>
                    </div>
                    <div className="flex flex-row">
                      <label className="w-32">前回交換日</label>
                      <label className="ml-10 w-36">{replacementDate}</label>
                    </div>
                  </div>
                  {reserveDisplay === '1' && (
                    <div className="flex flex-1 flex-row justify-end">
                      <AprosButton
                        text="予約へ進む"
                        comClassName="reserve"
                        className="lg:w-[260px]"
                        onClick={() => {
                          // GTMカスタムイベントパラメータ
                          const dataLayer = (window.dataLayer =
                            window.dataLayer || []);
                          dataLayer.push({
                            event: KARTE_SELECT_SERVICE,
                            karteSelectService: `{chubunCd: ${chubunCd}${
                              shobunCd ? `, shobunCd: ${shobunCd}` : ``
                            }}`,
                          });

                          const navToSelect = () => {
                            navigate(Path.RESERVATION_SERVICE_SELECT, {
                              state: {
                                shopCode: accountInfo.shopCode,
                                serviceSelectScreen: Path.KARTE,
                                selectedSrySeq,
                                chubunCd: chubunCd,
                                shobunCd: shobunCd,
                              },
                            });
                          };
                          if (!cautionDisplayDate) {
                            dispatch(displayCautionActions.check(navToSelect));
                          } else {
                            navToSelect();
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </ul>
            );
          })}
        </div>
      </div>
      <NotesModal />
      <KarteDetailModal
        isModalOpen={showDetailModal}
        toggleModal={() => dispatch(actions.closeDetailModal())}
        item={{
          vehicleName: vehicleName || '-',
          ...selectedKarteInfo,
          ...karteInfoDetail,
        }}
      />
    </>
  );
};

export default KarteDescription;
