import halfFullKana from 'json/halfFullKana';
import fullHalfKana from 'json/fullHalfKana';

// カンマ区切りにフォーマット
export const numberFormat = str =>
  str || str === 0 ? new Intl.NumberFormat().format(str) : '';

// 半角数字 → 全角数字
export const zenkakuNum2HankakuNum = str =>
  str.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

// 半角->全角
export const toFullWidth = str =>
  str &&
  str
    .replace(
      new RegExp(`(${Object.keys(halfFullKana).join('|')})`, 'g'),
      match => halfFullKana[match]
    )
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜')
    .replace(/ /g, '　')
    .replace(/[A-Za-z]/g, s => String.fromCharCode(s.charCodeAt(0) + 0xfee0));

// 全角->半角
export const toHalfWidth = str =>
  str &&
  str
    .replace(
      new RegExp(`(${Object.keys(fullHalfKana).join('|')})`, 'g'),
      match => fullHalfKana[match]
    )
    .replace(/゛/g, 'ﾞ')
    .replace(/゜/g, 'ﾟ')
    .replace(/　/g, ' ')
    .replace(/[Ａ-Ｚａ-ｚ]/g, s =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );

// 空文字または半角/全角スペースのみの文字列か判定する
export const isEmpty = str => !!!str?.replace(/\s+|　/g, '');
