import React from 'react';
import dayjs from 'dayjs';
import ServiceLabel from 'pages/reservation/components/serviceLabel';
import ServiceNameLabel from 'pages/reservation/components/serviceNameLabel';

const ReserveCard = ({
  reserveData,
  selectService,
  pitServiceList,
  externalSelectSerivce,
  linkArea,
}) => {
  return (
    <div className="flex w-full flex-col rounded border border-line bg-white lg:w-[960px]">
      {/* 予約日時 */}
      <div className="flex h-18 minsm:h-12 py-3 minsm:py-0 flex-row minsm:items-center justify-center bg-contents text-white rounded-t sm:h-auto sm:justify-start">
        <p className="text-sm sm:pl-10 whitespace-nowrap minsm:py-0 py-1.5">予約日時</p>

        <p className="mx-2 text-[20px] sm:mx-10 sm:text-[32px] lg:pl-8 lg:mx-0 sm:py-3">
          {(reserveData?.work ?? []).map((item,i) => {
            return (
              <p>
                <span className="text-[16px] sm:text-[24px]">{dayjs(item.sgyYmd).format('YYYY')}</span>
                <span className="text-[16px] sm:text-[24px]">年</span>
                <span >{dayjs(item.sgyYmd).format('M')}</span>
                <span className="text-[16px] sm:text-[24px]">月</span>
                <span >{dayjs(item.sgyYmd).format('D')}</span>
                <span className="text-[16px] sm:text-[24px]">日</span>
                <span className="text-[16px] sm:text-[24px]">({dayjs(item.sgyYmd).format('dd')})</span>
                <span className="minsm:pl-2 block minsm:inline">{dayjs(item.sgyStaHm, 'HHmm').format('HH:mm')}</span>
              </p>
            );
          })}
        </p>
      </div>
      <div className="text-sm flex w-full flex-col items-start px-3 pb-6 font-medium text-navy sm:px-8 lg:flex-row lg:justify-between lg:px-10">
        {/* 予約詳細 */}
        <div className="flex w-full flex-col justify-center">

          <div className="mt-5 flex flex-col justify-start gap-x-8 sm:mt-6 lg:mt-5 lg:flex-row">
            <p>予約店舗</p>

            <p className="mt-1 lg:mt-0">{reserveData?.tokNmRyk}</p>
          </div>

          <div className="mt-5 flex flex-col justify-start gap-x-8 sm:mt-6 lg:mt-5 lg:flex-row">
            <p>予約番号</p>

            <p className="mt-1 lg:mt-0">{reserveData?.yykNo}</p>
          </div>

          <div className="mt-5 flex flex-col justify-start gap-x-8 sm:mt-6 lg:mt-5 lg:flex-row">
            <p>予約内容</p>

            <div className="flex flex-col">
              {(reserveData?.work ?? []).map((item, i) => {
                return (
                  <div key={i}>
                    {selectService || externalSelectSerivce ? (
                      <ServiceNameLabel
                        className={{ labelClass: 'text-sm font-medium' }}
                        selectService={selectService}
                        externalSelectSerivce={externalSelectSerivce}
                      />
                    ) : (
                      <ServiceLabel
                        className={{ labelClass: 'text-sm font-medium' }}
                        serviceInfo={item}
                        pitServiceList={pitServiceList}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="mt-5 flex flex-col justify-start gap-x-8 sm:mt-6 lg:mt-5 lg:flex-row">
            <p>予約車両</p>

            <p className="mt-1 lg:mt-0">{`${reserveData?.sryMkrMei && reserveData?.sshMei
              ? `${reserveData?.sryMkrMei} ${reserveData?.sshMei}`
              : '未登録車両'
              }`}</p>
          </div>
          {!!linkArea && linkArea}
        </div>
      </div>
    </div>
  );
};

export default ReserveCard;
