import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axiosUtil';
import { loadingActions } from 'slices/loadingSlice';
import {
  CAR_LIST_SEARCH,
  SERVICE_LATITUDE_SEARCH,
  SHOP_LIST_SEARCH,
  SHOP_STATUS_SEARCH,
  SHOP_SERVICE_SEARCH,
  SERVICE_LIST_SEARCH,
} from 'constants/apiURL';
import { chubunCdToAprosCode } from 'pages/reservation/utils/reservationUtil';

const initialState = {
  mapAreaHeight: 0,
  subAreaHeight: {},
  selectedSrySeq: null,
  mainShopInfo: null,
  subShopInfoList: [],
  serviceList: [],
  allServiceList: [],
  subShopService: {},
  showVehicleRegisterModal: false,
  showShopChangeModal: false,
  carList: [],
  shopList: [],
  shopStatus: null,
  isSubShopLoading: false,
};

export const fetchServiceSelectInit = createAsyncThunk(
  'fetchServiceSelectInit',
  async (params, { dispatch }) => {
    dispatch(loadingActions.setPrimaryLoading(true));

    const [shopRes, carRes, allServiceRes, shopStatusRes] = await Promise.all([
      axios.get(SHOP_LIST_SEARCH, { params: { kbn: '5' } }),
      axios.get(CAR_LIST_SEARCH),
      axios.get(SERVICE_LIST_SEARCH, {
        params: { isMain: '0', sortNum: 0 },
      }),
      axios.get(SHOP_STATUS_SEARCH.replace('{shopCode}', params.shopCode)),
    ]);

    const shopStatus = shopStatusRes?.body?.shopStatus;

    let serviceRes;

    if (shopStatus === '0') {
      serviceRes = await axios.get(SHOP_SERVICE_SEARCH, {
        params: { shopCode: [params.shopCode] },
      });
    }

    const shopList = shopRes.body.shopList.map(shop => ({
      ...shop,
      value: shop.shopCode,
      label: shop.shopName,
    }));

    const carList = carRes.body.carList.map(car => ({
      ...car,
      value: car.sryMkrCd,
      label: car.sryMkrNm1,
    }));

    const serviceList = serviceRes?.body?.[params.shopCode] || [];

    dispatch(loadingActions.setPrimaryLoading(false));

    return {
      shopList,
      carList,
      mainShopInfo: shopList.find(s => s.shopCode === params.shopCode),
      allServiceList: allServiceRes?.body || [],
      serviceList,
      shopStatus,
    };
  }
);

export const fetchSubShop = createAsyncThunk(
  'shopAndService/fetchSubShop',
  async (params, { dispatch, getState }) => {
    const allShopList = getState().reservationShopAndServiceSelect.shopList;
    const allServiceList =
      getState().reservationShopAndServiceSelect.allServiceList;
    const chubunCd = params.chubunCd;
    const aprosCode = chubunCdToAprosCode(allServiceList, chubunCd);

    dispatch(actions.setSubShopLoading(true));

    const shopList = await (async () => {
      if (!!params.prefectures) {
        // 都道府県切り替え時
        const shopListRes = await axios.get(SHOP_LIST_SEARCH, {
          params: { kbn: '5', prefecturesCode: params.prefectures },
          hiddenLoading: true,
        });
        return shopListRes.body.shopList ?? [];
      } else {
        // 位置情報で検索時
        const shopListRes = await axios.get(SERVICE_LATITUDE_SEARCH, {
          params: params.searchParams,
          hiddenLoading: true,
        });
        return shopListRes.body.shopList ?? [];
      }
    })();

    const subShopInfoList = shopList.map((s, i) => {
      const shop = allShopList.find(e => e.shopCode === s.shopCode);
      return {
        ...s,
        address: `${shop?.address1 || ''}${shop?.address2 || ''}`,
      };
    });

    let serviceRes;

    if (subShopInfoList.length > 0) {
      serviceRes = await axios.get(SHOP_SERVICE_SEARCH, {
        params: { shopCode: subShopInfoList.map(shop => shop.shopCode) },
        hiddenLoading: true,
      });
    }

    const serviceMap = serviceRes?.body || {};

    const subShopService = subShopInfoList
      .map(s =>
        serviceMap[s.shopCode] &&
        (!aprosCode ||
          serviceMap[s.shopCode].findIndex(e => e.aprosCode === aprosCode) > -1)
          ? { [s.shopCode]: serviceMap[s.shopCode] }
          : null
      )
      .filter(s => s)
      .reduce((e, current) => {
        return {
          ...current,
          ...e,
        };
      }, {});
    const serviceShopCodeList = Object.keys(subShopService);

    dispatch(actions.setSubShopLoading(false));

    return {
      subShopInfoList: subShopInfoList.filter(s =>
        serviceShopCodeList.includes(s.shopCode)
      ),
      subShopService,
    };
  }
);

export const reservationShopAndServiceSelectSlice = createSlice({
  name: 'reservationShopAndServiceSelect',
  initialState,
  reducers: {
    setMapAreaHeight: (state, { payload }) => {
      state.mapAreaHeight = payload;
    },
    setSubAreaHeight: (state, { payload }) => {
      state.subAreaHeight[payload.shopCode] = payload.height;
    },
    setSrySeq: (state, { payload }) => {
      state.selectedSrySeq = payload;
    },
    setSubShopLoading: (state, { payload }) => {
      state.isSubShopLoading = payload;
    },
    showVehicleRegisterModal: state => {
      state.showVehicleRegisterModal = true;
    },
    showShopChangeModal: state => {
      state.showShopChangeModal = true;
    },
    closeVehicleRegisterModal: state => {
      state.showVehicleRegisterModal = false;
    },
    closeShopChangeModal: state => {
      state.showShopChangeModal = false;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchServiceSelectInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchSubShop.fulfilled, (state, { payload }) => {
      state.subShopInfoList = payload.subShopInfoList;
      state.subShopService = payload.subShopService;
    });
  },
});

export const actions = reservationShopAndServiceSelectSlice.actions;
export default reservationShopAndServiceSelectSlice.reducer;
