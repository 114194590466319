import { memo, useRef, useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Icons from 'images/icons';
import { useDeviceSize } from 'hooks/useDeviceSize';
import Linked from 'components/linked/index';
import EllipsisText from 'components/ellipsisText/index';

const DATE_KBN_COLOR_CLASS = {
  0: 'text-navy',
  1: 'text-link',
  2: 'text-red',
  3: 'text-red',
};

const AVAILABILITY_ICON = {
  '01': Icons.icon_OK_b,
  '02': Icons.icon_OK_s,
  '03': Icons.icon_OK_d,
};

export default memo(
  ({
    shopInfoList = [],
    isSubShop = false,
    className = '',
    rank = null,
    // 前週リンク押下時
    onClickLeft,
    // 次週リンク押下時
    onClickRight,
    // 〇マーク押下時
    onClickCircle,
    // □マーク押下時
    onClickSquare,
    // 鍵マーク押下時
    onClickLock,
    // 基準日
    currentDate,
    // view変更時
    onChangeView,
  }) => {
    const firstRef = useRef();

    const [dateWidth, setDateWidth] = useState(0);
    const { isPc, isSp } = useDeviceSize();

    const setRef = useCallback(node => {
      if (node) {
        setDateWidth(node?.offsetWidth ?? 0);
      }
      firstRef.current = node;
    }, []);

    const [_shopInfoList, setShopInfoList] = useState([]);
    const [outOfTermIndex, setOutOfTermIndex] = useState(-1);

    /**
     * 表示日付カウント
     * メイン店舗PCの場合：14
     * メイン店舗タブレット以下の場合：7
     * サブ店舗の場合：7
     */
    const displayCount = !isSubShop && isPc ? 14 : 7;

    /**
     * 表示日付カウント
     * メイン店舗PCの場合：2週間
     * メイン店舗タブレット以下の場合：1週間
     * サブ店舗の場合：1週間
     */
    const changeDateCount = !isSubShop && isPc ? 2 : 1;

    // 基準日から表示対象のデータを作成する
    const createDisplayList = date =>
      shopInfoList
        .filter(s => s.date >= dayjs(date).format('YYYYMMDD'))
        .filter((s, i) => s.date === dayjs(date).add(i, 'd').format('YYYYMMDD'))
        .filter((_, i) => i < displayCount);

    useEffect(() => {
      shopInfoList.length > 0 &&
        setShopInfoList(createDisplayList(currentDate));
    }, [shopInfoList, isPc]);

    useEffect(() => {
      setOutOfTermIndex(_shopInfoList.findIndex(i => i.outOfTermMessage));
    }, [_shopInfoList]);

    useEffect(() => {
      onChangeView?.(
        !_shopInfoList.some(l => l.date === dayjs().format('YYYYMMDD'))
      );
    }, [shopInfoList, _shopInfoList, isSubShop]);

    const createLink = (text, onClick, isDisplay, style, linkIcon) => (
      <>
        {isDisplay ? (
          <Linked
            text={text}
            isLine={true}
            linkIcon={linkIcon}
            className="text-sm mb-1 font-bold"
            comStyle={style}
            onClick={onClick}
          />
        ) : (
          <div />
        )}
      </>
    );

    const createAvailability = (shopInfo, i) => {
      if (shopInfo.availability === '〇') {
        // 予約可能
        const src =
          shopInfo.date === dayjs().format('YYYYMMDD') && rank
            ? AVAILABILITY_ICON[rank]
            : Icons.icon_OK;
        return (
          <div
            onClick={() => onClickCircle?.(shopInfo)}
            className="my-2 cursor-pointer self-center"
          >
            <Icons.Image src={src} className="h-5 w-5" />
          </div>
        );
      } else if (shopInfo.availability === '×') {
        // 予約不可
        return (
          <div
            onClick={() => onClickSquare?.(shopInfo)}
            className="my-2 cursor-pointer self-center"
          >
            {shopInfo.closedFlag !== '1' && (
              <Icons.Image src={Icons.icon_NG02} className="h-5 w-5" />
            )}
          </div>
        );
      }
    };

    // 店休日/鍵マークを生成
    const createDisabledArea = (info, i) => {
      const { showKeyFlag, closedFlag } = info;
      const isDisplay =
        (outOfTermIndex === -1 || i < outOfTermIndex) &&
        (showKeyFlag === '1' || closedFlag === '1');
      return (
        isDisplay && (
          <div
            className="absolute h-full overflow-hidden bg-navy/60"
            style={{ width: dateWidth }}
          >
            <div
              className="flex h-full w-full items-center justify-center text-center text-[14px] tracking-widest text-white lg:text-[18px]"
              style={isSp ? { writingMode: 'vertical-rl' } : {}}
            >
              {closedFlag === '1'
                ? '店休日'
                : showKeyFlag === '1' && (
                    <Icons.Image
                      src={Icons.lock}
                      className="h-[32px] w-[28px] cursor-pointer lg:h-[49px] lg:w-[35px]"
                      onClick={() => onClickLock()}
                    />
                  )}
            </div>
          </div>
        )
      );
    };

    // 選択不可領域を生成
    const createMultiDisabledArea = (info, i) => {
      const { outOfTermMessage } = info;
      const isDisplay = outOfTermIndex !== -1 && i === outOfTermIndex;

      return (
        isDisplay && (
          <>
            <div
              className="absolute z-10 h-full overflow-hidden bg-navy/60"
              style={{
                width: dateWidth * (_shopInfoList.length - outOfTermIndex) + 1,
              }}
            >
              <div className="flex h-full items-center justify-center text-center text-white">
                <EllipsisText text={outOfTermMessage} />
              </div>
            </div>
          </>
        )
      );
    };

    return (
      <div className={className}>
        <div className="flex flex-row justify-between">
          {createLink(
            '前週',
            () => {
              const newDate = dayjs
                .max(dayjs(), dayjs(currentDate).add(-changeDateCount, 'w'))
                .format('YYYYMMDD');
              const list = createDisplayList(newDate);

              if (list.length >= displayCount) {
                setShopInfoList(list);
              }
              onClickLeft(newDate);
            },
            dayjs(currentDate).isAfter(dayjs()),
            isSp ? { marginLeft: 8 } : {},
            'L'
          )}
          {createLink(
            '次週',
            () => {
              const newDate = dayjs(currentDate)
                .add(changeDateCount, 'w')
                .format('YYYYMMDD');
              const list = createDisplayList(newDate);
              if (list.length >= displayCount) {
                setShopInfoList(list);
              }
              onClickRight(newDate);
            },
            outOfTermIndex === -1,
            isSp ? { marginRight: 8 } : {},
            'R'
          )}
        </div>
        <div className="relative flex flex-row bg-white">
          {_shopInfoList.map((s, index) => (
            <div
              key={s.date}
              ref={element => (index === 0 ? setRef(element) : null)}
              className={classNames(
                'relative w-full border-b border-l border-t border-line',
                DATE_KBN_COLOR_CLASS[s.dayKbn],
                index === 0 ? 'border-l-0 sm:border-l' : '',
                index === _shopInfoList.length - 1 ? 'sm:border-r' : ''
              )}
            >
              {createDisabledArea(s, index)}
              {createMultiDisabledArea(s, index)}

              <div className="text-xs w-full border-b border-line text-center">
                {`${dayjs(s.date).format('ddd')}${
                  s.dayKbn === '3' ? '・祝' : ''
                }`}
              </div>
              <div className="flex w-full flex-col text-center text-[11px] sm:text-[16px]">
                <div>{dayjs(s.date).format('M月D日')}</div>
                {createAvailability(s, index)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);
