import { memo, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

const Lisener = memo(() => {
  const location = useLocation();

  const { accountInfo } = useSelector(state => state.account);

  const cardNumber = accountInfo?.cardNumber;

  useEffect(() => {
    if (cardNumber) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        customerNo: cardNumber,
      });
    }
  }, [location, cardNumber]);

  return <></>;
});

export default Lisener;
