/**
 * ローディング表示用Slice
 */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  primaryLoading: false,
  authLoading: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    /** 共通部品でのみ使用 */
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    /** 各画面でローディングを制御したい場合に使用 */
    setPrimaryLoading: (state, { payload }) => {
      state.primaryLoading = payload;
    },
    /** ログイン状態検証時のローディングを制御したい場合に使用 */
    setAuthLoading: (state, { payload }) => {
      state.authLoading = payload;
    },
    loadingOff: state => {
      state.loading = false;
      state.primaryLoading = false;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {},
});

export const loadingActions = loadingSlice.actions;
export default loadingSlice.reducer;
