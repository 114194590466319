import { Navigate, useLocation } from 'react-router-dom';
import Path from 'constants/routePath';
import DefaultRoute from './defaultRoute';
import { encode } from 'utils/base64Util';
import dayjs from 'dayjs';
import { sapLogin } from '../utils/sapSdkUtil';
import { useSelector } from 'react-redux';

const AfterLoginRoute = ({ isLogin, isUnsubscribe }) => {
  const location = useLocation();

  // 未ログイン時に遷移する画面
  const beforeLogin = {
    [Path.KARTE]: Path.KARTE_DESCRIPTION,
    [Path.RESERVATION_SHOP_SELECT]: null,
    [Path.RESERVATION_SERVICE_SELECT]: null,
    [Path.RESERVATION_DATE_SELECT]: null,
    [Path.RESERVATION_CONFIRM]: Path.TOP,
    [Path.RESERVATION_COMPLETE]: Path.TOP,
    [Path.RESERVATION_LIST]: null,
  };
  const { isMobila } = useSelector(state => state.mobila);

  const to = beforeLogin[location.pathname];

  const beforeRedirect = () => {
    let params = '';
    //外部遷移用のパラメータがあったら、リダイレクト先のパラメータに設定する
    if (location.state?.queryParams) {
      const obj = location.state.queryParams;
      Object.keys(obj).forEach(function (key) {
        if (key === 'p12' && (obj[key] === undefined || obj[key] === null))
          return;
        const param = key === 'p12' ? encode(obj[key]) : obj[key];
        if (params === '') {
          params = '?' + key + '=' + param;
        } else {
          params += '&' + key + '=' + param;
        }
      });
    }

    if (
      location.pathname === Path.RESERVATION_SHOP_SELECT &&
      location.state.yykInfo?.work
    ) {
      let [{ sgyYmd, codeCombinationsList }] =
        location.state.yykInfo.work || [];
      sgyYmd = sgyYmd !== undefined ? sgyYmd : dayjs().format('YYYYMMDD');
      //セントラルログインページに遷移するときは、ローカルストレージに必要なパラメータを保存しておく
      window.localStorage.setItem(
        'codeCombinationsList',
        JSON.stringify(codeCombinationsList, undefined, 1)
      );
      window.localStorage.setItem('sgyYmd', sgyYmd);
    }

    const redirectURL =
      params === ''
        ? process.env.REACT_APP_BASE_URL + location.pathname
        : process.env.REACT_APP_BASE_URL + Path.TOP + params;

    sapLogin(redirectURL, isMobila);
  };

  return isLogin ? (
    <DefaultRoute isLogin={isLogin} />
  ) : isUnsubscribe ? ( //退会済みの場合はCLPに遷移せず、トップページに遷移
    <Navigate replace to={Path.TOP} state={{ ...location.state }} />
  ) : location.search.match(/terms=terms/) ? ( //ログアウト済みかつ、規約更新確認画面からの遷移の場合、トップ画面に遷移する。
    <Navigate replace to={Path.TOP} state={{ ...location.state }} />
  ) : location.pathname === Path.RESERVATION_SERVICE_SELECT ||
    location.pathname === Path.RESERVATION_DATE_SELECT ||
    location.pathname === Path.RESERVATION_LIST ||
    location.pathname === Path.RESERVATION_SHOP_SELECT ? (
    beforeRedirect()
  ) : (
    <Navigate replace to={to} state={{ ...location.state }} />
  );
};

export default AfterLoginRoute;
