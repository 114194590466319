import React, { useState, useEffect } from 'react';
import ModalBase from 'components/modalBase/index';
import AprosButton from 'components/aprosButton/index';
import Proctitle from 'components/proctitle/index';
import RadioButton from 'components/radioButton/index';
import Icons from 'images/icons';
import {
  TIRE_CATEGORY_CODE_WHEEL,
  TIRE_SWITCH_LABEL,
  TIRE_CATEGORY_CODE_WHEEL_LABEL,
  TIRE_CATEGORY_CODE_TIRE_LABEL,
} from 'constants/service';

const SingleServiceSelectModal = ({
  isModalOpen,
  toggleModal,
  serviceName,
  options,
  onClickReserve,
}) => {
  const [checked, setChecked] = useState(null);

  useEffect(() => {
    setChecked(null);
  }, [isModalOpen]);

  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      className="flex flex-col overflow-auto px-6 pb-11 pt-12 sm:px-8 sm:pb-11 sm:pt-[66px] lg:px-14 lg:pb-[72px] lg:pt-14"
    >
      <div className="absolute right-0 top-0 py-2 pr-2">
        <Icons.Image
          src={Icons.closeModal}
          className="float-right h-8 cursor-pointer lg:h-[50px]"
          onClick={toggleModal}
        />
      </div>

      <div>
        <Proctitle text={serviceName} textSize="l" />

        <div className="mt-8 flex flex-wrap gap-x-14 gap-y-6 lg:mt-10">
          {options?.map((item, i) => (
            <RadioButton
              key={i}
              fieldName="singleServiceSelect"
              label={
                item.aprosSmallImageUrl ? (
                  <div className="flex flex-row items-center">
                    <p className="text-sm pr-[155px] sm:pr-[186px]">
                      {TIRE_SWITCH_LABEL}
                    </p>
                    <p className="text-xs absolute ml-[80px] flex items-center bg-[#E5E5E5] px-2 font-normal sm:ml-[100px]">
                      {item.aprosSmallCode === TIRE_CATEGORY_CODE_WHEEL
                        ? TIRE_CATEGORY_CODE_WHEEL_LABEL
                        : TIRE_CATEGORY_CODE_TIRE_LABEL}
                      <Icons.Image
                        src={item.aprosSmallImageUrl}
                        className="ml-2 h-7 w-7"
                      />
                    </p>
                  </div>
                ) : (
                  item.aprosSmallName
                )
              }
              value={item.aprosSmallCode}
              checked={() => item.aprosSmallCode === checked}
              onChange={e => setChecked(e.target.value)}
              className={{ boxClass: 'my-0', labelClass: 'mx-0' }}
            />
          ))}
        </div>
      </div>

      <div className="mt-10 mb-3 flex items-center justify-center lg:mt-24">
        <AprosButton
          disabled={!checked}
          comClassName="reserve"
          text="予約へ進む"
          className="lg:w-80"
          onClick={() => onClickReserve(checked)}
        />
      </div>
    </ModalBase>
  );
};
export default SingleServiceSelectModal;
