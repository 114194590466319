import React from 'react';
import ModalBase from 'components/modalBase';
import AprosButton from 'components/aprosButton';

const LogoutModal = ({ isModalOpen, closeModal, onClick }) => {
  return (
    <ModalBase
      isModalOpen={isModalOpen}
      toggleModal={closeModal}
      className="flex flex-col overflow-auto px-4 pb-[60px] pt-[72px] text-navy sm:px-10 sm:pb-[60px] sm:pt-[72px] lg:px-28 lg:pb-[104px] lg:pt-[120px]"
    >
      <div className="text-base whitespace-pre-wrap text-center font-bold">
        ログアウトしますか？
      </div>

      <div className="mt-[52px] flex w-full flex-row gap-x-[19px] sm:mt-[58px] sm:gap-x-11 lg:mt-[92px] lg:gap-x-[52px]">
        <AprosButton
          text="戻る"
          comClassName="sub"
          className="w-full"
          onClick={closeModal}
        />
        <AprosButton
          text="ログアウトする"
          comClassName="main"
          className="w-full"
          onClick={onClick}
        />
      </div>
    </ModalBase>
  );
};

export default LogoutModal;
