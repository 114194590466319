import { memo } from 'react';
import classNames from 'classnames';
import './style.scss';

export default memo(
  ({
    formik,
    fieldName,
    value,
    label,
    labelAfter = null,
    disabled = false,
    className = { boxClass: '', labelClass: '' },
    checked,
    onChange,
    basisFull,
  }) => {
    return (
      <span
        className={classNames(
          'md-radio my-3 flex flex-row items-center',
          className.boxClass,
          {
            'basis-full': basisFull,
          }
        )}
      >
        <input
          id={`${fieldName}_${value}`}
          type="radio"
          onChange={e => {
            if (onChange) {
              onChange(e);
            } else {
              formik.setFieldValue(fieldName, e.target.value);
              setTimeout(() => formik.setFieldTouched(fieldName, true));
            }
          }}
          name={fieldName}
          checked={
            formik
              ? String(formik.values[fieldName]) === String(value)
              : checked()
          }
          disabled={disabled}
          value={value}
          className={classNames(
            'ml-2 scale-150 items-center',
            disabled ? 'accent-disabled' : 'accent-navy'
          )}
        />
        <label
          htmlFor={`${fieldName}_${value}`}
          className={classNames(
            'text-sm mx-3 flex flex-row items-center whitespace-nowrap font-medium',
            disabled
              ? 'cursor-default text-disabled'
              : 'cursor-pointer text-navy',
            className.labelClass
          )}
        >
          {label}
          {labelAfter}
        </label>
      </span>
    );
  }
);
