import React from 'react';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { useEffect } from 'react';
import { useModalScrollLock } from './useModalScrollLock';
import inobounce from './inobounce';

const ModalBase = props => {
  /*
    isModalOpen:モダルの開閉 bool
    className:モダルのクラス string
    modalStyle:モダルのスタイル object
    isLogIn:ログインかログアウトの時背景のブラーを消す
  */
  const { isPc } = useDeviceSize();
  const {
    id = 'modal',
    isModalOpen,
    toggleModal,
    className,
    modalStyle,
    isLogIn,
    blur = true,
  } = props;

  useEffect(() => {
    if (isPc) return;
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  useModalScrollLock({ isModalOpen });

  inobounce.disable();
  var xStart,
    yStart = 0;

  document.addEventListener('touchstart', function (e) {
    xStart = e.touches[0].screenX;
    yStart = e.touches[0].screenY;
  });

  document.addEventListener('touchmove', function (e) {
    var xMovement = Math.abs(e.touches[0].screenX - xStart);
    var yMovement = Math.abs(e.touches[0].screenY - yStart);
    if (yMovement * 3 > xMovement) {
      e.preventDefault();
    }
  });

  return (
    <>
      <div
        className={`fixed top-0 left-0 z-50 flex h-full w-screen overflow-y-scroll overscroll-contain transition-all duration-200 ease-linear ${
          isModalOpen ? 'opacity-100' : 'invisible opacity-20'
        }`}
        onClick={toggleModal}
      >
        <div
          className={`${
            isLogIn ??
            `absolute top-0 left-0 h-full w-full bg-[rgb(2,6,4,0.15)] ${
              blur ? 'backdrop-blur-[15px]' : ''
            } backdrop-brightness-90`
          }`}
        >
          <div className="flex h-full w-full items-center justify-center">
            <div
              className={`relative mx-4 h-fit max-h-[calc(100%_-_50px)] w-full rounded-xl bg-background-main sm:max-h-[calc(100%_-_280px)] sm:w-[528px] lg:max-h-[calc(100%_-_150px)] lg:w-[800px] ${className}`}
              style={modalStyle}
              onClick={event => event.stopPropagation()}
            >
              {props.children}
            </div>
          </div>
        </div>
        {/** スクロールを禁止するためのつっかえ棒 */}
        {isPc && (
          <div className="bg-transparent h-[calc(100vh_+_1px)] w-[1px]"></div>
        )}
        {!isPc && (
          <div className="bg-transparent h-[calc(100%_+_1px)] w-[1px]"></div>
        )}
      </div>
    </>
  );
};
export default ModalBase;
