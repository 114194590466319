import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { accountActions } from 'slices/accountSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import { actions as loginActions } from 'slices/loginSlice';
import { fetchMaintenanceInfo } from 'slices/maintenanceSlice';
import HeaderRow from 'components/headerRow';
import Copyright from 'components/copyright';
import AbidDifferenceModal from 'components/messageModals/abidDifferenceModal';
import CloseConfirm from 'hooks/closeConfirm';
import { useExternalTransition } from 'hooks/useExternalTransition';
import Path, {
  CLOSE_CONFIRM_SCREENS,
  COMPONENT_PARENT_CLASS,
  HEADER_LINK_HIDDEN_SCREENS,
  RELATIVE_SCREENS,
} from 'constants/routePath';
import { mobilaActions } from 'slices/mobilaSlice';
import { useUpdateEffect } from 'react-use';
import React, { useEffect } from 'react';
import CorporationLoginModal from 'pages/topPage/modals/corporationLoginModal';
import TermsConfirmModal from '../components/messageModals/termsConfirmModal';

const DefaultRoute = ({ isLogin }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { queryParams } = location?.state || {};
  const { accountInfo, transitionAfterMessage, onTransitionAfterClose } =
    useSelector(state => state.account);

  const { redirect } = useExternalTransition();

  const { maintenanceInfo } = useSelector(state => state.maintenance);
  const { showCorporationLoginModal } = useSelector(state => state.login);

  useEffect(() => {
    var element = document.getElementById('root');

    element.scrollTo({
      top: 0,
    });
  }, [location]);

  useUpdateEffect(() => {
    if (transitionAfterMessage) {
      dispatch(accountActions.clearTransitionAfterMessage());
      dispatch(
        messageModalActions.showToastModal({
          message: transitionAfterMessage,
          onClose: onTransitionAfterClose,
        })
      );
    }
  }, [transitionAfterMessage, dispatch]);

  useEffect(() => {
    dispatch(fetchMaintenanceInfo());
  }, [dispatch, location.pathname]);

  useUpdateEffect(() => {
    // メンテナンス情報取得前/メンテナンス中
    if (!maintenanceInfo || maintenanceInfo.maintenance) return;

    // 未ログイン（アカウント情報が取得できない）
    if (!accountInfo?.cardNumber) return;

    const params = queryParams;
    if (
      accountInfo.cardNumber &&
      params?.p12 &&
      accountInfo.cardNumber !== params?.p12
    ) {
      // TODO 外部遷移
      const abidDifferenceMortgage = params?.p1 && params?.p1 !== 'MOBILA';
      dispatch(
        loginActions.showAbidDifferenceModal({
          abidDifferenceMortgage,
          abidDifferenceNextPage:
            location.pathname === Path.TOP ? 'TODO' : location.pathname,
          queryParams: params,
        })
      );
    }

    // MOBILA経由か
    if (params) {
      dispatch(mobilaActions.setMobila(params?.p1 === 'MOBILA'));
    }
  }, [dispatch, maintenanceInfo]);

  // 定義ファイルから各画面固有の設定を取得する
  const parentClass = COMPONENT_PARENT_CLASS[pathname];
  const isDisplayHeaderLink = !HEADER_LINK_HIDDEN_SCREENS.includes(pathname);
  const isRelative = RELATIVE_SCREENS.includes(pathname);
  const isCloseConfirm = CLOSE_CONFIRM_SCREENS.includes(pathname);

  const createBody = (
    <div
      className={classNames(
        'mx-4 flex flex-col font-medium text-navy sm:mx-10 lg:mx-auto lg:max-w-[1472px]',
        parentClass
      )}
    >
      <div className="my-auto lg:mx-10">
        <Outlet />
      </div>
    </div>
  );

  return location.pathname !== Path.MAINTENANCE &&
    maintenanceInfo?.maintenance ? (
    <Navigate replace to={Path.MAINTENANCE} />
  ) : location.pathname === Path.MAINTENANCE &&
    !maintenanceInfo?.maintenance ? (
    <Navigate replace to={Path.TOP} />
  ) : redirect ? (
    <Navigate replace to={redirect.to} state={redirect.state} />
  ) : (
    <div
      className="flex flex-col"
      style={{
        minHeight: '100vh',
        minHeight: '100dvh',
      }}
    >
      <HeaderRow isLogin={isLogin} links={isDisplayHeaderLink} />
      {isRelative ? (
        <div className="relative overflow-hidden">{createBody}</div>
      ) : (
        <div className="grow">{createBody}</div>
      )}
      {isCloseConfirm && <CloseConfirm />}
      <Copyright />
      <CorporationLoginModal
        isModalOpen={showCorporationLoginModal}
        closeModal={() => dispatch(loginActions.closeCorporationLoginModal())}
      />
      <AbidDifferenceModal />
      <TermsConfirmModal />
    </div>
  );
};

export default DefaultRoute;
