import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import loadingReducer from 'slices/loadingSlice';
import messageModalReducer from 'slices/messageModalSlice';
import accountReducer from 'slices/accountSlice';
import displayCautionReducer from 'slices/displayCautionSlice';
import topReducer from 'slices/topSlice';
import loginReducer from 'slices/loginSlice';
import descriptionReducer from 'slices/descriptionSlice';
import reservationShopSelectReducer from 'slices/reservationShopSelectSlice';
import reservationShopAndServiceSelectReducer from 'slices/reservationShopAndServiceSelectSlice';
import reservationDateSelectReducer from 'slices/reservationDateSelectSlice';
import reservationConfirmReducer from 'slices/reservationConfirmSlice';
import reservationCompleteReducer from 'slices/reservationCompleteSlice';
import reservationListReducer from 'slices/reservationListSlice';
import pitServiceReducer from 'slices/pitServiceSlice';
import karteReducer from 'slices/karteSlice';
import maintenanceReducer from 'slices/maintenanceSlice';
import mobilaReducer from 'slices/mobilaSlice';
import pageKeyReducer from 'slices/pageKeySlice';

export default configureStore({
  reducer: {
    loading: loadingReducer,
    messageModal: messageModalReducer,
    account: accountReducer,
    displayCaution: displayCautionReducer,
    top: topReducer,
    login: loginReducer,
    description: descriptionReducer,
    reservationShopSelect: reservationShopSelectReducer,
    reservationShopAndServiceSelect: reservationShopAndServiceSelectReducer,
    reservationDateSelect: reservationDateSelectReducer,
    reservationConfirm: reservationConfirmReducer,
    reservationComplete: reservationCompleteReducer,
    reservationList: reservationListReducer,
    pitService: pitServiceReducer,
    karte: karteReducer,
    maintenance: maintenanceReducer,
    mobila: mobilaReducer,
    pageKey: pageKeyReducer,
  },
  preloadedState: load({ states: ['displayCaution'] }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      save({ states: ['displayCaution'] })
    ),
});
