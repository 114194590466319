import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayCautionActions } from 'slices/displayCautionSlice';
import ModalBase from 'components/modalBase/index';
import Icons from 'images/icons';
import AprosButton from 'components/aprosButton/index';
import { useDeviceSize } from 'hooks/useDeviceSize';

const NotesModal = () => {
  const dispatch = useDispatch();

  const { isPc, isTablet, isSp } = useDeviceSize();

  const { isNotesModalOpen, closeCallback } = useSelector(
    state => state.displayCaution
  );

  const onOk = () => {
    closeCallback?.();
    dispatch(displayCautionActions.saveDisplayDate());
  };

  const {
    accountInfo,
  } = useSelector(state => state.account);
  // ログインしているか
  const isLogIn = !!accountInfo?.cardNumber;

  return (
    <ModalBase
      isModalOpen={isNotesModalOpen}
      className="mx-4 flex h-fit w-full max-w-[800px] flex-col pb-8 pt-12 text-navy sm:w-[560px] sm:pb-[60px] sm:pt-[62px] lg:w-full lg:pb-11 lg:pt-12"
    >
      <div className="absolute right-0 top-0 py-2 pr-2">
        <Icons.Image
          src={Icons.closeModal}
          className="float-right h-8 cursor-pointer lg:h-[50px]"
          onClick={() => dispatch(displayCautionActions.closeModal())}
        />
      </div>

      <div
        id="modal"
        className="scrollBar mr-2 overflow-y-scroll px-4 lg:px-24"
      >
        <div className="flex flex-row items-center justify-center gap-2 bg-calendar-sunday px-3 py-2 sm:py-4">
          <Icons.Image src={Icons.alert} />
          <p className="text-sm whitespace-pre-wrap font-bold text-red">
            {`以下の車両は作業できない場合があります。${
              isSp ? '' : '\n'
            }事前に店舗にご確認ください。`}
          </p>
        </div>
        {isSp ? (
          <>
            <div className="ml-3 mt-[3px] flex flex-col gap-y-[7px]">
              <div className="flex flex-row gap-x-1.5">
                <div className="text-[15px] font-bold">■</div>
                <div className="flex flex-col">
                  <label className="text-[15px] font-bold">輸入車</label>
                  <label className="text-[12px] font-medium text-navy-light">
                    交換部品の在庫がない場合があります。
                  </label>
                </div>
              </div>
              <div className="flex flex-row gap-x-1.5">
                <div className="text-[15px] font-bold">■</div>
                <div className="flex flex-col">
                  <label className="text-[15px] font-bold">ネット購入品</label>
                  <label className="text-[12px] font-medium text-navy-light">
                    適合していない、規格外の場合があります。
                  </label>
                </div>
              </div>
              <div className="flex flex-row gap-x-1.5">
                <div className="text-[15px] font-bold">■</div>
                <div className="flex flex-row items-center">
                  <label className="text-[15px] font-bold">8ナンバー</label>
                  <label className="text-[13px] font-bold">
                    (特種用途改造車)の車検
                  </label>
                </div>
              </div>
              <div className="flex flex-row gap-x-1.5">
                <div className="text-[15px] font-bold">■</div>
                <div className="flex flex-row items-center">
                  <label className="text-[15px] font-bold">1ナンバー</label>
                  <label className="text-[13px] font-bold">
                    (普通貨物自動車)の車検
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-4 flex flex-row gap-x-4 lg:gap-x-[42px]">
              <div className="flex flex-row gap-x-4 lg:items-center">
                <div className="w-24">
                  <Icons.Image src={Icons.imgImported} />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-bold">輸入車</label>
                  <label className="whitespace-pre-wrap text-[14px] font-medium text-navy-light">
                    交換部品の在庫が{isTablet ? '\n' : ''}ない{isPc ? '\n' : ''}
                    場合があります。
                  </label>
                </div>
              </div>
              <div className="flex flex-row gap-x-4 lg:items-center">
                <div className="w-24">
                  <Icons.Image src={Icons.imgEC} />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-bold">ネット購入品</label>
                  <label className="whitespace-pre-wrap text-[14px] font-medium text-navy-light">
                    適合していない、{'\n'}規格外の場合があります。
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-6 flex flex-row">
              <div className="flex flex-row gap-x-4 lg:items-center">
                <div className="w-[94px]">
                  <Icons.Image src={Icons.imgFreight} />
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className="text-sm font-bold">8ナンバー</label>
                    <label className="text-xs font-bold">
                      (特種用途改造車)の車検
                    </label>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className="text-sm font-bold">1ナンバー</label>
                    <label className="text-xs font-bold">
                      (普通貨物自動車)の車検
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="mt-6 flex flex-row items-center justify-center gap-2 bg-calendar-sunday px-3 py-2 sm:py-4">
          <Icons.Image src={Icons.forbid} />
          <p className="text-sm whitespace-pre-wrap font-bold text-red">
            {`以下の車両は車検を受付しておりません。${
              isSp ? '' : '\n'
            }ご了承ください。`}
          </p>
        </div>
        {isSp ? (
          <>
            <div className="ml-3 mt-[3px] flex flex-col gap-y-[7px]">
              <div className="flex flex-row gap-x-1.5">
                <div className="text-[15px] font-bold">■</div>
                <div className="flex flex-col">
                  <label className="text-[15px] font-bold">違法改造車</label>
                  <label className="text-[12px] font-medium text-navy-light">
                    保安基準、道路運送車両法に適合しない車。
                  </label>
                </div>
              </div>
              <div className="flex flex-row gap-x-1.5">
                <div className="text-[15px] font-bold">■</div>
                <div className="flex flex-row items-center">
                  <label className="text-[15px] font-bold">自動二輪車</label>
                </div>
              </div>
              <div className="flex flex-row gap-x-1.5">
                <div className="text-[15px] font-bold">■</div>
                <div className="flex flex-row items-center">
                  <label className="text-[15px] font-bold">特殊車両</label>
                  <label className="text-[13px] font-bold">
                    (福祉車両など)
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-4 flex flex-row sm:gap-x-4 lg:gap-x-2">
              <div className="flex flex-row gap-x-4 lg:items-center">
                <div className="w-24">
                  <Icons.Image src={Icons.imgModified} />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-bold">違法改造車</label>
                  <label className="whitespace-pre-wrap text-[14px] font-medium text-navy-light">
                    保安基準、道路運送車{isTablet ? '\n' : ''}両
                    {isPc ? '\n' : ''}
                    法に適合しない車。
                  </label>
                </div>
              </div>
              <div className="flex flex-row gap-x-4 lg:ml-[20px] lg:items-center">
                <div className="w-24">
                  <Icons.Image src={Icons.imaMotorcycle} />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-bold">自動二輪車</label>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-x-4 sm:items-center">
              <div className="w-24">
                <Icons.Image src={Icons.imgSpecial} />
              </div>
              <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="text-sm font-bold">特殊車両</label>
                <label className="text-xs font-bold">(福祉車両など)</label>
              </div>
            </div>
          </>
        )}

        <div className="mt-6 flex flex-row items-center justify-center gap-2 bg-calendar-sunday px-3 py-2 sm:py-4">
          <p className="text-sm whitespace-pre-wrap font-bold text-red">
            {`ご予約以外の作業を「その他」欄にご入力頂いても${
              isSp ? '' : '\n'
            }作業できない場合がございます。`}
          </p>
        </div>
        <div className="text-sm mt-2 px-3 font-medium text-navy sm:text-center lg:mt-6 lg:justify-center">
          <div>
          作業できない場合がありますので事前にご確認ください。
          </div>
          {
          isLogIn || (
          <div className="mt-5 sm:mt-2">
          ログインまたは新規登録後にご予約いただけます。
          </div>)
        }
        </div>
      </div>

      <div className="mt-8 flex items-center justify-center px-4 lg:px-24">
        <AprosButton
          comClassName="reserve"
          text="承諾して予約へ進む"
          className="w-full max-w-[320px] sm:w-[307px] lg:w-80"
          onClick={onOk}
        />
      </div>
    </ModalBase>
  );
};
export default NotesModal;
